import React, { useState, useEffect, useCallback } from 'react';

import { Tabs, Row } from 'antd';

import ClockSettings from './ClockSettings';
import GPSSettings from './GPSSettings';
import Payroll from './Payroll';
import Overtime from './Overtime';
import TimeCards from './TimeCards';
import BillingRates from './BillingRates';
import AutomaticBreaks from './AutomaticBreaks';
import CustomFields from './CustomFields';
import Exports from '../Exports/Exports';
import RoundingSettings from './RoundingSettings';

const tabStyle = { paddingTop: 7, overflowY: 'auto', height: '90vh' };

const { TabPane } = Tabs;

export default function ({
  settings,
  saveCompanySettings,
  users,
  positionNames,
  firstApprover,
  secondApprover,
  approverPositions,
  activeKey = 'clockSettings',
  createNuxEntry,
  nuxMap = {},
  nux = new Set(),
}) {
  const [stateKey, setStateKey] = useState(activeKey);

  const onChange = useCallback((tab) => {
    setStateKey(tab);
    if (tab in nuxMap && !nux.has(nuxMap[tab])) {
      createNuxEntry(nuxMap[tab]);
    }
  }, [setStateKey, nuxMap, nux, createNuxEntry]);

  useEffect(() => {
    setStateKey(activeKey);
  }, [ activeKey ]);
  return (
    <Row justify="start" style={{ height: '90%' }}>
      <Tabs
        defaultActiveKey={activeKey}
        tabPosition="left"
        tabBarStyle={{
          marginTop: 15,
          width: 135,
          marginRight: 17,
        }}
        style={{ width: '100%' }}
        activeKey={stateKey}
        onChange={onChange}
      >
        <TabPane style={tabStyle} tab="Clock Settings" key="clockSettings">
          <ClockSettings
            settings={settings}
            saveCompanySettings={saveCompanySettings}
          />
        </TabPane>
        <TabPane style={tabStyle} tab="GPS Location" key="gps">
          <GPSSettings
            settings={settings}
            saveCompanySettings={saveCompanySettings}
          />
        </TabPane>
        <TabPane style={tabStyle} tab="Payroll" key="payroll">
          <Payroll
            settings={settings}
            saveCompanySettings={saveCompanySettings}
          />
        </TabPane>
        <TabPane style={tabStyle} tab="Overtime" key="overtime">
          <Overtime
            settings={settings}
            saveCompanySettings={saveCompanySettings}
          />
        </TabPane>
        <TabPane style={tabStyle} tab="Time Cards" key="timeCards">
          <TimeCards
            settings={settings}
            saveCompanySettings={saveCompanySettings}
            users={users}
            positionNames={positionNames}
            firstApprover={firstApprover}
            secondApprover={secondApprover}
            approverPositions={approverPositions}
          />
        </TabPane>
        <TabPane style={tabStyle} tab="Rates" key="rates">
          <BillingRates visible={stateKey === 'rates'} />
        </TabPane>
        <TabPane style={tabStyle} tab="Automatic Breaks" key="autoBreaks">
          <AutomaticBreaks
            settings={settings}
            saveCompanySettings={saveCompanySettings}
          />
        </TabPane>
        <TabPane style={tabStyle} tab="Custom Fields" key="customFields">
          <CustomFields visible={stateKey === 'customFields'} />
        </TabPane>
        <TabPane style={tabStyle} tab="Exports" key="exports">
          <Exports visible={stateKey === 'exports'} type="timeEntries" />
        </TabPane>
        <TabPane style={tabStyle} tab="Rounding" key="rounding">
          <RoundingSettings
            visible={stateKey === 'rounding'}
            settings={settings}
            saveCompanySettings={saveCompanySettings}
          />
        </TabPane>
      </Tabs>
    </Row>
  );
}
