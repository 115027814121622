import React from 'react';
import { Select } from 'antd';

export default ({
  data,
  defaultValue,
  onChange,
  format = (value) => value,
  disabled = false
}) => (
  <Select
    style={{ marginRight: 56, minWidth: 120, textAlign:'right' }}
    defaultValue={defaultValue}
    disabled={disabled}
    onChange={onChange}
    >
      {data.map((value) => 
        <Select.Option value={value} key={value}>
          {format(value)}
        </Select.Option>
      )}
  </Select>
);