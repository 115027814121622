import axios from 'axios'; 

import { request } from '../../helpers/requests';

export default {
  getAddressBooks: () => request({
    call: axios.get('/contacts/addressBooks'),
    errMsg: 'Could not get address books',
    hideSuccessToast: true,
  }),
  deleteFromAddressBook: (id) => request({
    call: axios.delete(`/contacts/addressBooks/${id}`),
    errMsg: 'Could not delete entry from address book',
  }),
  updateAddressBook: (id, payload) => request({
    call: axios.put(`/contacts/addressBooks/${id}`, payload),
    errMsg: 'Could not update address book entry',
  }),
  addToAddressBook: (payload) => request({
    call: axios.post('/contacts/addressBooks', payload),
    errMsg: 'Could not add entry into address book',
  }),
};
