import React, {
  useMemo, useEffect, useState, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tree, Input } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';

import { getFileStructure } from '../../files/state/files.actions';

import Analytics from '../../helpers/Analytics';
import { constructTreeData } from '../../helpers/fileHelpers';

const fileGetter = axios.create({ responseType: 'blob' });

const { DirectoryTree } = Tree;
export default function FilePicker(props) {
  const {
    shouldDownload,
    onSelect,
    className,
    onlyFolders,
    selectedKeys = [],
  } = props;

  const dispatch = useDispatch();
  const fileStructure = useSelector((state) => state.files.fileStructure);
  const rootFiles = useSelector((state) => state.files.rootFiles);
  const [searchTerm, setSearchTerm] = useState();
  const onSearchChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchTerm(value);
  }, []);

  const onFileSelect = useCallback(async (files, { node: { title: name, id } = {} }) => {
    const [path] = files;
    if (onlyFolders) {
      return onSelect(path);
    }

    const url = path.substring(0, path.length - 1); // Has trailing slash
    const { data: signedURL } = await axios.get(`/download/files/${url}`);
    if (shouldDownload) {
      const { data: blob, headers: { 'content-type': type } } = await fileGetter.get(signedURL);
      const file = new File([blob], name, { type });
      file.id = id;
      file.existing = true;
      onSelect(file);
      Analytics.track('Files/SelectExisting', { fileType: file.type, size: file.size });
    } else {
      const { headers: { 'content-type': type } } = await fileGetter.get(signedURL, { headers: { Range: 'bytes=0-1' } });
      onSelect({
        url: signedURL, name, id, type, existing: true, fullPath: url,
      });
      Analytics.track('Files/SelectExisting', { fileType: type });
    }
  }, [onSelect, onlyFolders, shouldDownload]);

  const treeData = useMemo(() => (
    [constructTreeData({
      id: 'root',
      fileStructure,
      rootFiles,
      name: 'Files/',
      selectable: false,
      searchTerm,
      onlyFolders,
    })]
  ), [fileStructure, rootFiles, searchTerm, onlyFolders]);
  useEffect(() => {
    if (dispatch) dispatch(getFileStructure());
  }, [dispatch]);
  return (
    <div className={className}>
      <Input.Search
        className="searchbar"
        placeholder="Search"
        onChange={onSearchChange}
        allowClear
      />
      <div style={{ overflow: 'scroll', height: 'calc(100% - 32px)', paddingTop: 10 }}>
        <DirectoryTree
          treeData={treeData}
          selectedKeys={selectedKeys}
          defaultExpandAll
          onSelect={onFileSelect}
        />
      </div>
    </div>
  );
}

FilePicker.propTypes = {
  shouldDownload: PropTypes.bool,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  onlyFolders: PropTypes.bool,
  selectedKeys: PropTypes.arrayOf(PropTypes.string),
};

FilePicker.defaultProps = {
  shouldDownload: false,
  onSelect: () => {},
  className: '',
  onlyFolders: false,
  selectedKeys: [],
};
