import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Form, Select } from 'antd';
import FormTextInput from '../common/inputs/FormTextInput';
import { cardTitleValidator } from './boardHelpers';
import FormBuilder from '../forms/FormBuilder/FormBuilder';
import BoardStatusConfigure from './BoardStatusConfigure';
import StatusList from './StatusList';

function BoardTemplateForm({
  visible,
  form,
  isDisplay,
  selectedTemplate = {},
  sections,
  onCardChanged,
  style,
}) {
  const {
    id,
    name,
    description,
    cardTypeId,
    statuses = [],
  } = selectedTemplate;

  const cardTemplates = useSelector((state) => state.boards.cardTemplates);

  const {
    [cardTypeId]: selectedCard = {},
  } = cardTemplates;

  const cardTitleMap = useMemo(() => {
    const titleMap = {};
    Object.values(cardTemplates).forEach((card) => {
      const { title: existingTitle = '' } = card;
      titleMap[existingTitle.toLowerCase()] = card;
    });
    return titleMap;
  }, [cardTemplates]);

  const cardTemplateOptions = useMemo(() => (
    Object.values(cardTemplates).map((card) => ({
      label: card.title, value: card.id,
    }))
  ), [cardTemplates]);

  const [cardTypeIdValue, setCardTypeIdValue] = useState(cardTypeId);

  const cardTypeSections = useMemo(() => {
    if (!cardTypeIdValue) return [];
    const {
      [cardTypeIdValue]: {
        fields = [],
      } = {},
    } = cardTemplates;
    return fields;
  }, [cardTypeIdValue, cardTemplates]);

  useEffect(() => {
    if (visible && cardTypeId) {
      setCardTypeIdValue(cardTypeId);
    }
  }, [cardTypeId, visible]);

  useEffect(() => {
    if (!visible) {
      setCardTypeIdValue(false);
    }
  }, [visible]);

  const onFormValuesChanged = useCallback((values) => {
    const {
      cardTypeId: newCardTypeId,
    } = values;
    if (Object.keys(values).find((field) => field === 'cardTypeId')) {
      setCardTypeIdValue(newCardTypeId);
      const statuses = form.getFieldValue('statuses') || [];
      const newStatuses = statuses.map((status) => ({
        ...status,
        displayFields: null,
      }));
      form.setFieldsValue({
        statuses: newStatuses,
      });
    }
  }, [form]);

  const titleValidator = useCallback(cardTitleValidator(id, cardTitleMap), [id, cardTitleMap]);

  const cardTemplateForm = useMemo(() => (
    <Form.Item
      label="Card"
      name="card"
      labelCol={{ style: { marginTop: 10, marginBottom: -10, } }}
    >
      <FormBuilder
        isDisplay={cardTypeIdValue ? true : isDisplay}
        onSectionsChanged={onCardChanged}
        initialSections={cardTypeIdValue ? cardTypeSections : sections}
        sectionsScrollable={false}
        enablePermissions
        isBoardCards
        style={{
          position: 'relative',
          pointerEvents: cardTypeIdValue ? 'none' : null,
          marginTop: 10,
          inset: 0,
        }}
      />
    </Form.Item>
  ), [cardTypeIdValue, isDisplay, onCardChanged, cardTypeSections, sections])

  return (
    <Form
      form={form}
      onValuesChange={onFormValuesChanged}
      layout="vertical"
      initialValues={{
        ...selectedTemplate,
        card: selectedCard,
      }}
      style={{
        overflowY: 'auto',
        position: 'absolute',
        left: 24,
        right: 24,
        top: 55,
        bottom: 53,
        ...style,
      }}
    >
      <FormTextInput
        isNotDisplay={!isDisplay}
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}
        value={name}
      />
      <FormTextInput
        isNotDisplay={!isDisplay}
        label="Description"
        name="description"
        textarea
        rules={[{ required: true, message: 'Description is required' }]}
        value={description}
      />

      <Form.Item
        name="cardTypeId"
        label="Card Type"
        style={{ marginBottom: 0, marginTop: 5 }}
      >
        <Select
          showSearch
          options={cardTemplateOptions}
          optionFilterProp="label"
          placeholder="New Card Type"
          allowClear
        />
      </Form.Item>

      {!cardTypeIdValue ? (
        <>
          <FormTextInput
            name="cardTitle"
            label="Card Title"
            rules={[
              { required: true, message: 'Title is required' },
              { validator: titleValidator },
            ]}
            isNotDisplay={!isDisplay}
          />
          { cardTemplateForm }
        </>
      ) : (
        <>
          { cardTemplateForm }
        </>
      )}

      <Form.Item
        label="Statuses"
        name="statuses"
      >
        {isDisplay ? (
          <StatusList statuses={statuses} />
        ) : (
          <BoardStatusConfigure
            cardTypeId={cardTypeIdValue}
            isTemplate
          />
        )}
      </Form.Item>
    </Form>
  );
}

export default BoardTemplateForm;
