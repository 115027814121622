import React, { useRef, useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  Form,
  Col,
  Row,
  Select,
  Tag,
  Switch,
  DatePicker,
} from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import DisplayText from '../common/text/DisplayText';
import DivisionSelector from '../common/inputs/DivisionSelector';
import FileReelWithUpload from '../common/files/FileReelWithUpload';
import BorderlessButton from '../common/buttons/BorderlessButton';
import OnTraccrDatePicker from '../common/inputs/OnTraccrDatePicker';

import {
  reformatRemindersFromValues,
  timestampToPosition,
} from './scheduleHelpers';
import ScheduleUserSelector from './ScheduleUserSelector';
import ScheduleEventForm from './ScheduleEventForm';

import { toggleShiftView, getShiftGroup } from './state/schedule.actions';

import { getIdMap, isNullOrUndefined } from '../helpers/helpers';
import { validateRepeatEndDate } from '../helpers/subtaskHelpers';
import { dateRangeValidator } from './scheduleHelpers';

import config from '../config';
import ScheduleDateRepeatPopover from './ScheduleDateRepeatPopover';
import FormColorPicker from '../common/inputs/FormColorPicker';
import DynamicRemindersField from './reminder/DynamicRemindersField';
import { REMINDER_OPTIONS } from './reminder/reminder.constants';
import { downloadFiles } from '../helpers/fileHelpers';

const formLabelStyle = {
  style:{
    paddingBottom:5,
    marginTop:5,
  },
};

const updateForm = ({ ref, data }) => {
  if(!ref) return;
  ref.setFieldsValue(data);
};

const basicOptions = (arr = [], extractor = (item) => item.name) => (
  arr.map((item) => (
    <Select.Option key={item.id} label={extractor(item)} value={item.id}>
      {extractor(item)}
    </Select.Option>
  ))
);

const getFormView = ({ isDisplay, value, input }) => {
  if(isDisplay) return <DisplayText title={value}/>
  return input;
};

const getFromMap = ({
  id,
  idMap,
  formatter = (item) => item.name,
  defaultValue = null,
}) => id in idMap ? formatter(idMap[id]) : defaultValue;

const ScheduleDrawer = function ScheduleDrawer({
  isNew,
  visible,
  onClose,
  onSave,
  onDelete,
  shift,
  isDisplay,
  openFormDetail,
}) {
  const {
    id,
    title,
    day,
    startTime,
    endTime,
    projectId,
    phaseId,
    costcodeId,
    description,
    divisionId,
    users = [],
    files: shiftFiles,
    formId,
    isEvent: shiftIsEvent,
    formTemplateId,
    formTemplateName,
    formNumber,
    color,
    reminders = [],
    repeat,
    repeatEndDate,
    groupId,
    shouldLockClockIn = false,
  } = shift || {};
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const divisions = useSelector(state => state.settings.divisions);

  const {
    activeUsers,
    userMap,
   } = useSelector((state) => {
    const {
      users: {
        users: stateUsers = [],
      } = {},
    } = state;
    return {
      activeUsers: stateUsers.filter(user => user.active),
      userMap: getIdMap(stateUsers),
    };
  });

  const {
    activeProjects,
    projectMap,
   } = useSelector((state) => {
    const {
      projects: {
        projects: stateProjects = [],
      } = {},
    } = state;
    return {
      activeProjects: stateProjects.filter(project => project.active),
      projectMap: getIdMap(stateProjects),
    };
  });

  const {
    activePhases,
    activeCostcodes,
    costcodeMap,
  } = useSelector((state) => {
    const {
      costcodes: {
        phases: statePhases = [],
        costcodes: stateCostcodes = [],
      } = {},
    } = state;
    return {
      activePhases: statePhases,
      activeCostcodes: stateCostcodes.filter(cc => cc.active),
      costcodeMap: getIdMap(stateCostcodes),
    }
  });

  const groupShifts = useSelector((state) => state.schedule.groupShifts);
  const originalShift = useMemo(() => {
    if (!groupId || !groupShifts) return false;
    const list = Object.values(groupShifts);
    const ourShifts = list.filter((s) => s.groupId === groupId);
    const orderedShifts = ourShifts.sort((a, b) => (a.startTime - b.startTime));
    return orderedShifts[0];
  }, [groupShifts, groupId]);

  const formRef = useRef(null);
  const [files,setFiles] = useState([]);
  const [selectedProject,setSelectedProject] = useState(projectId);
  const [selectedPhase,setSelectedPhase] = useState(phaseId === null && costcodeId ? 'Unphased' : phaseId);
  const [selectedCC,setSelectedCC] = useState(costcodeId);
  const [dateRange,setDateRange] = useState();
  const [selectedDivision,setSelectedDivision] = useState(divisionId);
  const [isEvent, setIsEvent] = useState(shiftIsEvent);
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [repeatValue, setRepeatValue] = useState(repeat);

  const repeatStartDate = useMemo(() => {
    // Use start date for individual and new new shifts.
    if (!originalShift || !originalShift.startTime) {
      return dateRange ? dateRange[0] : null;
    }
    // Otherwise use original task's start
    return originalShift.startTime || null;
  }, [originalShift, dateRange]);

  const onFormClick = useCallback(() => {
    if (!formId) return;
    openFormDetail(formId);
  },[formId,openFormDetail]);

  const onUserClick = useCallback(async (userId) => {
    if(!formRef || !formRef.current) return;
    const values = await formRef.current.getFieldsValue();
    const { users: formUsers = [] } = values;
    const uniqueUsers = new Set(formUsers);
    uniqueUsers.add(userId);
    updateForm({ ref: formRef.current, data: { users: Array.from(uniqueUsers) } });
  },[formRef.current])

  const onProjectSelect = useCallback((selectedId) => {
    if(selectedId === selectedProject) return;
    setSelectedProject(selectedId);
    setSelectedPhase();
    setSelectedCC();
  },[selectedProject]);
  const onPhaseSelect = useCallback((selectedId) => {
    if(selectedId === selectedPhase) return;
    setSelectedPhase(selectedId);
    setSelectedCC();
  },[selectedPhase]);

  /**
   * Removes undefined/null reminders
   * @param {array} reminders
   * @returns {array}
   */
  const refineReminders = (reminders = []) => reminders.filter((reminder) => reminder);

  const onSaveClicked = useCallback(async () => {
    if (!formRef || !formRef.current) return;
    try {
      await formRef.current.validateFields();
      const values = await formRef.current.getFieldsValue();
      const {
        phaseId: newPhaseId,
        dateRange:[newStart,newEnd] = [],
        color: newColor = null,
        reminders: newReminders,
        repeatEndDate: newRepeatEndDate,
      } = values;

      // Create new shift:
      const newShift = {
        ...values,
        reminders: refineReminders(newReminders),
        startTime: newStart,
        endTime: newEnd,
        top: timestampToPosition(newStart, day),
        bottom: timestampToPosition(newEnd, day),
        phaseId: newPhaseId === 'Unphased' ? null : newPhaseId,
        color: newColor,
        formData,
      };

      if (newRepeatEndDate && moment.isMoment(newRepeatEndDate)) {
        delete newShift.repeatEndDate;
        newShift.repeatEndDate = newRepeatEndDate.startOf('day').valueOf();
      }

      setIsLoading(true);
      if (await onSave(newShift)) {
        setFiles([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      Sentry.withScope(() => {
        Sentry.captureException(err);
      });
    }
  }, [
    formRef,
    onSave,
    day,
    formData,
  ]);

  const onFormValuesChanged = useCallback((_,values) => {
    const {
      dateRange: newDateRange = [],
      isEvent: formIsEvent,
      repeat: newRepeat,
    } = values;
    setIsEvent(formIsEvent);
    if (newRepeat) {
      setRepeatValue(newRepeat);
    }
    if (!newDateRange || newDateRange.length < 2) return;
    const [newStart,newEnd] = newDateRange;
    if(!dateRange || dateRange[0] !== newStart || dateRange[1] !== newEnd) setDateRange([newStart, newEnd]);
  },[dateRange]);


  useEffect(() => {
    if (visible && formRef && formRef.current && !isNullOrUndefined(startTime) && !isNullOrUndefined(endTime)) {
      const newRange = [startTime,endTime];
      setDateRange(newRange);
      formRef.current.setFieldsValue({ dateRange: newRange });
    }

  },[startTime,endTime, visible, formRef]);

  useEffect(() => {
    const getURLS = async () => {
      const parsedFiles = await downloadFiles(shiftFiles);
      setFiles(parsedFiles);
    };
    if(shiftFiles && Array.isArray(shiftFiles)) {
      getURLS();
    } else {
      setFiles([]);
    }
  },[shiftFiles]);

  useEffect(() => {
    if (groupId) {
      dispatch(getShiftGroup(groupId));
    }
  }, [groupId]);

  useEffect(() => {
    setSelectedDivision(divisionId);
  }, [divisionId]);
  useEffect(() => {
    setIsEvent(shiftIsEvent);
  }, [shiftIsEvent]);
  useEffect(() => {
    setRepeatValue(repeat);
  }, [repeat]);

  // Disabling react-hooks/exhaustive-deps
  // ESLint throws a warning saying we dont need formRef.current in the deps array
  // But without it, the form is not updated correctly.
  useEffect(() => {
    updateForm({  ref: formRef.current, data: { projectId: selectedProject, }});
  },[selectedProject, formRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateForm({ ref: formRef.current, data: { phaseId: selectedPhase, }});
  },[selectedPhase, formRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateForm({ ref: formRef.current, data: { costcodeId: selectedCC, }});
  },[selectedCC, formRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const {
      [selectedProject]: {
        divisionId: projectDivisionId,
      } = {},
    } = projectMap;
    if(!config.showDivisions) return;
    if(projectDivisionId !== selectedDivision) {
      updateForm({
        ref: formRef.current,
        data: { projectId: null, phaseId: null, costcodeId: null, }
      });
    }
    if(selectedDivision && formRef && formRef.current) {
      const {
        [selectedDivision]: {
          users: divisionUsers = new Set(),
        } = {},
      } = divisions;
      const formUsers = formRef.current.getFieldValue('users') || [];

      const ourUsers = formUsers
        .map((userId) => userMap[userId])
        .filter((user) => user);
      updateForm({
        ref: formRef.current,
        data: {
          users: ourUsers
            .filter((user) => divisionUsers.has(user.id))
            .map((user) => user.id)
        },
      });
    }
  },[selectedDivision, divisions, selectedProject, projectMap, userMap, formRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(toggleShiftView(visible));
  },[dispatch,visible]);

  useEffect(() => {
    if (visible) {
      setSelectedProject(projectId);
      const ourPhase = phaseId === null && costcodeId ? 'Unphased' : phaseId;
      setSelectedPhase(ourPhase);
      setSelectedCC(costcodeId);
    } else {
      setSelectedProject();
      setSelectedPhase();
      setSelectedCC();
      setDateRange();
      setRepeatValue();
    }
  }, [visible, projectId, phaseId, costcodeId ]);

  const divProjects = useMemo(() => {
    if(!config.showDivisions) return activeProjects;
    return activeProjects.filter((project) => project.divisionId === selectedDivision);
  },[activeProjects,selectedDivision]);

  const divUsers = useMemo(() => {
    if(!config.showDivisions) return activeUsers;
    const {
      [selectedDivision]:{
        users: divisionUsers = new Set(),
      } = {},
    } = divisions;
    return activeUsers.filter((user) => divisionUsers.has(user.id));
  },[activeUsers,divisions,selectedDivision]);

  const {
    phases,
    phaseMap: phaseIdMap,
  } = useMemo(() => {
    const ccIdMap = getIdMap(activeCostcodes);
    const unphasedCC = activeCostcodes
      .filter(
        (cc) => cc.projectId === selectedProject
        && cc.phased === false,
      );
    const relevantPhases = activePhases
      .filter((phase) => phase.projectId === selectedProject);
    const phaseMap = {};
    if (unphasedCC.length > 0) {
      phaseMap.Unphased = {
        id: 'Unphased',
        name: 'Unphased',
        costcodes: unphasedCC,
      };
    }
    relevantPhases.forEach((phase) => {
      const newPhase = { ...phase };
      delete newPhase.costcodeId;
      if(!(phase.id in phaseMap)) {
        phaseMap[phase.id] = {
          ...newPhase,
          costcodes: [],
        };
      }
      if(phase.costcodeId && phase.costcodeId in ccIdMap) {
        phaseMap[phase.id].costcodes.push(ccIdMap[phase.costcodeId]);
      }
    });
    return {
      phases: Object.values(phaseMap),
      phaseMap,
    };
  },[selectedProject, activePhases, activeCostcodes]);

  const costcodes = useMemo(() => {
    if(!selectedPhase) return [];
    const {
      [selectedPhase]: {
        costcodes: phaseCC = [],
      } = {}
    } = phaseIdMap;
    return phaseCC;
  },[selectedPhase, phaseIdMap]);

  const getNewShiftReminders = () => {
    let newShiftReminders = [REMINDER_OPTIONS.DEFAULT_REMINDER];
    try {
      const lastReminderConfig = JSON.parse(window.sessionStorage.getItem('lastReminderConfig'));
      if (lastReminderConfig) newShiftReminders = reformatRemindersFromValues(lastReminderConfig);
    } catch (error) {
      console.error(error);
    }
    return newShiftReminders;
  };

  const article = isEvent ? 'an' : 'a';
  const type = isEvent ? 'Event' : 'Shift';

  const showRepeatStart = repeatValue
    && repeatStartDate
    && originalShift
    && originalShift.id !== id;
  const startDateDT = (repeatStartDate && Number.isInteger(repeatStartDate))
    ? DateTime.fromMillis(repeatStartDate)
    : null;
  const startDateText = startDateDT
    ? startDateDT.toLocaleString(DateTime.DATE_MED)
    : 'Not Set';

  const endDateText = (repeatEndDate && Number.isInteger(repeatEndDate))
    ? DateTime.fromMillis(repeatEndDate).toLocaleString(DateTime.DATE_MED)
    : 'Not Set';
  const parsedRepeatEndDate = useMemo(() => (
    repeatEndDate ? moment(repeatEndDate) : undefined
  ), [
    repeatEndDate,
  ]);
  const repeatEndValidator = useCallback(() => {
    if (!formRef || !formRef.current) return Promise.reject();
    const values = formRef.current.getFieldsValue();
    const {
      dateRange: [newStartTime] = [],
      repeat: newRepeat,
      repeatEndDate: newRepeatEndDateMoment,
    } = values;
    if (!newRepeat) {
      return Promise.resolve();
    }
    const newRepeatEndDate = newRepeatEndDateMoment && moment.isMoment(newRepeatEndDateMoment)
      ? newRepeatEndDateMoment.valueOf()
      : null;
    return validateRepeatEndDate({
      startDate: newStartTime,
      repeat: newRepeat,
      repeatEndDate: newRepeatEndDate,
      type: 'Start Time',
    });
  }, [formRef]);

  return (
    <Drawer
      title={title || `Schedule ${article} ${type}`}
      width={675}
      visible={visible}
      maskClosable={false}
      onClose={onClose}
      push={{ distance: 500 }}
      style={{
        zIndex: 150,
      }}
    >
      <Form
        layout='vertical'
        key={id + files.length + visible}
        ref={formRef}
        style={{ paddingBottom: 50 }}
        onValuesChange={onFormValuesChanged}
        initialValues={{
          isEvent: shiftIsEvent,
          dateRange,
          reminders: isNew ? getNewShiftReminders() : reformatRemindersFromValues(reminders),
        }}
      >
        <Form.Item
          name='isEvent'
          label={type}
          className='schedule-form-item'
          initialValue={!!isEvent}
          valuePropName='checked'
          labelCol={{ style: { marginTop: -20 }}}
        >
          {!isDisplay && <Switch/>}
        </Form.Item>
        <Form.Item
          name='title'
          label='Title'
          className='schedule-form-item'
          labelCol={formLabelStyle}
          initialValue={title}
          rules={[{ required: !isDisplay, message: 'Please enter a title' }]}
        >
          {getFormView({
            isDisplay,
            value: title,
            input: <OnTraccrTextInput placeholder='Add a title'/>
          })}
        </Form.Item>

        {config.showDivisions &&
          <Form.Item
            name='divisionId'
            key='divisionId'
            label='Division'
            style={{ marginBottom:0 }}
            rules={[{ required: !isDisplay, message: 'Select a division' }]}
            valuePropName='divisionId'
            initialValue={divisionId}
            >
              <DivisionSelector
                displayMode={isDisplay}
                onChange={(newDivision) => setSelectedDivision(newDivision)}
              />
            </Form.Item>
          }
        <Form.Item
          name='dateRange'
          label='Dates'
          className='schedule-form-item'
          labelCol={formLabelStyle}
          rules={[
            { required: !isDisplay, message: 'Please select a start and end date' },
            { validator: dateRangeValidator }
          ]}
        >
          <OnTraccrDatePicker isDisplay={isDisplay}/>
        </Form.Item>
        <Form.Item
          name='repeat'
          className='schedule-form-item'
          style={{ marginTop: -10 }}
          initialValue={repeat}
        >
          <ScheduleDateRepeatPopover
            isDisplay={isDisplay}
            startTime={repeatStartDate}
            onChange={setRepeatValue}
          />
        </Form.Item>

        { showRepeatStart && (
          <Form.Item
            label="Repeat Start Date"
            style={{ marginTop: -10 }}
          >
            {
            isDisplay
              ? (
                <DisplayText
                  label="Repeat Start Date"
                  title={startDateText}
                />
              )
              : (
                <DatePicker
                  label="Repeat Start Date"
                  format="MMM Do YY"
                  value={moment(repeatStartDate)}
                  disabled
                />
              )
            }
          </Form.Item>
        )}
        { repeatValue && (
          <Form.Item
            label="Repeat End Date"
            name="repeatEndDate"
            style={{ marginTop: -10 }}
            rules={[{
              required: !!repeatValue,
              validator: repeatEndValidator,
            }]}
            initialValue={parsedRepeatEndDate}
          >
            {isDisplay
              ? <DisplayText title={endDateText} />
              : <DatePicker format="MMM Do YY" allowClear />}
          </Form.Item>
        )}

        {!isEvent && <DynamicRemindersField isDisplay={isDisplay} /> }
      {!isEvent && <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            name='projectId'
            label={t('Project')}
            className='schedule-form-item'
            labelCol={formLabelStyle}
            initialValue={projectId}
          >
            {getFormView({
              isDisplay,
                value: getFromMap({ id: projectId, idMap: projectMap }),
                input:  (
                  <Select
                    placeholder={`Select a ${t('Project')}`}
                    optionLabelProp='label'
                    optionFilterProp='label'
                    showSearch
                    onSelect={onProjectSelect}
                    onChange={(selected) => {
                      if(!selected) {
                        setSelectedPhase();
                        setSelectedCC();
                      }
                    }}
                    allowClear
                  >
                    {basicOptions(divProjects)}
                  </Select>
                ),
              })}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name='phaseId'
            label='Phase'
            className='schedule-form-item'
            labelCol={formLabelStyle}
            initialValue={phaseId}
          >
            {getFormView({
              isDisplay,
              value: getFromMap({
                id: phaseId,
                idMap: phaseIdMap,
                defaultValue: costcodeId ? 'Unphased' : null,
              }),
              input:  (
                <Select
                  placeholder='Select a Phase'
                  optionLabelProp='label'
                  optionFilterProp='label'
                  showSearch
                  disabled={!selectedProject}
                  onSelect={onPhaseSelect}
                  onChange={(selected) => {
                    if(!selected) setSelectedCC();
                  }}
                  allowClear
                >
                  {basicOptions(phases)}
                </Select>
              ),
            })}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name='costcodeId'
            label='Costcode'
            className='schedule-form-item'
            labelCol={formLabelStyle}
            initialValue={costcodeId}
          >
            {getFormView({
              isDisplay,
              value: getFromMap({
                id: costcodeId,
                idMap: costcodeMap,
                formatter: (cc) => `${cc.code} - ${cc.name}`
              }),
              input:  (
                <Select
                  placeholder='Select a Cost Code'
                  optionLabelProp='label'
                  optionFilterProp='label'
                  showSearch
                  disabled={!selectedProject || !selectedPhase}
                  onSelect={(selectedId) => setSelectedCC(selectedId)}
                  allowClear
                >
                  {basicOptions(costcodes, ({ code, name }) => `${code} - ${name}`)}
                </Select>
              ),
            })}
          </Form.Item>
        </Col>
      </Row>}
      <Form.Item
        name='color'
        label='Color'
        style={{ marginBottom: isDisplay ? 0 : 10, maxWidth: 150 }}
        labelCol={{
          style:{
            paddingBottom:0,
            marginTop:5,
          },
        }}
        initialValue={color || undefined}
      >
        <FormColorPicker isNotDisplay={!isDisplay} allowClear={true}/>
      </Form.Item>
      <Form.Item
        name='users'
        label='Users'
        className='schedule-form-item'
        labelCol={formLabelStyle}
        initialValue={users}
      >
        {
          isDisplay ?
            users.map((userId) =>
              <Tag key={userId}>{getFromMap({ id: userId, idMap: userMap })}</Tag>
            )
          : <ScheduleUserSelector users={divUsers} onUserClick={onUserClick} dateRange={dateRange}/>
        }
      </Form.Item>
        {!isEvent && <Form.Item
          name='description'
          label='Description'
          className='schedule-form-item'
          labelCol={formLabelStyle}
          initialValue={description}
        >
           {getFormView({
              isDisplay,
              value: description,
              input:  <OnTraccrTextInput textarea/>
            })}
        </Form.Item>}
        {!isEvent && (
          <Form.Item
            name='shouldLockClockIn'
            label="Should Lock Clock In Details"
            className='schedule-form-item'
            initialValue={!!shouldLockClockIn}
            valuePropName='checked'
            labelCol={{ style: { marginTop: 20 }}}
          >
            {!isDisplay && <Switch/>}
          </Form.Item>
        )}

        {formId && <Form.Item
          label='Form'
          className='schedule-form-item'
          labelCol={formLabelStyle}
        >
          <BorderlessButton
            onClick={onFormClick}
            style={{ textAlign: 'left' }}
            title={
              <span>
                <FormOutlined style={{ marginRight: 10 }}/> {`${formTemplateName}${formNumber ? ` - ${formNumber}` : ''}`}
              </span>
            }
          />
        </Form.Item>}

        {!isEvent && <Form.Item
          name='files'
          label='Attachments'
          className='schedule-form-item'
          labelCol={formLabelStyle}
          initialValue={files}
          valuePropName='files'
        >
          <FileReelWithUpload
            isDisplay={isDisplay}
          />
        </Form.Item>}

        {
          isEvent ?
            <ScheduleEventForm
              isDisplay={isDisplay}
              initialValue={formTemplateId}
              formRef={formRef}
              onFormDataChanged={setFormData}
              shiftId={id}
              isNew={isNew}
            />
          : null
        }

      </Form>
      {!isDisplay && <div className='drawer-footer'>
        <Row justify='space-between' gutter={10}>
          <Col>
            {!isNew && <OnTraccrButton
              title='Delete'
              type='back'
              onClick={() => onDelete(shift)}
            />}
          </Col>
          <Col>
            <Row>
              <OnTraccrButton
                title='Cancel'
                type='cancel'
                style={{ marginRight: 8 }}
                onClick={onClose}
              />
              <OnTraccrButton
                title='Save'
                htmlType='submit'
                loading={isLoading}
                onClick={onSaveClicked}
              />
            </Row>
           </Col>
        </Row>
      </div>}
    </Drawer>
  );
};

export default ScheduleDrawer;
