import * as XLSX from 'xlsx';
import moment from 'moment';
import { DateTime } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';

import { roundTotalRuntime } from '../helpers/time';
import { toTitleCase } from '../helpers/helpers';

const formatDate = (timestamp) => {
  if (!timestamp) return '';
  return moment(timestamp).format('MMM Do YYYY');
};
const formatTime = (timestamp, zone) => {
  if (!timestamp) return '';
  return DateTime.fromMillis(timestamp, { zone })
    .toLocaleString({
      hour: '2-digit',
      minute: 'numeric',
      timeZoneName: 'short',
    });
};

const formatRuntime = (duration) => {
  const milliseconds = parseInt((duration % 1000) / 100, 10);
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? `0${hours}` : hours;
  minutes = (minutes < 10) ? `0${minutes}` : minutes;
  seconds = (seconds < 10) ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const createDayRow = ({
  dayRows = [],
  lastDay,
  timeSummary,
  roundingInterval,
  firstWrite,
  roundingType,
  roundingSetting,
}) => {
  let rows = [];
  const runtime = formatRuntime(
    roundTotalRuntime(timeSummary, roundingInterval, roundingType, roundingSetting)
  );
  const [hours, minutes] = runtime.split(':');
  if (!firstWrite) rows.push([]);
  rows.push([`SUMMARY: ${lastDay.toUpperCase()}`, '', '', '', '', `${hours}:${minutes}`]);
  rows = rows.concat(dayRows);
  return rows;
};

const getBaseExcelHeader = (t) => [
  'DATE',
  'NAME',
  `${t('Project').toUpperCase()} NAME`,
  'COST CODE',
  'PHASE',
  'RUNTIME',
  'START TIME',
  'END TIME',
  'BREAK START TIME',
  'BREAK END TIME',
  'OVERTIME START TIME',
  'OVERTIME END TIME',
  'DT OVERTIME START TIME',
  'DT OVERTIME END TIME',
  'TYPE',
  'APPROVAL',
  'NOTE',
];

export default ({
  t,
  user,
  tasks,
  start,
  end,
  regularHours,
  otHours,
  doubleOtHours,
  saturdayHours,
  saturdayOTHours,
  saturdayDoubleOTHours,
  sundayHours,
  sundayOTHours,
  sundayDoubleOTHours,
  totalPay,
  roundingInterval,
  roundingType,
  roundingSetting,
  isSummary,
  userMap = {},
}) => {
  const title = `${user} ${formatDate(start)} - ${formatDate(end)} Timecard Breakdown`;
  const workbook = XLSX.utils.book_new();
  const showSaturday = !isSummary && (saturdayHours || saturdayOTHours || saturdayDoubleOTHours);
  const showSunday = !isSummary && (sundayHours || sundayOTHours || sundayDoubleOTHours);
  const firstRow = ['Employee:', user, '', formatDate(start), '-', formatDate(end)];
  if (!isSummary) {
    firstRow.push(...['REGULAR:', 'OVERTIME:', 'DOUBLE OVERTIME:', 'TOTAL PAY:']);
  }
  let rows = [
    firstRow,
    !isSummary && ['', '', '', '', '', '', regularHours, otHours, doubleOtHours, totalPay],
    showSaturday && ['', '', '', '', '', '', 'SATURDAY HOURS:', 'SATURDAY OT HOURS:', 'SATURDAY DT OT HOURS:'],
    showSaturday && ['', '', '', '', '', '', saturdayHours, saturdayOTHours, saturdayDoubleOTHours],
    showSunday && ['', '', '', '', '', '', 'SUNDAY HOURS:', 'SUNDAY OT HOURS:', 'SUNDAY DT OT HOURS:'],
    showSunday && ['', '', '', '', '', '', sundayHours, sundayOTHours, sundayDoubleOTHours],
    [],
    getBaseExcelHeader(t),
  ].filter(Boolean);

  let lastDay = null;
  let firstWrite = true;
  let dayRows = [];
  let timeSummary = 0;
  let lastUser = null;
  tasks.forEach((task, idx) => {
    const {
      projectName,
      costCode,
      phase,
      startTime,
      endTime,
      breakStartTime,
      breakEndTime,
      otStartTime,
      otEndTime,
      doubleOTStartTime,
      doubleOTEndTime,
      state,
      note,
      type,
      hourBased,
      timezone,
      userId,
    } = task;
    const timestamp = TaskHelpers.getEndTime(task) ?? TaskHelpers.getStartTime(task);
    const runtimes = TaskHelpers.getRuntimes(task);
    const runtime = runtimes.regularTime + runtimes.overtime + runtimes.doubleOT;
    const dayKey = formatDate(timestamp);
    const ourUsername = userMap[userId]?.name;
    if (dayKey !== lastDay || userId !== lastUser) {
      if (dayRows.length > 0 && timeSummary) {
        rows = rows.concat(createDayRow({
          dayRows,
          lastDay,
          timeSummary,
          roundingInterval: roundingInterval || 1,
          firstWrite,
          roundingType,
          roundingSetting,
        }));
        firstWrite = false;
      }
      lastUser = userId;
      lastDay = dayKey;
      dayRows = [];
      timeSummary = 0;
    }
    dayRows.push([
      dayKey,
      ourUsername,
      projectName,
      costCode,
      phase,
      formatRuntime(runtime),
      hourBased ? '' : formatTime(startTime, timezone),
      hourBased ? '' : formatTime(endTime, timezone),
      hourBased ? '' : formatTime(breakStartTime, timezone),
      hourBased ? '' : formatTime(breakEndTime, timezone),
      hourBased ? '' : formatTime(otStartTime, timezone),
      hourBased ? '' : formatTime(otEndTime, timezone),
      hourBased ? '' : formatTime(doubleOTStartTime, timezone),
      hourBased ? '' : formatTime(doubleOTEndTime, timezone),
      toTitleCase(type),
      toTitleCase(state),
      note,
    ]);
    timeSummary += runtime;
    if (idx === tasks.length - 1 && dayRows.length > 0 && timeSummary) {
      rows = rows.concat(createDayRow({
        dayRows,
        lastDay,
        timeSummary,
        roundingInterval: roundingInterval || 1,
        firstWrite,
        roundingType,
        roundingSetting,
      }));
    }
  });

  rows.push([]);

  const sheet = XLSX.utils.aoa_to_sheet(rows);
  // Sheet name cannot exceed 31 char.
  XLSX.utils.book_append_sheet(workbook, sheet, title.slice(0, 31));
  XLSX.writeFile(workbook, `${title}.xlsx`);
};
