import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row, Col, Form, Divider, DatePicker, Typography, Spin, Select, Switch,
} from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// Import Components:
import FormCurrencyInput from '../../common/inputs/FormCurrencyInput';
import FormTextInput from '../../common/inputs/FormTextInput';
import OnTraccrSimpleDatePicker from '../../common/inputs/OnTraccrSimpleDatePicker';
import ContactSelector from '../../common/inputs/ContactSelector';
import FormTreeSelectInput from '../../common/inputs/FormTreeSelectInput';

import InvoiceAmountDistributor from './InvoiceAmountDistributor';
import InvoiceStatusSelector from './InvoiceStatusSelector';
import FileDestinationSelect from './FileDestinationSelect';

// Import Helpers/Constants
import { PAYABLE_FORM_TYPES } from '../payables.constants';
import { getFormattedCurrency, getFormattedDate } from '../../forms/formHelpers';
import { INVOICE_PROP } from './invoiceConstants';
import { getIdMap, sortByNumber } from '../../helpers/helpers';
import {
  getValueFormsQuery,
  getValueFormTitle,
} from './invoiceHelpers';

import { getForms } from '../../forms/state/forms.actions';
import ProjectSelector from '../../common/inputs/ProjectSelector';
import DisplayText from '../../common/text/DisplayText';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const TypeOptions = [
  { label: 'Expense', value: 'purchase' },
  { label: 'Bill', value: 'bill' },
];

/** Invoice Add/Edit Form Component */
function InvoiceAddForm({
  formRef,
  formId, // is defined when we access this component through form detail slider
  invoice: {
    id: invoiceId,
    formId: invoiceFormId,
    invoiceNumber,
    status: invoiceStatus,
    amount: invoiceAmount,
    description: invoiceDescription,
    dateIssued: invoiceDateIssued,
    dueDate: invoiceDueDate,
    vendor: invoiceVendor,
    projectId: invoiceProjectId,
    purchaseAccountId: invoicePurchaseAccountId,
    type: invoiceType,
    qboSyncPrevented,
  } = {},
  isDisplay,
  mode,
  visible,
  showSaveDestination,
  openDestinationDrawer,
  loading,
  projectId, // is defined when accessed through project 'progress' tab
  costcode, // is defined when accessed through a specific costcode in 'progress' tab
  zIndex,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    connectedToQuickbooks = false,
  } = useSelector((state) => state.settings.company);
  const projects = useSelector((state) => state.projects.projects);
  const forms = useSelector((state) => state.forms.forms);

  const [linkedFormId, setLinkedFormId] = useState(formId ?? invoiceFormId);
  const [formFilterRange, setFormFilterRange] = useState([
    moment().subtract(1, 'M').startOf('day'),
    moment().endOf('day'),
  ]);
  const [linkedProjectId, setLinkedProjectId] = useState(projectId ?? invoiceProjectId);
  const [purchaseAccounts, setPurchaseAccounts] = useState({});
  const [selectedPurchaseAccount, setSelectedPurchaseAccount] = useState(invoicePurchaseAccountId);

  useEffect(() => {
    const getPurchaseAccounts = async () => {
      try {
        const { data: newPurchaseAccounts = [] } = await axios.get('/quickbooks/accounts/purchase');
        setPurchaseAccounts(newPurchaseAccounts);
      } catch (err) {
        setPurchaseAccounts([]);
      }
    };

    if (!visible || !connectedToQuickbooks) return;
    getPurchaseAccounts();
  }, [visible, connectedToQuickbooks]);

  useEffect(() => {
    if (!formRef.current || !visible) return;
    formRef.current.setFieldsValue({
      type: invoiceType ?? 'purchase',
    });
  }, [formRef, visible, invoiceType]);

  const projectMap = useMemo(() => getIdMap(projects), [projects]);

  // This is used to determine the divisionId of the invoice on the backend
  const divisionId = useMemo(() => {
    if (linkedProjectId) {
      const project = projectMap[linkedProjectId];
      return project?.divisionId;
    }

    if (linkedFormId) {
      const form = forms[linkedFormId];
      return form?.divisionId;
    }

    return null;
  }, [linkedFormId, linkedProjectId, forms, projectMap]);

  const {
    defaultPurchaseAccount,
    purchaseAccountOptions,
    purchaseAccountOptionsMap,
  } = useMemo(() => {
    if (!connectedToQuickbooks || !divisionId) {
      return {
        purchaseAccountOptions: [],
        purchaseAccountOptionsMap: {},
      };
    }

    const options = purchaseAccounts[divisionId] || [];
    const defaultAccount = options.find(({ IsDefault }) => IsDefault);

    return {
      defaultPurchaseAccount: defaultAccount,
      purchaseAccountOptions: options,
      purchaseAccountOptionsMap: getIdMap(options, 'Id'),
    };
  }, [connectedToQuickbooks, divisionId, purchaseAccounts]);

  useEffect(() => {
    const selectedAccount = purchaseAccountOptionsMap[selectedPurchaseAccount];

    if (!selectedAccount) {
      if (defaultPurchaseAccount) {
        setSelectedPurchaseAccount(defaultPurchaseAccount.Id);
      } else if (selectedPurchaseAccount) {
        setSelectedPurchaseAccount();
      }
    }
  }, [
    defaultPurchaseAccount,
    selectedPurchaseAccount,
    purchaseAccountOptions,
    purchaseAccountOptionsMap,
  ]);

  useEffect(() => {
    if (!formRef.current) return;
    const selectedAccount = purchaseAccountOptionsMap[selectedPurchaseAccount];

    formRef.current.setFieldsValue({
      purchaseAccount: selectedPurchaseAccount ?? null,
      purchaseAccountType: selectedAccount?.AccountType ?? null,
    });
  }, [selectedPurchaseAccount, purchaseAccountOptionsMap]);

  const selectedPOLabel = useMemo(() => {
    if (!formId && !invoiceFormId) return '-';
    const {
      formValue,
      projectId: formProjectId,
      number,
      templateName,
      createdAt: date,
      type,
    } = forms[formId ?? invoiceFormId] || {};
    const { name: projectName } = projectMap[formProjectId] || {};
    return getValueFormTitle({
      formName: templateName,
      projectName,
      number,
      formValue,
      date,
      type,
      t,
    });
  }, [formId, invoiceFormId, forms, projectMap]);

  const poFormOptions = useMemo(() => {
    const selectOptions = {};
    const filteredForms = Object.values(forms).filter(({ type, isClosed }) => (
      PAYABLE_FORM_TYPES.has(type) && isClosed
    ));

    filteredForms.sort(sortByNumber('createdAt'));
    filteredForms.forEach(({
      id: filteredFormId,
      number,
      createdAt: date,
      projects: formProjects = [],
      templateName,
      formValue,
      type,
    }) => {
      formProjects.forEach((formProjectId) => {
        const { name: projectName } = projectMap[formProjectId] || {};
        if (!filteredFormId || !formProjectId) return;
        if (!(type in selectOptions)) selectOptions[type] = {};
        if (!(formProjectId in selectOptions[type])) {
          selectOptions[type][formProjectId] = {
            selectable: false,
            value: formProjectId,
            title: projectName,
            children: [],
          };
        }
        selectOptions[type][formProjectId].children.push({
          isLeaf: true,
          value: filteredFormId,
          title: getValueFormTitle({
            projectName,
            formName: templateName,
            number,
            date,
            formValue,
            type,
            t,
          }),
        });
      });
    });
    return Object.keys(selectOptions).map((type) => {
      const formProjectMap = selectOptions[type];
      return {
        selectable: false,
        value: type,
        title: type,
        children: Object.values(formProjectMap),
      };
    });
  }, [projectMap, forms]);

  const treeSelectRender = useCallback((menu) => (
    <div style={{ width: '100%', padding: 5 }}>
      <Row align="middle" gutter={[8, 0]}>
        <Col span={12}>
          <Text style={{ display: 'flex', justifyContent: 'flex-end' }}>Select date range:</Text>
        </Col>
        <Col span={12}>
          <RangePicker
            autoFocus
            allowClear={false}
            style={{ width: '100%' }}
            format="MMM Do YY"
            disabledDate={(current) => current > DateTime.local().endOf('day').toMillis()}
            value={formFilterRange}
            onChange={setFormFilterRange}
            popupStyle={{ zIndex: 1000, position: 'fixed' }}
            getPopupContainer={(node) => node.parentNode}
          />
        </Col>
      </Row>
      <Divider style={{ margin: '4px 0px 8px 0px' }} />
      {menu}
    </div>
  ), [formFilterRange]);

  const qboSyncText = useMemo(() => {
    if (qboSyncPrevented) {
      return (
        <div style={{ marginBottom: 10 }}>
          <b>
            QuickBooks syncing was manually disabled for this invoice
          </b>
        </div>
      );
    }

    return null;
  }, [qboSyncPrevented]);

  useEffect(() => {
    if (visible) {
      setLinkedFormId(formId ?? invoiceFormId);
      setLinkedProjectId(projectId ?? invoiceProjectId);
      setSelectedPurchaseAccount(invoicePurchaseAccountId);
    } else {
      setLinkedFormId();
      setFormFilterRange([
        moment().subtract(1, 'M').startOf('day'),
        moment().endOf('day'),
      ]);
      setLinkedProjectId();
      setSelectedPurchaseAccount();
    }
  }, [visible]);

  useEffect(() => {
    const query = getValueFormsQuery({
      range: formFilterRange,
      formId: formId ?? invoiceFormId,
    });
    dispatch(getForms(query));
  }, [formId, invoiceFormId, formFilterRange]);

  return (
    <Form
      ref={formRef}
      layout="vertical"
      style={{ width: '100%' }}
    >
      {isDisplay && qboSyncText}
      <FormTextInput
        isNotDisplay={!isDisplay}
        label="Invoice Number"
        name="invoiceNumber"
        value={invoiceNumber}
        rules={[{ required: true, message: 'Please input invoice number' }]}
      />
      <FormCurrencyInput
        isNotDisplay={!isDisplay}
        label="Invoice Amount"
        name="invoiceAmount"
        value={getFormattedCurrency(invoiceAmount)}
      />
      <FormTextInput
        isNotDisplay={!isDisplay}
        label="Invoice Description"
        name="invoiceDescription"
        value={invoiceDescription || '-'}
      />
      <Row gutter={8}>
        <Col span={12}>
          <OnTraccrSimpleDatePicker
            isNotDisplay={!isDisplay}
            label="Date Issued"
            name="invoiceIssueDate"
            value={getFormattedDate(invoiceDateIssued)}
            allowFutureDates={false}
            rules={[{ required: true, message: 'Please specify invoice issue date' }]}
          />
        </Col>
        <Col span={12}>
          <OnTraccrSimpleDatePicker
            isNotDisplay={!isDisplay}
            label="Due Date"
            name="invoiceDueDate"
            value={getFormattedDate(invoiceDueDate)}
            allowFutureDates
          />
        </Col>
      </Row>
      <FormTreeSelectInput
        isNotDisplay={!isDisplay}
        label="Payable Form"
        name="formId"
        value={selectedPOLabel}
        formLabelStyle={{
          style: {
            marginTop: 10,
          },
        }}
        treeSelectOptions={{
          showSearch: true,
          allowClear: true,
          // Dont allow PO Link modification when accessed through PO form detail slider
          disabled: formId || invoiceFormId || linkedProjectId,
          value: linkedFormId,
          treeNodeFilterProp: 'title',
          treeData: poFormOptions,
          placeholder: 'Select a PO',
          dropdownRender: treeSelectRender,
          onChange: setLinkedFormId,
        }}
      />
      <ProjectSelector
        isNotDisplay={!isDisplay}
        formRef={formRef}
        projectDisplay={projectMap[linkedProjectId]?.name ?? '-'}
        disabled={!!linkedFormId || !!invoiceProjectId || !!projectId}
        onChange={setLinkedProjectId}
        formLabelStyle={{
          style: {
            marginTop: -10,
          },
        }}
      />
      <ContactSelector
        isNotDisplay={!isDisplay}
        formRef={formRef}
        contact={invoiceVendor ?? '-'}
        isVendor
        formStyle={{ marginBottom: -50 }}
        formLabelStyle={{
          style: {
            marginTop: 10,
          },
        }}
        zIndex={zIndex + 1}
      />
      <InvoiceStatusSelector
        visible={visible}
        formRef={formRef}
        isNotDisplay={!isDisplay}
        status={invoiceStatus || '-'}
        formStyle={{
          marginBottom: 10,
        }}
      />
      {!!connectedToQuickbooks && (
        <>
          <Form.Item
            name="purchaseAccount"
            label="Purchase Account"
            style={{
              marginBottom: 10,
            }}
          >
            { !isDisplay ? (
              <Select
                disabled={!divisionId}
                style={{ width: '100%' }}
                placeholder="Select a purchase account"
                optionFilterProp="label"
                value={selectedPurchaseAccount}
                onChange={setSelectedPurchaseAccount}
                options={purchaseAccountOptions.map(({ Id, Name }) => ({ label: Name, value: Id }))}
              />
            ) : (
              <DisplayText
                title={purchaseAccountOptionsMap[selectedPurchaseAccount]?.Name ?? '-'}
              />
            )}
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            style={{
              marginBottom: 10,
            }}
          >
            { !isDisplay ? (
              <Select
                style={{ width: '100%' }}
                placeholder="Select a type"
                optionFilterProp="label"
                options={TypeOptions}
                disabled={invoiceId}
              />
            ) : (
              <DisplayText
                title={TypeOptions.find(({ value }) => value === invoiceType)?.label ?? 'Expense'}
              />
            )}
          </Form.Item>
          {!isDisplay && (
            <Form.Item
              valuePropName="checked"
              name="qboSyncPrevented"
              label="Disable QuickBooks Sync"
              style={{ marginBottom: 10 }}
            >
              <Switch
                defaultChecked={false}
                disabled={invoiceId}
              />
            </Form.Item>
          )}
        </>
      )}
      {showSaveDestination
      && (
        <Form.Item
          name="filePath"
          valuePropName="filePath"
          style={{ marginTop: 10, marginBottom: 10, color: 'rgba(0, 0, 0, 0.85)' }}
        >
          <FileDestinationSelect
            isDisplay={isDisplay}
            openDestinationDrawer={openDestinationDrawer}
          />
        </Form.Item>
      )}
      <InvoiceAmountDistributor
        formRef={formRef}
        formId={linkedFormId}
        invoiceId={invoiceId}
        isDisplay={isDisplay}
        mode={mode}
        visible={visible}
        projectId={linkedProjectId}
        costcode={costcode}
      />
      {loading
        && (
          <Row justify="center" align="middle" className="form-loading-container">
            <Spin size="large" />
          </Row>
        )}
    </Form>
  );
}

/* eslint-disable react/forbid-prop-types */
InvoiceAddForm.propTypes = {
  formRef: PropTypes.object.isRequired,
  formId: PropTypes.string,
  invoice: PropTypes.shape(INVOICE_PROP),
  isDisplay: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  openDestinationDrawer: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  showSaveDestination: PropTypes.bool,
  projectId: PropTypes.string,
  costcode: PropTypes.object,
  zIndex: PropTypes.number,
};

InvoiceAddForm.defaultProps = {
  formId: null,
  invoice: {},
  loading: false,
  showSaveDestination: false,
  projectId: null,
  costcode: undefined,
  zIndex: 1000,
};

export default InvoiceAddForm;
