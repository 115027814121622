import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import ContactAddressBookTabHeader from './ContactAddressBookTabHeader';
import ContactAddressBookSlider from './ContactAddressBookSlider';

import { includesTerm, isNullOrUndefined } from '../helpers/helpers';

import {
  CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE,
  CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE,
  CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE,
  ADDRESS_BOOK_COLUMNS,
} from './contactConstants';

/** Address Book Tab for Contacts (Customers/Vendors) */
const ContactAddressBookTab = ({
  visible,
  isVendor,
  id: contactId,
}) => {
  const contactAddressBooks = useSelector((state) => state.contacts.addressBooks);

  const [sliderVisible, setSliderVisible] = useState(false);
  const [sliderMode, setSliderMode] = useState();
  const [selected, setSelected] = useState();
  const [searchInput, setSearchInput] = useState('');

  const data = useMemo(() => {
    const addressBook = contactAddressBooks[contactId] ?? [];
    return addressBook.filter(({ name, role, phoneNumber, email }) => (
      !searchInput
        || (!isNullOrUndefined(name) && includesTerm(name, searchInput))
        || (!isNullOrUndefined(role) && includesTerm(role, searchInput))
        || (!isNullOrUndefined(phoneNumber) && includesTerm(phoneNumber, searchInput))
        || (!isNullOrUndefined(email) && includesTerm(email, searchInput))
    ));
  }, [contactId, contactAddressBooks, searchInput]);

  const openContactSlider = useCallback((mode, record) => () => {
    if (record) setSelected(record);
    setSliderMode(mode);
    setSliderVisible(true);
  }, []);

  const closeContactSlider = useCallback(() => {
    setSliderVisible(false);
    setSliderMode(CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE);
  }, []);

  const onSearchInputChange = useCallback((e) => setSearchInput(e.target.value), []);

  useEffect(() => {
    if (!visible) {
      setSliderVisible(false);
      setSliderMode(CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE);
      setSelected();
      setSearchInput('');
    }
  }, [visible]);

  return (
    <>
      <ContactAddressBookTabHeader
        searchInput={searchInput}
        onSearchInputChange={onSearchInputChange}
        onAddClick={openContactSlider}
      />
      <div className="file-list-container" style={{ height: 'calc(100vh - 240px)' }}>
        <Table
          columns={ADDRESS_BOOK_COLUMNS}
          dataSource={data}
          pagination={false}
          size="medium"
          rowClassName='file-table-row'
          scroll={{
            y: 'calc(100vh - 295px)',
          }}
          onRow={(record) => ({
            onClick: openContactSlider(CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE, record),
          })}
        />
      </div>
      <ContactAddressBookSlider
        visible={sliderVisible}
        mode={sliderMode}
        isVendor={isVendor}
        contactId={contactId}
        selected={selected}
        updateMode={setSliderMode}
        onClose={closeContactSlider}
      />
    </>
  );
};

ContactAddressBookTab.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.oneOf([
    CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE,
    CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE,
    CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE,
  ]),
  isVendor: PropTypes.bool,
  contactId: PropTypes.string,
};

export default ContactAddressBookTab;
