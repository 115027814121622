import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Tag, Select } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { deleteRoundingPolicy, getRoundingPolicies } from '../state/settings.actions';
import CompanySettingsCard from '../CompanySettingsCard';
import CheckRows from '../CheckRows';
import {
  defaultRoundingSettings,
  defaultRoundingTypes,
  defaultRoundingValues,
  getTimeColumns,
} from './timeTrackingConstants';
import TimeTrackingSelect from './TimeTrackingSelect';
import { getIdMap } from '../../helpers/helpers';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import RoundingPolicyDrawer from './RoundingPolicyDrawer';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import useToggle from '../../common/hooks/useToggle';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import Colors from '../../constants/Colors';

const roundingSettingRows = [
  {
    key: 'enableTimeRounding',
    title: 'Enable Time Rounding',
    helpText: 'Round user\'s total time worked on their Time Cards',
    divider: false,
  }, {
    key: 'roundingInterval',
    title: 'Rounding Time Interval',
    divider: false,
  }, {
    key: 'roundingType',
    title: 'Rounding Type',
    helpText: 'Overall time will round the overall time of the card but not the individual time entries. Task time will round the time of a task only on clock out, so that the total time ends up rounded to the nearest interval.',
    divider: false,
  }, {
    key: 'roundingSetting',
    title: 'Rounding Setting',
    divider: false,
  }
];

const getPolicyColumns = ({
  positionMap,
  setSelectedPolicy,
  onDelete,
}) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Positions',
    dataIndex: 'positions',
    key: 'positions',
    render: (positionIds) => positionIds.map((positionId) => (
      <Tag key={positionId}>
        {positionMap[positionId]?.name ?? 'Unknown'}
      </Tag>
    )),
  },
  {
    title: '',
    dataIndex: 'edit',
    key: 'edit',
    width: 15,
    render: (_, record) => (
      <BorderlessButton
        title=""
        iconNode={
          <EditOutlined />
      }
        onClick={() => {
          setSelectedPolicy(record);
        }}
        style={{
          paddingRight: 8,
          paddingLeft: 0,
        }}
      />
    ),
  },
  {
    title: '',
    dataIndex: 'delete',
    key: 'delete',
    width: 15,
    render: (_, record) => (
      <BorderlessButton
        title=""
        iconNode={
          <DeleteOutlined style={{ color: Colors.ONTRACCR_RED }} />
      }
        onClick={onDelete(record)}
      />
    ),
  },
];

export default function RoundingSettings({
  visible,
  settings = {},
  saveCompanySettings,
}) {
  const dispatch = useDispatch();
  const roundingPolicies = useSelector((state) => state.settings.roundingPolicies);
  const positionNames = useSelector((state) => state.settings.positionNames);
  const positionMap = useMemo(() => getIdMap(positionNames), [positionNames]);

  const {
    roundingInterval,
  } = settings;

  const [selectEnabled, setSelectEnabled] = useState(settings.roundingInterval);
  const [timeRoundingValue] = useState(settings.roundingInterval || defaultRoundingValues[0]);
  const [timeRoundingType] = useState(settings.roundingType || defaultRoundingTypes[0]?.value);
  const [timeRoundingSetting] = useState(settings.roundingSetting || defaultRoundingSettings[0]?.value);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState();
  const {
    toggle: toggleRoundingPoliciesDrawer,
    isToggled: isRoundingPoliciesDrawerToggled,
  } = useToggle();

  useEffect(() => {
    if (!visible) return;
    dispatch(getRoundingPolicies());
  }, [visible]);

  useEffect(() => {
    setExpandedRowKeys(roundingPolicies.map(({ id }) => id));
  }, [roundingPolicies]);

  const getView = useCallback((index) => {
    if (index === 0) return null; // use default checkbox
    if (index === 1) {
      return (
        <TimeTrackingSelect
          disabled={!selectEnabled}
          data={defaultRoundingValues}
          defaultValue={timeRoundingValue}
          format={(value) => `${value} minutes`}
          onChange={(newRoundingInterval) => (
            saveCompanySettings({ roundingInterval: newRoundingInterval })
          )}
        />
      );
    }
    if (index === 2) {
      return (
        <Select
          style={{ marginRight: 56, minWidth: 120, textAlign: 'right' }}
          disabled={!selectEnabled}
          defaultValue={timeRoundingType}
          options={defaultRoundingTypes}
          onChange={(newRoundingType) => (
            saveCompanySettings({ roundingType: newRoundingType })
          )}
        />
      );
    }

    return (
      <Select
        style={{ marginRight: 56, minWidth: 120, textAlign: 'right' }}
        disabled={!selectEnabled}
        defaultValue={timeRoundingSetting}
        options={defaultRoundingSettings}
        onChange={(newRoundingSetting) => (
          saveCompanySettings({ roundingSetting: newRoundingSetting })
        )}
      />
    )
  }, [
    selectEnabled,
    timeRoundingValue,
    saveCompanySettings,
    defaultRoundingValues,
  ]);

  const onDelete = (policy) => (policy?.id ? async () => {
    CustomConfirmModal({
      title: 'Delete Rounding Policy',
      content: `Are you sure you want to delete policy: ${policy.name}?`,
      onOk: async () => {
        if (await dispatch(deleteRoundingPolicy(policy.id))) setSelectedPolicy();
      },
    });
  } : null);

  const columns = getPolicyColumns({
    positionMap,
    setSelectedPolicy,
    onDelete,
  });

  const onExpand = useCallback((event, { id }) => {
    if (!event) {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== id));
      return;
    }

    setExpandedRowKeys([...expandedRowKeys, id]);
  }, [expandedRowKeys]);

  const rowExpandable = useCallback((record) => (
    !!record?.times?.length
  ), []);

  const expandedRowRender = useCallback((record) => (
    <Table
      columns={getTimeColumns()}
      dataSource={record?.times}
      pagination={false}
      size="small"
      bordered
    />
  ), []);

  const expandable = useMemo(() => ({
    defaultExpandAllRows: true,
    rowExpandable,
    expandedRowRender,
    expandedRowKeys,
    onExpand,
  }), [
    rowExpandable,
    expandedRowRender,
    expandedRowKeys,
    onExpand,
  ]);

  if (!visible) return null;

  return (
    <>
      <CompanySettingsCard title="Rounding Settings">
        <CheckRows
          data={roundingSettingRows}
          onChange={async (item) => {
            if (item.key === 'enableTimeRounding') {
              saveCompanySettings({
                roundingInterval: roundingInterval ? null : timeRoundingValue,
              });
              return setSelectEnabled(!selectEnabled);
            }
            return saveCompanySettings({ [item.key]: !settings[item.key] });
          }}
          isChecked={(item) => {
            if (item.key === 'enableTimeRounding') {
              return settings.roundingInterval || selectEnabled;
            }
            return settings[item.key];
          }}
          getView={getView}
        />
      </CompanySettingsCard>
      <CompanySettingsCard
        title="Rounding Policies"
        containerStyle={{ paddingLeft: 5 }}
        leftHeaderView={(
          <OnTraccrButton
            title="Add Rounding Policy"
            onClick={toggleRoundingPoliciesDrawer}
          />
      )}
      >
        <Table
          dataSource={roundingPolicies}
          columns={columns}
          pagination={false}
          size="small"
          expandable={expandable}
          rowKey="id"
        />
      </CompanySettingsCard>
      <RoundingPolicyDrawer
        visible={isRoundingPoliciesDrawerToggled || !!selectedPolicy}
        onClose={() => {
          setSelectedPolicy();
          if (isRoundingPoliciesDrawerToggled) toggleRoundingPoliciesDrawer();
        }}
        policy={selectedPolicy}
        onDelete={onDelete(selectedPolicy)}
      />
    </>
  );
}

RoundingSettings.propTypes = {
  visible: PropTypes.bool,
  settings: PropTypes.shape({
    roundingInterval: PropTypes.number,
  }),
  saveCompanySettings: PropTypes.func.isRequired,
};

RoundingSettings.defaultProps = {
  visible: false,
  settings: {},
};
