import {
  CREATE_PROJECT,
  GET_ALL_PROJECTS,
  ARCHIVE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  GET_QUICKBOOKS_PROJECTS,
  TRANSFER_DIVISIONS,
  UPDATE_PROJECT_CUSTOMER,
  GET_PROJECT_SCHEDULE_OF_VALUES,
  UPDATE_PROJECT_SCHEDULE_OF_VALUES,
  SUBMIT_PROJECT_SCHEDULE_OF_VALUES,
  UPDATE_PROJECT_SCHEDULE_OF_VALUES_ROW,
  DELETE_PROJECT_SCHEDULE_OF_VALUES_ROW,
  SWAP_ROW_PROJECT_SCHEDULE_OF_VALUES,
  GET_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
  UPDATE_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
  ADD_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
  DELETE_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
  UPDATE_INDIVIDUAL_PROJECT,
  GET_PROJECT_ATTACHMENTS,
  GET_ALL_PROJECT_COSTCODE_DETAILS,
  GET_SCHEDULE_OF_VALUE_TEMPLATES,
  CREATE_SCHEDULE_OF_VALUE_TEMPLATE,
  UPDATE_SCHEDULE_OF_VALUE_TEMPLATE,
  DELETE_SCHEDULE_OF_VALUE_TEMPLATE,
  APPLY_SCHEDULE_OF_VALUE_TEMPLATE,
  GET_PROJECT_FILE_TEMPLATES,
  ADD_PROJECT_FILE_TEMPLATE,
  UPDATE_PROJECT_FILE_TEMPLATE,
  DELETE_PROJECT_FILE_TEMPLATE,
  UPDATE_PROJECT_PROGRESS_FILTERS,
  GET_PROJECT_GROUPS,
  CREATE_PROJECT_GROUP,
  UPDATE_PROJECT_GROUP,
  DELETE_PROJECT_GROUP,
  GET_PROJECT_GROUP_NOTES,
  ADD_PROJECT_GROUP_NOTE,
  GET_PROJECT_TYPES,
  GET_QUICKBOOKS_CLASSES,
  GET_PROJECT_COST_UPDATES,
  GET_SCHEDULE_OF_VALUE_SECTIONS,
  CREATE_SCHEDULE_OF_VALUE_SECTION,
  UPDATE_SCHEDULE_OF_VALUE_SECTION,
  DELETE_SCHEDULE_OF_VALUE_SECTION,
  GET_PROGRESS_SUBCONTRACT_UPDATES,
  UPDATE_PROGRESS_SUBCONTRACT,
  DELETE_PROGRESS_SUBCONTRACT_UPDATE,
  UPDATE_PROGRESS_SUBCONTRACT_ROW,
  GET_PROJECT_CUSTOM_FIELD_TEMPLATE,
  UPDATE_PROJECT_CUSTOM_FIELD_TEMPLATE,
  GET_PROJECT_CUSTOM_DATA,
  GET_PROJECT_NOTES,
  ADD_PROJECT_NOTE,
} from '../../state/actionTypes';

import sortByString from '../../helpers/helpers';

const initialState = {
  projects: [],
  quickbooksProjects: [],
  scheduleOfValues: {},
  scheduleOfValuePDFRows: [],
  progressHistoryAttachments: [],
  project: {},
  projectCostcodeDetails: {},
  scheduleOfValueTemplates: [],
  newScheduleOfValueTemplate: {},
  projectFileTemplates: [],
  projectProgressFilters: {},
  projectGroups: [],
  projectGroupsNotes: {},
  projectTypes: [],
  quickbookClassMap: {},
  projectAttachments: {
    instantiatedFiles: [],
    locationMap: {},
  },
  projectCostUpdates: [],
  scheduleOfValueSections: [],
  subContractUpdates: {},
  customFieldTemplate: {},
  customData: [],
  customDataFiles: {},
  notes: {},
  notesFileMap: {},
};

export default function projectActions(state = initialState, action) {
  switch (action.type) {
    case CREATE_PROJECT:
      if (new Set(state.projects.map((p) => p.id)).has(action.payload.project.id)) return state;
      return {
        ...state,
        projects: state.projects.concat([{
          ...action.payload.project,
          active: 1,
        }]).sort(sortByString('name')),
      };
    case GET_ALL_PROJECTS:
      return {
        ...state,
        projects: action.payload.projects.sort(sortByString('name')),
      };
    case UPDATE_PROJECT: {
      const {
        projectId,
        data: {
          details,
        } = {},
      } = action.payload;
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.id !== projectId) return project;
          return { ...project, ...details };
        }),
      };
    }
    case UPDATE_INDIVIDUAL_PROJECT: {
      const {
        data = {},
      } = action.payload;
      return {
        ...state,
        project: {
          ...data,
        },
      };
    }
    case GET_PROJECT_ATTACHMENTS: {
      const {
        payload: {
          projectAttachments = [],
        } = {},
      } = action;

      return {
        ...state,
        projectAttachments,
      };
    }
    case ARCHIVE_PROJECT: {
      const {
        payload: {
          projectId,
          active,
        },
      } = action;
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.id !== projectId) return project;
          const newProject = { ...project };
          newProject.active = active ? 1 : 0;
          return newProject;
        }),
      };
    }
    case DELETE_PROJECT: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      return {
        ...state,
        projects: state.projects.filter((project) => project.id !== id),
      };
    }
    case GET_QUICKBOOKS_PROJECTS: {
      const {
        data: quickBooksProjects,
      } = action.payload;
      return {
        ...state,
        quickBooksProjects,
      };
    }
    case TRANSFER_DIVISIONS: {
      const {
        payload: { type, divisionId, ids = [] },
      } = action;
      const {
        projects: stateProjects = [],
      } = state;
      if (type !== 'projects') return state;
      const idSet = new Set(ids);
      return {
        ...state,
        projects: stateProjects.map((project) => {
          if (!idSet.has(project.id)) return project;
          return {
            ...project,
            divisionId,
          };
        }),
      };
    }
    case UPDATE_PROJECT_CUSTOMER: {
      const {
        payload: { customerId, projectIds = [] },
      } = action;
      const {
        projects: stateProjects = [],
      } = state;
      const idSet = new Set(projectIds);
      return {
        ...state,
        projects: stateProjects.map((project) => {
          if (!idSet.has(project.id)) return project;
          return {
            ...project,
            customerId,
          };
        }),
      };
    }
    case GET_PROJECT_SCHEDULE_OF_VALUES:
    case UPDATE_PROJECT_SCHEDULE_OF_VALUES:
    case DELETE_PROJECT_SCHEDULE_OF_VALUES_ROW:
    case SUBMIT_PROJECT_SCHEDULE_OF_VALUES:
    case SWAP_ROW_PROJECT_SCHEDULE_OF_VALUES:
      return {
        ...state,
        scheduleOfValues: action.payload.scheduleOfValues || [],
      };
    case APPLY_SCHEDULE_OF_VALUE_TEMPLATE:
      return {
        ...state,
        scheduleOfValues: action.payload.scheduleOfValues,
        scheduleOfValueSections: action.payload.sections,
      }
    case UPDATE_PROJECT_SCHEDULE_OF_VALUES_ROW: {
      const {
        scheduleOfValues: {
          [action.payload.key]: rowHistory,
        },
      } = state;

      if (rowHistory) {
        rowHistory.push(action.payload.value);
        return {
          ...state,
          scheduleOfValues: {
            ...state.scheduleOfValues,
            [action.payload.key]: rowHistory,
          },
        };
      }

      return {
        ...state,
      };
    }
    case GET_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS:
    case UPDATE_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS:
    case ADD_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS:
    case DELETE_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS:
      return {
        ...state,
        scheduleOfValuePDFRows: action.payload.scheduleOfValuePDFRows,
      };
    case GET_ALL_PROJECT_COSTCODE_DETAILS:
      return {
        ...state,
        projectCostcodeDetails: action.payload.data,
      };
    case GET_SCHEDULE_OF_VALUE_TEMPLATES:
    case CREATE_SCHEDULE_OF_VALUE_TEMPLATE:
    case UPDATE_SCHEDULE_OF_VALUE_TEMPLATE:
    case DELETE_SCHEDULE_OF_VALUE_TEMPLATE: {
      const {
        payload: {
          templates,
          newTemplate,
        },
      } = action;

      return {
        ...state,
        scheduleOfValueTemplates: templates,
        newTemplate: newTemplate || {},
      };
    }
    case GET_PROJECT_FILE_TEMPLATES:
    case ADD_PROJECT_FILE_TEMPLATE:
    case UPDATE_PROJECT_FILE_TEMPLATE:
    case DELETE_PROJECT_FILE_TEMPLATE: {
      const {
        payload: {
          templates,
        },
      } = action;

      return {
        ...state,
        projectFileTemplates: templates,
      };
    }
    case UPDATE_PROJECT_PROGRESS_FILTERS: {
      const {
        payload: {
          filters: newFilters,
        },
      } = action;

      const {
        projectProgressFilters: existingFilters = {},
      } = state;

      let filters;

      // We will not add the filters if none are passed as they are being cleared
      if (newFilters) {
        filters = {
          ...existingFilters,
          ...newFilters,
        };
      }

      return {
        ...state,
        projectProgressFilters: filters,
      };
    }
    case GET_PROJECT_GROUPS:
    case CREATE_PROJECT_GROUP:
    case UPDATE_PROJECT_GROUP:
    case DELETE_PROJECT_GROUP:
      return {
        ...state,
        projectGroups: action.payload.projectGroups,
      };
    case GET_PROJECT_GROUP_NOTES: {
      const {
        payload: {
          id,
          data: notes = [],
        } = {},
      } = action;
      const { projectGroupsNotes: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      existingNotes[id] = notes;
      existingNotes[id].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        projectGroupsNotes: existingNotes,
      };
    }
    case ADD_PROJECT_GROUP_NOTE: {
      const {
        payload = {},
      } = action;
      const { projectGroupId } = payload;
      const { projectGroupsNotes: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      const {
        [projectGroupId]: existingUserNotes = [],
      } = existingNotes;
      existingNotes[projectGroupId] = existingUserNotes.concat([payload]);
      existingNotes[projectGroupId].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        projectGroupsNotes: existingNotes,
      };
    }
    case GET_PROJECT_TYPES:
      return {
        ...state,
        projectTypes: action.payload.customTypes,
      };
    case GET_QUICKBOOKS_CLASSES: {
      const {
        payload: {
          data: quickbookClassMap = {},
        } = {},
      } = action;
      return {
        ...state,
        quickbookClassMap,
      };
    }
    case GET_PROJECT_COST_UPDATES: {
      const {
        payload: costUpdates = [],
      } = action;

      return {
        ...state,
        projectCostUpdates: costUpdates,
      };
    }
    case GET_SCHEDULE_OF_VALUE_SECTIONS:
      return {
        ...state,
        scheduleOfValueSections: action.payload.scheduleOfValueSections,
      };
    case CREATE_SCHEDULE_OF_VALUE_SECTION: {
      const {
        payload: {
          scheduleOfValueSection,
        },
      } = action;

      const { scheduleOfValueSections = [] } = state;

      return {
        ...state,
        scheduleOfValueSections: [
          ...scheduleOfValueSections,
          scheduleOfValueSection,
        ],
      };
    }
    case UPDATE_SCHEDULE_OF_VALUE_SECTION: {
      const {
        payload: {
          data,
        },
      } = action;

      const { scheduleOfValueSections = [] } = state;

      return {
        ...state,
        scheduleOfValueSections: scheduleOfValueSections.map((section) => {
          if (section.id === data.id) {
            return {
              ...section,
              ...data,
            };
          }
          return section;
        }),
      };
    }
    case DELETE_SCHEDULE_OF_VALUE_SECTION: {
      const {
        payload: {
          id,
        },
      } = action;

      const { scheduleOfValueSections = [] } = state;

      return {
        ...state,
        scheduleOfValueSections: scheduleOfValueSections.filter((section) => section.id !== id),
      };
    }
    case GET_PROGRESS_SUBCONTRACT_UPDATES:
    case UPDATE_PROGRESS_SUBCONTRACT:
    case DELETE_PROGRESS_SUBCONTRACT_UPDATE: {
      return {
        ...state,
        subContractUpdates: action.payload.subContractUpdates,
      };
    }
    case UPDATE_PROGRESS_SUBCONTRACT_ROW: {
      const {
        subContractUpdates: {
          [action.payload.key]: rowHistory,
        },
      } = state;

      if (rowHistory) {
        rowHistory.push(action.payload.value);
        return {
          ...state,
          subContractUpdates: {
            ...state.subContractUpdates,
            [action.payload.key]: rowHistory,
          },
        };
      }

      return {
        ...state,
      };
    }
    case GET_PROJECT_CUSTOM_FIELD_TEMPLATE:
    case UPDATE_PROJECT_CUSTOM_FIELD_TEMPLATE: {
      const {
        payload: {
          template = {},
        } = {},
      } = action;
      return {
        ...state,
        customFieldTemplate: template,
      };
    }
    case GET_PROJECT_CUSTOM_DATA: {
      const {
        data: {
          data = [],
          fileMap = {},
        } = {},
      } = action.payload;
      return {
        ...state,
        customData: data,
        customDataFiles: fileMap,
      };
    }
    case GET_PROJECT_NOTES: {
      const {
        payload: {
          id,
          notes = [],
          fileMap = {},
        } = {},
      } = action;
      const { notes: oldNotesMap = {} } = state;
      const newNotesMap = { ...oldNotesMap };
      newNotesMap[id] = notes;
      return {
        ...state,
        notes: newNotesMap,
        notesFileMap: fileMap,
      };
    }
    case ADD_PROJECT_NOTE: {
      const {
        payload = {},
      } = action;
      const { projectId } = payload;
      const { notes: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      const {
        [projectId]: existingProjectNotes = [],
      } = existingNotes;
      existingNotes[projectId] = existingProjectNotes.concat([payload]);
      existingNotes[projectId].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        notes: existingNotes,
      };
    }
    default:
      return state;
  }
}
