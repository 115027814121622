import { PageSizes } from 'pdf-lib';

export default {};

export const [PAGE_WIDTH, PAGE_HEIGHT] = PageSizes.Letter;

export const ANCHOR_RADIUS = 3;
export const GRID_SIZE = 5;
export const COLLECTED_INITIAL_OFFSET = 50;
export const COLLECTED_HEIGHT = 20;
export const COLLECTED_GUTTER = 5;
export const FIELD_HEIGHT = 90;
export const SIGNATURE_VIEW_HEIGHT = 120;
export const MIN_COLUMN_WIDTH = 30;
export const COMPANY_INFO_WIDTH = 300;
export const DEFAULT_FIELD_BOX_HEIGHT = 60;

export const COLOR_BLACK = {
  r: 0, g: 0, b: 0, a: 1,
};

export const COLOR_WHITE = {
  r: 255, g: 255, b: 255, a: 1,
};

export const COLOR_TRANSPARENT = {
  r: 255, g: 255, b: 255, a: 0,
};
export const COLOR_LIGHT_GRAY = {
  r: 155, g: 155, b: 155, a: 1,
};

export const COLOR_LIGHT_GRAY_TRANSPARENT = {
  ...COLOR_LIGHT_GRAY, a: 0.28,
};

export const COLOR_TRANSPARENT_GRAY = {
  r: 240, g: 240, b: 240, a: 0.5,
};

export const SECTION_BACKGROUND_GRAY = {
  r: 74, g: 74, b: 74, a: 1,
};

export const defaultEmployeeName = 'John Doe';
export const defaultEmployeeId = '123';

export const defaultDrawOptions = {
  x: 100,
  y: 100,
  width: 150,
  height: 30,
  fontFamily: 'Helvetica',
  fontSize: 16,
  color: { ...COLOR_BLACK },
  backgroundColor: { ...COLOR_TRANSPARENT },
  textAlign: 'center',
  verticalAlign: 'flex-start',
  borderStyle: 'none',
  borderWidth: 1,
  borderColor: { ...COLOR_BLACK },
  growToFitText: true,
};

export const defaultTitleDrawOptions = {
  ...defaultDrawOptions,
  x: 15,
  y: 10,
  width: 400,
  height: 40,
  fontSize: 24,
  textAlign: 'left',
};

export const defaultLogoDrawOptions = {
  ...defaultDrawOptions,
  x: PAGE_WIDTH - 150,
  y: 0,
  width: 150,
  height: 50,
};

const defaultCompanyInfoOptions = {
  ...defaultDrawOptions,
  x: PAGE_WIDTH - COMPANY_INFO_WIDTH - 10,
  y: 55,
  width: COMPANY_INFO_WIDTH,
  height: 15,
  textAlign: 'right',
  color: { ...COLOR_LIGHT_GRAY },
  fontSize: 10,
};

export const defaultCompanyNameDrawOptions = {
  ...defaultCompanyInfoOptions,
};

export const defaultCompanyAddressDrawOptions = {
  ...defaultCompanyInfoOptions,
  y: 70,
};
export const defaultCompanyNumberDrawOptions = {
  ...defaultCompanyInfoOptions,
  y: 85,
};
export const defaultCompanyWebsiteDrawOptions = {
  ...defaultCompanyInfoOptions,
  y: 100,
};

export const defaultSectionHeader = {
  drawOptions: {
    ...defaultDrawOptions,
    height: 30,
    x: 15,
    fontSize: 18,
    width: PAGE_WIDTH - 30,
    textAlign: 'left',
    backgroundColor: { ...SECTION_BACKGROUND_GRAY },
    color: COLOR_WHITE,
  },
  type: 'text',
  deletable: true,
  isSectionHeader: true,
};

export const defaultFieldTitle = {
  deletable: true,
  type: 'text',
  drawOptions: {
    ...defaultDrawOptions,
    x: 20,
    width: PAGE_WIDTH - 40,
    height: COLLECTED_HEIGHT,
    fontSize: 14,
    textAlign: 'left',
    growToFitText: false,
  },
};

export const defaultFieldBox = {
  drawOptions: {
    ...defaultDrawOptions,
    x: 20,
    width: PAGE_WIDTH - 40,
    height: DEFAULT_FIELD_BOX_HEIGHT,
    borderStyle: 'solid',
    borderColor: { ...COLOR_LIGHT_GRAY },
    backgroundColor: { ...COLOR_TRANSPARENT_GRAY },
    textAlign: 'left',
    verticalAlign: 'flex-start',
    fontSize: 12,
  },
  text: '',
  deletable: true,
  type: 'text',
};

export const defaultPageNumber = {
  id: 'pageNumber',
  deletable: true,
  type: 'text',
  drawOptions: {
    ...defaultDrawOptions,
    x: PAGE_WIDTH - 30,
    y: PAGE_HEIGHT - 30,
    width: 20,
    height: 20,
    fontSize: 10,
    color: { ...COLOR_LIGHT_GRAY },
  },
};

export const defaultSignatureField = {
  id: 'signature',
  deletable: true,
  type: 'text',
  drawOptions: {
    ...defaultDrawOptions,
    x: PAGE_WIDTH - 250,
    y: PAGE_HEIGHT - SIGNATURE_VIEW_HEIGHT,
    width: 200,
    height: 50,
    fontSize: 10,
    backgroundColor: { ...COLOR_TRANSPARENT_GRAY },
    borderStyle: 'solid',
    borderColor: { ...COLOR_TRANSPARENT_GRAY, a: 0.8 },
  },
  link: {
    collectedId: 'employeeSignature',
    collectedTitle: 'Signature',
  },
};

export const defaultSignatureLine = {
  deletable: true,
  type: 'rectangle',
  drawOptions: {
    ...defaultDrawOptions,
    x: PAGE_WIDTH - 250,
    y: PAGE_HEIGHT - SIGNATURE_VIEW_HEIGHT + 52,
    width: 200,
    height: 1,
    fontSize: 10,
    backgroundColor: { ...COLOR_BLACK },
  },
};

export const defaultSignatureName = {
  deletable: true,
  type: 'text',
  text: defaultEmployeeName,
  drawOptions: {
    ...defaultDrawOptions,
    x: PAGE_WIDTH - 250,
    y: PAGE_HEIGHT - SIGNATURE_VIEW_HEIGHT + 55,
    width: 200,
    height: 20,
    fontSize: 14,
    textAlign: 'right',
    verticalAlign: 'flex-start',
    color: { ...COLOR_BLACK },
  },
  link: {
    collectedId: 'employeeName',
    collectedTitle: 'Employee Name',
  },
};

export const defaultTableField = {
  ...defaultDrawOptions,
  fontSize: 10,
  height: 60,
  rowHeight: 30,
  width: PAGE_WIDTH - 40,
  x: 20,
  verticalAlign: 'flex-start',
  borderColor: {
    r: 0, g: 0, b: 0, a: 1,
  },
  borderWidth: 1,
  borderStyle: 'solid',
  headerOptions: {
    textAlign: 'center',
    fontFamily: defaultDrawOptions.fontFamily,
    fontSize: 12,
    backgroundColor: { ...COLOR_LIGHT_GRAY_TRANSPARENT },
    color: {
      r: 0, g: 0, b: 0, a: 1,
    },
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: {
      r: 0, g: 0, b: 0, a: 1,
    },
    verticalAlign: 'flex-start',
  },
};

export const defaultStaticText = {
  backgroundColor: {
    r: 155, g: 155, b: 155, a: 0.35,
  },
  fontSize: 12,
  growToFitText: true,
};

export const DEFAULT_ATTACHMENT_FIELD_HEIGHT = 350;

export const SUPPORTED_FONTS = {
  Courier: 'Courier',
  Helvetica: 'Helvetica',
  TimesRoman: 'TimesRoman',
};
