import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

import { CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE } from './contactConstants';

const ContactAddressBookTabHeader = ({
  searchInput,
  onSearchInputChange,
  onAddClick,
}) => (
  <Row type="flex" justify="start" gutter={14} style={{ height: 32, marginBottom: 14 }} >
    <Col>
      <Input.Search
        type="search"
        className="searchbar"
        placeholder="Search"
        value={searchInput}
        style={{ width: 250 }}
        onChange={onSearchInputChange}
        allowClear
      />
    </Col>
    <Col style={{ marginLeft: 15 }}>
      <OnTraccrButton
        title="Add"
        onClick={onAddClick(CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE)}
        icon={<PlusOutlined />}
      />
    </Col>
  </Row>
);

ContactAddressBookTabHeader.propTypes = {
  searchInput: PropTypes.string,
  onSearchInputChange: PropTypes.func,
  onAddClick: PropTypes.func,
};

ContactAddressBookTabHeader.defaultProps = {
  searchInput: '',
}

export default ContactAddressBookTabHeader;
