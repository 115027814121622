import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form, Tabs, Spin, Row, Col,
} from 'antd';

import StepsContainer from '../common/containers/StepsContainer';

// import NuxFocusBackground from '../nux/NuxFocusBackground';
// import {
//   FORMS_ADD_NUX_STEP_1,
//   FORMS_ADD_FIELDS_STEP_PREFIX,
//   FORMS_ADD_DRAWER_STEP_PREFIX
// } from '../nux/nux.constants';

import FormDetails from './FormDetails/FormDetails';
import FormBuilder from './FormBuilder/FormBuilder';
import FormWorkflows from './FormWorkflows/FormWorkflows';
import PDFDesigner from '../common/pdf/PDFDesigner/PDFDesigner';

import { getTemplateDetails } from './state/forms.actions';
import { getIntegratedEmails } from '../settings/state/settings.actions';

import {
  PAYABLE_FORM_TYPES,
} from '../payables/payables.constants';

import config from '../config';
import { uuid } from '../helpers/helpers';
import { fallbackAccentColor, fallbackPrimaryColor, fallbackSecondaryAccentColor } from '../clientPortal/clientPortal.constants';
import useToggle from '../common/hooks/useToggle';

const { TabPane } = Tabs;

const STEP_1_TITLE = 'Details';
const STEP_2_TITLE = 'Fields';
const STEP_3_TITLE = 'Workflow';
const STEP_4_TITLE = 'PDF Designer';

const tabStyle = {
  minHeight: '100%',
  width: '100%',
};

const keyToStep = {
  [STEP_1_TITLE]: 0,
  [STEP_2_TITLE]: 1,
  [STEP_3_TITLE]: 2,
  [STEP_4_TITLE]: 3,
};

export default function FormsAddView({
  isAdd,
  currentStep,
  formRef,
  onFormDataChange,
  visible,
  formProps,
  canAddGlobal,
  canAddFromLibrary,
  projectId,
  currentStepChanged,
  actionButtonEnabled
}) {
  const {
    id: templateId,
    name: templateName,
    editing,
    type, typeId,
  } = formProps
   || {};
  const isDisplay = !(isAdd || editing);

  const dispatch = useDispatch();

  // const activeNuxAction = useSelector(state => state.nux.activeNuxAction);
  const formTypes = useSelector((state) => state.forms.types);
  const templates = useSelector((state) => state.forms.templates);

  const template = useMemo(() => {
    if (!templateId) return {};
    return templates[templateId];
  }, [templateId, templates]);

  const {
    formData,
    divisionId,
    fileMap,
    allowEdit,
    allowResubmission,
    allowReassignment,
    allowSubmitChild,
    poField: storePoField,
    valueField: storeValueField,
    useStandardTemplate: initialStandard = true,
    isExternalForm: initialIsExternalForm = false,
    settings: {
      url,
      password,
      primaryColor,
      accentColor,
      secondaryAccentColor,
    } = {},
    displayFields: templateDisplayFields = [],
    jsonFormData,
    showInfoBar,
    pdfName: initialPDFName,
  } = template || {};

  const {
    exportLocation,
    storeWorkflow,
  } = useMemo(() => {
    if (formData) {
      return {
        exportLocation: formData.exportLocation,
        storeWorkflow: formData.workflow,
      };
    }

    if (jsonFormData) {
      try {
        const parsedFormData = JSON.parse(jsonFormData);
        return {
          exportLocation: parsedFormData.exportLocation,
          storeWorkflow: parsedFormData.workflow,
        };
      } catch (e) {
        // fail silently
      }
    }

    return {};
  }, [template]);

  const initialValues = {
    exportLocation,
    name: templateName,
    divisionId,
    typeId,
    valueField: storePoField || storeValueField,
  };

  const [collected, setCollected] = useState({});
  const [valueField, setValueField] = useState(storePoField || storeValueField);
  const [sections, setSections] = useState();
  const [workflow, setWorkflow] = useState({});
  const [drawOptions, setDrawOptions] = useState();
  const [selectedDivision, setSelectedDivision] = useState(divisionId);
  const [currentFormData, setCurrentFormData] = useState(initialValues);
  const [canEdit, setCanEdit] = useState(allowEdit);
  const [canResubmit, setCanResubmit] = useState(allowResubmission);
  const [canReassign, setCanReassign] = useState(allowReassignment);
  const [canSubmitChild, setCanSubmitChild] = useState(allowSubmitChild);
  const [isExternalForm, setIsExternalForm] = useState(initialIsExternalForm);
  const [stateFileMap, setStateFileMap] = useState(fileMap || {});
  const [initialWorkflow, setInitialWorkflow] = useState(storeWorkflow);
  const [useStandardTemplate, setUseStandardTemplate] = useState(!!initialStandard);
  const [prevUseStandardTemplate, setPrevUseStandardTemplate] = useState(!!initialStandard); // used to track changes to useStandardTemplate
  const [libraryTemplateStatusesToAdd, setLibraryTemplateStatusesToAdd] = useState(false); // true if we are currently adding a imported form template
  const [displayFields, setDisplayFields] = useState(templateDisplayFields);
  const [shouldShowInfoBar, setShouldShowInfoBar] = useState(showInfoBar);
  const [pdfName, setPDFName] = useState(initialPDFName ?? '[Form Name]-[Form Author]-[Date]');

  const {
    toggle: toggleLoading,
    isToggled: loading,
  } = useToggle();

  const onCollectedChanged = ({ key, data = {} }) => {
    const {
      [key]: existingConfig = {},
    } = collected;
    setCollected({
      ...collected,
      [key]: {
        ...existingConfig,
        ...data,
      },
    });
  };

  useEffect(() => {
    if (visible && formRef?.current) {
      formRef.current.setFieldsValue({
        externalFormPassword: password,
        externalFormPasswordConfirm: password,
        externalFormPrimaryColor: primaryColor || fallbackPrimaryColor,
        externalFormAccentColor: accentColor || fallbackAccentColor,
        externalFormSecondaryAccentColor: secondaryAccentColor || fallbackSecondaryAccentColor,
      });
    }
  }, [visible, formRef, password]);

  useEffect(() => {
    if (isExternalForm) {
      setCollected({});
    }
  }, [isExternalForm]);

  const externalFormURL = useMemo(() => {
    if (visible) {
      return url || uuid();
    }
  }, [url, visible]);

  useEffect(() => {
    onFormDataChange({
      sections,
      collected,
      workflow,
      drawOptions,
      allowEdit: !!canEdit,
      allowResubmission: !!canResubmit,
      allowReassignment: !!canReassign,
      allowSubmitChild: !!canSubmitChild,
      valueField,
      useStandardTemplate,
      isExternalForm: !!isExternalForm,
      externalFormURL,
      displayFields,
      showInfoBar: !!shouldShowInfoBar,
      pdfName,
    });
  }, [
    sections,
    collected,
    workflow,
    drawOptions,
    canEdit,
    canResubmit,
    canReassign,
    canSubmitChild,
    valueField,
    onFormDataChange,
    useStandardTemplate,
    isExternalForm,
    externalFormURL,
    displayFields,
    shouldShowInfoBar,
    pdfName,
  ]);

  useEffect(() => {
    const loadTemplate = async () => {
      toggleLoading();
      await dispatch(getTemplateDetails(templateId));
      toggleLoading();
    };

    if (templateId && visible) {
      loadTemplate();
    }
  }, [visible, templateId]);

  useEffect(() => {
    if (visible) dispatch(getIntegratedEmails());
  }, [visible, dispatch]);

  useEffect(() => {
    if (fileMap) setStateFileMap(fileMap);
  }, [fileMap]);

  useEffect(() => {
    setInitialWorkflow(storeWorkflow);
  }, [template]);

  useEffect(() => {
    setValueField(storePoField || storeValueField);
  }, [storePoField, storeValueField]);

  useEffect(() => {
    if (template && template.formData) {
      const {
        formData: {
          collected: storeCollected,
          sections: storeSections,
        },
        drawOptions: storeDrawOptions,
      } = template;
      setCollected(storeCollected); // every form field has this field. Will use this to indicate: true: Load saved siganture, false: New signature
      setSections(storeSections);
      setDrawOptions(storeDrawOptions);
    }
    if (template && template.name && formRef && formRef.current) {
      formRef.current.setFieldsValue('name', template.name);
    }
  }, [template, formRef]);

  useEffect(() => {
    actionButtonEnabled?.(!loading);
  }, [loading]);

  /*
    Hide NUX for now: https://projectharbour.atlassian.net/browse/HARBOUR-1867
  */
  const showNux = false;
  // const showNux = useMemo(() => (
  //   (currentStep === 0 && activeNuxAction === FORMS_ADD_NUX_STEP_1)
  //   || (
  //       currentStep === 1
  //       && activeNuxAction
  //       && (
  //         activeNuxAction.startsWith(FORMS_ADD_FIELDS_STEP_PREFIX)
  //         || activeNuxAction.startsWith(FORMS_ADD_DRAWER_STEP_PREFIX)
  //       )
  //     )
  // ),[activeNuxAction,currentStep]);

  const formType = useMemo(() => (
    formTypes.find((formType) => formType.id === currentFormData.typeId) ?? {}
  ), [formTypes, currentFormData]);

  const fields = useMemo(() => {
    const templateFields = [];
    sections?.forEach(({ fields = [] }) => {
      fields?.forEach((field) => {
        templateFields.push({
          ...field,
          name: field.name ?? field.configProps?.title,
        });
      });
    });
    return templateFields;
  }, [sections]);

  const step1Content = useMemo(() => (
    <FormDetails
      name={templateName}
      type={type}
      typeId={typeId}
      collected={collected}
      onCollectedChanged={onCollectedChanged}
      onCollectFullUpdate={setCollected}
      isDisplay={isDisplay}
      currentStep={currentStep}
      isAdd={isAdd}
      onDivisionChanged={setSelectedDivision}
      canEdit={canEdit}
      onEditChanged={setCanEdit}
      canResubmit={canResubmit}
      onResubmitChanged={setCanResubmit}
      canReassign={canReassign}
      onReassignChanged={setCanReassign}
      canSubmitChild={canSubmitChild}
      onSubmitChildChanged={setCanSubmitChild}
      canAddGlobal={canAddGlobal}
      canAddFromLibrary={canAddFromLibrary}
      isExternalForm={isExternalForm}
      onIsExternalFormChanged={setIsExternalForm}
      formRef={formRef}
      onSectionsChanged={setSections}
      onWorkflowChange={(newWorflow) => {
        setInitialWorkflow(newWorflow);
        setWorkflow(newWorflow);
      }}
      onDrawOptionsChanged={setDrawOptions}
      onFileMapChanged={setStateFileMap}
      externalFormURL={externalFormURL}
      setLibraryTemplateStatusesToAdd={setLibraryTemplateStatusesToAdd}
      onStandardTemplateChanged={setUseStandardTemplate}
      setPrevUseStandardTemplate={setPrevUseStandardTemplate}
      displayFields={displayFields}
      setDisplayFields={setDisplayFields}
      fields={fields}
      showInfoBar={shouldShowInfoBar}
      onShowInfoBarChange={setShouldShowInfoBar}
      setPDFName={setPDFName}
    />
  ), [
    templateName,
    type,
    typeId,
    collected,
    onCollectedChanged,
    setCollected,
    isDisplay,
    currentStep,
    isAdd,
    setSelectedDivision,
    canEdit,
    setCanEdit,
    canResubmit,
    setCanResubmit,
    canReassign,
    setCanReassign,
    canSubmitChild,
    setCanSubmitChild,
    canAddGlobal,
    canAddFromLibrary,
    isExternalForm,
    setIsExternalForm,
    formRef,
    setSections,
    setInitialWorkflow,
    setWorkflow,
    setDrawOptions,
    setStateFileMap,
    externalFormURL,
    setLibraryTemplateStatusesToAdd,
    setUseStandardTemplate,
    setPrevUseStandardTemplate,
    displayFields,
    fields,
    shouldShowInfoBar,
    setShouldShowInfoBar,
  ]);

  const step2Content = useMemo(() => (
    <FormBuilder
      onSectionsChanged={setSections}
      onValueFieldChanged={setValueField}
      valueField={valueField}
      initialSections={sections}
      isDisplay={isDisplay}
      isAdd={isAdd}
      allowValueFieldSelection={
        formType
        && PAYABLE_FORM_TYPES.has(formType.name)
      }
      formType={formType}
      currentStep={currentStep}
      isExternalForm={isExternalForm}
      divisions={[selectedDivision]}
      templateId={templateId}
      name={templateName}
      enablePermissions
    />
  ), [
    setSections,
    setValueField,
    sections,
    valueField,
    isDisplay,
    isAdd,
    currentStep,
    formType,
    isExternalForm,
    selectedDivision,
    templateId,
  ]);

  const step3Content = useMemo(() => (
    <FormWorkflows
      name={templateName}
      templateId={templateId}
      formRef={formRef}
      collected={collected}
      sections={sections}
      exportLocation={exportLocation}
      isDisplay={isDisplay}
      isAdd={isAdd}
      onWorkflowChange={setWorkflow}
      workflow={initialWorkflow}
      currentStep={currentStep}
      divisionId={selectedDivision}
      drawOptions={drawOptions}
      fileMap={stateFileMap}
      currentFormData={currentFormData}
      projectId={projectId}
      useStandardTemplate={useStandardTemplate}
      isExternalForm={isExternalForm}
      libraryTemplateStatusesToAdd={libraryTemplateStatusesToAdd}
    />
  ), [
    formRef,
    templateId,
    collected,
    sections,
    exportLocation,
    isDisplay,
    isAdd,
    initialWorkflow,
    currentStep,
    selectedDivision,
    drawOptions,
    stateFileMap,
    templateName,
    currentFormData,
    projectId,
    useStandardTemplate,
    isExternalForm,
    libraryTemplateStatusesToAdd,
  ]);

  const step4Content = useMemo(() => (
    // Need to add currentStep as the key
    // because StepsContainer creates the component before
    // the current step is reached, and before the form template details
    // are retrieved from the backend
    <PDFDesigner
      key={currentStep}
      formRef={formRef}
      name={templateName}
      collected={collected}
      sections={sections}
      isDisplay={isDisplay}
      currentStep={currentStep}
      onDrawOptionsChanged={setDrawOptions}
      drawOptions={drawOptions}
      fileMap={stateFileMap}
      typeId={typeId}
      useStandardTemplate={useStandardTemplate}
      onUseStandardTemplateChange={setUseStandardTemplate}
      isExternalForm={isExternalForm}
      prevUseStandardTemplate={prevUseStandardTemplate}
      setPrevUseStandardTemplate={setPrevUseStandardTemplate}
      pdfName={pdfName}
      setPDFName={setPDFName}
    />
  ), [
    formRef,
    collected,
    sections,
    templateName,
    isDisplay,
    currentStep,
    drawOptions,
    stateFileMap,
    typeId,
    isExternalForm,
    useStandardTemplate,
    prevUseStandardTemplate,
    pdfName,
  ]);

  const onTabChange = (activeTab) => {
    const relevantStep = keyToStep[activeTab];
    if (relevantStep !== undefined) {
      currentStepChanged(relevantStep);
    }
  };

  const child = () => {
    if (isDisplay) {
      return (
        <Tabs
          defaultActiveKey={STEP_1_TITLE}
          style={{ marginTop: -24}}
          onChange={onTabChange}
        >
          <TabPane tab={STEP_1_TITLE} key={STEP_1_TITLE} style={tabStyle}>
            {step1Content}
          </TabPane>
          <TabPane tab={STEP_2_TITLE} key={STEP_2_TITLE} style={tabStyle}>
            {step2Content}
          </TabPane>
          <TabPane tab={STEP_3_TITLE} key={STEP_3_TITLE} style={tabStyle}>
            {step3Content}
          </TabPane>
          {config.showPDFDesigner && (
            <TabPane tab={STEP_4_TITLE} key={STEP_4_TITLE} style={tabStyle}>
              {step4Content}
            </TabPane>
          )}
        </Tabs>
      );
    }
    const steps = [
      {
        title: STEP_1_TITLE,
        key: STEP_1_TITLE,
        content: loading
          ? (
            <Row justify="center" align="middle" style={{ marginTop: 200 }}>
              <Col>
                <Spin />
              </Col>
            </Row>
          )
          : step1Content,
      },
      {
        title: STEP_2_TITLE,
        key: STEP_2_TITLE,
        content: step2Content,
      },
      {
        title: STEP_3_TITLE,
        key: STEP_3_TITLE,
        content: step3Content,
      }];
    if (config.showPDFDesigner) {
      steps.push({
        title: STEP_4_TITLE,
        key: STEP_4_TITLE,
        content: step4Content,
      });
    }
    return (
      <StepsContainer
        currentStep={currentStep}
        steps={steps}
      />
    );
  };

  return (
    <Form
      style={{ width: 1000, paddingBottom: 25 }}
      ref={formRef}
      initialValues={initialValues}
      onValuesChange={(_change, all) => {
        setCurrentFormData(all);
      }}
    >
      {child()}
    </Form>
  );
}
