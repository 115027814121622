import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Popover,
  Tree,
  Row,
  PageHeader,
} from 'antd';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';

export default function SelectLabelFilter({
  style = {},
  selected = [],
  onSelectedChanged,
  type,
}) {
  const labels = useSelector((state) => state.labels);
  const options = useMemo(() => (
    labels
      .filter((label) => label.type === type)
      .map((label) => ({ title: label.title, key: label.id }))
  ), [labels]);
  const filter = (
    <>
      <Row>
        <PageHeader
          title="Filter by Label"
          style={{
            padding: 0,
            marginBottom: 5,
            fontFamily: 'raleway-medium',
          }}
        />
      </Row>
      <Tree
        checkable
        selectable={false}
        defaultExpandAll
        checkedKeys={selected}
        onCheck={(checked) => onSelectedChanged(checked)}
        treeData={options}
      />
    </>
  );
  return (
    <div className="form-user-selector-filter">
      <Popover trigger="click" placement="topRight" content={filter}>
        <OnTraccrButton
          title="Filter"
          type={selected.length > 0 ? 'back' : 'primary'}
          style={style}
        />
      </Popover>
    </div>
  );
}
