/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  Divider,
  Row,
  Col,
  Typography,
} from 'antd';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import colors from '../../constants/Colors';
import WorkSlot from './WorkSlot';
import EditButton from '../../common/buttons/EditButton';
import { roundTotalRuntime } from '../../helpers/time';
import { msToString } from '../timecard.helpers';
import { sortByStartTime } from '../../helpers/tasks';
import TimeCardApprovalButton, { APPROVE_TIMECARD_SUBMISSION, REJECT_TIMECARD_SUBMISSION } from '../TimeCardApprovalButton';

const { Text } = Typography;

const parseEntries = (day = {}) => {
  const {
    dayTasks = [],
  } = day;
  if (dayTasks.length === 0) {
    return (
      <Row justify="space-around" type="flex">
        {/* TODO : make this look nice */}
        <i>No entries</i>
      </Row>
    );
  }
  let canEditCards = false;
  const views = [];
  const seen = new Set();
  const uniqueTasks = dayTasks.filter((task) => {
    if (task.id && seen.has(task.id)) {
      return false;
    }
    seen.add(task.id);
    return true;
  });
  uniqueTasks.sort(sortByStartTime);
  uniqueTasks.forEach((task) => {
    if (task.canEdit) canEditCards = true;
    views.push(
      <div
        key={task.id}
        style={{
          width: '95%',
          margin: 'auto',
        }}
      >
        <WorkSlot task={task} />
        <div style={{ height: '0.5rem' }} />
      </div>,
    );
  });
  return { views, canEditCards };
};

function Scroller({
  day,
  onEditClicked,
  roundingType,
  roundingInterval,
  roundingSetting,
  isApprovals,
  userId,
}) {
  const titleStyle = {
    fontSize: 10,
    width: '100%',
    fontFamily: 'raleway-semibold',
    color: colors.ONTRACCR_TITLE_BLACK,
  };
  const { views, canEditCards } = parseEntries(day);
  return (
    <div style={{ width: '-webkit-fill-available' }}>
      <Row justify="space-around" type="flex">
        <Text
          style={{
            fontSize: 16,
            width: '100%',
            textAlign: 'center',
            fontFamily: 'raleway-semibold',
            color: colors.ONTRACCR_TITLE_BLACK,
          }}
        >
          {msToString(
            roundTotalRuntime(
              day.total,
              roundingInterval,
              roundingType,
              roundingSetting,
            )
          )}
        </Text>
      </Row>
      <Row justify="space-between">
        <Col span={12}>
          <Row style={{ marginBottom: '0.2rem', marginLeft: '0.2rem' }}>
            <Text style={titleStyle}>
              {day?.minTS?.dt?.toLocaleString?.(DateTime.TIME_SIMPLE) ?? '--'}
            </Text>
          </Row>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Row style={{ marginBottom: '0.2rem', marginRight: '0.2rem' }}>
            <Text style={titleStyle}>
              {day?.maxTS?.dt?.toLocaleString?.(DateTime.TIME_SIMPLE) ?? '--'}
            </Text>
          </Row>
        </Col>
      </Row>
      <Divider
        style={{
          backgroundColor: colors.ONTRACCR_BLACK,
          height: 1,
          marginTop: 0,
          marginBottom: 0,
          paddingBottom: 0,
        }}
      />
      <div
        style={{
          overflowY: 'auto',
          height: '9.866rem',
        }}
      >
        <div style={{ height: '0.5rem' }} />
        {views}
      </div>
      <Divider
        style={{
          backgroundColor: colors.ONTRACCR_BLACK,
          height: 1,
          marginTop: 0,
          marginBottom: 0,
          paddingBottom: 0,
        }}
      />
      {isApprovals && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TimeCardApprovalButton
            record={day}
            type={APPROVE_TIMECARD_SUBMISSION}
            userId={userId}
          />
          <TimeCardApprovalButton
            record={day}
            type={REJECT_TIMECARD_SUBMISSION}
            userId={userId}
            style={{ backgroundColor: 'grey', borderColor: 'grey' }}
          />
        </div>
      )}
      {canEditCards && (
        <Row justify="space-around" type="flex">
          <EditButton
            editable
            onClick={(e) => {
              e.stopPropagation();
              onEditClicked({
                day,
              });
            }}
            customStyle={{ border: 'none' }}
          />
        </Row>
      )}
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
Scroller.propTypes = {
  day: PropTypes.object.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  roundingType: PropTypes.string,
  roundingInterval: PropTypes.number.isRequired,
  roundingSetting: PropTypes.string,
  isApprovals: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

Scroller.defaultProps = {
  roundingType: null,
  isApprovals: false,
  roundingSetting: undefined,
};

export default Scroller;
