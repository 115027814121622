import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer, Form, Select, Row,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

import BoardLinkSelector from './BoardLinkSelector';

import { mergeSets } from '../helpers/helpers';

import {
  setBoardFilters,
} from './state/boards.actions';

function BoardFilterDrawer({
  visible,
  onClose,
  selectedBoard,
}) {
  const {
    divisions: boardDivisions,
    title,
  } = selectedBoard;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const divisions = useSelector((state) => state.settings.divisions);
  const users = useSelector((state) => state.users.users);
  const filters = useSelector((state) => state.boards.filters);

  const userOptions = useMemo(() => {
    const divSet = new Set(boardDivisions ?? []);
    const userDivSets = mergeSets(
      Object.values(divisions)
        .filter(({ id: divisionId }) => divSet.has(divisionId))
        .map(({ users: divUsers }) => divUsers),
    );
    return users
      .filter((user) => user.active && userDivSets.has(user.id))
      .map((user) => ({
        value: user.id,
        label: user.name,
      }));
  }, [users, divisions, boardDivisions]);

  const onApply = useCallback(() => {
    const values = form.getFieldsValue();
    dispatch(setBoardFilters(values));
    onClose();
  }, []);

  const onClear = useCallback(() => {
    form.resetFields();
    dispatch(setBoardFilters(null));
    onClose();
  }, [form]);

  const onCancel = useCallback(() => {
    form.setFieldsValue({
      user: filters?.user ?? null,
      link: filters?.link ?? null,
    });
    onClose();
  }, [onClose, form, filters]);

  return (
    <Drawer
      title={`Filters for ${title}`}
      visible={visible}
      onClose={onCancel}
      width={700}
      destroyOnClose
    >
      <div>
        <Form
          form={form}
          layout="vertical"
          initialValues={filters}
        >
          <Form.Item
            name="user"
            label="Assigned User"
            style={{ marginBottom: 16 }}
          >
            <Select
              allowClear
              options={userOptions}
              placeholder="Users"
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>
          <Form.Item
            name="link"
            label="Link"
            style={{ marginBottom: 16 }}
          >
            <BoardLinkSelector />
          </Form.Item>
        </Form>
      </div>
      <div className="drawer-footer">
        <Row justify="end" gutter={10}>
          <div style={{ marginRight: 'auto' }}>
            <OnTraccrButton
              title="Cancel"
              type="cancel"
              onClick={onCancel}
            />
            <OnTraccrButton
              onClick={onClear}
              title="Clear"
              style={{ marginLeft: 8 }}
            />
          </div>
          <OnTraccrButton
            title="Apply"
            onClick={onApply}
          />
        </Row>
      </div>
    </Drawer>
  );
}

BoardFilterDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectedBoard: PropTypes.shape({
    title: PropTypes.string,
    divisions: PropTypes.arrayOf(PropTypes.string),
  }),
};

BoardFilterDrawer.defaultProps = {
  visible: false,
  selectedBoard: {},
};

export default BoardFilterDrawer;
