import { DateTime } from 'luxon';

import Analytics from '../../helpers/Analytics';
import {
  CREATE_PROJECT,
  GET_ALL_PROJECTS,
  ARCHIVE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECT_EQUIPMENT,
  UPDATE_PROJECT_CUSTOMER,
  GET_PROJECT_SCHEDULE_OF_VALUES,
  UPDATE_PROJECT_SCHEDULE_OF_VALUES,
  UPDATE_PROJECT_SCHEDULE_OF_VALUES_ROW,
  DELETE_PROJECT_SCHEDULE_OF_VALUES_ROW,
  SUBMIT_PROJECT_SCHEDULE_OF_VALUES,
  SWAP_ROW_PROJECT_SCHEDULE_OF_VALUES,
  GET_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
  UPDATE_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
  ADD_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
  DELETE_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
  UPDATE_INDIVIDUAL_PROJECT,
  GET_ALL_PROJECT_COSTCODE_DETAILS,
  GET_PROJECT_ATTACHMENTS,
  ADD_PROJECT_PROGRESS_HISTORY_FILES,
  GET_SCHEDULE_OF_VALUE_TEMPLATES,
  CREATE_SCHEDULE_OF_VALUE_TEMPLATE,
  UPDATE_SCHEDULE_OF_VALUE_TEMPLATE,
  DELETE_SCHEDULE_OF_VALUE_TEMPLATE,
  APPLY_SCHEDULE_OF_VALUE_TEMPLATE,
  GET_PROJECT_FILE_TEMPLATES,
  ADD_PROJECT_FILE_TEMPLATE,
  UPDATE_PROJECT_FILE_TEMPLATE,
  DELETE_PROJECT_FILE_TEMPLATE,
  UPDATE_PROJECT_PROGRESS_FILTERS,
  GET_PROJECT_GROUPS,
  CREATE_PROJECT_GROUP,
  UPDATE_PROJECT_GROUP,
  DELETE_PROJECT_GROUP,
  GET_PROJECT_GROUP_NOTES,
  ADD_PROJECT_GROUP_NOTE,
  GET_PROJECT_TYPES,
  GET_PROJECT_COST_UPDATES,
  GET_SCHEDULE_OF_VALUE_SECTIONS,
  CREATE_SCHEDULE_OF_VALUE_SECTION,
  UPDATE_SCHEDULE_OF_VALUE_SECTION,
  DELETE_SCHEDULE_OF_VALUE_SECTION,
  GET_PROGRESS_SUBCONTRACT_UPDATES,
  UPDATE_PROGRESS_SUBCONTRACT,
  UPDATE_PROGRESS_SUBCONTRACT_ROW,
  DELETE_PROGRESS_SUBCONTRACT_UPDATE,
  SWAP_ROW_PROGRESS_SUBCONTRACT,
  GET_PROJECT_CUSTOM_FIELD_TEMPLATE,
  UPDATE_PROJECT_CUSTOM_FIELD_TEMPLATE,
  GET_PROJECT_CUSTOM_DATA,
  GET_PROJECT_NOTES,
  ADD_PROJECT_NOTE,
} from '../../state/actionTypes';
import ProjectsService from './projects.service';
import { createFolders, uploadFiles } from '../../files/state/files.actions';
import Permissions from '../../auth/Permissions';
import { getUpdatedRow } from '../ProjectScheduleOfValues/helpers';
import { decorateFormWithFiles } from '../../forms/formHelpers';

const PROJECT_GET_DEBOUNCE_TIMER = 30;

let lastProjectGet = DateTime.fromMillis(0);

export const createProject = (project, fileMap = {}, divisionName = '') => async (dispatch) => {
  if (!(Permissions.has('PROJECTS_WRITE'))) return false;
  Analytics.track('Project/Create');
  const fileMapValues = Object.values(fileMap);
  if (fileMapValues.length) {
    Analytics.track('Project/Files/Upload');
    const { name } = project;
    const prefix = `Projects/${name}`;
    const files = [];
    const folders = [];
    fileMapValues.forEach((file) => {
      const { type, fullPath } = file;
      if (type === 'folder') {
        folders.push(`${prefix}${fullPath}/`);
      } else {
        files.push(file);
      }
    });

    const { divisionId } = project;

    if (folders.length) {
      const createdFolders = await dispatch(createFolders({ names: folders, divisionId }));
      if (!createdFolders) return false;
    }
    if (files.length) {
      const divisionPrefix = divisionName ? `${divisionName}/` : '';
      const payload = files.map((file) => ({
        jsFileObject: file.jsFileObject,
        path: `${divisionPrefix}${prefix}${file.parent}`,
      }));

      const uploadedFiles = await dispatch(uploadFiles(payload));
      if (!uploadedFiles) return false;
    }
  }
  const { data: newProject } = await ProjectsService.create(project, fileMap);
  if (!newProject) return false;
  dispatch({
    type: CREATE_PROJECT,
    payload: {
      project: newProject,
    },
  });
  return newProject;
};

export const getProjects = (query) => async (dispatch) => {
  const now = DateTime.local();
  if (now.diff(lastProjectGet).as('seconds') < PROJECT_GET_DEBOUNCE_TIMER) return true;
  lastProjectGet = now;
  // if (!(Permissions.has('PROJECTS_WRITE'))) return false;
  const projects = await ProjectsService.getAll(query);
  dispatch({
    type: GET_ALL_PROJECTS,
    payload: {
      projects,
    },
  });
  return true;
};

export const getProjectAttachments = (id) => async (dispatch) => {
  const { data } = await ProjectsService.getProjectAttachments(id);
  if (!data) return false;
  dispatch({
    type: GET_PROJECT_ATTACHMENTS,
    payload: {
      projectAttachments: data,
    },
  });
  return true;
};

export const archiveProject = (id, active = false) => async (dispatch) => {
  if (!(Permissions.has('PROJECTS_WRITE'))) return false;
  Analytics.track('Project/Archive');
  const { data } = await ProjectsService.archive(id, active);
  if (!data) return false;
  dispatch({
    type: ARCHIVE_PROJECT,
    payload: {
      projectId: id,
      active,
    },
  });
  return true;
};

export const updateProject = (id, payload) => async (dispatch) => {
  if (!(Permissions.has('PROJECTS_WRITE'))) return false;
  Analytics.track('Project/Edit');
  const { data } = await ProjectsService.update(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_PROJECT,
    payload: {
      projectId: id,
      data: payload,
    },
  });
  return true;
};

export const updateIndividualProject = ({
  projectId,
  payload,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECTS_WRITE'))) return false;
  Analytics.track('Project/Edit');
  const { data } = await ProjectsService.update(projectId, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_INDIVIDUAL_PROJECT,
    payload: {
      data,
    },
  });
  return true;
};

export const deleteProject = (id) => async (dispatch) => {
  if (!(Permissions.has('PROJECTS_WRITE'))) return false;
  Analytics.track('Project/Delete');
  const { data } = await ProjectsService.delete(id);
  if (!data) return false;
  dispatch({
    type: DELETE_PROJECT,
    payload: {
      id,
    },
  });
  return true;
};

export const getProjectEquipment = () => async (dispatch) => {
  const { data = {} } = await ProjectsService.getEquipment();
  if (!data) return false;
  dispatch({
    type: GET_PROJECT_EQUIPMENT,
    payload: data,
  });
  return true;
};

export const updateProjectsCustomer = (payload) => async (dispatch) => {
  const { data } = await ProjectsService.updateCustomer(payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_PROJECT_CUSTOMER,
    payload,
  });
  return true;
};

export const getProjectScheduleOfValues = ({
  projectId,
  templateId,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_READ'))) return false;

  const { data } = templateId
    ? await ProjectsService.getScheduleOfValuesTemplateData(templateId)
    : await ProjectsService.getScheduleOfValues(projectId);

  if (!data) {
    return false;
  }

  dispatch({
    type: GET_PROJECT_SCHEDULE_OF_VALUES,
    payload: {
      scheduleOfValues: data,
    },
  });
  return true;
};

export const updateProjectScheduleOfValues = ({
  projectId,
  templateId,
  payload,
  projectName = '',
  files = [],
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;

  const { data } = await ProjectsService.updateScheduleOfValues({
    projectId,
    templateId,
    payload,
    projectName,
    files,
  });

  if (!data) {
    return false;
  }

  dispatch({
    type: UPDATE_PROJECT_SCHEDULE_OF_VALUES,
    payload: {
      scheduleOfValues: data,
    },
  });
  return true;
};

export const submitProjectScheduleOfValues = (
  projectId,
  payload,
  projectName = '',
  files = [],
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;
  const { data: scheduleOfValues } = await ProjectsService.submitScheduleOfValues(
    projectId,
    payload,
    projectName,
    files,
  );

  if (!scheduleOfValues) {
    return false;
  }

  dispatch({
    type: SUBMIT_PROJECT_SCHEDULE_OF_VALUES,
    payload: {
      scheduleOfValues,
    },
  });
  return true;
};

export const swapRowsProjectScheduleOfValues = ({
  projectId,
  templateId,
  updatedRows,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;

  const { data: scheduleOfValues } = await ProjectsService.swapRowsScheduleOfValues({
    projectId,
    templateId,
    payload: { updatedRows },
  });

  if (!scheduleOfValues) {
    return false;
  }

  dispatch({
    type: SWAP_ROW_PROJECT_SCHEDULE_OF_VALUES,
    payload: {
      scheduleOfValues,
    },
  });
  return true;
};

export const updateScheduleOfValuesRow = ({
  projectId,
  templateId,
  row,
  payload,
}) => (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;

  const updatedRow = getUpdatedRow({ row, payload, isProgress: false });

  dispatch({
    type: UPDATE_PROJECT_SCHEDULE_OF_VALUES_ROW,
    payload: {
      key: `${projectId || templateId}.${row.rowId}`,
      value: updatedRow,
    },
  });

  return true;
};

export const deleteProjectScheduleOfValueRow = ({
  projectId,
  templateId,
  rowId,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;
  const { data } = await ProjectsService.deleteScheduleOfValuesRow({
    projectId,
    templateId,
    rowId,
  });

  if (!data) {
    return false;
  }

  dispatch({
    type: DELETE_PROJECT_SCHEDULE_OF_VALUES_ROW,
    payload: {
      scheduleOfValues: data,
    },
  });
  return true;
};

export const getProjectScheduleOfValuePDFRows = ({
  projectId,
  templateId,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;
  const { data } = await ProjectsService.getProjectScheduleOfValuePDFRows({
    projectId,
    templateId,
  });

  if (!data) {
    return false;
  }

  dispatch({
    type: GET_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
    payload: {
      scheduleOfValuePDFRows: data,
    },
  });
  return true;
};

export const updateProjectScheduleOfValuePDFRows = ({
  projectId,
  templateId,
  rowId,
  payload,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;
  const { data } = await ProjectsService.updateProjectScheduleOfValuePDFRows({
    projectId,
    templateId,
    rowId,
    payload,
  });

  if (!data) {
    return false;
  }

  dispatch({
    type: UPDATE_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
    payload: {
      scheduleOfValuePDFRows: data,
    },
  });
  return true;
};

export const addProjectScheduleOfValuePDFRows = ({
  projectId,
  templateId,
  payload,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;
  const { data } = await ProjectsService.addProjectScheduleOfValuePDFRows({
    projectId,
    templateId,
    payload,
  });

  if (!data) {
    return false;
  }

  dispatch({
    type: ADD_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
    payload: {
      scheduleOfValuePDFRows: data,
    },
  });
  return true;
};

export const deleteProjectScheduleOfValuePDFRows = ({
  projectId,
  templateId,
  rowId,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;
  const { data } = await ProjectsService.deleteProjectScheduleOfValuePDFRows({
    projectId,
    templateId,
    rowId,
  });

  if (!data) {
    return false;
  }

  dispatch({
    type: DELETE_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS,
    payload: {
      scheduleOfValuePDFRows: data,
    },
  });
  return true;
};

export const getAllProjectCostcodeDetails = (projectId, query) => async (dispatch) => {
  const { data } = await ProjectsService.getAllProjectCostcodeDetails(projectId, query);
  if (!data) return false;
  dispatch({
    type: GET_ALL_PROJECT_COSTCODE_DETAILS,
    payload: {
      data,
    },
  });
  return true;
};
export const addProjectProgressHistoryFiles = (
  projectId,
  payload,
  projectName = '',
  files = [],
) => async (dispatch) => {
  const { data } = await ProjectsService.addProjectProgressHistoryFiles(projectId, payload, projectName, files);
  if (!data) return false;
  dispatch({
    type: ADD_PROJECT_PROGRESS_HISTORY_FILES,
    payload: {
      progress: data,
    },
  });
  return true;
};

export const getScheduleOfValueTemplates = () => async (dispatch) => {
  const { data } = await ProjectsService.getScheduleOfValueTemplates();
  if (!data) return false;
  dispatch({
    type: GET_SCHEDULE_OF_VALUE_TEMPLATES,
    payload: {
      templates: data,
    },
  });
  return true;
};

export const addScheduleOfValueTemplate = (payload) => async (dispatch) => {
  const {
    data: {
      templates,
      newTemplate,
    },
  } = await ProjectsService.addScheduleOfValueTemplate(payload);
  if (!newTemplate) return false;
  dispatch({
    type: CREATE_SCHEDULE_OF_VALUE_TEMPLATE,
    payload: {
      templates,
      newTemplate,
    },
  });
  return true;
};

export const updateScheduleOfValueTemplate = (templateId, payload) => async (dispatch) => {
  const { data } = await ProjectsService.updateScheduleOfValueTemplate(templateId, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_SCHEDULE_OF_VALUE_TEMPLATE,
    payload: {
      templates: data,
    },
  });
  return true;
};

export const deleteScheduleOfValueTemplate = (templateId) => async (dispatch) => {
  const { data } = await ProjectsService.deleteScheduleOfValueTemplate(templateId);
  if (!data) return false;
  dispatch({
    type: DELETE_SCHEDULE_OF_VALUE_TEMPLATE,
    payload: {
      templates: data,
    },
  });
  return true;
};

export const applyScheduleOfValueTemplate = (projectId, templateId) => async (dispatch) => {
  const { data } = await ProjectsService.applyScheduleOfValueTemplate(projectId, templateId);
  if (!data) return false;
  dispatch({
    type: APPLY_SCHEDULE_OF_VALUE_TEMPLATE,
    payload: {
      ...data,
    },
  });
  return true;
};

export const getProjectFileTemplates = () => async (dispatch) => {
  const { data } = await ProjectsService.getProjectFileTemplates();
  if (!data) return false;
  dispatch({
    type: GET_PROJECT_FILE_TEMPLATES,
    payload: {
      templates: data,
    },
  });
  return true;
};

export const addProjectFileTemplate = (payload) => async (dispatch) => {
  const { data } = await ProjectsService.addProjectFileTemplate(payload);
  if (!data) return false;
  dispatch({
    type: ADD_PROJECT_FILE_TEMPLATE,
    payload: {
      templates: data,
    },
  });
  return true;
};

export const updateProjectFileTemplate = (templateId, payload) => async (dispatch) => {
  const { data } = await ProjectsService.updateProjectFileTemplate(templateId, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_PROJECT_FILE_TEMPLATE,
    payload: {
      templates: data,
    },
  });
  return true;
};

export const deleteProjectFileTemplate = (templateId) => async (dispatch) => {
  const { data } = await ProjectsService.deleteProjectFileTemplate(templateId);
  if (!data) return false;
  dispatch({
    type: DELETE_PROJECT_FILE_TEMPLATE,
    payload: {
      templates: data,
    },
  });
  return true;
};

export const updateProjectProgressFilters = (filters) => (dispatch) => {
  dispatch({
    type: UPDATE_PROJECT_PROGRESS_FILTERS,
    payload: {
      filters,
    },
  });
};

export const getProjectGroups = () => async (dispatch) => {
  const { data } = await ProjectsService.getProjectGroups();
  if (!data) return false;
  dispatch({
    type: GET_PROJECT_GROUPS,
    payload: {
      projectGroups: data,
    },
  });
  return true;
};

export const createProjectGroup = (payload) => async (dispatch) => {
  const { data } = await ProjectsService.createProjectGroup(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_PROJECT_GROUP,
    payload: {
      projectGroups: data,
    },
  });
  return true;
};

export const updateProjectGroup = (id, payload) => async (dispatch) => {
  const { data } = await ProjectsService.updateProjectGroup(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_PROJECT_GROUP,
    payload: {
      projectGroups: data,
    },
  });
  return true;
};

export const deleteProjectGroup = (id) => async (dispatch) => {
  const { data } = await ProjectsService.deleteProjectGroup(id);
  if (!data) return false;
  dispatch({
    type: DELETE_PROJECT_GROUP,
    payload: {
      projectGroups: data,
    },
  });
  return true;
};

export const getProjectGroupNotes = (id) => async (dispatch) => {
  const { data } = await ProjectsService.getProjectGroupNotes(id);
  if (!data) return false;
  dispatch({
    type: GET_PROJECT_GROUP_NOTES,
    payload: {
      id,
      data,
    },
  });
  return true;
};

export const addProjectGroupNote = ({ id, text }) => async (dispatch) => {
  const { data } = await ProjectsService.addProjectGroupNote(id, text);
  if (!data) return false;
  dispatch({
    type: ADD_PROJECT_GROUP_NOTE,
    payload: data,
  });
  return true;
};

export const getProjectTypes = () => async (dispatch) => {
  const { data } = await ProjectsService.getProjectTypes();
  if (!data) return false;
  dispatch({
    type: GET_PROJECT_TYPES,
    payload: {
      customTypes: data,
    },
  });
  return true;
};

export const getProjectCostUpdates = (id) => async (dispatch) => {
  const { data } = await ProjectsService.getProjectCostUpdates(id);
  if (!data) return false;
  dispatch({
    type: GET_PROJECT_COST_UPDATES,
    payload: data,
  });
  return true;
};

export const getScheduleOfValueSections = ({
  projectId,
  templateId,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_READ'))) return false;
  const { data } = await ProjectsService.getScheduleOfValueSections({
    projectId,
    templateId,
  });

  if (!data) {
    return false;
  }

  dispatch({
    type: GET_SCHEDULE_OF_VALUE_SECTIONS,
    payload: {
      scheduleOfValueSections: data,
    },
  });
  return true;
};

export const createScheduleOfValueSection = ({
  projectId,
  templateId,
  payload,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;
  const { data } = await ProjectsService.createScheduleOfValueSection({
    projectId,
    templateId,
    payload,
  });

  if (!data) {
    return false;
  }

  dispatch({
    type: CREATE_SCHEDULE_OF_VALUE_SECTION,
    payload: {
      scheduleOfValueSection: data,
    },
  });
  return true;
};

export const updateScheduleOfValueSection = ({
  projectId,
  templateId,
  sectionId,
  payload,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;
  const { data } = await ProjectsService.updateScheduleOfValueSection({
    projectId,
    templateId,
    sectionId,
    payload,
  });

  if (!data) {
    return false;
  }

  dispatch({
    type: UPDATE_SCHEDULE_OF_VALUE_SECTION,
    payload: {
      data: {
        id: sectionId,
        ...payload,
      },
    },
  });
  return true;
};

export const deleteScheduleOfValueSection = ({
  projectId,
  templateId,
  sectionId,
}) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE'))) return false;
  const { data } = await ProjectsService.deleteScheduleOfValueSection({
    projectId,
    templateId,
    sectionId,
  });

  if (!data) {
    return false;
  }

  dispatch({
    type: DELETE_SCHEDULE_OF_VALUE_SECTION,
    payload: {
      id: sectionId,
    },
  });
  return true;
};

export const getProgressSubContractUpdates = (id, filters) => async (dispatch) => {
  const { data } = await ProjectsService.getProgressSubContractUpdates({
    projectId: id,
    filters,
  });
  if (!data) return false;
  dispatch({
    type: GET_PROGRESS_SUBCONTRACT_UPDATES,
    payload: {
      subContractUpdates: data,
    },
  });
  return true;
};

export const updateProgressSubContract = ({
  projectId,
  payload,
  projectName = '',
  files = [],
}) => async (dispatch) => {
  const { data } = await ProjectsService.updateProgressSubContract({
    projectId,
    payload,
    projectName,
    files,
  });
  if (!data) return false;
  dispatch({
    type: UPDATE_PROGRESS_SUBCONTRACT,
    payload: {
      subContractUpdates: data,
    },
  });
  return true;
};

export const updateProgressSubContractRow = ({
  row,
  payload,
  formId,
}) => (dispatch) => {
  const updatedRow = getUpdatedRow({ row, payload, isProgress: true });

  dispatch({
    type: UPDATE_PROGRESS_SUBCONTRACT_ROW,
    payload: {
      key: `${formId}.${row.rowId}`,
      value: updatedRow,
    },
  });

  return true;
};

export const deleteProgressSubContractUpdate = ({
  projectId,
  rowId,
}) => async (dispatch) => {
  const { data } = await ProjectsService.deleteProgressSubContractUpdate({
    projectId,
    rowId,
  });
  if (!data) return false;
  dispatch({
    type: DELETE_PROGRESS_SUBCONTRACT_UPDATE,
    payload: {
      subContractUpdates: data,
    },
  });
  return true;
};

export const swapRowsProgressSubContract = ({
  projectId,
  updatedRows,
}) => async (dispatch) => {
  const { data } = await ProjectsService.swapRowsProgressSubContract({
    projectId,
    payload: { updatedRows },
  });
  if (!data) return false;
  dispatch({
    type: SWAP_ROW_PROGRESS_SUBCONTRACT,
    payload: {
      subContractUpdates: data,
    },
  });
  return true;
};

export const getProjectCustomFieldTemplate = () => async (dispatch) => {
  const { data: template } = await ProjectsService.getCustomFieldTemplate();
  dispatch({
    type: GET_PROJECT_CUSTOM_FIELD_TEMPLATE,
    payload: {
      template,
    },
  });
};

export const updateProjectCustomFieldTemplate = (payload) => async (dispatch) => {
  const { data: template } = await ProjectsService.updateCustomFieldTemplate(payload);
  dispatch({
    type: UPDATE_PROJECT_CUSTOM_FIELD_TEMPLATE,
    payload: {
      template,
    },
  });
};

export const getProjectCustomData = (id) => async (dispatch) => {
  if (!id) return dispatch({ type: GET_PROJECT_CUSTOM_DATA, payload: { data: [] } });

  const { data } = await ProjectsService.getCustomData(id);
  await decorateFormWithFiles({ fileMap: data.fileMap });

  if (!data) return false;
  dispatch({
    type: GET_PROJECT_CUSTOM_DATA,
    payload: {
      data,
    },
  });
  return true;
};

export const getNotes = (id) => async (dispatch) => {
  const { data } = await ProjectsService.getNotes(id);
  if (!data) return false;
  dispatch({
    type: GET_PROJECT_NOTES,
    payload: {
      id,
      ...data,
    },
  });
  return true;
};

export const addNote = ({
  id, text, tags, files,
}) => async (dispatch) => {
  const { data } = await ProjectsService.addNote({
    id, text, tags, files,
  });
  if (!data) return false;
  dispatch({
    type: ADD_PROJECT_NOTE,
    payload: data,
  });
  return true;
};
