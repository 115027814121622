import React from 'react';
import { Checkbox } from 'antd';
import CompanyEditRow from './CompanyEditRow';

export default ({ 
  data,
  onChange = () => null,
  isChecked,
  checkBoxDisabled = () => false,
  getMultiView = () => null,
  getView = () => null,
}) => data.map((data,index) => {
  const {
    key, title, helpText, multi = false, divider = true, style,
  } = data;
  return (<CompanyEditRow
    key={key}
    title={title}
    helpText={helpText}
    divider={divider}
    style={style}
  >
    {multi ? 
      getMultiView()
      :
      getView(index) || <Checkbox 
        onChange={() => onChange(data)}
        style={{ marginRight:56 }}
        checked={isChecked(data)}
        disabled={checkBoxDisabled(data)}
    />}

  </CompanyEditRow>);
});