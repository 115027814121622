import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Descriptions,
  Select,
  Row,
  Col,
} from 'antd';

import HoverHelp from '../../common/HoverHelp';

import { DEFAULTS_HEADERS, COLOR_OPTIONS_MAP } from './BoardCardUploadHelpers';
import BoardLinkSelector from '../BoardLinkSelector';

import { stripText } from '../../common/excel/excelHelpers';

const { Option } = Select;

function BoardCardSelectHeader({
  selectedSheet = [],
  headerMapping,
  setHeaderMapping,
  customHeaders,
  link,
  onLinkChange,
  useCardNumberAsCardTitle,
}) {
  const boardHeaders = useMemo(() => [...DEFAULTS_HEADERS, ...customHeaders], [customHeaders]);

  const sheetHeaders = useMemo(() => {
    if (!selectedSheet || selectedSheet.length === 0) return [];
    const firstRow = selectedSheet.find((row = {}) => Object.keys(row).length > 0);
    return Object.keys(firstRow);
  }, [selectedSheet]);

  const matchHeader = useCallback((key) => {
    let match;
    boardHeaders.forEach((bh) => {
      const rk = stripText(key);
      const strippedTitle = stripText(bh.title);
      if (rk === strippedTitle) match = bh.key;
    });
    return match;
  }, [boardHeaders]);

  const onSelect = useCallback((key) => (newValue) => {
    setHeaderMapping({
      ...headerMapping,
      [key]: newValue,
    });
  }, [headerMapping]);

  useEffect(() => {
    const initialMapping = {};
    sheetHeaders.forEach((header) => {
      const foundMatch = matchHeader(header);
      if (foundMatch) initialMapping[foundMatch] = header;
    });
    setHeaderMapping(initialMapping);
  }, [sheetHeaders, matchHeader]);

  return (
    <>
      <Row>
        <Col style={{
          fontSize: '16px',
          fontWeight: 'bold',
          marginRight: '5px',
          marginBottom: 25,
        }}
        >
          Column Headers and Links:
        </Col>
        <Col>
          <HoverHelp
            placement="topRight"
            content={(
              <div style={{ width: 300 }}>
                Select which spreadsheet columns to map to the board card fields.
                Standard board card fields are title and color.
                Additional text fields are available from the selected Card Type of the Board.
                <br />
                <br />
                The selected link will be used for all imported board cards.
                <br />
                <br />
                The following colors are supported for mapping:
                <ul>
                  {Object.keys(COLOR_OPTIONS_MAP).map((color) => (
                    <li key={color}>{color}</li>
                  ))}
                </ul>
              </div>
            )}
          />
        </Col>
      </Row>
      <Descriptions title="Select Column Headers">
        {boardHeaders.map((boardHeader) => (
          <Descriptions.Item label={boardHeader.title} key={boardHeader.key}>
            <Select
              value={useCardNumberAsCardTitle && boardHeader.key === 'title' ? null : headerMapping[boardHeader.key]}
              style={{ width: 120 }}
              onSelect={onSelect(boardHeader.key)}
              disabled={boardHeader.key === 'title' && useCardNumberAsCardTitle}
            >
              {sheetHeaders.map((header) => (
                <Option value={header} key={header}>{header}</Option>
              ))}
            </Select>
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Row style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: 20 }}>
        Select Links
      </Row>
      <Row style={{ display: 'flex', flexDirection: 'column' }}>
        <BoardLinkSelector
          value={link}
          onChange={onLinkChange}
        />
      </Row>
    </>
  );
}

export default BoardCardSelectHeader;

/* eslint-disable react/forbid-prop-types */
BoardCardSelectHeader.propTypes = {
  selectedSheet: PropTypes.array,
  headerMapping: PropTypes.object,
  setHeaderMapping: PropTypes.func.isRequired,
  customHeaders: PropTypes.array,
  link: PropTypes.object,
  onLinkChange: PropTypes.func.isRequired,
  useCardNumberAsCardTitle: PropTypes.bool,
};

BoardCardSelectHeader.defaultProps = {
  selectedSheet: [],
  headerMapping: {},
  customHeaders: [],
  link: {},
  useCardNumberAsCardTitle: false,
};
