import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, DatePicker, TreeSelect,
} from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';

import DateRangePickerButtons from '../common/datepicker/DateRangePickerButtons';
import BorderlessButton from '../common/buttons/BorderlessButton';
import Colors from '../constants/Colors';
import { SCHEDULE_DATE_PICKER_TREE_OPTS } from './schedule.constants';

const typeMap = {
  Day: 'date',
  Week: 'week',
  Month: 'month',
  UserDay: 'date',
  UserBiWeekly: 'week',
};

const getFormat = (viewType) => {
  switch (viewType) {
    case 'Day':
    case 'UserDay':
      return 'MMM Do YY';
    case 'Week':
    case 'UserBiWeekly':
      return '[Week of] MMM Do YY';
    case 'Month': return 'MMM YYYY';
    default: return 'MMM YYYY';
  }
};

function ScheduleDatePicker({
  viewType,
  onViewTypeChange,
  onDateChange,
  date,
  onVisibleChanged,
}) {
  const [pickerOpen, setPickerOpen] = useState(false);

  const pickerValue = useMemo(() => moment(date.toMillis()), [date]);
  const format = useMemo(() => getFormat(viewType), [viewType]);

  const onButtonPress = useCallback((left) => () => {
    let mod = viewType.toLowerCase();
    if (mod === 'userday') mod = 'day';
    const isBiWeekly = viewType === 'UserBiWeekly';
    const dateShift = isBiWeekly ? { week: 2 } : { [mod]: 1 };
    if (left) {
      onDateChange(date.minus(dateShift));
    } else {
      onDateChange(date.plus(dateShift));
    }
  }, [date, viewType, onDateChange]);

  const getFooter = useCallback(() => {
    let label = '';
    switch (typeMap[viewType]) {
      case typeMap.Week:
        label = 'This Week';
        break;
      case typeMap.Month:
        label = 'This Month';
        break;
      default:
        return null;
    }
    return (
      <BorderlessButton
        title={label}
        onClick={() => {
          onDateChange(DateTime.local());
          setPickerOpen(false);
        }}
        style={{ color: Colors.ONTRACCR_RED }}
      />
    );
  }, [viewType, onDateChange]);

  useEffect(() => {
    if (onVisibleChanged) onVisibleChanged(pickerOpen);
  }, [onVisibleChanged, pickerOpen]);

  return (
    <Row gutter={10}>
      <Col>
        <TreeSelect
          treeData={SCHEDULE_DATE_PICKER_TREE_OPTS}
          defaultValue={SCHEDULE_DATE_PICKER_TREE_OPTS[1].value}
          value={viewType}
          style={{ width: 125 }}
          onSelect={(type) => onViewTypeChange(type)}
        />
      </Col>
      <Col>
        <DateRangePickerButtons
          left
          onClick={onButtonPress(true)}
        />
      </Col>
      <Col>
        <DatePicker
          className="schedule-date-picker"
          key={viewType}
          picker={typeMap[viewType]}
          onChange={onDateChange}
          value={pickerValue}
          format={format}
          allowClear={false}
          renderExtraFooter={getFooter}
          open={pickerOpen}
          onOpenChange={setPickerOpen}
        />
      </Col>
      <Col>
        <DateRangePickerButtons
          onClick={onButtonPress(false)}
        />
      </Col>
    </Row>
  );
}

ScheduleDatePicker.propTypes = {
  viewType: PropTypes.string.isRequired,
  onViewTypeChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(DateTime).isRequired,
  onVisibleChanged: PropTypes.func,
};

ScheduleDatePicker.defaultProps = {
  onVisibleChanged: null,
};

export default ScheduleDatePicker;
