import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import ProfileAvatar from '../ProfileAvatar';
import SelectLabelFilter from '../../forms/FormBuilder/FormFields/SelectLabelFilter';

export default function FilteredUserSelection({
  users = [],
  onChange,
  value,
  mode = 'multiple',
  allowClear,
  placeholder,
  filterStyle = null,
  selectStyle = {},
  className = '',
  id = '',
}) {
  const userToLabel = useSelector((state) => state.users.userToLabel);
  const [filterLabels, setFilterLabels] = useState([]);

  const filteredUsers = useMemo(() => (
    filterLabels.length === 0
      ? users
      : users.filter((user) => {
        const {
          [user.id]: userLabels = [],
        } = userToLabel;
        const userLabelSet = new Set(userLabels);
        return filterLabels.every((label) => userLabelSet.has(label));
      })
  ), [users, userToLabel, filterLabels]);
  return (
    <div className={className} id={id}>
      <SelectLabelFilter
        selected={filterLabels}
        onSelectedChanged={setFilterLabels}
        style={filterStyle ?? { margin: '15px 10px 5px 0px' }}
        type="users"
      />
      <Select
        className="OnTraccrSelect"
        optionLabelProp="label"
        optionFilterProp="label"
        mode={mode}
        onChange={onChange}
        value={value}
        showSearch
        allowClear={allowClear}
        placeholder={placeholder}
        style={selectStyle}
      >
        {
          filteredUsers.map((user) => (
            <Select.Option
              key={user.id}
              value={user.id}
              label={user.name}
            >
              <ProfileAvatar
                role={user.position}
                name={user.name}
              />
            </Select.Option>
          ))
        }
      </Select>
    </div>
  );
}
