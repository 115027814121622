import React from "react";
import { Row, Typography, Tag } from 'antd';
import ListCard from '../common/ListCard';
import colors from '../constants/Colors';
import { useSelector } from "react-redux";

import Permissions from '../auth/Permissions';

export default ({
  id,
  name,
  description,
  divisionId,
  code,
  category,
  hourlyWage,
  dailyWage,
  wageMultiplier,
  hourlyBillingRate,
  dailyBillingRate,
  procoreId,
  intuitId,
  sageId,
  active,
  onArchive,
  onEdit,
  onDelete,
  onClick,
  style,
}) => {
  const {
    [divisionId]: {
      name: divisionName = '',
    } = {},
  } = useSelector(state => state.settings.divisions);

  const categoryMap = useSelector(state => state.costcodes.categories);

  const {
    [category]: {
      id: categoryId,
    } = {}
  } = categoryMap;

  return (
  <ListCard
    style={style}
    onArchive={Permissions.has('COSTCODES_WRITE') ? () => onArchive({
      name,description,code,id,active,
    }) : null}
    onEdit={Permissions.has('COSTCODES_WRITE') ? () => onEdit({
      id,
      name,
      description,
      code,
      active,
      divisionId,
      categoryId,
      hourlyWage,
      dailyWage,
      wageMultiplier,
      hourlyBillingRate,
      dailyBillingRate,
    }) : null}
    onDelete={Permissions.has('COSTCODES_WRITE') ? () => onDelete({
      id,name,description,code,active,
    }) : null}
    onClick={() => onClick({
      id,
      name,
      description,
      code,
      active,
      procoreId,
      intuitId,
      sageId,
      divisionId,
      categoryId,
      hourlyWage,
      dailyWage,
      wageMultiplier,
      hourlyBillingRate,
      dailyBillingRate,
    })}
    isActive={active}
  >
    <Row style={{
      height:17,
      width:'100%',
      paddingBottom:25,
      paddingTop:6,
      overflowWrap:'anywhere',
      }} type='flex'>
      <Typography.Text
          ellipsis
          style={{
            width:'100%',
            fontFamily:'roboto-bold',
            fontSize:16,
            color:colors.ONTRACCR_BLACK,
          }}>
          {`${code} - ${name}`}
        </Typography.Text>
    </Row>
    <Row>
      <Typography.Text ellipsis
         style={{
          width:'100%',
          fontFamily:'roboto-light',
          fontSize:12,
          color:colors.DARK_GRAY,
          lineHeight:'12px'
        }}>
          {divisionName}
        </Typography.Text>
    </Row>
    <Row style={{ margin: '10px 0px 5px 0px'}}>
      <Typography.Text style={{
        fontFamily:'roboto-regular',
        fontSize:14,
        color:colors.ONTRACCR_BLACK,
        lineHeight:'14px'
      }}>
        Description
      </Typography.Text>
    </Row>
    <Row style={{
      width:'100%',
    }} type='flex' align={description.length > 0 ? 'top' : 'middle'} justify={description.length > 0 ? 'start' : 'center'}>
      <Typography.Paragraph  style={{
        color:description.length > 0 ? colors.ONTRACCR_BLACK : colors.ONTRACCR_OPACITY_GRAY,
        fontFamily:'roboto-light',
        fontSize:12,
        overflowX: 'hidden',
        maxHeight: 40,
      }} ellipsis={{ rows: 2 }}>
        {description}
      </Typography.Paragraph>
    </Row>
    <Row>
      <Tag>{category}</Tag>
    </Row>
  </ListCard>
)};