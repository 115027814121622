/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  Row,
  Col,
  Radio,
  Checkbox,
} from 'antd';

import TitleRow from './TitleRow';
import OptionalRow from './OptionalRow';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import HoverHelp from '../../../common/HoverHelp';

import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import FieldTriggerFlag from './FieldTriggerFlag';
import ConditionalRenderingRow from './ConditionalRenderingRow';

const configure = ({
  setConfigProps,
  configProps = {},
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  id,
  formTemplates = [],
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  labels = [],
  contactAddressBooks = {},
  buckets = [],
} = {}) => {
  const {
    optional,
    explain = [],
    hasConditionalRendering = false,
    conditionalRenderingFormula,
  } = configProps;

  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title: value,
    });
  };

  const setExplain = (key) => (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    let newExplain = [...explain];
    if (checked) {
      newExplain.push(key);
    } else {
      newExplain = newExplain.filter((item) => item !== key);
    }
    setConfigProps({
      ...configProps,
      explain: newExplain,
    });
  };

  const setOptional = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      optional: checked,
    });
  };

  const setConditionalRenderingFormula = (formula) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: formula,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setFieldTrigger = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      fieldTrigger: checked,
    });
  };

  return (
    <div>
      <Row className="form-required-field">
        Title:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <OnTraccrTextInput
          textarea
          placeholder="Insert title here"
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <OptionalRow onChange={setOptional} optional={optional} disabled={disableOptional}/>
      <ConditionalRenderingRow
        onChange={setHasConditionalRendering}
        onFormulaChange={setConditionalRenderingFormula}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
        sections={sections}
        id={id}
        users={users}
        customers={customers}
        projects={projects}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
      />
      <Row style={{ marginTop: 20 }} gutter={10}>
        <Col>
          Requires Explanation:
        </Col>
        <Col>
          <HoverHelp placement="top" content="Select which options require a user explanation" />
        </Col>
      </Row>
      <Row style={{ marginTop: 5 }}>
        <Checkbox
          onChange={setExplain('yes')}
          checked={explain.includes('yes')}
        >
          Require explanation for 'Yes' answers
        </Checkbox>
      </Row>
      <Row>
        <Checkbox
          onChange={setExplain('no')}
          checked={explain.includes('no')}
        >
          Require explanation for 'No' answers
        </Checkbox>
      </Row>
      <Row>
        <Checkbox
          onChange={setExplain('n/a')}
          checked={explain.includes('n/a')}
        >
          Require explanation for 'N/A' answers
        </Checkbox>
      </Row>
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={setFieldTrigger}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          isExternalForm={isExternalForm}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  );
};

const preview = ({
  setPreviewProps,
  configProps = {},
  previewProps = {},
  id,
  responses = {},
  setResponses,
  responding = false,
  sections,
  isExternalForm,
  projectId,
  templateId,
  divisions,
  fieldTriggerMap = {},
  setFieldTriggerMap,
  isDisplay,
  name,
} = {}) => {
  const {
    optional,
    title = 'Title goes here',
    explain = [],
    fieldTrigger,
  } = configProps;

  const { selected, explanation } = previewProps;

  const {
    [id]: {
      value: respSelected,
      explanation: respExplanation = '',
    } = {},
  } = responding ? responses : {};

  const actualSelected = responding ? respSelected : selected;
  const explanationValue = responding ? respExplanation : explanation;

  const setSelected = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;

    let newExplanationValue = explanationValue;

    // Clear value if new selection does not require explanation
    if (!explain.includes(value)) {
      newExplanationValue = null;
    }

    if (responding) {
      const newResp = {
        [id]: {
          ...(responses[id]),
          value,
          explanation: newExplanationValue,
        },
      };
      setResponses({
        ...responses,
        ...newResp,
      });
    } else {
      if (!setPreviewProps) return;
      setPreviewProps({
        ...previewProps,
        selected: value,
        explanation: newExplanationValue,
      });
    }
  };
  const onTextChange = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    if (responding) {
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          explanation: value,
        },
      });
    } else if (!setPreviewProps) return;
    setPreviewProps({
      ...previewProps,
      explanation: value,
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <TitleRow
        title={title}
        optional={optional}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                isExternalForm={isExternalForm}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                id={id}
                fieldTriggerMap={fieldTriggerMap}
                setFieldTriggerMap={setFieldTriggerMap}
                name={name}
              />
            ) : null
        }
      />
      <Row style={{ marginTop: 10 }}>
        <Radio.Group onChange={setSelected} value={actualSelected}>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
          <Radio value="n/a">N/A</Radio>
        </Radio.Group>
      </Row>
      {
        !!actualSelected && explain.includes(actualSelected)
        && (
        <Row style={{ marginTop: 15 }}>
          <OnTraccrTextInput
            placeholder="Enter additional details"
            textarea
            value={explanationValue}
            onChange={onTextChange}
          />
        </Row>
        )
      }
    </div>
  );
};

export default {
  configure,
  preview,
  title: 'Yes/No',
  description: 'User can select Yes or No. You can also require the user to enter an explanation',
};
