import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Divider,
  Row,
  Col,
} from 'antd';
import PropTypes from 'prop-types';

import FormDefaultData from './FormDefaultData';
import { collectedDataMap } from '../forms.constants';

import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DisplayText from '../../common/text/DisplayText';
import HoverHelp from '../../common/HoverHelp';
import DivisionSelector from '../../common/inputs/DivisionSelector';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import NuxPopover from '../../nux/NuxPopover';

import FormTypeSelector from '../FormTypeSelector';
import FormNumber from './FormNumber';
import FormCheckbox from './FormCheckbox';
import FormImportDrawer from './FormImportDrawer';
import ExternalFormCheckbox from './ExternalFormCheckbox';
import FormLibraryImportDrawer from '../FormLibrary/FormLibraryImportDrawer';

import {
  FORMS_ADD_DETAIL_TYPE,
  FORMS_ADD_NUX_STEP_1,
  FORMS_ADD_NUX_STEP_1_CHECK_TEXT,
} from '../../nux/nux.constants';

import {
  createNuxEntry,
} from '../../nux/state/nux.actions';

import config from '../../config';
// import {
//   compareToFirstPasswordHelper,
//   validatePasswordFormatHelper,
// } from '../../helpers/helpers';
import FormColorPicker from '../../common/inputs/FormColorPicker';
import FormTemplatesDisplayFieldSettings from '../FormTemplatesDisplayFieldSettings';

let baseEndpoint = 'https://app.ontraccr.com/';
if (config.env === 'alpha') {
  baseEndpoint = 'https://app.alpha.ontraccr.com/';
}

export default function FormDetails({
  formRef,
  name,
  type,
  typeId,
  onCollectedChanged,
  onCollectFullUpdate,
  onDivisionChanged,
  canEdit,
  onEditChanged,
  canResubmit,
  onResubmitChanged,
  canReassign,
  onReassignChanged,
  canSubmitChild,
  onSubmitChildChanged,
  isExternalForm,
  onIsExternalFormChanged,
  onSectionsChanged,
  onWorkflowChange,
  onDrawOptionsChanged,
  onFileMapChanged,
  currentStep,
  collected = {},
  isDisplay = false,
  isAdd = false,
  canAddGlobal, // Project specific forms can be copied in from global.
  canAddFromLibrary, // Global templates can be imported from library
  externalFormURL,
  setLibraryTemplateStatusesToAdd,
  onStandardTemplateChanged,
  setPrevUseStandardTemplate,
  displayFields,
  setDisplayFields,
  fields,
  showInfoBar,
  onShowInfoBarChange,
  setPDFName,
}) {
  const dispatch = useDispatch();

  const activeNuxAction = useSelector((state) => state.nux.activeNuxAction);
  const nux = useSelector((state) => state.nux.nux);
  const isExternalFormEnabled = useSelector((state) => (
    state.settings.company.isExternalFormEnabled
  ));

  const [stateCollect, setStateCollect] = useState({});
  const [formTypeId, setFormTypeId] = useState(typeId);
  const [showImportDrawer, setShowImportDrawer] = useState(false);

  const onShowImportClicked = useCallback(() => setShowImportDrawer(true), []);
  const onHideImport = useCallback(() => setShowImportDrawer(false), []);
  const onImportGlobalTemplate = useCallback((updateDivisionId) => async (template = {}) => {
    if (!formRef || !formRef.current) return;
    const {
      divisionId,
      allowEdit,
      allowResubmission,
      drawOptions = [],
      formData: {
        name: templateName,
        typeId,
        collected: newCollect = {},
        sections,
        workflow,
        useStandardTemplate = true,
      } = {},
      fileMap,
      pdfName,
    } = template;

    const updateObj = {
      name: templateName,
      typeId,
    };
    if (updateDivisionId) updateObj.divisionId = divisionId;

    formRef.current.setFieldsValue(updateObj);

    onStandardTemplateChanged(useStandardTemplate);
    onCollectFullUpdate(newCollect);
    onEditChanged(allowEdit);
    onResubmitChanged(allowResubmission);
    onWorkflowChange(workflow);
    setFormTypeId(typeId);
    setStateCollect(newCollect);
    onDrawOptionsChanged(drawOptions);
    onFileMapChanged(fileMap);
    setPrevUseStandardTemplate(useStandardTemplate);
    setPDFName(pdfName ?? '[Form Name]-[Form Author]-[Date]');

    // Delay this so workflow can update before key.
    // See line 185 and 218 in FormWorkflows.js
    setTimeout(() => {
      formRef.current.setFieldsValue(updateObj);
      if (updateDivisionId) onDivisionChanged(divisionId);
      onSectionsChanged(sections);
    }, 500);

    setShowImportDrawer(false);
  }, [formRef, onWorkflowChange, onStandardTemplateChanged]);

  const onChange = ({ key, checked, subKey }) => {
    onCollectedChanged({ key, data: { [subKey]: checked } });
    setStateCollect({
      ...stateCollect,
      key: {
        ...stateCollect[key],
        [subKey]: checked,
      },
    });
  };
  const onCheckChange = (key) => (checked) => onChange({ key, checked, subKey: 'collect' });
  const onEditableChange = (key) => (checked) => onChange({ key, checked, subKey: 'editable' });

  useEffect(() => {
    setStateCollect(collected);
  }, [collected]);

  useEffect(() => {
    if (
      currentStep === 0
      && isAdd
      && dispatch
      && !nux.has(FORMS_ADD_DETAIL_TYPE)
    ) {
      dispatch(createNuxEntry(FORMS_ADD_DETAIL_TYPE));
      setTimeout(() => {
        // dispatch(startNuxAction(FORMS_ADD_NUX_STEP_1));
      }, 450); // Wait 450 seconds for drawer open animation.
    }
  }, [isAdd, nux, dispatch, currentStep]);

  const showNux = activeNuxAction === FORMS_ADD_NUX_STEP_1;

  const importTitle = canAddGlobal
    ? 'Import from Global Forms'
    : 'Import from Form Library';

  // const compareToFirstPassword = compareToFirstPasswordHelper(formRef, 'externalFormPassword');
  // const validateFormat = validatePasswordFormatHelper(formRef, 'externalFormPasswordConfirm', true);

  return (
    <>
      <div className={showNux ? 'form-detail-nux-view' : null}>
        <Row justify='start' gutter={40}>
          <Col span={8}>
            <Form.Item
              name='name'
              label='Form Name'
              rules={[
                { required: !isDisplay, message: 'Please enter the form name' },
              ]}
            >
              {
                isDisplay
                  ? <DisplayText title={name} style={{ fontSize: 14, marginBottom: 0 }}/>
                  : <OnTraccrTextInput/>

              }
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name='typeId'
              label='Type'
              style={{ marginBottom: 0 }}
              rules={[{ required: !isDisplay, message: 'Select a type' }]}
              >
                <FormTypeSelector
                  isDisplay={isDisplay}
                  displayValue={type}
                  displayStyle={{ marginBottom: 0, fontSize: 14 }}
                  onChange={setFormTypeId}
                />
            </Form.Item>
          </Col>
          <Col span={8}>
          {
            config.showDivisions &&
            <Form.Item
                name='divisionId'
                key='divisionId'
                label='Division'
                style={{ marginBottom: 0 }}
                rules={[{ required: !isDisplay, message: 'Select a division' }]}
                valuePropName='divisionId'
                >
                  <DivisionSelector
                    displayMode={isDisplay}
                    displayStyle={{ marginBottom: 0, fontSize: 14 }}
                    onChange={onDivisionChanged}
                  />
              </Form.Item>
          }
          </Col>
        </Row>
        <Divider/>
        <Form.Item
          name='defaultData'
          label={
            <Row gutter={10}>
              <Col>
                <HoverHelp
                  placement='left'
                  content={
                    <div style={{ width: 300 }}>
                      These fields will automatically be attached to the form by OnTraccr.
                      <br/><br/>
                      If you set the field to <b>Editable</b> the user filling out the form
                      will be able to override the automatically attached data.
                    </div>
                  }
                />
              </Col>
              <Col>
                Automatically Collected Data
              </Col>

            </Row>
          }
        />
        <FormDefaultData title='Date and Time' disabled isDisplay={isDisplay}/>
        { !isExternalForm ? (
          <>
            <FormDefaultData title={collectedDataMap.employeeName.title} disabled isDisplay={isDisplay}/>
            <FormDefaultData title={collectedDataMap.employeeId.title} disabled isDisplay={isDisplay}/>
            <NuxPopover
              placement='left'
              text={FORMS_ADD_NUX_STEP_1_CHECK_TEXT}
              visible={showNux}
            >
              <FormDefaultData
                title='Collect Signature'
                onCheckChange={onCheckChange('employeeSignature')}
                onEditableChange={onEditableChange('employeeSignature')}
                checkName='employeeSignature'
                values={stateCollect.employeeSignature}
                isDisplay={isDisplay}
              />
            </NuxPopover>
            <FormDefaultData
              title='Geolocation'
              onCheckChange={onCheckChange('geolocation')}
              onEditableChange={onEditableChange('geolocation')}
              checkName='geolocation'
              values={stateCollect.geolocation}
              isDisplay={isDisplay}
            />
            <FormNumber
              typeId={formTypeId}
              onCheckChange={onCheckChange('number')}
              value={stateCollect.number}
              isDisplay={isDisplay}
            />
          </>
        ) : null }
        <br />
        <Form.Item
          name='formOptions'
          label={
            <Row gutter={10}>
              <Col style={{ fontWeight: 650 }}>
                Form Options
              </Col>
            </Row>
          }
          style={{ marginBottom: 0 }}
        />
        <FormCheckbox
          title="Allow Edit"
          isDisplay={isDisplay}
          value={canEdit}
          onCheckChange={onEditChanged}
          tooltip={(
            <div style={{ maxWidth: 400 }}>
              Check this box to allow users with <b>Manage Forms</b> permission to
              edit a form during any workflow step.
              <br /><br />
              When a form is edited, the workflow will not be triggered again.
              <br />
              The current workflow will continue with the added changes.
            </div>
          )}
        />
        <FormCheckbox
          title="Allow Resubmission"
          isDisplay={isDisplay}
          value={canResubmit}
          onCheckChange={onResubmitChanged}
          tooltip={(
            <div style={{ maxWidth: 400 }}>
              Check this box to allow users with <b>Manage Forms</b> permission to resubmit a form.
              <br/><br/>
              When a form is resubmitted, the workflow will be triggered again.
              <br/>
              Emails, notifications and approvals will be re-sent.
              <br/>
              Shifts and form triggers will be updated.
            </div>
          )}
        />
        <FormCheckbox
          title="Allow Reassignment"
          isDisplay={isDisplay}
          value={canReassign}
          onCheckChange={onReassignChanged}
        />
        <FormCheckbox
          title="Allow Child Form Submission"
          isDisplay={isDisplay}
          value={canSubmitChild}
          onCheckChange={onSubmitChildChanged}
          tooltip={(
            <div style={{ maxWidth: 400 }}>
              Check this box to allow users to directly
              {' '}
              submit a child form to this parent form.
              <br />
              <br />
              Please note that the available child forms
              {' '}
              are only those that have been configured
              {' '}
              with a &apos;Form Submissions&apos; dropdown field
              {' '}
              tied to this form template.
            </div>
          )}
        />
        <ExternalFormCheckbox
          isDisplay={isDisplay}
          value={isExternalForm}
          onCheckChange={onIsExternalFormChanged}
          isExternalFormEnabled={isExternalFormEnabled}
        />
        <FormCheckbox
          title="Show Info Bar"
          isDisplay={isDisplay}
          value={showInfoBar}
          onCheckChange={onShowInfoBarChange}
          tooltip={(
            <div style={{ maxWidth: 400 }}>
              Check this box to show an info bar on each form indicating
              when a form was triggered and which form triggered it.
              <br />
              <br />
              Having this checked will allow users to view the parent form.
            </div>
          )}
        />
        {
          (canAddGlobal || canAddFromLibrary) ?
          <div className='form-template-global-import'>
            <OnTraccrButton title={importTitle} onClick={onShowImportClicked}/>
          </div>
          : null
        }
       {canAddGlobal && <FormImportDrawer
          visible={showImportDrawer}
          onClose={onHideImport}
          onSubmit={onImportGlobalTemplate(true)}
        />}

        {canAddFromLibrary && <FormLibraryImportDrawer
          visible={showImportDrawer}
          onClose={onHideImport}
          onSubmit={onImportGlobalTemplate(false)}
          setTemplateStatusesToAdd={setLibraryTemplateStatusesToAdd}
        />}
        { isExternalForm ? (
          <>
            <Form.Item name="externalFormURL" label="External Form URL">
              <DisplayText title={`${baseEndpoint}${externalFormURL}`} style={{ fontSize: 14, marginBottom: 0 }}/>
            </Form.Item>
            {/* <Form.Item
              name="externalFormPassword"
              label="External Form Password"
              hasFeedback
              rules={[
                { validator: validateFormat }
              ]}
            >
              <OnTraccrTextInput password />
            </Form.Item>
            <Form.Item
              name="externalFormPasswordConfirm"
              label="Confirm External Form Password"
              hasFeedback
              rules={[
                { validator: compareToFirstPassword }
              ]}
            >
              <OnTraccrTextInput password />
            </Form.Item> */}
            <Form.Item
              name="externalFormPrimaryColor"
              label="External Form Primary Color"
              className="form-detail-color-picker"
            >
              <FormColorPicker
                isNotDisplay={!isDisplay}
              />
            </Form.Item>
            <DisplayText title="This is the main color for your external form" />
            <Form.Item
              name="externalFormAccentColor"
              label="External Form Accent Color"
              className="form-detail-color-picker"
            >
              <FormColorPicker
                isNotDisplay={!isDisplay}
              />
            </Form.Item>
            <DisplayText title="Your external form buttons will have this color" />
            <Form.Item
              name="externalFormSecondaryAccentColor"
              label="External Form Secondary Accent Color"
              className="form-detail-color-picker"
            >
              <FormColorPicker
                isNotDisplay={!isDisplay}
              />
            </Form.Item>
            <DisplayText title="Your secondary external form buttons will have this color" />
          </>
        ) : null }
        <br />
        { !!(!isDisplay || displayFields?.length) && (
          <Form.Item
            name='assignedFormDisplay'
            label={
              <Row gutter={10}>
                <Col style={{ fontWeight: 650 }}>
                  Assigned/Approved Form Display:
                </Col>
                <Col>
                  <HoverHelp
                    placement='right'
                    content={
                      <div style={{ width: 300 }}>
                        The fields below will be displayed on the assigned and approved forms page.
                        <br/><br/>
                        Fields should be entered in the order you want them to appear (left to right),
                        but can be rearranged below.
                      </div>
                    }
                  />
                </Col>
              </Row>
            }
            style={{ marginBottom: 0 }}
          />
        )}
        <FormTemplatesDisplayFieldSettings
          isDisplay={isDisplay}
          displayFields={displayFields}
          setDisplayFields={setDisplayFields}
          fields={fields}
        />
      </div>
    </>
  );
}

FormDetails.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.shape({
      setFieldsValue: PropTypes.func,
    }),
  }),
  name: PropTypes.string,
  type: PropTypes.string,
  typeId: PropTypes.number,
  onCollectedChanged: PropTypes.func,
  onCollectFullUpdate: PropTypes.func,
  onDivisionChanged: PropTypes.func,
  canEdit: PropTypes.bool,
  onEditChanged: PropTypes.func,
  canResubmit: PropTypes.bool,
  onResubmitChanged: PropTypes.func,
  canReassign: PropTypes.bool,
  onReassignChanged: PropTypes.func,
  canSubmitChild: PropTypes.bool,
  onSubmitChildChanged: PropTypes.func,
  isExternalForm: PropTypes.bool,
  onIsExternalFormChanged: PropTypes.func,
  onSectionsChanged: PropTypes.func,
  onWorkflowChange: PropTypes.func,
  onDrawOptionsChanged: PropTypes.func,
  onFileMapChanged: PropTypes.func,
  currentStep: PropTypes.number,
  collected: PropTypes.shape({
    employeeSignature: PropTypes.shape({
      collect: PropTypes.bool,
      editable: PropTypes.bool,
    }),
    geolocation: PropTypes.shape({
      collect: PropTypes.bool,
      editable: PropTypes.bool,
    }),
    number: PropTypes.shape({
      collect: PropTypes.bool,
      editable: PropTypes.bool,
    }),
  }),
  isDisplay: PropTypes.bool,
  isAdd: PropTypes.bool,
  canAddGlobal: PropTypes.bool,
  canAddFromLibrary: PropTypes.bool,
  externalFormURL: PropTypes.string,
  setLibraryTemplateStatusesToAdd: PropTypes.func,
  onStandardTemplateChanged: PropTypes.func,
  setPrevUseStandardTemplate: PropTypes.func,
  displayFields: PropTypes.arrayOf(PropTypes.shape({})),
  setDisplayFields: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  showInfoBar: PropTypes.bool,
  onShowInfoBarChange: PropTypes.func,
  setPDFName: PropTypes.func.isRequired,
};

FormDetails.defaultProps = {
  formRef: null,
  name: '',
  type: '',
  typeId: null,
  onCollectedChanged: null,
  onCollectFullUpdate: null,
  onDivisionChanged: null,
  canEdit: false,
  onEditChanged: null,
  canResubmit: false,
  onResubmitChanged: null,
  canReassign: false,
  onReassignChanged: null,
  canSubmitChild: false,
  onSubmitChildChanged: null,
  isExternalForm: false,
  onIsExternalFormChanged: null,
  onSectionsChanged: null,
  onWorkflowChange: null,
  onDrawOptionsChanged: null,
  onFileMapChanged: null,
  currentStep: 0,
  collected: {},
  isDisplay: false,
  isAdd: false,
  canAddGlobal: false,
  canAddFromLibrary: false,
  externalFormURL: '',
  setLibraryTemplateStatusesToAdd: null,
  onStandardTemplateChanged: null,
  setPrevUseStandardTemplate: null,
  displayFields: [],
  setDisplayFields: null,
  fields: [],
  showInfoBar: false,
  onShowInfoBarChange: null,
};
