import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import FormColorPicker from '../../common/inputs/FormColorPicker';

import { BASE_COL_WIDTH, COLOR_OPTIONS_MAP } from './BoardCardUploadHelpers';
import { stripText } from '../../common/excel/excelHelpers';

function BoardCardUploadPreview({
  selectedSheet,
  headerMapping,
  onMassUploadDataChanged,
  customHeaders,
  useCardNumberAsCardTitle,
}) {
  const [data, setData] = useState([]);

  const onValueChanged = useCallback((index, newData) => {
    setData(
      data.map((datum, idx) => {
        if (idx !== index) return datum;
        return {
          ...datum,
          ...newData,
        };
      }),
    );
  }, [data]);

  const onDelete = useCallback((id) => {
    setData(data.filter((datum) => datum.id !== id));
  }, [data]);

  const titleToKeyMap = useMemo(() => {
    // Convert header mappings from key: title to title: key
    const convertedMap = {};
    Object.keys(headerMapping).forEach((key) => {
      const title = headerMapping[key];
      if (!(title in convertedMap)) {
        convertedMap[title] = [];
      }
      convertedMap[title].push(key);
    });
    return convertedMap;
  }, [headerMapping]);

  useEffect(() => {
    const newData = selectedSheet.map((row, idx) => {
      const parsedRow = {};
      Object.keys(row).forEach((rowTitle) => {
        const {
          [rowTitle]: rowKeys = [],
        } = titleToKeyMap;
        if (rowKeys.length === 0) return;
        rowKeys.forEach((rowKey) => {
          const columnValue = row[rowTitle];
          const strippedString = stripText(String(columnValue));
          if (rowKey === 'color' && strippedString in COLOR_OPTIONS_MAP) {
            parsedRow[rowKey] = COLOR_OPTIONS_MAP[strippedString];
          } else if (rowKey === 'color') {
            parsedRow[rowKey] = COLOR_OPTIONS_MAP.white;
          } else {
            parsedRow[rowKey] = columnValue;
          }
        });
      });
      parsedRow.id = idx;
      return parsedRow;
    });
    setData(newData);
  }, [titleToKeyMap, selectedSheet]);

  useEffect(() => {
    onMassUploadDataChanged((prevState) => ({
      ...prevState,
      data,
    }));
  }, [data]);

  const columns = useMemo(() => {
    const relevantCols = [
      {
        title: '',
        dataIndex: '',
        width: 30,
        render: (_, record) => (
          <BorderlessButton
            iconNode={<DeleteOutlined style={{ color: 'red', marginLeft: 0 }} />}
            onClick={() => onDelete(record.id)}
          />
        ),
      }, {
        title: 'Title',
        dataIndex: 'title',
        width: BASE_COL_WIDTH,
        render: (value, _, index) => (
          <OnTraccrTextInput
            value={useCardNumberAsCardTitle ? null : value}
            onChange={(e) => {
              const {
                target: {
                  value: newValue,
                } = {},
              } = e;
              onValueChanged(index, { title: newValue });
            }}
            disabled={useCardNumberAsCardTitle}
            style={value === '' ? { color: 'red', borderColor: 'red' } : {}}
          />
        ),
      }, {
        title: 'Color',
        dataIndex: 'color',
        width: BASE_COL_WIDTH,
        render: (value, _, index) => (
          <FormColorPicker
            isNotDisplay
            value={value}
            onChange={(newValue) => onValueChanged(index, { color: newValue })}
          />
        ),
      },
    ];

    if (customHeaders.length > 0) {
      customHeaders.forEach((ch) => {
        relevantCols.push({
          title: ch.title,
          dataIndex: ch.key,
          width: BASE_COL_WIDTH,
          render: (value, _, index) => (
            <OnTraccrTextInput
              value={value}
              onChange={(e) => {
                const {
                  target: {
                    value: newValue,
                  } = {},
                } = e;
                onValueChanged(index, { [ch.key]: newValue });
              }}
            />
          ),
        });
      });
    }

    return relevantCols;
  }, [onDelete, onValueChanged, customHeaders]);

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={data}
      scroll={{ x: 'max-content' }}
    />
  );
}

export default BoardCardUploadPreview;

/* eslint-disable react/forbid-prop-types */
BoardCardUploadPreview.propTypes = {
  selectedSheet: PropTypes.array,
  headerMapping: PropTypes.object,
  onMassUploadDataChanged: PropTypes.func.isRequired,
  customHeaders: PropTypes.array,
  useCardNumberAsCardTitle: PropTypes.bool,
};

BoardCardUploadPreview.defaultProps = {
  selectedSheet: [],
  headerMapping: {},
  customHeaders: [],
  useCardNumberAsCardTitle: false,
};
