import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';

import { getIdMap } from '../helpers/helpers';
import { clearFormSelection, getAssignedFormTemplates, getTemplateDetails } from '../forms/state/forms.actions';
import { getProjects } from '../projects/state/projects.actions';
import { getPayableFormOptions } from './payableHelpers';
import {
  PO_FORM_RESPONDER_MODE,
  SUB_CONTRACT_FORM_RESPONDER_MODE,
} from '../forms/ResponderHelpers';
import FormResponder from '../forms/FormResponder';
/** Drawer component which allows user to select and submit a PO form  */
function PurchaseOrderDrawer({
  projectId,
  type,
  onClose,
}) {
  const visible = !!type;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const assignedFormTemplates = useSelector((state) => state.forms.assignedFormTemplates);
  const selectedFormTemplate = useSelector((state) => state.forms.selectedFormTemplate);
  const projects = useSelector((state) => state.projects.projects);

  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const formOptions = useMemo(() => (
    getPayableFormOptions({
      assignedFormTemplates,
      projectMap,
      projectId,
      type,
      t,
    })
  ), [assignedFormTemplates, projectMap, projectId, type]);

  const onSelectedFormChange = useCallback(async (templateId) => {
    if (!dispatch) return;
    await dispatch(getTemplateDetails(templateId));
  }, [dispatch, projectMap]);

  useEffect(() => {
    if (dispatch && visible) {
      dispatch(getAssignedFormTemplates());
      dispatch(getProjects());
    }
  }, [dispatch, visible]);

  useEffect(() => {
    if (dispatch && !visible) {
      dispatch(clearFormSelection());
    }
  }, [dispatch, visible]);

  return (
    <FormResponder
      visible={visible}
      mode={
        type === 'PO'
          ? PO_FORM_RESPONDER_MODE
          : SUB_CONTRACT_FORM_RESPONDER_MODE
      }
      onClose={onClose}
      draftDisabled
      lockedProjectId={selectedFormTemplate.id ? projectId : null}
      formSelector={(
        <>
          Select
          {` ${type} `}
          Form:
          <TreeSelect
            onChange={onSelectedFormChange}
            value={selectedFormTemplate.id}
            treeNodeFilterProp="title"
            treeData={formOptions}
            style={{ width: '100%' }}
          />
          <Divider />
        </>
      )}
    />
  );
}

PurchaseOrderDrawer.propTypes = {
  type: PropTypes.string,
  projectId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

PurchaseOrderDrawer.defaultProps = {
  type: false,
  projectId: null,
};

export default PurchaseOrderDrawer;
