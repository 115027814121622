import React, { useCallback, useMemo, useState } from 'react';
import { Drawer, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import ManualEntry from './ManualEntry';

const ManualEntryDrawer = function ManualEntryDrawer({
  user = {},
  onClose,
  visible,
  personal,
  initialEntries,
  defaultDate,
  isDisplay,
  onEditClicked,
  canEditApproved,
  canEditUnapproved,
}) {
  const {
    name,
  } = user;

  const [showFormDrawer, setShowFormDrawer] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const title = useMemo(() => {
    const prefix = defaultDate && moment.isMoment(defaultDate)
      ? `${defaultDate.format('ddd MMM Do YYYY')} - `
      : '';
    const suffix = name ? `${name}'s time` : 'Your Time';
    return `${prefix}${suffix}`;
  }, [defaultDate]);

  const canEdit = canEditApproved || canEditUnapproved;

  const onAddClicked = useCallback(() => {
    setShowFormDrawer(true);
  }, []);

  return (
    <Drawer
      width="95vw"
      title={(
        <Row gutter={20} align="middle" justify="space-between" style={{ marginRight: 50, height: 32 }}>
          <Col>
            {title}
          </Col>
          <Col>
            {!isDisplay && canEditUnapproved && (
              <OnTraccrButton
                title="Add"
                icon={<PlusOutlined />}
                onClick={onAddClicked}
                disabled={isLoading}
              />
            )}
          </Col>
        </Row>
      )}
      onClose={onClose}
      visible={visible}
      bodyStyle={{
        padding: 0,
        paddingBottom: isDisplay && !canEdit ? 0 : 53,
      }}
      maskClosable={false}
      push={false}
    >
      <ManualEntry
        user={user}
        onClose={onClose}
        visible={visible}
        personal={personal}
        initialEntries={initialEntries}
        defaultDate={defaultDate}
        isDisplay={isDisplay}
        onEditClicked={onEditClicked}
        canEditApproved={canEditApproved}
        canEditUnapproved={canEditUnapproved}
        showFormDrawer={showFormDrawer}
        setShowFormDrawer={setShowFormDrawer}
        isCopying={isCopying}
        setIsCopying={setIsCopying}
        setIsLoading={setIsLoading}
      />
    </Drawer>
  );
};

export default ManualEntryDrawer;
