export const DEFAULTS_HEADERS = [
  { key: 'title', title: 'Title' },
  { key: 'color', title: 'Color' },
];

export const COLOR_OPTIONS_MAP = {
  red: '#FF0000FF',
  green: '#008000FF',
  blue: '#0000FFFF',
  orange: '#FFA500FF',
  white: '#FFFFFFFF',
  yellow: '##FFFF00FF',
  gray: '#808080FF',
};

export const BASE_COL_WIDTH = 150;
