/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { EditOutlined, FormOutlined } from '@ant-design/icons';
import { PropTypes } from 'prop-types';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import Badge from '../../common/badge';

import BoardCardTimeInStatusIcon from '../BoardCardTimeInStatusIcon';
import BoardCardTaskIcon from '../BoardCardTaskIcon';
import BoardCardUsers from '../BoardCardUsers';
import BoardCardNotesIcon from '../BoardCardNotesIcon';
import BoardListCardContainer from './BoardListCardContainer';
import BoardCardShadowPopover from '../BoardCardShadowPopover';

import { setBoardTargetCard } from '../state/boards.actions';

import { useInterval } from '../../helpers/helpers';
import { parseCompletedForm } from '../../forms/formHelpers';
import { getTextColor } from '../../schedule/scheduleHelpers';

import {
  getLastMove,
  getUnreadCommCount,
  parseCustomResponses,
} from '../boards.helpers';

const badgeStyle = {
  outline: '1px white solid',
  margin: 3,
  height: 14,
  borderRadius: 7,
  minWidth: 14,
  maxWidth: 14,
  lineHeight: '9px',
  fontSize: 10,
};

export default function BoardListCard({
  card,
  templateMap,
  displayFields = [],
  displayMap = {},
  onEditClicked,
  onCardClick,
  onFormClicked,
  rowProps = {},
  idx,
  isLocked,
  showCardNumber,
  showListIndex,
  userBoardPermissions = {},
  projectIdMap,
  onBoardSelected,
}) {
  const {
    id,
    title,
    number: cardNumber,
    data = [],
    color: cardColor,
    lastMoveTimestamp,
    link,
    tasks = {},
    createdAt,
    users: cardUsers = [],
    orderIndex,
    noteCount,
    isShadow,
    boardId,
    statusId,
  } = card ?? {};
  const realLastMove = lastMoveTimestamp ?? createdAt;
  const backgroundColor = cardColor ?? '#FFFFFFFF';
  const color = backgroundColor ? getTextColor(backgroundColor) : 'black';

  const dispatch = useDispatch();
  const ourRef = useRef();

  const tagsPerCard = useSelector((state) => state.boards.tagsPerCard);
  const unreadClientComms = useSelector((state) => state.customers.unreadClientComms);
  const sortType = useSelector((state) => state.boards.sortType.type);
  const {
    cardId: targetCardId,
  } = useSelector((state) => state.boards.targetCard);

  const {
    isFormsPermitted,
    canEdit,
  } = userBoardPermissions;

  const unreadCount = useMemo(() => (
    getUnreadCommCount({ link, unreadClientComms, projectIdMap })
  ), [link, unreadClientComms, projectIdMap]);

  const {
    [id]: myTagCount = 0,
  } = tagsPerCard;

  const fullTagCount = unreadCount + myTagCount;

  const [lastMove, setLastMove] = useState(getLastMove(realLastMove));
  const [highlight, setHighlight] = useState(false);

  useInterval(() => {
    setLastMove(getLastMove(realLastMove));
  }, [1000 * 60 * 60]); // Once an hour

  useEffect(() => {
    setLastMove(getLastMove(realLastMove));
  }, [realLastMove]);

  useEffect(() => {
    if (targetCardId === id && ourRef?.current) {
      ourRef.current.scrollIntoView(false);
      setHighlight(true);
      setTimeout(() => {
        dispatch(setBoardTargetCard({}));
        setHighlight(false);
      }, 1000 * 10);
    } else if (targetCardId && targetCardId !== id) {
      setHighlight(false);
    }
  }, [dispatch, targetCardId, id, ourRef]);

  const isDragDisabled = useMemo(() => (
    !!isLocked || sortType !== 'default' || !canEdit || isShadow
  ), [isLocked, sortType, canEdit, isShadow]);

  const numberClass = isDragDisabled ? 'board-list-row-title' : 'board-list-row-title-draggable';

  const dataList = useMemo(() => {
    if (!displayFields) return [];
    const fullData = parseCompletedForm({
      sections: data,
      templateMap,
    });

    const dList = new Array(displayFields.length).fill(null);
    fullData.forEach((sections) => {
      const { fields = [] } = sections;
      fields.forEach((field) => {
        const realId = field.id ?? field.fieldId;
        const {
          [realId]: displayIndex = -1,
        } = displayMap;
        if (displayIndex < 0 || displayIndex >= displayFields.length) return;
        const displayData = parseCustomResponses({ field });
        dList[displayIndex] = displayData;
      });
    }, []);
    return dList;
  }, [data, templateMap, displayFields, displayMap]);

  const titleNode = (
    <span
      className={numberClass}
      style={highlight ? { fontWeight: 'bold' } : {}}
    >
      {title}
    </span>
  );
  return (
    <BoardListCardContainer
      id={id}
      isShadow={isShadow}
      orderIndex={orderIndex}
      isDragDisabled={isDragDisabled}
    >
      {({ draggableProps = {}, dragHandleProps = {}, innerRef } = {}) => (
        <tr
          key={id}
          {...rowProps}
          {...draggableProps}
          style={{
            ...draggableProps.style ?? {},
            backgroundColor,
            color,
            opacity: isShadow ? 0.5 : 1,
          }}
          ref={innerRef}
        >
          <td className="board-list-title-column" style={{ backgroundColor, color }} ref={ourRef}>
            <Row style={{ flexFlow: 'unset', width: 350, paddingLeft: 5 }} align="middle">
              {!!showListIndex && (
                <Col {...dragHandleProps} onClick={onCardClick(card)}>
                  <span
                    className={numberClass}
                    style={highlight ? { fontWeight: 'bold' } : {}}
                  >
                    {showListIndex ? `${idx + 1}.` : ''}
                  </span>
                </Col>
              )}
              <Col {...dragHandleProps} onClick={onCardClick(card)}>
                <span
                  className={numberClass}
                  style={highlight ? { fontWeight: 'bold' } : {}}
                >
                  {showCardNumber ? `${cardNumber}${showListIndex ? '' : '.'}` : ''}
                </span>
              </Col>
              <Col
                {...dragHandleProps}
                onClick={onCardClick(card)}
                style={{ lineHeight: '16px', marginLeft: 5 }}
              >
                {
                  isShadow
                    ? (
                      <BoardCardShadowPopover
                        boardId={boardId}
                        cardId={id}
                        statusId={statusId}
                        onBoardSelected={onBoardSelected}
                        canEdit={canEdit}
                      >
                        {titleNode}
                      </BoardCardShadowPopover>
                    )
                    : titleNode
                }
              </Col>
              {
                fullTagCount > 0
                  ? <Badge count={fullTagCount} style={badgeStyle} />
                  : null
              }
              {canEdit && !isShadow && (
                <Col style={{ height: 20 }}>
                  <BorderlessButton
                    iconNode={<EditOutlined style={{ color }} />}
                    style={{ height: 20, padding: 0, backgroundColor }}
                    onClick={onEditClicked(card)}
                  />
                </Col>
              )}
              {isFormsPermitted && (
                <Col style={{ height: 20 }}>
                  <BorderlessButton
                    iconNode={<FormOutlined style={{ color }} />}
                    style={{ height: 20, padding: 0, backgroundColor }}
                    onClick={onFormClicked(card)}
                  />
                </Col>
              )}
              {
              !!noteCount
              && (
                <Col style={{ marginLeft: 15, width: 40 }}>
                  <BoardCardNotesIcon count={noteCount} />
                </Col>
              )
              }
              <Col style={{ marginLeft: 15, width: 40 }}>
                <BoardCardTimeInStatusIcon lastMove={lastMove} placement="top" />
              </Col>
              <Col style={{ marginLeft: 15, width: 40 }}>
                <BoardCardTaskIcon tasks={tasks} placement="top" />
              </Col>
              <Col style={{ minWidth: 30 }} flex="auto">
                <BoardCardUsers users={cardUsers} style={{ maxWidth: '100%' }} justify="left" />
              </Col>
            </Row>
          </td>
          {
            dataList.map((datum, index) => (
              <td className="board-list-cell" key={`${id}-${displayFields?.[index] ?? index}`}>
                <div className="board-list-datum">
                  {datum}
                </div>
              </td>
            ))
          }
        </tr>
      )}
    </BoardListCardContainer>
  );
}

/* eslint-disable react/forbid-prop-types */
BoardListCard.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        fieldId: PropTypes.string,
        selectedType: PropTypes.string,
        response: PropTypes.shape({
          value: PropTypes.string,
          selected: PropTypes.arrayOf(PropTypes.string),
        }),
      })),
    })),
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
    })),
    tasks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
    })),
    users: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      avatar: PropTypes.string,
    })),
    lastMove: PropTypes.shape({
      statusId: PropTypes.string,
      statusName: PropTypes.string,
      time: PropTypes.string,
    }),
    isShadow: PropTypes.bool.isRequired,
  }).isRequired,
  templateMap: PropTypes.shape({}).isRequired,
  displayFields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  displayMap: PropTypes.shape({}),
  onEditClicked: PropTypes.func,
  onCardClick: PropTypes.func,
  onFormClicked: PropTypes.func,
  rowProps: PropTypes.shape({}),
  idx: PropTypes.number.isRequired,
  isLocked: PropTypes.bool,
  userBoardPermissions: PropTypes.shape({}),
  showCardNumber: PropTypes.bool,
  projectIdMap: PropTypes.object,
  onBoardSelected: PropTypes.func,
};

BoardListCard.defaultProps = {
  displayFields: [],
  displayMap: {},
  onEditClicked: null,
  onCardClick: null,
  onFormClicked: null,
  rowProps: {},
  isLocked: false,
  userBoardPermissions: {},
  projectIdMap: {},
  showCardNumber: false,
  onBoardSelected: undefined,
};
