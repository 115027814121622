import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { mergeSets } from '../../helpers/helpers';

export default function useDivisionUsers() {
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const users = useSelector((state) => state.users.users);
  const divisions = useSelector((state) => state.settings.divisions);

  const relevantUsers = useMemo(() => {
    const selectedDivUsers = Object
      .values(divisions)
      .filter(({ id }) => selectedDivisions.has(id))
      .map(({ users: divUsers = new Set() }) => divUsers);
    const mergedSelectedDivUsers = mergeSets(selectedDivUsers);
    return users.filter((user) => user.active && mergedSelectedDivUsers.has(user.id));
  }, [users, selectedDivisions, divisions]);
  return relevantUsers;
}
