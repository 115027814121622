import React, {
  useEffect,
  useState,
  useMemo,
  forwardRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Spin } from 'antd';
import PropTypes from 'prop-types';

import OnTraccrEmpty from '../common/OnTraccrEmpty';

import AnalyticsTable from './AnalyticsTable';
import AnalyticsNumber from './AnalyticsNumber';
import AnalyticsChart from './AnalyticsChart';

import { getAnalyticsData } from './state/analytics.actions';

import { rangePresetToTimestamps } from './analytics.helpers';

const AnalyticsBody = forwardRef(({
  type,
  analyticsConfig = {},
  getAction = getAnalyticsData,
  projectId,
  cardId,
}, ref) => {
  const dispatch = useDispatch();
  const boards = useSelector((state) => state.boards.boards);
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);
  const formTemplates = useSelector((state) => state.forms.templates);

  const {
    data = [],
    boardId,
    formTemplateId,
    dateRange,
    datePreset,
    dateBreakdown,
    fieldBreakdown,
    chartType,
    fieldId,
    aggregate,
    breakdown,
    groups,
  } = analyticsConfig;

  const [loading, setLoading] = useState();

  const fieldTitle = useMemo(() => {
    const isTimeInStatus = fieldId === 'timeInStatus';
    const isConversion = fieldId === 'conversion';
    if (isTimeInStatus) return 'Time in Status';
    if (isConversion) return 'Conversion Rate';
    if (fieldId === 'numberOfCards') return 'Number of Cards';
    let realFieldId = fieldId;
    let columnKey = '';
    if (fieldId && fieldId.includes('/')) {
      [realFieldId, columnKey] = fieldId.split('/');
    }
    let ourSections = [];
    if (boardId) {
      const {
        [boardId]: { cardTypeId } = {},
      } = boards;
      const {
        [cardTypeId]: {
          fields: sections = [],
        } = {},
      } = cardTemplates;
      ourSections = sections;
    } else if (formTemplateId) {
      const {
        [formTemplateId]: {
          formData: {
            sections = [],
          } = {},
        } = {},
      } = formTemplates;
      ourSections = sections;
    }

    let ft;
    ourSections.forEach(({ fields = [] }) => {
      fields.forEach((field) => {
        const {
          id,
          configProps: { title: fieldName, columns = [] } = {},
          selectedType,
        } = field;
        if (id === realFieldId) {
          if (selectedType === 'table') {
            const ourCol = columns.find((col) => col.key === columnKey);
            const suffix = ourCol ? ` - ${ourCol.name}` : '';
            ft = `${fieldName}${suffix}`;
          } else {
            ft = fieldName;
          }
        }
      });
    });
    return ft;
  }, [boards, boardId, formTemplateId, cardTemplates, formTemplates, fieldId]);

  useEffect(() => {
    const isNumber = chartType === 'number';
    const isPie = chartType === 'pie';
    const isList = chartType === 'list';
    const getData = async () => {
      setLoading(true);
      const rangeFromPreset = rangePresetToTimestamps(datePreset);
      const [startMoment, endMoment] = dateRange ?? [];
      const payload = {
        boardId,
        formTemplateId,
        startTime: startMoment ? startMoment.valueOf() : rangeFromPreset[0],
        endTime: endMoment ? endMoment.valueOf() : rangeFromPreset[1],
        fieldId,
        aggregate,
        breakdown,
        chartType,
        groups: groups ?? {},
        projectId,
        cardId,
      };
      if (fieldBreakdown) {
        payload.fieldBreakdown = fieldBreakdown;
      } else {
        payload.dateBreakdown = isNumber || isPie || isList ? 'none' : dateBreakdown;
      }
      await dispatch(getAction({
        type,
        payload,
      }));
      setLoading(false);
    };
    if ((boardId || formTemplateId)
        && ((dateRange && dateRange.length === 2) || datePreset)
        && (dateBreakdown || fieldBreakdown || isNumber || isPie || isList)
        && fieldId
        && aggregate
        && chartType) {
      getData();
    }
  }, [
    dispatch,
    boardId,
    formTemplateId,
    dateRange,
    dateBreakdown,
    fieldBreakdown,
    fieldId,
    aggregate,
    breakdown,
    chartType,
    groups,
    datePreset,
    projectId,
    cardId,
  ]);

  const body = useMemo(() => {
    if (data.length === 0 || loading) {
      return (
        <Row style={{ flex: 1 }} justify="center" align="middle">
          {loading ? <Spin /> : <OnTraccrEmpty />}
        </Row>
      );
    }
    if (chartType === 'table' || chartType === 'list') return <AnalyticsTable fieldTitle={fieldTitle} ref={ref} analyticsConfig={analyticsConfig} />;
    if (chartType === 'number') return <AnalyticsNumber fieldTitle={fieldTitle} ref={ref} analyticsConfig={analyticsConfig} />;
    return <AnalyticsChart fieldTitle={fieldTitle} ref={ref} analyticsConfig={analyticsConfig} />;
  }, [data, loading, chartType, fieldTitle, ref, analyticsConfig]);

  return (
    <div className="analytics-body-container">
      {body}
    </div>
  );
});

/* eslint-disable react/forbid-prop-types */
AnalyticsBody.propTypes = {
  type: PropTypes.string,
  analyticsConfig: PropTypes.object,
  getAction: PropTypes.func,
  projectId: PropTypes.string,
  cardId: PropTypes.string,
};

AnalyticsBody.defaultProps = {
  type: undefined,
  analyticsConfig: {},
  getAction: getAnalyticsData,
  projectId: undefined,
  cardId: undefined,
};

export default AnalyticsBody;
