import { 
  GET_ADDRESS_BOOKS,
  ADD_TO_ADDRESS_BOOK,
  UPDATE_ADDRESS_BOOK,
  DELETE_FROM_ADDRESS_BOOK,
} from '../../state/actionTypes';

import sortByString from '../../helpers/helpers';

const initialState = {
  addressBooks: {},    // { [contactId: string]: [ addressBookEntry1, addressBookEntry2 ... ]}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_ADDRESS_BOOKS: {
      const {
        payload: {
          addressBooks = {},
        } = {},
      } = action;
      return {
        ...state,
        addressBooks,
      };
    }
    case ADD_TO_ADDRESS_BOOK: {
      const {
        payload: {
          isVendor,
          customerId,
          vendorId,
          addressBookEntryId,
          name,
          role,
          phoneNumber,
          email,
        } = {},
      } = action;
      const { addressBooks: stateAddressBooks = {} } = state;
      const contactId = isVendor ? vendorId : customerId;
      const stateContactAddressBook = stateAddressBooks[contactId] ?? [];
      const newContactAddressBook = [...stateContactAddressBook];
      const addressBookEntryIdExists = newContactAddressBook
        .some((entry) => entry.id === addressBookEntryId);
      if (addressBookEntryIdExists) return state;
      newContactAddressBook.push({
        id: addressBookEntryId,
        name,
        role,
        phoneNumber,
        email,
      });
      newContactAddressBook.sort(sortByString('name'));
      return {
        ...state,
        addressBooks: {
          ...stateAddressBooks,
          [contactId]: newContactAddressBook,
        },
      };
    }
    case UPDATE_ADDRESS_BOOK: {
      const {
        payload: {
          isVendor,
          customerId,
          vendorId,
          id: addressBookEntryId,
          name,
          role,
          phoneNumber,
          email,
        } = {},
      } = action;
      const { addressBooks: stateAddressBooks = {} } = state;
      const contactId = isVendor ? vendorId : customerId;
      const stateContactAddressBook = stateAddressBooks[contactId] ?? [];
      const updatedContactAddressBook = stateContactAddressBook.map((entry) => {
        if (entry.id !== addressBookEntryId) return entry;
        return {
          ...entry,
          name,
          role,
          phoneNumber,
          email,
        };
      });
      return {
        ...state,
        addressBooks: {
          ...stateAddressBooks,
          [contactId]: updatedContactAddressBook,
        },
      };
    }
    case DELETE_FROM_ADDRESS_BOOK: {
      const {
        payload: {
          contactId,
          addressBookEntryId,
        } = {},
      } = action;
      const { addressBooks: stateAddressBooks = {} } = state;
      const stateContactAddressBook = stateAddressBooks[contactId] ?? [];
      const newContactAddressBook = stateContactAddressBook.filter((entry) => entry.id !== addressBookEntryId);
      return {
        ...state,
        addressBooks: {
          ...stateAddressBooks,
          [contactId]: newContactAddressBook,
        },
      };
    }
    default:
      return state;
  }
};
