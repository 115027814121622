/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
  Row,
  Col,
  notification,
} from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import { PropTypes } from 'prop-types';
import CompanySettingsCard from './CompanySettingsCard';
import CompanyEditRow from './CompanyEditRow';
import CompanyOwnerModal from './CompanyOwnerModal';
import CheckRows from './CheckRows';
import { getPreferenceRows } from './companyDetailConstants';

import DisplayText from '../common/text/DisplayText';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import Editable from '../common/editable/Editable';
import CurrancySelector from '../common/inputs/CurrencySelector';
import TimezoneSelector from '../common/inputs/TimezoneSelector';

import Colors from '../constants/Colors';
import { getIdMap } from '../helpers/helpers';

import Permissions from '../auth/Permissions';

const getFileList = function getFileList(file) {
  return file ? [
    {
      uid: '-1',
      name: 'companyImage.png',
      status: 'done',
      url: file,
    },
  ] : [];
};

const keys = ['name', 'phoneNumber', 'address', 'website'];
const titleMap = {
  name: 'Company Name',
  phoneNumber: 'Phone Number',
  address: 'Address',
  website: 'Website',
};

const ownerFlavourText = 'Highest level of access for the company account, including billing management and account deactivation priviliges';

const getAdminUser = ({ users, ownerId }) => {
  let owner;
  const adminUsers = [];
  users.forEach((user) => {
    if (user.id === ownerId) {
      owner = user;
    }
    if (user.position.toLowerCase() === 'admin') {
      adminUsers.push(user);
    }
  });
  return { owner, adminUsers };
};

class CompanyDetail extends React.Component {
  constructor(props) {
    super(props);
    const {
      settings: {
        address = '',
        name = '',
        phoneNumber = '',
        subType = '',
        website = '',
        currency = '',
        timezone = '',
        ownerId,
        users = [],
      },
    } = props;

    const { adminUsers, owner } = getAdminUser({ users, ownerId });
    this.userNameMap = getIdMap(users, 'name');
    this.state = {
      address,
      name,
      phoneNumber,
      subType,
      website,
      currency,
      timezone,
      editing: false,
      modalVisible: false,
      avatar: props.companyImageURL,
      fileList: getFileList(props.companyImageURL),
      adminUsers,
      owner,
      newOwner: false,
      imageUpdated: false,
    };
    this.inputFile = React.createRef();

    this.updateKeys = new Set(['address', 'name', 'phoneNumber', 'subType']);

    this.edits = {};
    this.oldValues = {};
    this.onChange = this.valueChanged.bind(this);
    this.onTransfer = this.transferMembership.bind(this);
    this.onModalClose = this.closeModal.bind(this);
    this.onCompanyOwnerChange = this.companyOwnerChanged.bind(this);
    this.onCompanyOwnerSubmit = this.submitCompanyOwnerChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onImageInputClick = this.onImageInputClick.bind(this);
    this.onCancelImageUpload = this.cancelImageUpload.bind(this);
    this.timers = {};
  }

  componentDidUpdate(prevProps) {
    const {
      settings: prevSettings,
      users: prevUsers = [],
    } = prevProps;
    const { settings, users = [], ownerId } = this.props;
    const { owner: stateOwner } = this.state;

    if (settings !== prevSettings) {
      const {
        address = '',
        name = '',
        phoneNumber = '',
        subType = '',
        website = '',
        currency = '',
        timezone = '',
        suggestNumbers = false,
      } = settings;
      this.setState({
        address,
        name,
        phoneNumber,
        subType,
        website,
        currency,
        timezone,
        suggestNumbers,
      });
    }

    if (users !== prevUsers || (ownerId && !stateOwner && users.length)) {
      this.userNameMap = getIdMap(users, 'name');
      const { adminUsers, owner } = getAdminUser({ users, ownerId });

      this.setState({
        adminUsers,
        owner,
      });
    }
  }

  onUndo(type) {
    if (!(type in this.timers)) return;
    clearTimeout(this.timers[type]);
    notification.close(type);
    this.setState({
      [type]: this.oldValues[type],
    });
  }

  onFileStateChange(info) {
    let fileList = [...info.fileList];
    if (info.file.status !== 'uploading') fileList = fileList.filter((file) => file.status !== 'error').slice(-1);

    this.setState({
      fileList,
    });
  }

  getCompanyInfoRows() {
    return keys.map((key, index) => {
      const { [key]: stateKey } = this.state;
      return (
        <CompanyEditRow title={titleMap[key]} divider={index !== keys.length - 1} key={key}>
          <Editable
            key={stateKey}
            name={key}
            defaultValue={stateKey}
            style={{ marginBottom: 0 }}
            onChange={this.onChange}
            inputNode={({
              setReturnValue,
              setFocus,
              setHover,
              defaultValue,
            }) => (
              <OnTraccrTextInput
                style={{ textAlign: 'right', width: 300 }}
                defaultValue={defaultValue}
                placeholder="Enter details"
              /*
                onBlur needs a timeout due to a race condition.
                If user clicks checkmark, onBlur will fire, as will onClick for
                the check. These will race, causing the input to not be saved
                if onBlur executes first. We delay by 100ms to allow
                onClick check to execute first.
              */
                onBlur={() => setTimeout(() => setFocus(false), 100)}
                onMouseLeave={() => setHover(false)}
                onChange={(e) => setReturnValue({ name: key, value: e.target.value })}
                onClick={() => setFocus(true)}
                onPressEnter={(e) => {
                  setFocus(false);
                  setReturnValue({ name: key, value: e.target.value });
                  this.onChange(key, e.target.value);
                }}
              />
            )}
          />
        </CompanyEditRow>
      );
    });
  }

  openNotification(type) {
    notification.open({
      key: type,
      message: 'Undo Edit',
      description:
        `Click here to undo ${titleMap[type]} edit`,
      onClick: () => this.onUndo(type),
      duration: 1.5,
      icon: <UndoOutlined style={{ color: 'red' }} />,
    });
  }

  valueChanged(type, value) {
    if (value === null || value === undefined) return;

    const { [type]: stateType } = this.state;
    const {
      saveCompanySettings,
    } = this.props;

    this.oldValues[type] = stateType;
    this.edits[type] = value;
    this.setState({
      [type]: value,
    });
    this.openNotification(type);
    this.timers[type] = setTimeout(async () => {
      await saveCompanySettings({
        [type]: this.edits[type],
      });
      delete this.edits[type];
    }, 1750);
  }

  transferMembership() {
    this.setState({
      modalVisible: true,
    });
  }

  closeModal() {
    this.setState({
      modalVisible: false,
      newOwner: null,
    });
  }

  companyOwnerChanged(value) {
    const newOwner = this.userNameMap[value];
    this.setState({
      newOwner,
    });
  }

  async submitCompanyOwnerChange() {
    const {
      newOwner,
    } = this.state;

    const {
      users = [],
      updateCompanyOwner,
    } = this.props;

    if (!newOwner) {
      this.setState({
        modalVisible: false,
      });
      return;
    }

    if (await updateCompanyOwner(newOwner.id)) {
      const { owner } = getAdminUser({ users, ownerId: newOwner.id });
      this.setState({
        modalVisible: false,
        owner,
      });
    }
  }

  onFileChange(event) {
    const {
      target: {
        files: [file] = [],
      } = {},
    } = event;

    if (file && file.type.includes('image')) {
      this.setState({
        avatar: file,
        imageUpdated: true,
      });
    }
  }

  async onImageInputClick() {
    const {
      uploadCompanyImage,
    } = this.props;
    const {
      imageUpdated,
      avatar,
    } = this.state;
    if (imageUpdated) {
      if (await uploadCompanyImage(avatar)) {
        this.setState({
          imageUpdated: false,
        });
      }
    } else {
      this.inputFile.current.click();
    }
  }

  cancelImageUpload() {
    const {
      companyImageURL,
    } = this.props;
    this.setState({
      avatar: companyImageURL,
      imageUpdated: false,
    });
  }

  render() {
    const {
      saveCompanySettings,
      settings = {},
      t,
    } = this.props;

    const {
      owner: {
        name: ownerName,
        id: ownerId,
      } = {},
      adminUsers = [],
      modalVisible,
      newOwner,
      avatar,
      imageUpdated,
      currency,
      timezone,
    } = this.state;

    const src = avatar && avatar instanceof File
      ? URL.createObjectURL(avatar)
      : avatar;

    const preferenceRows = getPreferenceRows(t);

    return (
      <Row style={{
        width: '100%', height: '100%', overflowX: 'hidden', marginLeft: 177, marginTop: 7,
      }}
      >
        <Col>
          <CompanySettingsCard title="Image" containerStyle={{ paddingRight: 30 }}>
            <Row style={{ minHeight: 32, marginTop: 10, width: '100%' }} justify="center" align="middle">
              { !imageUpdated && !avatar && avatar.trim().length === 0 && 'No Image' }
              <div id="company-image-preview-container">
                {src && <img src={src} style={{ maxWidth: 300 }} alt="company uploaded logo" />}
              </div>
            </Row>
            <Row
              style={{
                minHeight: 32, marginTop: 10, marginBottom: 10, width: '100%',
              }}
              justify="center"
              align="middle"
              gutter={10}
            >
              <input type="file" hidden ref={this.inputFile} onChange={this.onFileChange} value="" />
              {
                  imageUpdated
                  && (
                  <OnTraccrButton
                    title="Cancel"
                    type="cancel"
                    onClick={this.onCancelImageUpload}
                    style={{ marginRight: 15 }}
                  />
                  )
                }
              <OnTraccrButton title={imageUpdated ? 'Save Image' : 'Upload Image'} onClick={this.onImageInputClick} />
            </Row>
          </CompanySettingsCard>

          <CompanySettingsCard title="Company Info">
            {this.getCompanyInfoRows()}
          </CompanySettingsCard>

          <CompanySettingsCard title="Account" style={{ paddingRight: 56 }}>
            <Row style={{ minHeight: 32, marginTop: 10, width: '100%' }} justify="space-between" align="middle">
              <Col>
                <DisplayText
                  title="Primary Account Owner"
                  style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
                />
              </Col>
              <Col>
                <DisplayText
                  title={ownerName}
                  style={{
                    fontFamily: 'roboto-light',
                    marginBottom: 0,
                    marginRight: 56,
                  }}
                />
              </Col>
            </Row>
            <DisplayText
              title={ownerFlavourText}
              style={{
                fontFamily: 'roboto-light',
                marginBottom: 0,
                maxWidth: 275,
              }}
            />
            <Row style={{ width: '100%' }} justify="center">
              <OnTraccrButton
                title="Transfer Ownership"
                type={Permissions.id === ownerId ? 'primary' : 'back'}
                disabled={Permissions.id !== ownerId}
                style={{ margin: 10 }}
                onClick={this.onTransfer}
              />
              <DisplayText
                style={{
                  textAlign: 'center',
                  fontFamily: 'roboto-bold',
                  color: Colors.ONTRACCR_OPACITY_GRAY,
                  height: 32,
                }}
                title="Only the account owner can transfer ownership"
              />
            </Row>
          </CompanySettingsCard>

          <CompanySettingsCard title="Regional Options">
            <CompanyEditRow title="Currency">
              <Editable
                key={currency}
                name="currency"
                defaultValue={currency}
                style={{ marginBottom: 0 }}
                onChange={this.onChange}
                showButtons={false}
                inputNode={({
                  setReturnValue,
                  setFocus,
                  setHover,
                  defaultValue,
                }) => (
                  <CurrancySelector
                    defaultValue={defaultValue}
                    onBlur={() => setFocus(false)}
                    onMouseLeave={() => setHover(false)}
                    onClick={() => setFocus(true)}
                    onSelect={(value) => {
                      setFocus(false);
                      setReturnValue({ name: 'currency', value });
                      this.onChange('currency', value);
                    }}
                  />
                )}
              />
            </CompanyEditRow>

            <CompanyEditRow title="Timezone" divider={false}>
              <Editable
                key={timezone}
                name="timezone"
                defaultValue={timezone}
                style={{ marginBottom: 0 }}
                onChange={this.onChange}
                showButtons={false}
                inputNode={({
                  setReturnValue,
                  setFocus,
                  setHover,
                  defaultValue,
                }) => (
                  <TimezoneSelector
                    defaultValue={defaultValue}
                    onBlur={() => setFocus(false)}
                    onMouseLeave={() => setHover(false)}
                    onClick={() => setFocus(true)}
                    onSelect={(value) => {
                      setFocus(false);
                      setReturnValue({ name: 'timezone', value });
                      this.onChange('timezone', value);
                    }}
                  />
                )}
              />
            </CompanyEditRow>
          </CompanySettingsCard>

          <CompanySettingsCard title="Preferences" style={{ marginBottom: 50 }}>
            <CheckRows
              data={preferenceRows}
              isChecked={({ key }) => settings[key]}
              onChange={({ key }) => saveCompanySettings({ [key]: !settings[key] })}
            />
          </CompanySettingsCard>
        </Col>
        <CompanyOwnerModal
          visible={modalVisible}
          onCloseClicked={this.onModalClose}
          adminUsers={adminUsers}
          onChange={this.onCompanyOwnerChange}
          onSubmit={this.onCompanyOwnerSubmit}
          canSubmit={newOwner}
        />
      </Row>
    );
  }
}

CompanyDetail.propTypes = {
  settings: PropTypes.shape({
    address: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    subType: PropTypes.string,
    website: PropTypes.string,
    currency: PropTypes.string,
    timezone: PropTypes.string,
    ownerId: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape({})),
    suggestNumbers: PropTypes.bool,
  }),
  ownerId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  companyImageURL: PropTypes.string,
  uploadCompanyImage: PropTypes.func.isRequired,
  saveCompanySettings: PropTypes.func.isRequired,
  updateCompanyOwner: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CompanyDetail.defaultProps = {
  users: [],
  settings: {},
  companyImageURL: '',
};

export default withTranslation()(CompanyDetail);
