import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select, Descriptions } from 'antd';

function BoardCardSelectSheet({
  workbook: {
    SheetNames = [],
  } = {},
  onSheetChanged,
}) {
  const sheetOptions = useMemo(() => (
    SheetNames.map((sheet) => ({ value: sheet, label: sheet }))
  ), [SheetNames]);

  return (
    <Descriptions title="Select Sheet">
      <Descriptions.Item label="Sheet">
        <Select
          style={{ width: 200 }}
          defaultValue={SheetNames[0]}
          onSelect={onSheetChanged}
          options={sheetOptions}
        />
      </Descriptions.Item>
    </Descriptions>
  );
}

/* eslint-disable react/forbid-prop-types */
BoardCardSelectSheet.propTypes = {
  workbook: PropTypes.object.isRequired,
  onSheetChanged: PropTypes.func.isRequired,
};

export default BoardCardSelectSheet;
