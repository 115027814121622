import { getNames, getUnique } from '../../helpers/helpers';

export default function getEmployeeProjectFilters({
  showEmployee = true,
  showWorkType,
  showBreak,
  showProject = true,
  showPhase = true,
  showCostcode = true,
  showServiceLocations,
  onFilterToggle,
  onCheckChanged,
  checkedColumns = new Set(),
  users = [],
  projects = [],
  phases = [],
  costcodes = [],
  projectCheckDisabled = false,
  t,
}) {
  const filters = [];
  if (showEmployee) {
    filters.push({
      content: getNames(users),
      title: 'Employee',
      onFilterToggle: (checked) => onFilterToggle('users', checked),
      checkDisabled: true,
    });
  }

  if (showWorkType) {
    const content = ['Project', 'Service'];
    if (showBreak) content.push('Break');
    filters.push({
      content,
      title: 'Work Type',
      key: 'type',
      onFilterToggle: (checked) => onFilterToggle('workType', checked),
      checkDisabled: true,
    });
  }

  if (showProject) {
    filters.push({
      content: getUnique(projects).concat('None'),
      title: t('Project'),
      key: 'project',
      onFilterToggle: (checked) => onFilterToggle('projects', checked),
      onCheckChanged: (checked) => onCheckChanged('project', checked),
      checkDisabled: projectCheckDisabled,
      checked: projectCheckDisabled || checkedColumns.has('project'),
    });
  }

  if (showPhase) {
    filters.push({
      content: getUnique(phases).concat('None'),
      title: 'Phase',
      key: 'phase',
      onFilterToggle: (checked) => onFilterToggle('phases', checked),
      onCheckChanged: (checked) => onCheckChanged('phase', checked),
      checked: checkedColumns.has('phase'),
    });
  }

  if (showCostcode) {
    filters.push({
      content: getUnique(costcodes, { valueExtractor: (cc) => `${cc.code} - ${cc.name}` }).concat('None'),
      title: 'Cost Code',
      key: 'costcode',
      onFilterToggle: (checked) => {
        const checkedCC = Array.from(checked).map((cc) => cc.split(' - ')[0]);
        onFilterToggle('costcodes', new Set(checkedCC));
      },
      onCheckChanged: (checked) => onCheckChanged('costcode', checked),
      checked: checkedColumns.has('costcode'),
    });
  }
  if (showServiceLocations) {
    filters.push({
      title: 'Service Location',
      key: 'serviceLocation',
      onCheckChanged: (checked) => onCheckChanged('serviceLocation', checked),
      checked: checkedColumns.has('serviceLocation'),
    });
  }
  return filters;
};

export const EmployeeFilter = (users, onFilterToggle) => ({
  content: getNames(users),
  title: 'Employee',
  onFilterToggle,
  checkDisabled: true,
});
