/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { ProductFruits } from 'react-product-fruits';
import { withTranslation } from 'react-i18next';

import { Layout } from 'antd';
import axios from 'axios';

import FullScreen from './FullScreen';

import Projects from '../projects/Projects';
import CostCodes from '../costcodes/costcodes';
import Users from "../users/users";
import Teams from '../teams/Teams';
import Settings from '../settings/settings';
import Dashboard from '../dashboard/dashboard';
import Files from '../files/Files';
import Forms from '../forms/Forms';
import Schedule from '../schedule/Schedule';
import Reports from '../reports/Reports';
import Exports from '../reports/Exports/Exports';
import Equipment from '../equipment/Equipment';
import Contacts from '../contacts/Contacts';
import Materials from '../materials/Materials';
import Boards from '../boards/Boards';
import GanttSchedule from '../schedule/GanttSchedule/GanttSchedule';
import ClientPortal from '../clientPortal/ClientPortal';
import AnalyticsPage from '../analytics/Analytics';
import Payables from '../payables/Payables';
import ActiveUsersMap from '../users/UsersMap/ActiveUsersMap';

import SocketService from '../sockets/SocketService';
import Permissions from '../auth/Permissions';
import Analytics from '../helpers/Analytics';

import { getUsers, massArchive } from '../users/state/users.actions';
import { getProjects, getProjectTypes } from '../projects/state/projects.actions';
import { getAllCostCodes, getAllPhases } from '../costcodes/state/costcodes.actions';
import { getTeams } from '../teams/state/teams.actions';
import { getVendors } from '../contacts/vendors/state/vendors.actions';
import { getAddressBooks } from '../contacts/state/contacts.actions';
import {
  getPositions,
  getCompany,
  getDivisions,
  getCompanySettings,
  setSelectedDivisions,
  getCompanyTranslations,
  getWorkingHours,
  getRoundingPolicies,
} from '../settings/state/settings.actions';
import { getApprovals } from '../clock/state/clockin.actions';
import { getProfile } from '../profile/state/profile.actions';
import { toggleFullscreen, userLogout, toggleMenuCollapse, setMainLoaded } from '../main/state/main.actions';
import { getNuxState } from '../nux/state/nux.actions';
import { getNotifications } from '../notifications/state/notifications.actions';
import { getMyCardTags } from '../boards/state/boards.actions';
import { getUnreadClientComms } from '../contacts/customers/state/customers.actions';
import { getGlobalMaterialLocations } from '../globalMaterialLocations/state/globalMaterialLocations.actions';
import { getLabels } from '../labels/state/labels.actions';
import { getExternalFormTemplates } from '../forms/state/forms.actions';
import { getBucketTemplates } from '../buckets/state/buckets.actions';
import TimeTracking from '../state/timeTracking';

import config from '../config';
import Menu from '../menu/Menu';
import PermissionResetModal from '../auth/PermissionResetModal';
// gotta import card grid here and pass user info
import TimeCards from '../timecards/timecards';
import TeamTimeCards from '../timecards/TeamTimeCards';
import ForceDowngradeModal from './ForceDowngradeModal';
import NonAdminDowngradeModal from './NonAdminDowngradeModal';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

import { showLiveFeedToast, getNewEvent } from '../dashboard/LiveFeed/liveFeedHelpers';
import ExternalForm from '../forms/ExternalForm/ExternalForm';

import {
  getPathTitle,
  DEFAULT_TITLE
} from '../menu/menu.constants';
import Bucket from '../buckets/Bucket';
import Search from '../search/Search';

const FULLSCREEN_PATHS = [
  'reports',
  'gantt-schedule',
];

const pageCanBeFullscreen = (currentPath) => (
  FULLSCREEN_PATHS.some((path) => currentPath.includes(path))
);

// Refresh data every 30 minutes if the user has not been active
const REFRESH_DATA_INTERVAL = 1000 * 60 * 30;

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn:window.sessionStorage.getItem("token"),
      shouldRedirect:false,
      updateTime:Date.now(),
      needsDowngrade:false,
      isAfterSubscriptionEnd:false,
      hideDowngradeForever:window.sessionStorage.getItem('seenDowngradeModal'),
      version: null,
      lastUpdatePrompt: DateTime.local().toMillis(),
      title: DEFAULT_TITLE,
    };

    this.modalVisible = false;

    this.onShowResetModal = this.showResetModal.bind(this);
    this.onRefresh = this.refresh.bind(this);
    this.onMassArchive = this.massArchive.bind(this);
    this.contactRender = this.contactRender.bind(this);
    this.onLatestVersionChange = this.latestVersionChange.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);

    // Set up interceptor to add token to request;
    axios.interceptors.request.use((config) => {
      const token = window.sessionStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer  ${token}`;
      }
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
  }

  componentDidMount() {
    const {
      location:{
        pathname,
      } = {},
      setSelectedDivisions,
      getExternalFormTemplates,
      t,
    } = this.props;

    if (pathname) {
      this.setState({
        title: getPathTitle(t, pathname),
      })
    }

    if(window.sessionStorage.getItem("token")) {
      const key = pathname === '/' ? 'dashboard' : pathname.substring(1);
      this.onMenuPicked({
        key,
      });

      try {
        const id = window.sessionStorage.getItem('id');
        const companyId = window.sessionStorage.getItem('companyId');
        const companyName = window.sessionStorage.getItem('companyName');
        const selectedDivisions = window.localStorage.getItem('selectedDivisions');
        if(selectedDivisions) setSelectedDivisions(new Set(JSON.parse(selectedDivisions)));

        Analytics.setProperties({ id, companyName, companyId });
      } catch (err) {
        console.error('failed to load data from session storage', err);
      }
    }

    document.addEventListener('visibilitychange', this.onVisibilityChange);

    this.responseInterceptor = axios.interceptors.response.use((response) => {
      return response;
    },(error) => {
      if (error.response && error.response.status === 401) {
        this.setState({
          shouldRedirect:true,
        });
        window.sessionStorage.removeItem('token');
        delete axios.defaults.headers.common.Authorization;
      } else {
        return Promise.reject(error);
      }
    });

    getExternalFormTemplates();
    // eslint-disable-next-line no-undef
    const token = window.sessionStorage.getItem('token');
    const companyId = window.sessionStorage.getItem('companyId');
    const position =  window.sessionStorage.getItem('position');
    if(token && companyId) {
      SocketService.connect({ token, companyId, position },this.onShowResetModal);
      Permissions.resync();
      this.reload();
    }
    if (config.allowUpdatePrompt) window.addEventListener('sessionStorageVersionUpdate', this.onLatestVersionChange);
  }

  updateTranslations({ translations: prevTranslations = [] }) {
    const {
      translations = [],
      i18n,
    } = this.props;
    if (translations !== prevTranslations) {
      i18n.addResourceBundle('en', 'translation', {
        ...translations.reduce((acc, { key, value }) => ({
          ...acc,
          [key]: value,
        }), {}),
      }, true, true);
    }
  };

  componentWillUnmount() {
    axios.interceptors.response.eject(this.responseInterceptor);
    if (config.allowUpdatePrompt) window.removeEventListener('sessionStorageVersionUpdate', this.onLatestVersionChange);
  }

  componentDidUpdate(prevProps) {
    const {
      location:{
        pathname:prevPath,
      } = {},
      events:prevEvents = [],
    } = prevProps;
    const {
      location:{
        pathname,
      },
      fullscreen,
      menuCollapsed,
      users = [],
      company:{
        userLimit,
        freeUserLimit,
        subscriptionEnd,
        downgradeDate,
      } = {},
      events = [],
      history,
      toggleMenuCollapse,
      t,
    } = this.props;

    this.updateTranslations(prevProps);

    if(!pathname.includes('dashboard') &&
      events.length && prevEvents.length &&
      events.length > prevEvents.length) {
        const mostRecent = getNewEvent({
          oldEvents:prevEvents,
          newEvents:events,
        });
        if(mostRecent && mostRecent.userId !== Permissions.id) {
          showLiveFeedToast({ history, event: mostRecent, t });
        }
    }
    if(events.length && events !== prevEvents) {
      this.setState({
        events,
      });
    }

    if(pathname !== prevPath) {

      if(pathname) {
        this.setState({
          title: getPathTitle(t, pathname),
        });
      }

      if (fullscreen && !pageCanBeFullscreen(pathname)) {
        this.props.toggleFullscreen();
      }

    }

    const validPathnames = new Set(['/boards', '/timecards/team'])

    if (menuCollapsed && !validPathnames.has(pathname)) {
      toggleMenuCollapse();
    }

    const activeUserCount = users.filter((user) => user.active).length;
    const isAfterSubscriptionEnd = DateTime.local() > subscriptionEnd;
    if(users.length) {
      const isDowngrade = activeUserCount > userLimit;
      const needsDowngrade = isDowngrade || (
        isAfterSubscriptionEnd && activeUserCount > freeUserLimit
      );

      if(needsDowngrade !== this.state.needsDowngrade) {
        this.setState({
          needsDowngrade,
          isAfterSubscriptionEnd,
          relevantEndDate: isAfterSubscriptionEnd ? subscriptionEnd : downgradeDate,
        });
      }
    }
  }

  latestVersionChange() {
    // Retrieve values from Session Storage:
    const availableVersion = sessionStorage.getItem('latest-available-version');
    if (!availableVersion) return;
    // CASE 1: On initial page render set local state version to latest available version:
    if (!this.state.version) {
      this.setState({ version: availableVersion });
    } else {
    // CASE 2: If versions do not match and the user hasn't already been prompted in the last 10 minutes, prompt user:
      if (this.state.version !== availableVersion
        && DateTime.fromMillis(this.state.lastUpdatePrompt).plus({ minutes: 10 }) <= DateTime.local()) {
          CustomConfirmModal({
            title: `Version ${availableVersion} is available, would you like to refresh page to update?`,
            okText: 'Yes',
            cancelText: 'No',
            onCancel: this.setState({ lastUpdatePrompt: DateTime.local().toMillis() }),
            onOk: this.refreshPage,
          });
      }
    }
  }

  refreshPage() {
    window.location.reload(true);
  }

  onVisibilityChange() {
    if (document.hidden) {
      this.setState({
        lastVisible: DateTime.local().toMillis(),
      });
      return;
    }

    const lastVisibleTime = this.state.lastVisible;
    const shouldRefresh = DateTime.local().toMillis() - lastVisibleTime > REFRESH_DATA_INTERVAL;
    const token = window.sessionStorage.getItem('token');
    const companyId = window.sessionStorage.getItem('companyId');

    if (shouldRefresh && lastVisibleTime && token && companyId) {
      this.reload();
      this.setState({
        lastVisible: null,
      })
    }
  }

  async reload() {
    try {
      const {
        data: newPermissions = [],
      } = await axios.get('/permissions');
      Permissions.reload(newPermissions);
    } catch (err) {
      Sentry.withScope(() => {
        Sentry.captureException(err);
      });
    }
    const proms = [
      this.props.setMainLoaded(false),
      this.props.getUsers(),
      this.props.getVendors(),
      this.props.getProjects(),
      this.props.getAllCostCodes(),
      this.props.getAllPhases(),
      this.props.getTeams(),
      this.props.getPositions(),
      this.props.getCompany(),
      this.props.getCompanySettings(),
      this.props.getProfile(),
      this.props.getApprovals(),
      this.props.getNuxState(),
      this.props.getNotifications(),
      this.props.getMyCardTags(),
      this.props.getUnreadClientComms(),
      this.props.getGlobalMaterialLocations(),
      this.props.getLabels(),
      this.props.getAddressBooks(),
      this.props.getProjectTypes(),
      this.props.getBucketTemplates(),
      this.props.getCompanyTranslations(),
      this.props.getTimeEntries(),
      this.props.getWorkingHours(),
      this.props.getRoundingPolicies(),
    ];
    if(config.showDivisions) {
      proms.push(this.props.getDivisions());
    }
    await Promise.all(proms);
    this.props.setMainLoaded(true);
  }

  refresh() {
    this.modalVisible = false;
    this.forceUpdate();
    this.setState({
      updateTime:Date.now(),
    });
  }

  showResetModal() {
    if(this.modalVisible) return;
    this.modalVisible = true;
    PermissionResetModal({
      onCloseClicked:this.onRefresh,
    });
  }

  onLogout(didLogout) {
    const {
      userLogout,
    } = this.props;
    if (didLogout) {
      userLogout();
      this.setState({
        loggedIn:false,
        shouldRedirect:true,
      })
    }
  }

  onMenuPicked(item) {
    this.props.history.push(`/${item.key}`);
  };

  async massArchive(ids) {
    const {
      massArchive:massArchiveUsers,
    } = this.props;
    await massArchiveUsers(ids);
  }

  contactRender(isVendor) {
    const { updateTime } = this.state;
    return (props) => {
      return <Contacts key={updateTime} isVendor={isVendor} {...props}/>
    }
  }

  render() {
    const {
      fullscreen,
      menuCollapsed,
      history,
      users = [],
      positionNames = [],
      company:{
        userLimit,
        userId:ownerId,
        freeUserLimit,
      } = {},
      location:{
        pathname,
      },
      externalFormTemplates,
    } = this.props;
    const {
      updateTime,
      needsDowngrade,
      isAfterSubscriptionEnd,
      hideDowngradeForever,
      relevantEndDate,
      shouldRedirect,
      title,
    } = this.state;

    if(!window.sessionStorage.getItem("token") || shouldRedirect) {
      if (pathname && pathname !== '/' && pathname !== '/login') {
        // Wait for API call to finish before redirecting
        // Otherwise client portal will needlessly load
        if (!externalFormTemplates) {
          return null;
        }

        const pathWithoutSlash = pathname.slice(1);
        const externalFormTemplate = externalFormTemplates[pathWithoutSlash];
        if (externalFormTemplate) {
          return <ExternalForm externalFormTemplate={externalFormTemplate} />
        }

        return <ClientPortal/>
      }
      return <Redirect to='/login' />;
    }

    const isOwner = ownerId === Permissions.id;
    const noMenu = fullscreen || menuCollapsed;
    const username = window.sessionStorage.getItem('username') ?? 'unknown';
    return(
      <>
      {
        config.productFruitsCode &&
        (
        <ProductFruits
          workspaceCode={config.productFruitsCode}
          language="en"
          user={{
            username,
          }}
        />
        )
      }
        <FullScreen fullscreen={fullscreen}>
          <Helmet>
            <title>
              {title}
            </title>
          </Helmet>
          <Menu
            history={history}
            onMenuPicked={this.onMenuPicked.bind(this)}
            onLogout={this.onLogout.bind(this)}
            fullscreen={noMenu}
            pathname={pathname}
          />
          <Layout style={{
            marginLeft:noMenu ? 0 : 200,
            transition:'margin-left 0.2s',
            height:'100vh',
          }}>
            {config.showBoards && <Route path='/boards' render={props => <Boards {...props}/>} />}
            <Route path='/contacts/customers' render={this.contactRender(false)} />
            <Route path='/contacts/vendors' render={this.contactRender(true)} />
            {config.showPayablesPage && <Route path='/payables' render={props => <Payables {...props} />} />}
            <Route path="/buckets/:bucketTemplateId" render={props => <Bucket key={updateTime} {...props} type="Buckets" />} />
            <Route exact path="/projects" render={props => <Projects key={updateTime}/>} />
            <Route path="/projects/type/:typeId" render={props => <Projects key={updateTime} {...props} />} />
            <Route path="/projectGroups" render={props => <Bucket key={updateTime} {...props} type="ProjectGroups" />} />
            <Route path="/costcodes" render={props => <CostCodes key={updateTime}/>} />
            <Route exact path="/users" render={props => <Users key={updateTime}/>} />
            <Route path="/users/map" render={props => <ActiveUsersMap key={updateTime} {...props}/>} />
            <Route path="/teams" render={props => <Teams key={updateTime}/>} />
            <Route path="/timecards/my" render={props => <TimeCards key={updateTime}/>} />
            <Route path="/timecards/team" render={props => <TeamTimeCards key={updateTime}/>} />
            <Route path='/settings' render={props => <Settings {...props} key={updateTime}/>}/>
            <Route path='/dashboard' render={props => <Dashboard />}/>
            <Route path='/files' render={props => <Files {...props}/>}/>
            {config.showEquipment && <Route path='/equipment' render={props => <Equipment {...props}/>}/>}
            {config.showMaterials && <Route path='/materials' render={props => <Materials {...props}/>}/>}
            {config.showForms && <Route path='/forms' render={props => <Forms {...props}/>}/>}
            <Route path='/schedule' render={props => <Schedule {...props}/>}/>
            {config.showGantt && <Route path='/gantt-schedule' render={props => <GanttSchedule {...props} fullscreen={fullscreen}/>}/>}
            <Route path='/reports' render={props => <Reports key={updateTime} fullscreen={fullscreen}/>}/>
            <Route path='/exports' render={props => <Exports />}/>
            <Route path='/analytics' render={props => <AnalyticsPage key={updateTime} fullscreen={fullscreen}/>}/>
            <Route path='/search' render={props => <Search {...props} />}/>
          {isOwner && <ForceDowngradeModal
            visible={needsDowngrade}
            history={history}
            users={users}
            positionNames={positionNames}
            userLimit={isAfterSubscriptionEnd ? freeUserLimit : userLimit}
            ownerId={ownerId}
            onCloseClicked={this.onMassArchive}
            subscriptionEnd={relevantEndDate}
          />}
          {!isOwner && <NonAdminDowngradeModal
            visible={needsDowngrade && !hideDowngradeForever}
            users={users}
            userLimit={isAfterSubscriptionEnd ? freeUserLimit : userLimit}
            onCloseClicked={() => {
              this.setState({
                hideDowngradeForever:true,
              });
              window.sessionStorage.setItem('seenDowngradeModal',true)
            }}
            subscriptionEnd={relevantEndDate}
          />}
          </Layout>
        </FullScreen>
        </>
    );
  }
};

export default withTranslation()(
  connect(
    (state,ownProps) => {
      return {
        ...ownProps,
        fullscreen:state.main.fullscreen,
        menuCollapsed: state.main.menuCollapsed,
        users:state.users.users,
        company: state.settings.company,
        positionNames:state.settings.positionNames,
        events:state.livefeed.events,
        externalFormTemplates: state.forms.externalFormTemplates,
        translations: state.settings.translations,
      };
    },{
      getUsers,
      getVendors,
      getProjects,
      getAllCostCodes,
      getAllPhases,
      getTeams,
      getPositions,
      getCompany,
      getCompanySettings,
      toggleFullscreen,
      toggleMenuCollapse,
      getProfile,
      getApprovals,
      massArchive,
      userLogout,
      getNuxState,
      getNotifications,
      setSelectedDivisions,
      getDivisions,
      getMyCardTags,
      getUnreadClientComms,
      getGlobalMaterialLocations,
      getLabels,
      getAddressBooks,
      getExternalFormTemplates,
      getProjectTypes,
      getBucketTemplates,
      getCompanyTranslations,
      getWorkingHours,
      getRoundingPolicies,
      getTimeEntries: TimeTracking.getTimeEntries,
      setMainLoaded,
    }
  )(Main)
);
