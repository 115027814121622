import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Tabs } from 'antd';

import QuickBooks from './Quickbooks/Quickbooks';
import Procore from './Procore/Procore';
import Sage from './Sage300/Sage';
import Sage50 from './Sage50/Sage50';
import Microsoft365 from './Microsoft365/Microsoft365';
import Gmail from './Gmail/Gmail';
import SalesRabbit from './SalesRabbit/SalesRabbit';
import Eclipse from './Eclipse/Eclipse';
import AuroraSolar from './AuroraSolar/AuroraSolar';

import CompanySettingsCard from '../CompanySettingsCard';

import Permissions from '../../auth/Permissions';

import { getPathEnd } from '../../helpers/helpers';

import config from '../../config';
import Docusign from './Docusign/Docusign';

const { TabPane } = Tabs;

const tabStyle = {
  paddingTop: 7,
  paddingBottom: 7,
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 70px)',
};

const QUICKBOOKS_KEY = 'quickbooks';
const PROCORE_KEY = 'procore';
const SAGE_300_KEY = 'sage300';
const SAGE_100_KEY = 'sage100';
const SAGE_50_KEY = 'sage50';
const MS_KEY = 'microsoft365';
const GMAIL_KEY = 'gmail';
const SALES_RABBIT_KEY = 'salesrabbit';
const ECLIPSE_KEY = 'eclipse';
const DOCUSIGN_KEY = 'docusign';
const AURORA_KEY = 'aurora';

const keyMap = new Set([
  QUICKBOOKS_KEY,
  PROCORE_KEY,
  SAGE_300_KEY,
  SAGE_100_KEY,
  SAGE_50_KEY,
  MS_KEY,
  GMAIL_KEY,
  SALES_RABBIT_KEY,
  ECLIPSE_KEY,
  DOCUSIGN_KEY,
  AURORA_KEY,
]);

function IntegrationSettings({
  company: {
    connectedToSage,
    connectedToSage100,
    connectedToProcore,
    userId: ownerId,
  } = {},
  history,
  location,
  visible,
}) {
  const [activeKey, setActiveKey] = useState(QUICKBOOKS_KEY);

  const pathEnd = useMemo(() => getPathEnd(location), [location]);

  useEffect(() => {
    if (keyMap.has(pathEnd)) {
      setActiveKey(pathEnd);
    }
  }, [pathEnd]);

  useEffect(() => {
    if (!visible) setActiveKey(QUICKBOOKS_KEY);
  }, [visible]);

  return (
    <Row justify="start">
      <Tabs
        defaultActiveKey={QUICKBOOKS_KEY}
        tabPosition="left"
        tabBarStyle={{
          marginTop: 15,
          width: 135,
          marginRight: 17,
          marginBottom: 15,
        }}
        style={{ width: '100%' }}
        activeKey={activeKey}
        onChange={(tab) => {
          history.replace(`/settings/integrations/${tab}`);
        }}
      >
        <TabPane style={tabStyle} tab="QuickBooks" key={QUICKBOOKS_KEY}>
          <QuickBooks ownerId={ownerId} visible={activeKey === QUICKBOOKS_KEY} />
        </TabPane>
        <TabPane style={tabStyle} tab="Sage 300 CRE" key={SAGE_300_KEY}>
          <Sage
            version="sage300"
            connectedToSage={connectedToSage}
            ownerId={ownerId}
            visible={activeKey === SAGE_300_KEY}
          />
        </TabPane>
        <TabPane style={tabStyle} tab="Sage 100" key={SAGE_100_KEY}>
          <Sage
            version="sage100"
            connectedToSage={connectedToSage100}
            visible={activeKey === SAGE_100_KEY}
          />
        </TabPane>
        {
          Permissions.id === ownerId
          && (
          <TabPane style={tabStyle} tab="Sage 50" key={SAGE_50_KEY}>
            <CompanySettingsCard title="Sage 50">
              <Sage50
                visible={activeKey === SAGE_50_KEY}
              />
            </CompanySettingsCard>
          </TabPane>
          )
        }
        <TabPane style={tabStyle} tab="Procore" key={PROCORE_KEY}>
          <CompanySettingsCard title="Procore">
            <Procore
              connectedToProcore={connectedToProcore}
              ownerId={ownerId}
            />
          </CompanySettingsCard>
        </TabPane>
        <TabPane style={tabStyle} tab="Microsoft 365" key={MS_KEY}>
          <CompanySettingsCard title="Microsoft 365">
            <Microsoft365 visible={activeKey === MS_KEY} />
          </CompanySettingsCard>
        </TabPane>
        <TabPane style={tabStyle} tab="Gmail" key={GMAIL_KEY}>
          <CompanySettingsCard title="Gmail">
            <Gmail visible={activeKey === GMAIL_KEY && pathEnd === GMAIL_KEY} />
          </CompanySettingsCard>
        </TabPane>
        <TabPane style={tabStyle} tab="SalesRabbit" key={SALES_RABBIT_KEY}>
          <CompanySettingsCard title="SalesRabbit">
            <SalesRabbit
              visible={visible && activeKey === SALES_RABBIT_KEY && pathEnd === SALES_RABBIT_KEY}
            />
          </CompanySettingsCard>
        </TabPane>
        { config.showDocusign && (
          <TabPane style={tabStyle} tab="Docusign" key={DOCUSIGN_KEY}>
            <CompanySettingsCard title="Docusign">
              <Docusign />
            </CompanySettingsCard>
          </TabPane>
        )}

        {config.showEclipse && (
          <TabPane style={tabStyle} tab="Eclipse" key={ECLIPSE_KEY}>
            <CompanySettingsCard title="Eclipse">
              <Eclipse
                visible={visible && activeKey === ECLIPSE_KEY && pathEnd === ECLIPSE_KEY}
              />
            </CompanySettingsCard>
          </TabPane>
        )}
        <TabPane style={tabStyle} tab="Aurora Solar" key={AURORA_KEY}>
          <CompanySettingsCard title="Aurora Solar">
            <AuroraSolar
              visible={visible && activeKey === AURORA_KEY && pathEnd === AURORA_KEY}
            />
          </CompanySettingsCard>
        </TabPane>
      </Tabs>
    </Row>
  );
}

IntegrationSettings.propTypes = {
  company: PropTypes.shape({

  }).isRequired,
  visible: PropTypes.bool,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.string.isRequired,
};

IntegrationSettings.defaultProps = {
  visible: false,
};

export default IntegrationSettings;
