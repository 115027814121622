import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Divider,
  Spin,
} from 'antd';

import { getCompanyImageURL } from '../../settings/state/settings.actions';
import { getExternalFormTemplateById, submitForm } from '../state/forms.actions';
import FormResponder from '../FormResponder';

export default function ExternalForm({
  externalFormTemplate,
}) {
  const dispatch = useDispatch();
  const companyImageURL = useSelector((state) => state.settings.company.companyImageURL);

  const [loading, setLoading] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (externalFormTemplate) {
      dispatch(getCompanyImageURL(externalFormTemplate.companyId, true));
      dispatch(getExternalFormTemplateById(externalFormTemplate.id));
      setLoading(false);
    }
  }, [externalFormTemplate]);

  const Header = useMemo(() => (
    <>
      <Row justify="space-between">
        <Col>
          <span className="external-form-title">
            { externalFormTemplate?.name }
          </span>
        </Col>
        <Col>
          <img
            src={companyImageURL}
            alt="Company Logo"
            style={{
              maxHeight: 50,
              maxWidth: 400,
            }}
          />
        </Col>
      </Row>
      <Divider />
    </>
  ), [externalFormTemplate, companyImageURL]);

  const onSubmit = useCallback(async (payload) => {
    setLoading(true);
    const res = await dispatch(submitForm(payload, true));
    setLoading(false);

    if (res) {
      setHasSubmitted(true);
    }
  }, []);

  return (
    <div
      className="login-container"
      style={{ background: externalFormTemplate?.primaryColor, overflowY: 'scroll' }}
    >
      { !loading && !hasSubmitted && (
        <FormResponder
          visible
          hideCancelButton
          showAsContainer
          draftDisabled
          isExternalForm
          previewDisabled
          header={Header}
          submitButtonStyle={{
            backgroundColor: externalFormTemplate?.accentColor,
            borderColor: externalFormTemplate?.accentColor,
          }}
          cancelButtonStyle={{
            backgroundColor: externalFormTemplate?.secondaryAccentColor,
            borderColor: externalFormTemplate?.secondaryAccentColor,
          }}
          onSubmit={onSubmit}
        />
      )}
      {
        loading && (
          <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col>
              <Spin size="large" />
            </Col>
          </Row>
        )
      }
      {
        hasSubmitted && (
          <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col>
              <h1>Thank you for submitting your form!</h1>
            </Col>
          </Row>
        )
      }
    </div>
  );
}

ExternalForm.propTypes = {
  externalFormTemplate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    primaryColor: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    accentColor: PropTypes.string.isRequired,
    secondaryAccentColor: PropTypes.string.isRequired,
  }).isRequired,
};
