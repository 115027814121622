/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Row,
  Col,
  Statistic,
  Switch,
  Pagination,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  FilterFilled,
  FilterOutlined,
} from '@ant-design/icons';

import TimeCardDatePicker from './TimeCardDatePicker';
import UserStats, { getValueStyle } from './userStats';
import exportExcel from './timecardExcelExport';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

import { calculatePayrollHours } from '../helpers/payroll';
import Analytics from '../helpers/Analytics';

import TimeTracking from '../state/timeTracking';
import {
  getApprovals,
} from '../clock/state/clockin.actions';
import {
  EXPORT_TYPE_EXCEL,
  EXPORT_TYPE_PDF,
  timecardPDFExportAdapter,
  getCanEditUnapproved,
} from './timecard.helpers';
import MoreButton from '../common/buttons/MoreButton';
import { getCompanyImageURL } from '../settings/state/settings.actions';
import TimeCardsColumnChanger from './TimeCardsColumnChanger';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import { getIdMap, isNullOrUndefined } from '../helpers/helpers';
import Permissions from '../auth/Permissions';
import BorderlessButton from '../common/buttons/BorderlessButton';
import Debouncer from '../helpers/Debouncer';
import { toggleMenuCollapse } from '../main/state/main.actions';
import { isFilterActive } from '../schedule/GanttSchedule/ganttScheduleHelpers';
import { updateTimeCardFilters } from './state/timecards.actions';
import { ITEMS_PER_SUMMARY_PAGE } from './timecardListSummaryView.helpers';

const fsDebouncer = new Debouncer();

function TimeCardsHeader({
  isApprovals,
  isIndividual,
  hideWeekSelector,
  tasks = [], // Either the payroll tasks on approvals, depending on which mode we are in
  allTasks = [],
  user = {},
  viewType,
  setViewType,
  onAddEntry,
  selectedSummaryRowKeys = [],
  isSummary,
  setShowFilterDrawer,
  summaryPage = 1,
  setSummaryPage,
}) {
  const { t } = useTranslation();
  const { id: userId, wage, position = '' } = user ?? {};

  const isSummaryWithSelected = !!(isSummary && selectedSummaryRowKeys.length);

  const wagePerm = userId === Permissions.id
    ? 'USERS_WAGE_SELF'
    : `USERS_WAGE_${Permissions.formatPosition(position)}`;
  const hasWagePerms = Permissions.has(wagePerm);

  const canAddNew = getCanEditUnapproved(userId, position);

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.profile.profile);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const users = useSelector((state) => state.users.users);
  const isFullScreen = useSelector((state) => state.main.menuCollapsed);
  const timeCardFilters = useSelector((state) => state.timecards.filters);
  const {
    classes = [],
  } = useSelector((state) => state.unions);
  const mainLoaded = useSelector((state) => (state.main.loaded));

  const FullScreenIcon = isFullScreen ? FullscreenExitOutlined : FullscreenOutlined;

  const {
    timeRange,
    firstPayrollDay,
    payPeriod,
  } = useSelector((state) => state.timecards);
  const {
    settings,
    companyImageURL,
  } = useSelector((state) => {
    const {
      settings: {
        company: {
          settings: companySettings = {},
          companyImageURL = '',
        } = {},
      } = {},
    } = state;
    return {
      settings: companySettings,
      companyImageURL,
    };
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const classMap = useMemo(() => getIdMap(classes), [classes]);
  const payrollHours = useMemo(() => {
    if (isSummary) return {};

    return calculatePayrollHours({
      tasks,
      settings,
      user,
      costcodeMap,
      classMap,
      range: timeRange,
    });
  }, [tasks, settings, user, costcodeMap, classMap, isSummary, timeRange]);

  const submittableTasks = useMemo(() => {
    let filteredTasks;

    const { lockPayPeriodSubmissions } = settings;

    if (!lockPayPeriodSubmissions || currentUser.position === 'Admin') {
      filteredTasks = tasks.filter((task) => !task.state && !isNullOrUndefined(task.endTime));
    } else {
      filteredTasks = tasks.filter((task) => (
        !task.state
        && task.startTime >= firstPayrollDay.toMillis()
        && task.endTime > 0
      ));
    }

    return filteredTasks;
  }, [
    settings,
    firstPayrollDay,
    payPeriod,
    currentUser,
    tasks,
  ]);

  const isFiltersActive = useMemo(() => {
    const timeCardFiltersObj = timeCardFilters ?? {};
    return isFilterActive(timeCardFiltersObj);
  }, [timeCardFilters]);

  const onSubmit = useCallback(async () => {
    if (!userId || submittableTasks.length === 0 || submitLoading) return;
    setSubmitLoading(true);
    if (await dispatch(TimeTracking.submit({
      tasks: submittableTasks, userId,
    }))) {
      dispatch(getApprovals());
    }
    setSubmitLoading(false);
  }, [dispatch, userId, submittableTasks]);

  const onApproveTasks = useCallback(async () => {
    let approvalTasks = tasks.map((task) => ({
      id: task.id,
      projectId: task.projectId,
      userId: task.userId,
    }));
    if (isSummaryWithSelected) {
      const summaryRowKeysSet = new Set(selectedSummaryRowKeys);
      approvalTasks = approvalTasks.filter((task) => summaryRowKeysSet.has(task.id));
    }
    if (await dispatch(TimeTracking.approveTimecards({
      tasks: approvalTasks,
      userId: isSummary ? null : user.id,
      isSummary,
    }))) {
      dispatch(getApprovals());
    }
  }, [tasks, user, isSummary, selectedSummaryRowKeys]);

  const showApproveModal = useCallback((e) => {
    e.stopPropagation();
    const title = `Approve ${isSummaryWithSelected ? 'Selected' : 'All'} Timecards?`;
    CustomConfirmModal({
      title,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: async () => {
        await onApproveTasks();
      },
    });
  }, [onApproveTasks, isSummaryWithSelected]);

  const onExport = useCallback((type) => async () => {
    if (!user || !timeRange || timeRange.length !== 2) return;
    const [startDT, endDT] = timeRange;
    const startMillis = startDT.toMillis();
    const endMillis = endDT.toMillis();
    const { roundingInterval = 1, roundingType, roundingSetting } = settings;
    Analytics.track('Timecards/Export', { ExportFileType: 'xlsx' });
    if (type === EXPORT_TYPE_PDF) {
      await timecardPDFExportAdapter({
        t,
        companyImageURL,
        tasks: allTasks,
        start: startMillis,
        end: endMillis,
        user,
        roundingInterval,
        userMap,
        isSummary,
        roundingType,
        roundingSetting,
      });
    } else {
      exportExcel({
        t,
        tasks: allTasks,
        start: startMillis,
        end: endMillis,
        user: isSummary ? 'Summary' : user.name,
        roundingInterval,
        userMap,
        isSummary,
        roundingType,
        roundingSetting,
        ...payrollHours,
      });
    }
  }, [allTasks, settings, timeRange, user, payrollHours, companyImageURL, isSummary, userMap]);

  useEffect(() => {
    dispatch(getCompanyImageURL());
  }, []);

  const onViewTypeChanged = useCallback((checked) => {
    const newViewType = checked ? 'list' : 'card';
    window.localStorage.setItem('timeCardViewType', newViewType);
    setViewType(newViewType);
  }, []);

  const onToggleFullscreen = useCallback(async () => {
    await fsDebouncer.debounce(() => {
      dispatch(toggleMenuCollapse());
    }, 1000);
  }, []);

  const onClearFilter = useCallback(() => {
    dispatch(updateTimeCardFilters(null));
  }, []);

  return (
    <Row justify="start" gutter={16} align="middle" style={{ marginBottom: '0.5rem' }}>
      {(!isApprovals && !hideWeekSelector) && <TimeCardDatePicker />}
      { !isSummary && (
        <Col>
          <Row style={{ flexWrap: 'nowrap' }}>
            <Col style={{ padding: '10px 8px', overflowX: 'auto' }}>
              <UserStats {...payrollHours} />
            </Col>
            { !!(hasWagePerms && wage) && (
              <Col
                className="timecard-stats-container"
                style={{
                  padding: 3,
                  marginTop: 10,
                }}
              >
                <Statistic title="Total Pay" value={payrollHours?.totalPay ?? 0} valueStyle={getValueStyle('green')} />
              </Col>
            )}
          </Row>
        </Col>
      )}
      {!isSummary && (
        <Col>
          {viewType === 'list' ? 'List ' : 'Card '}
          View
          <Switch
            checked={viewType === 'list'}
            onChange={onViewTypeChanged}
            style={{ marginLeft: 5 }}
          />
        </Col>
      )}
      {!isApprovals && canAddNew && (
        <Col>
          <OnTraccrButton
            title="Add"
            icon={<PlusOutlined />}
            onClick={onAddEntry}
            disabled={!mainLoaded}
            loading={!mainLoaded}
          />
        </Col>
      )}
      {!isApprovals && !isSummary && (
        <Col>
          <OnTraccrButton
            title="Submit"
            disabled={submitLoading || submittableTasks.length === 0}
            loading={submitLoading}
            onClick={onSubmit}
          />
        </Col>
      )}
      {isApprovals && (
        <Col>
          <OnTraccrButton
            title={isSummaryWithSelected ? 'Approve Selected' : 'Approve All'}
            disabled={tasks.length === 0 || (!Permissions.has('ENABLE_APPROVE_ALL') && !isSummaryWithSelected)}
            onClick={(e) => showApproveModal(e)}
          />
        </Col>
      )}
      {isSummary && (
        <>
          <Col>
            <OnTraccrButton
              title=""
              onClick={() => setShowFilterDrawer(true)}
              icon={(
                isFiltersActive
                  ? <FilterFilled style={{ marginLeft: 0 }} />
                  : <FilterOutlined style={{ marginLeft: 0 }} />
              )}
            />
          </Col>
          {isFiltersActive && (
            <Col>
              <OnTraccrButton
                title="Clear Filter"
                onClick={onClearFilter}
              />
            </Col>
          )}
        </>
      )}
      {(viewType === 'list' || isSummary) && (
        <Col>
          <TimeCardsColumnChanger
            isSummary={isSummary}
          />
        </Col>
      )}
      {!isIndividual && (
        <>
          <Col>
            <BorderlessButton
              style={{ width: 30, backgroundColor: 'transparent', padding: 0 }}
              iconNode={<FullScreenIcon style={{ fontSize: 30, marginLeft: 0 }} />}
              onClick={onToggleFullscreen}
            />
          </Col>
          <MoreButton
            options={[
              {
                title: 'Export to Excel',
                icon: 'file-excel',
                style: { padding: 0 },
                onClick: onExport(EXPORT_TYPE_EXCEL),
              },
              {
                title: 'Export to PDF',
                icon: 'file-text',
                style: { padding: 0 },
                onClick: onExport(EXPORT_TYPE_PDF),
              },
            ]}
          />
        </>
      )}
      {isSummary && (
        <Col style={{ marginLeft: 'auto' }}>
          <Pagination
            current={summaryPage}
            hideOnSinglePage
            pageSize={ITEMS_PER_SUMMARY_PAGE}
            total={allTasks?.length ?? 0}
            size="small"
            showSizeChanger={false}
            onChange={(page) => setSummaryPage(page)}
            showLessItems
          />
        </Col>
      )}
    </Row>
  );
}

/* eslint-disable react/forbid-prop-types */
TimeCardsHeader.propTypes = {
  isApprovals: PropTypes.bool,
  isIndividual: PropTypes.bool,
  hideWeekSelector: PropTypes.bool,
  tasks: PropTypes.array,
  allTasks: PropTypes.array,
  user: PropTypes.object,
  viewType: PropTypes.string.isRequired,
  setViewType: PropTypes.func.isRequired,
  onAddEntry: PropTypes.func.isRequired,
  isSummary: PropTypes.bool,
  selectedSummaryRowKeys: PropTypes.array,
  setShowFilterDrawer: PropTypes.func.isRequired,
  summaryPage: PropTypes.number,
  setSummaryPage: PropTypes.func.isRequired,
};

TimeCardsHeader.defaultProps = {
  isApprovals: false,
  isIndividual: false,
  hideWeekSelector: false,
  tasks: [],
  allTasks: [],
  isSummary: false,
  selectedSummaryRowKeys: [],
  user: {},
  summaryPage: 1,
};

export default TimeCardsHeader;
