import React, { useCallback } from 'react';
import { Row, Col, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import ScheduleDatePicker from './ScheduleDatePicker';
import ScheduleZoomButtons from './ScheduleZoomButtons';
import WhoseScheduledDrawer from './WhoseScheduledDrawer';
import UserSelector from '../common/inputs/UserSelector';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import Permissions from '../auth/Permissions';
import { getBiWeeklyEnd, getWeekStart } from './scheduleHelpers';

function ScheduleHeader({
  viewType,
  onViewTypeChange,
  onDateChange,
  date,
  onSearch,
  onAdd,
  selectedUsers,
  onSelectedUsersChanged,
  enableHotkeys,
  disableHotkeys,
}) {
  const onSearchChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    onSearch(value);
  }, [onSearch]);

  const onInputVisibleChanged = useCallback((visible) => {
    if (visible) {
      disableHotkeys();
    } else {
      enableHotkeys();
    }
  }, [enableHotkeys, disableHotkeys]);

  const lowerViewType = viewType.toLowerCase();
  const isBiWeekly = viewType === 'UserBiWeekly';
  let dateMod = lowerViewType.replace('user', '');
  if (lowerViewType === 'user') {
    // Prior to 3.21.0 user day view was just called User view.
    dateMod = 'day';
  }

  const endDate = isBiWeekly ? getWeekStart(date) : date.endOf(dateMod);
  const startDate = isBiWeekly ? getBiWeeklyEnd(date) : date.startOf(dateMod);
  return (
    <Col>
      <Row
        id="schedule-header-container"
        justify="space-between"
        align="middle"
        gutter={20}
        style={{
          display: 'flex',
        }}
      >
        <Col style={{
          flexGrow: 1,
        }}
        >
          <Row justify="start" gutter={14}>
            <Col>
              <Input.Search
                className="searchbar"
                placeholder="Search"
                onChange={onSearchChange}
                allowClear
                onFocus={disableHotkeys}
                onBlur={enableHotkeys}
              />
            </Col>
            <Col>
              {Permissions.has('SCHEDULE_WRITE') && (
              <OnTraccrButton
                title="Add"
                icon={<PlusOutlined />}
                onClick={onAdd}
              />
              )}
            </Col>
            <Col>
              <WhoseScheduledDrawer startDate={startDate} endDate={endDate} />
            </Col>
          </Row>
        </Col>
        <Col style={{
          flexGrow: 1,
        }}
        >
          <Row justify="end" gutter={14}>
            <Col>
              <UserSelector
                multiple
                filteredUsers={selectedUsers}
                onUserChange={onSelectedUsersChanged}
                onVisibleChanged={onInputVisibleChanged}
              />
            </Col>
            <Col>
              <ScheduleDatePicker
                viewType={viewType}
                onViewTypeChange={onViewTypeChange}
                onDateChange={onDateChange}
                date={date}
                onVisibleChanged={onInputVisibleChanged}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col justify="end" span={24}>
          {viewType !== 'Month' && viewType !== 'UserBiWeekly' && <ScheduleZoomButtons />}
        </Col>
      </Row>
    </Col>
  );
}

/* eslint-disable react/forbid-prop-types */
ScheduleHeader.propTypes = {
  viewType: PropTypes.string.isRequired,
  onViewTypeChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(DateTime).isRequired,
  onSearch: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array,
  onSelectedUsersChanged: PropTypes.func.isRequired,
  enableHotkeys: PropTypes.func.isRequired,
  disableHotkeys: PropTypes.func.isRequired,
};

ScheduleHeader.defaultProps = {
  selectedUsers: [],
};

export default ScheduleHeader;
