import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

import FilteredUserSelector from '../common/inputs/FilteredUserSelector';

import WhoseScheduledDrawer from './WhoseScheduledDrawer';

export default ({
  value,
  onChange,
  onUserClick,
  dateRange,
  users,
}) => {
  const [startDate,endDate] = useMemo(() => {
    const [startTs, endTs] = dateRange ?? [];
    return [
      startTs ? DateTime.fromMillis(startTs) : startTs,
      endTs ? DateTime.fromMillis(endTs) : endTs,
    ];
  },[dateRange]);
  return (
    <>
      <div style={{ position: 'absolute', maxWidth: 200, left: 60, top: 15 }}>
        <WhoseScheduledDrawer
          onClick={users && users.length > 0 ? onUserClick : null}
          users={users}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <FilteredUserSelector users={users} value={value} onChange={onChange}/>
    </>
  );
}