import React, { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';
import PropTypes from 'prop-types';

import DashboardComponent from './DashboardComponent';
import UserClockInModal from '../users/UserClockInModal';
import ClockInTimer from '../common/clock/ClockInTimer';
import ManualEntryDrawer from '../clock/ManualEntry/ManualEntryDrawer';

import Permissions from '../auth/Permissions';

import { getIdMap } from '../helpers/helpers';
import { getTodaysManualEntries } from '../helpers/payroll';

export default function DashboardClock({
  user = {},
  settings = {},
  onClockIn,
  onClockOut,
  onSwitch,
  onBreakStart,
  onBreakEnd,
  costcodes,
  projects,
  phases,
  unions,
  onDelete,
  isEdit,
}) {
  const { enableManualEntry } = settings || {};

  const canManual = enableManualEntry && Permissions.has('MANUAL_ENTRY_SELF');
  const canClock = Permissions.has('TIME_TRACKING_SELF');

  const { name } = useSelector(state => state.profile.profile ?? {});
  const {
    timeRange,
    firstPayrollDay,
    payPeriod,
    semiMonthlyPayPeriodDates,
  } = useSelector(state => state.timecards);
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);

  const [ showModal, setShowModal ] = useState(false);
  const [manualEntryVisible, setManualEntryVisible] = useState(false);
  const [ clockRef, setClockRef ] = useState();
  const [ switchOpenDefault, setSwitchOpenDefault ] = useState(false);

  const showManualEntryDrawer = useCallback(() => setManualEntryVisible(true),[]);
  const hideManualEntryDrawer = useCallback(() => setManualEntryVisible(false),[]);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const initialEntries = useMemo(() => (
    getTodaysManualEntries({
      payPeriod,
      semiMonthlyPayPeriodDates,
      firstPayrollDay,
      timeRange,
      user,
      timeEntryUserMap,
      projectIdMap,
      costcodeIdMap,
      isIndividual: true,
      canEdit: Permissions.has('TIMECARD_EDIT_SELF')
    })
  ), [
    timeRange,
    firstPayrollDay,
    payPeriod,
    semiMonthlyPayPeriodDates,
    user,
    timeEntryUserMap,
    projectIdMap,
    costcodeIdMap,
  ]);

  const titlePrefix = name ? `${name}'s` : 'My';
  return(
    <DashboardComponent
      title={`${titlePrefix} Clock`}
      onDelete={onDelete}
      isEdit={isEdit}
    >
      <Row
        type='flex'
        align='middle'
        justify='center'
        style={{
          width: '100%',
          paddingBottom: 32,
        }}>
        <ClockInTimer
          onRef={setClockRef}
          user={user}
          onClockIn={canClock ? () => {
            setShowModal(true)
          } : null}
          onClockOut={canClock ? onClockOut : null}
          onManualEntry={canManual ? showManualEntryDrawer : null}
          onBreakStart={canClock ? onBreakStart : null}
          onBreakEnd={canClock ? onBreakEnd : null}
          onSwitchStart={canClock ? () => {
            setSwitchOpenDefault(true);
            setShowModal(true);
          }: null}
          onSwitchCancel={() => null}
          onSwitch={() => false}
          externalSwitchHandling
        />
        <ManualEntryDrawer
          visible={manualEntryVisible}
          onClose={hideManualEntryDrawer}
          user={user}
          canEditUnapproved
          initialEntries={initialEntries}
        />
        <UserClockInModal
          key={user.id}
          visible={showModal}
          user={user}
          costcodes={costcodes}
          projects={projects}
          phases={phases}
          unions={unions}
          settings={settings}
          onCloseClicked={() => setShowModal(false)}
          onClockIn={(...args) => {
            onClockIn(...args);
            if(clockRef) clockRef.startClock();
          }}
          onClockOut={(...args) => {
            onClockOut(...args);
            if(clockRef) clockRef.stopClock();
          }}
          onSwitch={onSwitch}
          onBreakStart={onBreakStart}
          onBreakEnd={onBreakEnd}
          switchOpen={switchOpenDefault}
        />
      </Row>
    </DashboardComponent>
  );
}

DashboardClock.propTypes = {
  isEdit: PropTypes.bool,
};

DashboardClock.defaultProps = {
  isEdit: false,
};
