import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import IntegrationTag from '../common/IntegrationTag';
import DisplayText from '../common/text/DisplayText';
import DivisionSelector from '../common/inputs/DivisionSelector';

import CostcodeCategorySelector from './CostcodeCategorySelector';
import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';
import CustomFields from '../common/customFields/CustomFields';
import { getCostcodeCustomData, getCostcodeCustomFieldTemplate } from './state/costcodes.actions';
import { doesNumberExist } from '../helpers/helpers';

const validateKey = (id, costcodes, key) => (_rule, value) => {
  if (costcodes.some((cc) => cc[key] === value && id !== cc.id)) {
    return Promise.reject(new Error(`Costcode ${key} ${value} already exists`));
  }

  return Promise.resolve();
};

export default function CostCodeEditView({
  form,
  formProps,
  isProjectSpecific = false,
  isProjectAdd = false,
  errors,
  customFields,
  setCustomFields,
}) {
  const dispatch = useDispatch();
  const {
    id,
    name,
    code,
    divisionId,
    categoryId,
    description,
    hourlyWage,
    dailyWage,
    wageMultiplier,
    hourlyBillingRate,
    dailyBillingRate,
    editing = false,
    costcodes = [],
    intuitId,
    procoreId,
    sageId,
    initialCode = code,
    key = 'editcode',
  } = formProps;

  const {
    customData,
    customDataFiles,
    customFieldTemplate,
  } = useSelector((state) => state.costcodes);

  const { formRef, isAdd } = form;

  const [formValues, setFormValues] = useState(0);
  const [shouldUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
  const [selectedDivisionId, setSelectedDivisionId] = useState(divisionId);

  const onValuesChange = useCallback((_, values) => {
    setFormValues(values);
  }, []);

  useEffect(() => {
    dispatch(getCostcodeCustomFieldTemplate());
    dispatch(getCostcodeCustomData(id));
  }, [id]);

  useEffect(() => {
    forceUpdate();
  }, [customData]);

  useEffect(() => {
    setCustomFields(customFieldTemplate?.sections);
  }, [customFieldTemplate]);

  useEffect(() => {
    if (formRef?.current) {
      setFormValues(formRef.current.getFieldsValue());
    }
  }, [formRef]);

  const isEquipment = formValues?.categoryId === 4;

  return (
    <Form
      layout="vertical"
      ref={formRef}
      initialValues={{
        name,
        code: initialCode,
        description,
        divisionId,
        categoryId,
        hourlyWage,
        dailyWage,
        wageMultiplier,
        hourlyBillingRate,
        dailyBillingRate,
      }}
      key={key}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        name="code"
        label="Cost Code"
        style={{ marginBottom: 20 }}
        rules={[
          { required: isAdd, message: 'Please enter a cost code' },
          { max: 12, message: 'Code cannot exceed 12 characters ' },
          { validator: validateKey(id, costcodes, 'code') },
        ]}
      >
        {editing ? (
          <OnTraccrTextInput
            style={{ width: '100%' }}
          />
        ) : <DisplayText title={code} />}
      </Form.Item>

      <Form.Item
        name="name"
        label="Name"
        style={{ marginBottom: 20 }}
        rules={[
          { required: isAdd, message: 'Please enter a name' },
          { validator: validateKey(id, costcodes, 'name') },
        ]}
      >
        {editing ? (
          <OnTraccrTextInput
            style={{ width: '100%' }}
          />
        ) : <DisplayText title={name} />}
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        style={{ marginBottom: 20 }}
        rules={[{ required: isAdd, message: 'Please enter a description' }]}
      >
        {editing ? (
          <OnTraccrTextInput
            style={{ width: '100%' }}
          />
        ) : <DisplayText title={description} />}
      </Form.Item>
      {!editing && !isProjectAdd && (
        <Form.Item
          name="integrations"
          key="integrations"
          label="Integrations"
          style={{ marginBottom: 20, width: '100%' }}
        >
          <IntegrationTag id={intuitId} title="QuickBooks" />
          <IntegrationTag id={procoreId} title="Procore" />
          <IntegrationTag id={sageId} title="Sage" />
        </Form.Item>
      )}
      { !isProjectAdd && (
        <Form.Item
          name="divisionId"
          key="divisionId"
          label="Division"
          style={{ marginBottom: 20, width: '100%' }}
          valuePropName="divisionId"
        >
          <DivisionSelector
            displayMode={isProjectSpecific || !editing}
            divisionId={selectedDivisionId}
            onChange={setSelectedDivisionId}
          />
        </Form.Item>
      )}

      <Form.Item
        name="categoryId"
        key="categoryId"
        label="Category"
        style={{ marginBottom: 20, width: '100%' }}
        valuePropName="categoryId"
      >
        <CostcodeCategorySelector
          displayMode={!editing || isProjectAdd}
        />
      </Form.Item>

      <Form.Item
        name="hourlyWage"
        label={isEquipment ? 'Hourly Cost' : 'Hourly Wage'}
        key="hourlyWage"
        style={{ marginBottom: 20, width: '100%' }}
      >
        {editing ? (
          <OnTraccrNumberInput
            style={{ width: '100%' }}
            disabled={doesNumberExist(formValues?.wageMultiplier) || doesNumberExist(formValues?.dailyWage)}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            step={0.01}
            precision={2}
            min={0}
          />
        ) : <DisplayText title={hourlyWage ?? '-'} />}
      </Form.Item>

      <Form.Item
        name="dailyWage"
        label={isEquipment ? 'Daily Cost' : 'Daily Wage'}
        key="dailyWage"
        style={{ marginBottom: 20, width: '100%' }}
      >
        {editing ? (
          <OnTraccrNumberInput
            style={{ width: '100%' }}
            disabled={doesNumberExist(formValues?.wageMultiplier) || doesNumberExist(formValues?.hourlyWage)}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            step={0.01}
            precision={2}
            min={0}
          />
        ) : <DisplayText title={dailyWage ?? '-'} />}
      </Form.Item>

      {!isEquipment && (
        <Form.Item
          name="wageMultiplier"
          label="Wage Multiplier"
          key="wageMultiplier"
          style={{ marginBottom: 20, width: '100%' }}
        >
          {editing ? (
            <OnTraccrNumberInput
              style={{ width: '100%' }}
              disabled={doesNumberExist(formValues?.hourlyWage) || doesNumberExist(formValues?.dailyWage)}
              step={0.01}
              precision={2}
              min={0}
            />
          ) : <DisplayText title={wageMultiplier ?? '-'} />}
        </Form.Item>
      )}

      <Form.Item
        name="hourlyBillingRate"
        label="Hourly Billing Rate"
        key="hourlyBillingRate"
        style={{ marginBottom: 20, width: '100%' }}
      >
        {editing ? (
          <OnTraccrNumberInput
            style={{ width: '100%' }}
            disabled={formValues?.dailyBillingRate || formValues?.dailyBillingRate === 0}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            step={0.01}
            precision={2}
            min={0}
          />
        ) : <DisplayText title={hourlyBillingRate ?? '-'} />}
      </Form.Item>

      <Form.Item
        name="dailyBillingRate"
        label="Daily Billing Rate"
        key="dailyBillingRate"
        style={{ marginBottom: 20, width: '100%' }}
      >
        {editing ? (
          <OnTraccrNumberInput
            style={{ width: '100%' }}
            disabled={formValues?.hourlyBillingRate || formValues?.hourlyBillingRate === 0}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            step={0.01}
            precision={2}
            min={0}
          />
        ) : <DisplayText title={dailyBillingRate ?? '-'} />}
      </Form.Item>
      { !!customFields?.length && (
        <Form.Item
          name="customData"
          style={{ marginBottom: 20 }}
        >
          <CustomFields
            key={`${id}${shouldUpdate}`} // Need to force re-render
            initialValues={customData}
            customFields={customFields}
            divisions={[selectedDivisionId]}
            errors={errors}
            fileMap={customDataFiles}
            isDisplay={!editing}
          />
        </Form.Item>
      )}
    </Form>
  );
}

CostCodeEditView.propTypes = {
  form: PropTypes.shape({
    formRef: PropTypes.shape({
      current: PropTypes.shape({
        getFieldsValue: PropTypes.func,
      }),
    }),
    isAdd: PropTypes.bool,
  }).isRequired,
  formProps: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    divisionId: PropTypes.string,
    categoryId: PropTypes.string,
    description: PropTypes.string,
    hourlyWage: PropTypes.number,
    dailyWage: PropTypes.number,
    wageMultiplier: PropTypes.number,
    hourlyBillingRate: PropTypes.number,
    dailyBillingRate: PropTypes.number,
    editing: PropTypes.bool,
    costcodes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      code: PropTypes.string,
      divisionId: PropTypes.number,
      categoryId: PropTypes.number,
      description: PropTypes.string,
      hourlyWage: PropTypes.number,
      dailyWage: PropTypes.number,
      wageMultiplier: PropTypes.number,
      hourlyBillingRate: PropTypes.number,
      dailyBillingRate: PropTypes.number,
    })),
    intuitId: PropTypes.string,
    procoreId: PropTypes.string,
    sageId: PropTypes.string,
    initialCode: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
  isProjectSpecific: PropTypes.bool,
  isProjectAdd: PropTypes.bool,
  errors: PropTypes.shape({}),
  customFields: PropTypes.arrayOf(PropTypes.shape({})),
  setCustomFields: PropTypes.func.isRequired,
};

CostCodeEditView.defaultProps = {
  isProjectSpecific: false,
  isProjectAdd: false,
  errors: {},
  customFields: [],
};
