import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import { DateTime } from 'luxon';

import Permissions from '../auth/Permissions';
import Analytics from '../helpers/Analytics';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

import FormDetailView from '../forms/CompletedForms/FormDetailView';

import ScheduleHeader from './ScheduleHeader';
import DailySchedule from './DailySchedule/DailySchedule';
import WeeklySchedule from './WeeklySchedule/WeeklySchedule';
import MonthlySchedule from './MonthlySchedule/MonthlySchedule';
import UserSchedule from './UserSchedule/UserSchedule';
import ScheduleDrawer from './ScheduleDrawer';
import UserWeeklySchedule from './UserSchedule/UserWeeklySchedule';
import RecurringChangeModal from '../common/modals/RecurringChangeModal';

import {
  filterShifts,
  getDayKey,
  getId,
  timestampToPosition,
  prepareShiftUpdatePayload,
  refineReminders,
  getWeekStart,
  getBiWeeklyEnd,
} from './scheduleHelpers';

import {
  getSchedule,
  createShift,
  updateShift,
  deleteShift,
} from './state/schedule.actions';
import { getUserLabels } from '../users/state/users.actions';
import {
  getFormById,
  getTemplates,
  getStatuses,
  getCustomTables,
} from '../forms/state/forms.actions';
import { getCustomerLabels, getCustomers } from '../contacts/customers/state/customers.actions';
import {
  getEmails,
} from '../emails/state/email.actions';
import { getBillingRates } from '../billingRates/state/billingRates.actions';

import { getIdMap } from '../helpers/helpers';

const getViewTypeFromAsync = () => {
  const type = window.localStorage.getItem('scheduleViewType') ?? 'Week';
  if (type === 'User' || type === 'user') {
    window.localStorage.setItem('scheduleViewType', 'UserDay');
    return 'UserDay';
  }
  return type;
}
const crumbs = [{ text:'Scheduling', icon:'calendar' }];

const showDeleteModal = (shift, callback) => (
  CustomConfirmModal({
    title: `Delete ${shift.title}`,
    okText: 'Delete',
    cancelText: 'Cancel',
    onOk: callback,
  })
);

export default function Schedule({
  history
}) {
  const defaultDisplay= !Permissions.has('SCHEDULE_WRITE');
  const dispatch = useDispatch();
  const stateShifts = useSelector(state => state.schedule.shifts);
  const shiftMap = useSelector(state => state.schedule.shiftMap);
  const selectedDivisions = useSelector(state => state.settings.selectedDivisions);
  const companySettings = useSelector(state => state.settings.company);
  const users = useSelector(state => state.users.users);
  const [viewType,setViewType] = useState(getViewTypeFromAsync());
  const [date,setDate] = useState(DateTime.local());
  const [selectedUsers,setSelectedUsers] = useState(
    window.sessionStorage.getItem('scheduleSelectedUsers')
      ? JSON.parse(window.sessionStorage.getItem('scheduleSelectedUsers'))
      : []
  );
  const [showDrawer,setShowDrawer] = useState(false);
  const [selectedShift,setSelectedShift] = useState();
  const [newShift,setNewShift] = useState();
  const [editShift,setEditShift] = useState();
  const [shifts,setShifts] = useState({});
  const [searchText,setSearchText] = useState();
  const [isDisplay,setIsDisplay] = useState(defaultDisplay);
  const [changeModalMode,setChangeModalMode] = useState();
  const [changeModalType, setChangeModalType] = useState();
  const [stagedUpdate,setStagedUpdate] = useState();
  const [showFormDetail, setShowFormDetail] = useState();
  const [lastDownload, setLastDownload] = useState();
  const [loading, setLoading] = useState(true);
  const [rangeStartDate, setRangeStartDate] = useState(DateTime.local());
  const [rangeEndDate, setRangeEndDate] = useState(DateTime.local());
  const [canUseHotkeys, setCanUseHotkeys] = useState(true);

  const hideDrawer = useCallback(() => {
    setShowDrawer(false);
    setSelectedShift();
    setNewShift();
    setEditShift();
    setIsDisplay(defaultDisplay);
    setChangeModalMode();
    setChangeModalType();
    setStagedUpdate();
  },[]);

  const openFormDetail = useCallback(async (formId) => {
    if(await dispatch(getFormById(formId))) {
      setShowFormDetail(true);
    }
  }, []);

  const closeFormDetail = useCallback(() => {
    setShowFormDetail(false);
    if (editShift) {
      if (editShift.id in shiftMap) {
        // Force reload data
        setShowDrawer(false);
        setEditShift({
          ...editShift,
          ...shiftMap[editShift.id] ?? {},
        });
        setTimeout(() => {
          setShowDrawer(true);
        }, 200);
      } else {
        hideDrawer();
      }
    }
  }, [hideDrawer, editShift, shiftMap]);

  const hideChangeModal = useCallback(() => {
    setChangeModalMode();
    setChangeModalType();
  },[]);

  const onShiftCreate = useCallback((shift) => {
    const { day } = shift;
    setSelectedShift({
      ...shift,
      date: day.toMillis(),
    });
    setShowDrawer(true);
    setIsDisplay(false);
  },[]);

  const openDrawer = useCallback(() => {
    const dayStart = date.startOf('day');
    const startTime = date.set({ hour: 9, minute: 0 }).toMillis();
    const endTime = date.set({ hour: 10, minute: 0}).toMillis();
    setNewShift({
      id: getId(),
      day: date,
      date: date.toMillis(),
      startTime,
      endTime,
      top: timestampToPosition(startTime, dayStart),
      bottom: timestampToPosition(endTime, dayStart),
    });
    setShowDrawer(true);
    setIsDisplay(false);
  },[date]);

  const onDateChange = useCallback((newDate) => {
    const ts = newDate.valueOf();
    const dt = DateTime.fromMillis(ts);
    setDate(dt);
  },[]);

  const dispatchShiftUpdate = useCallback(async (shift, isGroup) => {
    const { emailNotification, pushNotification } = companySettings.settings;
    const day = editShift ? editShift.day : newShift.day;
    const dayKey = getDayKey(day);
    if(!(dayKey in shifts)) {
      shifts[dayKey] = [];
    }
    const {
      [dayKey]: dayShifts = [],
    } = shifts;
    const existingShift = dayShifts.find((dayShift) => dayShift.id === editShift.id);
    if(!existingShift) return false;
    const payload = prepareShiftUpdatePayload(existingShift, {
      ...editShift,
      ...shift,
      emailNotification,
      pushNotification,
    }, isGroup);
    return dispatch(updateShift(editShift.id, payload));
  },[editShift, shifts, newShift, companySettings]);

  const onShiftSave = useCallback(async (shift) => {
    const { emailNotification, pushNotification } = companySettings.settings;
    let passed = false;
    if(editShift) {
      if(editShift.groupId) {
        setChangeModalMode('update');
        setChangeModalType(editShift.isEvent ? 'Event' : 'Shift');
        setStagedUpdate(shift);
        return;
      }
      passed = await dispatchShiftUpdate(shift);
    } else {
      const refinedReminders = refineReminders(shift.reminders);
      window.sessionStorage.setItem('lastReminderConfig', JSON.stringify(refinedReminders));
      const toCreate = {
        ...newShift,
        ...shift,
        reminders: refinedReminders,
        emailNotification: emailNotification,
        pushNotification: pushNotification,
      };
      passed = await dispatch(createShift(toCreate));
    }
    if(passed) hideDrawer();
    return passed;
  },[
    dispatch,
    newShift,
    editShift,
    hideDrawer,
    dispatchShiftUpdate,
    companySettings,
    shifts,
  ]);

  const onShiftSelect = useCallback((shift) => {
    Analytics.track('Schedule/ShiftView');
    setSelectedShift(shift);
    setShowDrawer(true);
    setIsDisplay(defaultDisplay);
  },[defaultDisplay]);

  const onShiftDelete = useCallback((shift) => {
    if(shift.groupId) {
      setChangeModalMode('delete');
      setChangeModalType(shift.isEvent ? 'Event' : 'Shift');
      setSelectedShift(shift);
    } else {
      showDeleteModal(shift, async () => {
        if(await dispatch(deleteShift(shift))) {
          hideDrawer();
        }
      });
    }
  }, [dispatch, hideDrawer]);

  const onShiftGroupSave = useCallback(async (mode) => {
    const isGroup = mode === 'all';
    if(changeModalMode === 'delete') {
      if(await dispatch(deleteShift(selectedShift, isGroup))) {
        hideDrawer();
      }
    } else {
      if(await dispatchShiftUpdate(stagedUpdate, isGroup)) {
        hideDrawer();
      }
    }
  },[selectedShift, editShift, stagedUpdate, changeModalMode]);

  const onDaySelect = useCallback((day) => {
    Analytics.track('Schedule/ChangeView', { viewType: 'Day' });
    setDate(day);
    setViewType('Day');
    window.localStorage.setItem('scheduleViewType', 'Day');
  },[]);

  const onViewTypeChange = useCallback((newViewType) => {
    Analytics.track('Schedule/ChangeView', { viewType: newViewType });
    setViewType(newViewType);
    window.localStorage.setItem('scheduleViewType', newViewType);
  },[]);

  useEffect(() => {
    // Optimize this thing...
    if(dispatch) {
      dispatch(getUserLabels());
      dispatch(getCustomers());
      dispatch(getCustomerLabels());
      dispatch(getTemplates());
      dispatch(getEmails());
      dispatch(getBillingRates());
      dispatch(getStatuses());
      dispatch(getCustomTables());
    }
  },[dispatch]);

  useEffect(() => {
    const loadShifts = async ({ startTime, endTime}) => {
      setLoading(true);
      if (await dispatch(getSchedule({ startTime, endTime }))) {
        setLastDownload([startTime, endTime])
      }
      setLoading(false);
    };
    let startDT = DateTime.local();
    let endDT = DateTime.local();
    if (viewType === 'Month') {
      startDT = date.startOf('month');
      endDT = date.endOf('month');
    } else if (viewType === 'UserBiWeekly') {
      startDT = getWeekStart(date);
      endDT = getBiWeeklyEnd(date);
    } else {
      startDT = getWeekStart(date);
      endDT = startDT.plus({ days: 7 });
    }
    const startTime = startDT.toMillis();
    const endTime = endDT.toMillis();
    if (!lastDownload || lastDownload[0] > startTime || lastDownload[1] < endTime) {
      loadShifts({ startTime, endTime });
    }
  }, [dispatch, lastDownload, date, viewType]);

  useEffect(() => {
    setShifts(stateShifts);
  }, [stateShifts]);

  useEffect(() => {
    setViewType(getViewTypeFromAsync());
  },[]);

  useEffect(() => {
    if (viewType === 'UserBiWeekly') {
      setRangeStartDate(getWeekStart(date));
      setRangeEndDate(getBiWeeklyEnd(date));
    }
    // extend here for custom ranges
  }, [date, viewType]);

  const userMap = useMemo(() => getIdMap(users.filter((user) => user.active)), [users]);

  const filteredShifts = useMemo(() => (
    filterShifts({
      shifts,
      day: date,
      searchText,
      userMap,
      viewType,
      selectedDivisions,
      selectedUsers: new Set(selectedUsers),
      rangeStartDate,
      rangeEndDate,
    })
  ), [
    searchText,
    shifts,
    userMap,
    date,
    viewType,
    selectedDivisions,
    selectedUsers,
    rangeStartDate,
    rangeEndDate,
  ]);

  const onUserChange = useCallback((users) => {
    setSelectedUsers(users);
    window.sessionStorage.setItem('scheduleSelectedUsers', JSON.stringify(users));
  }, []);

  const disableHotkeys = useCallback(() => setCanUseHotkeys(false), []);
  const enableHotkeys = useCallback(() => setCanUseHotkeys(true), []);

  useEffect(() => {
    const hotkeys = (event) => {
      if (showDrawer || !canUseHotkeys) return;
      const c = String.fromCharCode(event.keyCode);
      switch (c) {
        case 'D':
          onViewTypeChange('Day');
          break;
        case 'W':
          onViewTypeChange('Week');
          break;
        case 'M':
          onViewTypeChange('Month');
          break;
        case 'U':
          onViewTypeChange('UserDay');
          break;
        case 'B':
          onViewTypeChange('UserBiWeekly');
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', hotkeys, false);

    return () => {
      document.removeEventListener('keydown', hotkeys, false);
    };
  }, [showDrawer, canUseHotkeys, onViewTypeChange]);

  if(!Permissions.match('SCHEDULE')) {
    history.replace('/dashboard');
    return <></>;
  }
  return (
    <BreadCrumbContainer
      crumbs={crumbs}
      bodyStyle={{
        position:'absolute',
        top: 71,
        left: 15,
        right: 15,
        bottom: viewType === 'User' ? 0 : 15,
        height: 'auto',
      }}
    >
      <Row id='schedule-container'>
        <ScheduleHeader
          viewType={viewType}
          onViewTypeChange={onViewTypeChange}
          onDateChange={onDateChange}
          date={date}
          selectedUsers={selectedUsers}
          onSelectedUsersChanged={onUserChange}
          onSearch={setSearchText}
          onAdd={openDrawer}
          enableHotkeys={enableHotkeys}
          disableHotkeys={disableHotkeys}
        />
        {viewType === 'Day' && <DailySchedule
          day={date}
          onShiftCreate={onShiftCreate}
          shifts={filteredShifts[getDayKey(date)]}
          newShift={newShift}
          onNewShiftChange={setNewShift}
          onShiftSelect={onShiftSelect}
          editShift={editShift}
          onShiftEdit={setEditShift}
        />}
         {viewType === 'Week' && <WeeklySchedule
          date={date}
          onShiftCreate={onShiftCreate}
          shifts={filteredShifts}
          newShift={newShift}
          onNewShiftChange={setNewShift}
          onShiftSelect={onShiftSelect}
          editShift={editShift}
          onShiftEdit={setEditShift}
          onDaySelect={onDaySelect}
        />}

        {viewType === 'Month' &&
          <MonthlySchedule
            date={date}
            onDaySelect={onDaySelect}
            shifts={filteredShifts}
            onShiftSelect={onShiftSelect}
            onShiftEdit={setEditShift}
          />
        }

        {viewType === 'UserDay' && <UserSchedule
          day={date}
          onShiftCreate={onShiftCreate}
          shifts={filteredShifts[getDayKey(date)]}
          newShift={newShift}
          onNewShiftChange={setNewShift}
          onShiftSelect={onShiftSelect}
          editShift={editShift}
          onShiftEdit={setEditShift}
          searchText={searchText}
          selectedUsers={selectedUsers}
        />}

        {viewType === 'UserBiWeekly' && (
          <UserWeeklySchedule
            date={date}
            startDate={rangeStartDate}
            endDate={rangeEndDate}
            selectedUsers={selectedUsers}
            searchText={searchText}
            shifts={filteredShifts}
            onShiftSelect={onShiftSelect}
            onShiftEdit={setEditShift}
            editShift={editShift}
            onShiftCreate={onShiftCreate}
            newShift={newShift}
            onNewShiftChange={setNewShift}
            onDaySelect={onDaySelect}
          />
        )}
        {
          loading &&
          <Row className="schedule-loading-container" justify="center" align="middle">
            <Col>
              <Spin/>
            </Col>
          </Row>
        }
      </Row>
      <ScheduleDrawer
        shift={selectedShift || editShift || newShift}
        visible={showDrawer}
        onClose={hideDrawer}
        onSave={onShiftSave}
        onDelete={onShiftDelete}
        isNew={newShift}
        isDisplay={isDisplay}
        openFormDetail={openFormDetail}
      />
      <RecurringChangeModal
        mode={changeModalMode}
        onClose={hideChangeModal}
        onSave={onShiftGroupSave}
        type={changeModalType}
      />
      <FormDetailView
        visible={showFormDetail}
        onClose={closeFormDetail}
        userMap={userMap}
        shouldRedirect={false}
      />
    </BreadCrumbContainer>
  );
}
