import React, { useMemo } from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DisplayText from '../text/DisplayText';

/**
 * Project Selector for invoice slider
 */
function ProjectSelector({
  isNotDisplay,
  projectDisplay,
  formStyle = {},
  formLabelStyle = {},
  disabled,
  onChange,
}) {
  const { t } = useTranslation();

  const projects = useSelector((state) => state.projects.projects);

  const activeProjects = useMemo(() => projects.filter((project) => project.active), [projects]);

  const projectOptions = useMemo(() => (
    activeProjects.map((project) => ({ label: `${project.number} - ${project.name}`, value: project.id }))
  ), [activeProjects]);

  return (
    <Form.Item
      name="projectId"
      label={t('Project')}
      style={{ marginBottom: 0, ...formStyle }}
      labelCol={formLabelStyle}
    >
      {isNotDisplay ? (
        <Select
          placeholder={`Select a ${t('Project')}`}
          showSearch
          allowClear
          optionFilterProp="label"
          options={projectOptions}
          onChange={onChange}
          disabled={disabled}
        />
      ) : (
        <DisplayText title={projectDisplay} />
      )}
    </Form.Item>
  );
}

/* eslint-disable react/forbid-prop-types */
ProjectSelector.propTypes = {
  isNotDisplay: PropTypes.bool.isRequired,
  projectDisplay: PropTypes.string.isRequired,
  formStyle: PropTypes.object,
  formLabelStyle: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

ProjectSelector.defaultProps = {
  formStyle: {},
  formLabelStyle: {},
  disabled: false,
};

export default ProjectSelector;
