import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Drawer,
  Form,
  Row,
  Col,
  Switch,
  Select,
  Checkbox,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import FormTextInput from '../../common/inputs/FormTextInput';
import DivisionSelector from '../../common/inputs/DivisionSelector';

import ManualEntryCustomFields from './ManualEntryCustomFields';
import ManualEntryTimeHoursInput from './ManualEntryTimeHoursInput';
import ManualEntryDatePicker from './ManualEntryDatePicker';
import Analytics from '../../helpers/Analytics';

import sortByString, { getIdMap, isNullOrUndefined } from '../../helpers/helpers';
import {
  dateValidator,
  getOpt,
  getCostcodeOptions,
  getPhaseOptions,
  getProjectOptions,
  getUserOptions,
  UNPHASED,
  convertFromTimeBasedToHourBased,
} from './manualEntryHelpers';
import {
  getErrorsFromResponses,
  prepareResponsePayload,
} from '../../forms/ResponderHelpers';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import { TIME_TYPE_FLAG } from '../../constants/Flags';
import Permissions from '../../auth/Permissions';

const DEFAULT_TYPES = [
  { label: 'Work', value: 'work' },
  { label: 'Break', value: 'break' },
];

const BASE_TIME_ENTRY_DATA = {
  totalTime: 0,
  workTime: 0,
  breakTime: 0,
};

function ManualEntryFormDrawer({
  visible,
  onClose,
  onSubmit,
  selectedEntry,
  defaultSageShiftId,
  defaultClassId,
  defaultProjectId,
  defaultDate,
  fileMap,
  userId,
  entries = [],
  lockedDivisionId,
  user = {},
  allowInlineTimes,
  hideUserSelector,
}) {
  const { t } = useTranslation();

  const {
    projectId,
    phaseId,
    costcodeId,
    classId,
    type = 'work',
    id,
    sageShiftId,
    customData = [],
    divisionId,
    timezone = DateTime.local().zoneName,
    date: entryDate = defaultDate,
    startTime: selectedEntryStartTime = 0,
    endTime: selectedEntryEndTime,
    hourBased: selectedEntryHourBased,
    userId: selectedEntryUserId,
    state: selectedEntryState = null,
  } = selectedEntry || {};

  const ourClassId = classId || defaultClassId;
  const [form] = Form.useForm();

  const [currentDate, setCurrentDate] = useState(entryDate);

  const customFields = useSelector((state) => state.timecards.customFields);
  const sageShifts = useSelector((state) => state.sage.shifts);
  const userDivisions = useSelector((state) => state.users.userDivisions);
  const projects = useSelector((state) => state.projects.projects);
  const teams = useSelector((state) => state.teams.teams);
  const users = useSelector((state) => state.users.users);
  const {
    paidFlags = [],
    settings: {
      autoBreakEnabled = false,
    } = {},
  } = useSelector((state) => state.settings.company);

  const paidFlagSet = new Set(paidFlags);

  const {
    activePhases,
    costcodes,
  } = useSelector((state) => {
    const {
      costcodes: {
        phases: statePhases = [],
        costcodes: stateCostcodes = [],
      } = {},
    } = state;
    return {
      activePhases: statePhases,
      costcodes: stateCostcodes,
    };
  });
  const {
    unions = [],
    locals = [],
    classes = [],
  } = useSelector((state) => state.unions);
  const { settings = {} } = useSelector((state) => state.settings.company || {});
  const {
    enableHourBasedTracking = false,
    reqProject,
    reqCostcode,
    enableLocalSelection,
    enableClassSelection,
    enableSageShiftSelection,
    enableManualOT,
  } = settings;
  const {
    hourBased: taskIsHourBased = enableHourBasedTracking,
  } = selectedEntry || {};

  const [hourBased, setHoursBased] = useState(enableHourBasedTracking && taskIsHourBased);
  const [selectedProject, setSelectedProject] = useState(projectId || defaultProjectId);
  const [selectedPhase, setSelectedPhase] = useState(projectId
    && phaseId === null
    && costcodeId
    ? UNPHASED
    : phaseId);
  const [selectedCostCode, setSelectedCostCode] = useState(costcodeId);
  const [selectedLocal, setSelectedLocal] = useState();
  const [customFieldErrors, setCustomFieldErrors] = useState({});
  const [currentDivisionId, setCurrentDivisionId] = useState(lockedDivisionId || divisionId);
  const [timeData, setTimeData] = useState();
  const [showBreak, setShowBreak] = useState(false);
  const [showOvertime, setShowOvertime] = useState(false);
  const [showDoubleOvertime, setShowDoubleOvertime] = useState(false);
  const [currentType, setCurrentType] = useState(type);

  const hasPaidFlag = paidFlagSet.has(TIME_TYPE_FLAG);

  const {
    existingAllTasksTimeEntryData,
    existingSelectedEntryTimeEntryData,
  } = useMemo(() => {
    const data = { ...BASE_TIME_ENTRY_DATA };
    const selectedEntryData = { ...BASE_TIME_ENTRY_DATA };

    entries.forEach((task) => {
      const { type: entryType, id: taskId } = task;
      const runtimes = TaskHelpers.getRuntimes(task, true);
      const workRuntime = runtimes.regularTime + runtimes.overtime + runtimes.doubleOT;
      const breakRuntime = runtimes.breakTime;
      const totalRuntime = workRuntime + breakRuntime;

      const shouldAddToSelectedEntry = selectedEntry?.id === taskId;
      const isBreak = entryType === 'break';
      data.totalTime += totalRuntime;
      selectedEntryData.totalTime += shouldAddToSelectedEntry ? totalRuntime : 0;

      if (!isBreak) {
        data.workTime += workRuntime;
        selectedEntryData.workTime += shouldAddToSelectedEntry ? workRuntime : 0;
      } else {
        data.breakTime += breakRuntime;
        selectedEntryData.breakTime += shouldAddToSelectedEntry ? breakRuntime : 0;
      }
    });
    return {
      existingAllTasksTimeEntryData: data,
      existingSelectedEntryTimeEntryData: selectedEntryData,
    };
  }, [entries, selectedEntry]);

  useEffect(() => {
    if (visible) {
      return setTimeData(existingAllTasksTimeEntryData);
    }

    return setTimeData();
  }, [visible, existingAllTasksTimeEntryData]);

  const activeCostcodes = useMemo(() => (
    costcodes.filter((cc) => cc.active)
  ), [costcodes]);
  const ourCustomFields = useMemo(() => {
    const {
      [currentDivisionId]: { fields = [] } = {},
    } = customFields;
    return fields;
  }, [customFields, currentDivisionId]);

  const getNewEntryTimeData = useCallback(({
    type: newType,
    startTime,
    endTime,
    breakStartTime,
    breakEndTime,
    otStartTime,
    otEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
  }) => {
    if (
      (!newType || hasPaidFlag)
      || (
        !startTime
        && !endTime
        && !breakStartTime
        && !breakEndTime
        && !otStartTime
        && !otEndTime
        && !doubleOTStartTime
        && !doubleOTEndTime
      )) {
      return;
    }

    const newTimeData = {
      ...existingAllTasksTimeEntryData,
    };

    if (existingSelectedEntryTimeEntryData) {
      newTimeData.totalTime -= existingSelectedEntryTimeEntryData.totalTime;
      newTimeData.workTime -= existingSelectedEntryTimeEntryData.workTime;
      newTimeData.breakTime -= existingSelectedEntryTimeEntryData.breakTime;
    }

    const runtimes = TaskHelpers.getRuntimes({
      type: newType,
      startTime,
      endTime,
      breakStartTime,
      breakEndTime,
      otStartTime,
      otEndTime,
      doubleOTStartTime,
      doubleOTEndTime,
    }, true);
    const runtime = runtimes.regularTime + runtimes.overtime + runtimes.doubleOT;

    newTimeData.totalTime += runtime;

    if (newType === 'break') {
      newTimeData.breakTime += endTime - startTime;
    } else if (breakEndTime && breakStartTime) {
      newTimeData.breakTime += (breakEndTime - breakStartTime);
    } else {
      newTimeData.workTime += runtime;
    }

    setTimeData(newTimeData);
  }, [
    hourBased,
    selectedEntry,
    existingAllTasksTimeEntryData,
    existingSelectedEntryTimeEntryData,
    hasPaidFlag,
  ]);

  useEffect(() => {
    if (!visible || !selectedEntry) return;
    const {
      breakStartTime,
      breakEndTime,
      otStartTime,
      otEndTime,
      doubleOTStartTime,
      doubleOTEndTime,
    } = selectedEntry;

    if (breakStartTime && breakEndTime) setShowBreak(true);
    if (otStartTime && otEndTime) setShowOvertime(true);
    if (doubleOTStartTime && doubleOTEndTime) setShowDoubleOvertime(true);
  }, [visible, selectedEntry]);

  const onValuesChange = useCallback((changedValues = {}, newValues = {}) => {
    const relevantTimeDataFields = new Set([
      'type',
      'startTime',
      'endTime',
      'breakStartTime',
      'breakEndTime',
      'otStartTime',
      'otEndTime',
      'doubleOTStartTime',
      'doubleOTEndTime',
    ]);
    const hasRelevantTimeData = Object.keys(changedValues).some(
      (key) => relevantTimeDataFields.has(key),
    );

    if (hasRelevantTimeData) {
      getNewEntryTimeData(newValues);
    }

    const {
      projectId: selectedProjectId,
      phaseId: selectedPhaseId,
      localId: selectedLocalId,
      divisionId: newDivisionId,
      costcodeId: selectedCostCodeId,
      date: newDate,
    } = newValues;
    setCurrentDate(newDate);
    const formUpdate = { ...newValues };
    let newProjectId = selectedProjectId;
    if (currentDivisionId !== newDivisionId) {
      newProjectId = null;
      formUpdate.projectId = null;
      formUpdate.sageShiftId = null;

      const { users: formUsers = [] } = newValues;
      const newUsers = formUsers.filter((formUserId) => (
        !!userDivisions?.[formUserId]?.find((divId) => divId === newDivisionId)
      ));
      formUpdate.users = newUsers;
    }

    setCurrentType(newValues.type);
    if (newValues.type === 'break') setShowBreak(false);
    if (newValues.type === 'overtime') setShowOvertime(false);

    setCurrentDivisionId(newDivisionId);
    setSelectedProject(newProjectId);
    setSelectedLocal(selectedLocalId);

    if (!selectedLocalId || selectedLocalId !== selectedLocal) {
      formUpdate.classId = null;
    }
    const relevantPhases = activePhases
      .filter((phase) => phase.projectId === newProjectId);
    if (newProjectId !== selectedProject) {
      let ourPhase = null;
      if (newProjectId) {
        if (relevantPhases.length === 0) {
          ourPhase = UNPHASED;
        }
      }

      formUpdate.phaseId = ourPhase;
      formUpdate.costcodeId = null;
      setSelectedPhase(ourPhase);
      setSelectedCostCode(null);
      form.setFieldsValue(formUpdate);
      return;
    }

    let newSelectedPhase = (
      selectedProjectId
      && selectedPhaseId === null
      && selectedCostCodeId
    )
      ? UNPHASED
      : selectedPhaseId;

    if (!newSelectedPhase && relevantPhases.length === 0) {
      newSelectedPhase = UNPHASED;
    }

    formUpdate.phaseId = newSelectedPhase;

    if (newSelectedPhase !== selectedPhase) {
      formUpdate.costcodeId = null;
      setSelectedPhase(newSelectedPhase);
      setSelectedCostCode(null);
    } else {
      setSelectedCostCode(selectedCostCodeId);
      setSelectedPhase(newSelectedPhase);
    }

    form.setFieldsValue(formUpdate);
  }, [
    form,
    selectedProject,
    selectedPhase,
    selectedLocal,
    currentDivisionId,
    getNewEntryTimeData,
    activePhases,
    activeCostcodes,
    userDivisions,
  ]);

  const onSwitchChange = useCallback(() => {
    setHoursBased(!hourBased);
    if (!hourBased) {
      // Switching from time to hour based
      const values = form.getFieldsValue();
      form.setFieldsValue(convertFromTimeBasedToHourBased(values));
    }
  }, [hourBased, form]);

  const calculateStartTime = (endTime) => {
    const parsedEnd = DateTime.fromMillis(endTime, { zone: timezone });
    return parsedEnd.minus({
      hours: parsedEnd.hour,
      minutes: parsedEnd.minute,
    }).toMillis();
  };

  // Gets the appropriate matching start time for given end time (for hour based)
  // Since hour based tasks only have an end time form field
  const getTimes = ({
    startTime,
    endTime,
    startTimeKey,
    endTimeKey,
    isTimeShown = true,
  }) => {
    if (!isTimeShown) {
      return {
        [startTimeKey]: null,
        [endTimeKey]: null,
      };
    }

    if (startTime) {
      return {
        [startTimeKey]: startTime,
        [endTimeKey]: endTime,
      };
    }

    if (endTime === 0) {
      return {
        [startTimeKey]: 0,
        [endTimeKey]: 0,
      };
    }

    let newStartTime = null;
    let newEndTime = endTime;
    if (!isNullOrUndefined(endTime)) newStartTime = calculateStartTime(endTime);

    // If hour and minutes is 0, we don't need to set a start time
    // End time could also equal start time for non hour based
    // But this shouldn't occur unless it's an accident
    if (newStartTime === endTime) {
      newStartTime = null;
      newEndTime = null;
    }

    return {
      [startTimeKey]: newStartTime,
      [endTimeKey]: newEndTime,
    };
  };

  const onSubmitClicked = useCallback(async () => {
    let values;
    let responses = {};
    try {
      values = await form.validateFields(); // Throws if field error
      const {
        customData: formCustomData = {},
      } = values;
      responses = formCustomData;
      const {
        errorMap,
      } = getErrorsFromResponses({ sections: ourCustomFields, responses });
      setCustomFieldErrors(errorMap);
      if (Object.keys(errorMap).length > 0) return;
    } catch (err) {
      //
    }
    if (!values) return;
    const { responses: preparedResponses } = prepareResponsePayload({
      sections: ourCustomFields,
      responses,
    });

    const {
      breakStartTime,
      breakEndTime,
      otStartTime,
      otEndTime,
      doubleOTStartTime,
      doubleOTEndTime,
      date,
      startTime,
      endTime,
      users: taskUsers,
      ...restValues
    } = values;

    const startOfDay = DateTime.fromSQL(date, { zone: timezone }).startOf('day').toMillis();

    const ourStart = startTime;
    let ourEnd = endTime;

    let offset = 0;

    if (hourBased && selectedEntryHourBased && date === entryDate) {
      /*
        Hour based entry values only has endTime
        Prevent overwriting of startTime for existing entries

        endTime comes back as referenced from startOfDay
        so we shift it by order offset to keep duration
      */
      offset = selectedEntryStartTime % (60 * 1000);
      ourEnd = endTime + offset;
    }

    const tasks = {
      task: TaskHelpers.shiftOvernightTimes({
        ...restValues,
        date,
        startTime: ourStart,
        endTime: ourEnd,
        hourBased,
        customData: preparedResponses,
        canEdit: true,
        timezone,
        ...getTimes({
          startTime: ourStart,
          endTime: ourEnd,
          startTimeKey: 'startTime',
          endTimeKey: 'endTime',
        }),
      }),
    };

    if (hasPaidFlag) {
      // Shift overnight times
      tasks.task = TaskHelpers.shiftOvernightTimes({
        ...tasks.task,
        type: 'work',
        ...getTimes({
          startTime: otStartTime,
          endTime: otEndTime,
          startTimeKey: 'otStartTime',
          endTimeKey: 'otEndTime',
          isTimeShown: showOvertime,
        }),
        ...getTimes({
          startTime: doubleOTStartTime,
          endTime: doubleOTEndTime,
          startTimeKey: 'doubleOTStartTime',
          endTimeKey: 'doubleOTEndTime',
          isTimeShown: showDoubleOvertime,
        }),
        ...getTimes({
          startTime: breakStartTime,
          endTime: breakEndTime,
          startTimeKey: 'breakStartTime',
          endTimeKey: 'breakEndTime',
          isTimeShown: showBreak,
        }),
      });

      if (
        !startTime
        && !endTime
        && !breakStartTime
        && !breakEndTime
        && !otStartTime
        && !otEndTime
        && !doubleOTStartTime
        && !doubleOTEndTime
      ) {
        tasks.task = {
          ...tasks.task,
          startTime: startOfDay,
          endTime: startOfDay,
        };
      }
    }

    if (showBreak && !hasPaidFlag) {
      const hasData = ((hourBased || breakStartTime) && breakEndTime);
      if (hasData) {
        let breakStart = breakStartTime;
        if (!breakStart) {
          breakStart = calculateStartTime(breakEndTime);
        }
        tasks.breakObject = TaskHelpers.shiftOvernightTimes({
          ...restValues,
          type: 'break',
          date,
          startTime: breakStart,
          endTime: breakEndTime,
          hourBased,
          customData: preparedResponses,
          canEdit: true,
          timezone,
        });
      }
    }

    if (showOvertime && !hasPaidFlag) {
      const hasData = ((hourBased || otStartTime) && otEndTime);
      if (hasData) {
        let otStart = otStartTime;
        if (!otStart) {
          otStart = calculateStartTime(otEndTime);
        }
        tasks.overtime = TaskHelpers.shiftOvernightTimes({
          ...restValues,
          type: 'overtime',
          date,
          startTime: otStart,
          endTime: otEndTime,
          hourBased,
          customData: preparedResponses,
          canEdit: true,
          timezone,
        });
      }
    }

    tasks.users = taskUsers;

    Analytics.track('Manual Entry Done', {
      baseTaskType: restValues.type,
      isHourBased: hourBased,
      isUsingInlineBreak: showBreak,
      isUsingInlineOvertime: showOvertime,
    });

    onSubmit(tasks, hourBased);
  }, [
    form,
    onSubmit,
    id,
    hourBased,
    ourCustomFields,
    showBreak,
    showOvertime,
    showDoubleOvertime,
    timezone,
    selectedEntryStartTime,
    selectedEntryHourBased,
    entryDate,
    hasPaidFlag,
  ]);

  const activeProjects = useMemo(() => (
    projects.filter((project) => project.active)
  ), [projects]);
  const userDivs = useMemo(() => userDivisions[userId] ?? [], [userDivisions, userId]);
  const projectMap = useMemo(() => getIdMap(activeProjects), [activeProjects]);
  const ccMap = useMemo(() => getIdMap(activeCostcodes), [activeCostcodes]);

  const projectOptions = useMemo(() => getProjectOptions({
    activeProjects,
    teams,
    user,
    divisionId: currentDivisionId,
    projectId: selectedProject,
  }), [activeProjects, user, selectedProject, currentDivisionId]);

  const phaseOptions = useMemo(() => getPhaseOptions({
    activeCostcodes,
    activePhases,
    projectId: selectedProject,
  }), [activeCostcodes, activePhases, selectedProject]);

  const costcodeOptions = useMemo(() => getCostcodeOptions({
    activeCostcodes,
    activePhases,
    projectId: selectedProject,
    phaseId: selectedPhase,
  }), [activeCostcodes, activePhases, selectedProject, selectedPhase]);

  const userOptions = useMemo(() => getUserOptions({
    user,
    users,
    divisionId: currentDivisionId,
    userDivisions,
  }), [user, users, userDivisions, currentDivisionId, user]);

  const localMap = useMemo(() => getIdMap(locals), [locals]);
  const classMap = useMemo(() => getIdMap(classes), [classes]);
  const defaultLocalId = useMemo(() => {
    const {
      [ourClassId]: { localId: ourLocalId } = {},
    } = classMap;
    return ourLocalId;
  }, [ourClassId, classMap]);
  const relevantLocals = useMemo(() => {
    const {
      [selectedLocal || defaultLocalId]: { unionId: ourUnionId } = {},
    } = localMap;
    return locals.filter((local) => local.unionId === ourUnionId)
      .map(getOpt);
  }, [selectedLocal, localMap, locals, defaultLocalId]);

  const relevantClasses = useMemo(() => (
    classes.filter((unionClass) => unionClass.localId === selectedLocal)
      .map(getOpt)
  ), [classes, selectedLocal]);

  const sageShiftArray = useMemo(() => {
    const shiftArr = Object
      .values(sageShifts)
      .filter((shift) => shift.divisionId === currentDivisionId);
    shiftArr.sort(sortByString('name'));
    return shiftArr.map(getOpt);
  }, [sageShifts, currentDivisionId]);

  const typeOptions = useMemo(() => {
    const types = [...DEFAULT_TYPES];

    if (enableManualOT) {
      types.push({ label: 'Overtime', value: 'overtime' });
    }

    if (type === 'service') {
      types.push({ label: 'Service', value: 'service' });
    }

    return types;
  }, [enableManualOT, type]);

  const shiftedEndTime = useMemo(() => {
    if (!selectedEntryHourBased || !selectedEntryStartTime) return selectedEntryEndTime;

    const startValue = DateTime.fromMillis(selectedEntryStartTime, { zone: timezone });
    const startTimeMs = (startValue.minute * 60000);
    return selectedEntryEndTime - startTimeMs;
  }, [selectedEntryHourBased, selectedEntryEndTime, selectedEntryStartTime, timezone]);

  useEffect(() => {
    setSelectedLocal(defaultLocalId);
  }, [defaultLocalId]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      form.setFieldsValue({
        divisionId: lockedDivisionId,
      });
      setSelectedProject(defaultProjectId);
      setSelectedPhase();
      setSelectedCostCode();
      setSelectedLocal(defaultLocalId);
      setCustomFieldErrors({});
      setCurrentDivisionId(lockedDivisionId);
      setShowBreak(false);
      setShowOvertime(false);
      setShowDoubleOvertime(false);
      setCurrentType('work');
      setCurrentDate(DateTime.local().toSQLDate());
    }
  }, [visible, form, defaultLocalId, lockedDivisionId, defaultProjectId]);

  useEffect(() => {
    if (!form) return;
    const safeSelected = (selectedEntry || {});
    let ourDivisionId = safeSelected.divisionId || lockedDivisionId;
    if (!ourDivisionId && userDivs.length === 1) [ourDivisionId] = userDivs;
    if (!ourDivisionId && safeSelected.projectId) {
      ourDivisionId = projectMap[safeSelected.projectId]?.divisionId;
    }
    if (!ourDivisionId && safeSelected.costcodeId) {
      ourDivisionId = ccMap[safeSelected.costcodeId]?.divisionId;
    }
    const {
      userId: safeSelectedUserId,
    } = safeSelected;
    const entryUsers = [];
    if (safeSelectedUserId) {
      entryUsers.push(safeSelectedUserId);
    } else if (user?.id) {
      entryUsers.push(user.id);
    }
    const newData = {
      ...safeSelected,
      localId: defaultLocalId,
      classId: ourClassId,
      sageShiftId: sageShiftId || defaultSageShiftId,
      date: safeSelected.date || defaultDate,
      divisionId: ourDivisionId,
      endTime: shiftedEndTime,
      users: entryUsers,
    };

    let ourPhase = safeSelected.projectId
      && safeSelected.phaseId === null
      && safeSelected.costcodeId
      ? UNPHASED
      : safeSelected.phaseId;
    if (!ourPhase && safeSelected.projectId) {
      const relevantPhases = activePhases
        .filter((phase) => phase.projectId === safeSelected.projectId);
      if (relevantPhases.length === 0) {
        ourPhase = UNPHASED;
      }
    }

    form.setFieldsValue(newData);
    setSelectedProject(safeSelected.projectId);
    setSelectedLocal(defaultLocalId);
    setSelectedCostCode(safeSelected.costcodeId);
    setSelectedPhase(ourPhase);
    setCurrentDivisionId(ourDivisionId);
    setCurrentType(safeSelected.type ?? 'work');
    setCurrentDate(selectedEntry?.date ?? defaultDate);
  }, [
    form,
    selectedEntry,
    defaultLocalId,
    ourClassId,
    defaultSageShiftId,
    sageShiftId,
    userDivs,
    projectMap,
    ccMap,
    lockedDivisionId,
    shiftedEndTime,
    activePhases,
    user,
  ]);

  useEffect(() => {
    if (!enableHourBasedTracking) {
      setHoursBased(false);
    } else {
      setHoursBased(isNullOrUndefined(taskIsHourBased) ? true : taskIsHourBased);
    }
  }, [enableHourBasedTracking, taskIsHourBased]);

  const showUnion = unions.length > 0
    && defaultClassId
    && (enableLocalSelection || enableClassSelection);

  const isApproved = selectedEntryState === 'approved';

  const initialUsers = useMemo(() => {
    const taskUsers = [];
    if (selectedEntryUserId) {
      taskUsers.push(selectedEntryUserId);
    } else if (user?.id) {
      taskUsers.push(user.id);
    }
    return taskUsers;
  }, [selectedEntryUserId, user]);

  return (
    <Drawer
      title="Add Time Entry"
      width={700}
      visible={visible}
      onClose={onClose}
      maskClosable={false}
      bodyStyle={{ marginBottom: 53, overflowY: 'auto' }}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}
        initialValues={{
          ...(selectedEntry || {}),
          projectId: selectedEntry?.projectId ?? defaultProjectId,
          localId: defaultLocalId,
          classId: ourClassId,
          sageShiftId: defaultSageShiftId,
          type,
          date: defaultDate,
          customData,
          divisionId: lockedDivisionId || userDivs.length === 1 ? userDivs[0] : null,
          endTime: shiftedEndTime,
          users: initialUsers,
        }}
      >
        <Row>
          <Col span={8}>
            <Form.Item
              name="date"
              label="Date"
              rules={[{
                validator: dateValidator(form, hourBased),
                required: true,
              }]}
              style={{ marginBottom: 5 }}
            >
              <ManualEntryDatePicker />
            </Form.Item>
          </Col>
          <ManualEntryTimeHoursInput
            date={currentDate}
            hourBased={!!hourBased}
            timezone={timezone}
            start={{
              name: 'startTime',
              label: 'Start Time',
            }}
            end={{
              name: 'endTime',
              label: 'End Time',
            }}
            hours={{
              label: 'Hours',
            }}
            required={!hasPaidFlag}
          />
          {
            enableHourBasedTracking
            && (
            <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Row align="bottom" gutter={8}>
                <Col>
                  <Switch checked={hourBased} onChange={onSwitchChange} />
                </Col>
                <Col>
                  {hourBased ? 'Hours' : 'Times'}
                </Col>
              </Row>
            </Col>
            )
          }
        </Row>
        <Row style={{ margin: '5px 0px' }}>
          <Col span={8} />
          {
            showBreak && (
              <ManualEntryTimeHoursInput
                date={currentDate}
                hourBased={!!hourBased}
                start={{
                  name: 'breakStartTime',
                  label: 'Break Start Time',
                }}
                end={{
                  name: 'breakEndTime',
                  label: 'Break End Time',
                }}
                hours={{
                  label: 'Break Hours',
                }}
              />
            )
          }
          {allowInlineTimes && (!id || hasPaidFlag) && currentType !== 'break' && (
            <Col span={4}>
              <BorderlessButton
                title={!showBreak ? 'Add Break' : 'Remove Break'}
                onClick={() => setShowBreak(!showBreak)}
                style={{ padding: 0, height: 20, textAlign: 'left' }}
              />
            </Col>
          )}
        </Row>
        <Row style={{ margin: '5px 0px' }}>
          <Col span={8} />
          {
            showOvertime && (
              <ManualEntryTimeHoursInput
                date={currentDate}
                hourBased={!!hourBased}
                start={{
                  name: 'otStartTime',
                  label: 'Overtime Start Time',
                }}
                end={{
                  name: 'otEndTime',
                  label: 'Overtime End Time',
                }}
                hours={{
                  label: 'Overtime Hours',
                }}
              />
            )
          }
          {enableManualOT && allowInlineTimes && (!id || hasPaidFlag) && currentType !== 'overtime' && (
            <Col span={4}>
              <BorderlessButton
                title={!showOvertime ? 'Add Overtime' : 'Remove Overtime'}
                onClick={() => setShowOvertime(!showOvertime)}
                style={{ padding: 0, height: 20, textAlign: 'left' }}
              />
            </Col>
          )}
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col span={8} />
          {
            showDoubleOvertime
            && (
              <ManualEntryTimeHoursInput
                date={currentDate}
                hourBased={!!hourBased}
                start={{
                  name: 'doubleOTStartTime',
                  label: '2X Overtime Start Time',
                }}
                end={{
                  name: 'doubleOTEndTime',
                  label: '2X Overtime End Time',
                }}
                hours={{
                  label: '2X Overtime Hours',
                }}
              />
            )
          }
          {enableManualOT && allowInlineTimes && hasPaidFlag && (
            <Col span={4}>
              <BorderlessButton
                title={!showDoubleOvertime ? 'Add 2X Overtime' : 'Remove 2X Overtime'}
                onClick={() => setShowDoubleOvertime(!showDoubleOvertime)}
                style={{ padding: 0, height: 20, textAlign: 'left' }}
              />
            </Col>
          )}
        </Row>
        <Row gutter={20}>
          { (!hasPaidFlag || type !== 'work') && (
            <Col>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: 'Type is required' }]}
              >
                <Select
                  defaultValue="work"
                  options={typeOptions}
                  placeholder="Select a Type"
                  style={{ width: 200 }}
                />
              </Form.Item>
            </Col>
          )}
          {
            userDivs.length > 0
            && (
              <Col span={8}>
                <Form.Item
                  name="divisionId"
                  valuePropName="divisionId"
                  label="Division"
                  rules={[{ required: true, message: 'Type is required' }]}
                >
                  <DivisionSelector disabled={!!lockedDivisionId} />
                </Form.Item>
              </Col>
            )
          }
        </Row>
        {(!selectedEntry && autoBreakEnabled && Permissions.has('AUTO_BREAK_EXCLUDE')) && (
          <Row gutter={20}>
            <Col>
              <Form.Item
                name="excludeFromAutoBreak"
                label={(
                  <Row gutter={20}>
                    <Col>
                      Exclude from Auto Break Calculations
                    </Col>
                  </Row>
                )}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item
              name="projectId"
              label={t('Project')}
              rules={[{ required: reqProject, message: `${t('Project')} is required` }]}
            >
              <Select
                options={projectOptions}
                placeholder={`Select a ${t('Project')}`}
                showSearch
                optionFilterProp="label"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="phaseId"
              label="Phase"
              rules={[{ required: reqCostcode, message: 'Phase is required' }]}
            >
              <Select
                options={phaseOptions}
                placeholder="Select a Phase"
                showSearch
                optionFilterProp="label"
                allowClear
                disabled={(reqProject && !selectedProject)
                    || (phaseOptions.length === 1 && phaseOptions[0].value === UNPHASED)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="costcodeId"
              label="Cost Code"
              rules={[{ required: reqCostcode, message: 'Cost Code is required' }]}
            >
              <Select
                options={costcodeOptions}
                placeholder="Select a Cost Code"
                showSearch
                optionFilterProp="label"
                allowClear
                disabled={reqProject && (!selectedProject || !selectedPhase)}
              />
            </Form.Item>
          </Col>
        </Row>
        {
          (showUnion || enableSageShiftSelection)
          && (
          <Row gutter={20}>
            {enableLocalSelection && (
            <Col span={8}>
              <Form.Item
                name="localId"
                label="Union Local"
              >
                <Select
                  options={relevantLocals}
                  placeholder="Select a Local"
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
            </Col>
            )}
            {enableClassSelection && (
            <Col span={8}>
              <Form.Item
                name="classId"
                label="Work Classification"
              >
                <Select
                  options={relevantClasses}
                  placeholder="Select a Classification"
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
            </Col>
            )}
            {enableSageShiftSelection && (
            <Col span={8}>
              <Form.Item
                name="sageShiftId"
                label="Shift"
              >
                <Select
                  options={sageShiftArray}
                  placeholder="Select a Shift"
                  showSearch
                  optionFilterProp="label"
                  allowClear
                />
              </Form.Item>
            </Col>
            )}
          </Row>
          )
        }
        {!hideUserSelector && (
          <Row>
            <Col span={24}>
              <Form.Item
                name="users"
                label="Users"
                rules={[{ required: true, message: 'User is required' }]}
              >
                <Select
                  options={userOptions}
                  placeholder="Select Users"
                  showSearch
                  allowClear
                  optionFilterProp="label"
                  mode="multiple"
                  disabled={isApproved}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <FormTextInput
          isNotDisplay
          label="Note"
          name="note"
          textarea
        />
        {
          ourCustomFields && ourCustomFields.length > 0
          && (
            <Form.Item
              name="customData"
              label="Custom Fields"
              style={{ marginTop: 10 }}
            >
              <ManualEntryCustomFields
                key={`${id}${visible}`} // Need to reset component for initialValues to load
                errors={customFieldErrors}
                initialValues={customData}
                fileMap={fileMap}
                divisionId={currentDivisionId}
                timeData={timeData}
                project={selectedProject}
                costcode={selectedCostCode}
              />
            </Form.Item>
          )
        }
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmitClicked}
        submitTitle="Save"
      />
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
ManualEntryFormDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedEntry: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    breakStartTime: PropTypes.number,
    breakEndTime: PropTypes.number,
    otStartTime: PropTypes.number,
    otEndTime: PropTypes.number,
    doubleOTStartTime: PropTypes.number,
    doubleOTEndTime: PropTypes.number,
  }),
  defaultSageShiftId: PropTypes.string,
  defaultClassId: PropTypes.string,
  defaultProjectId: PropTypes.string,
  defaultDate: PropTypes.string,
  fileMap: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  lockedDivisionId: PropTypes.string,
  entries: PropTypes.array,
  user: PropTypes.object,
  allowInlineTimes: PropTypes.bool,
  hideUserSelector: PropTypes.bool,
};

ManualEntryFormDrawer.defaultProps = {
  selectedEntry: null,
  defaultSageShiftId: null,
  defaultClassId: null,
  defaultProjectId: null,
  defaultDate: DateTime.local().toSQLDate(),
  lockedDivisionId: null,
  entries: [],
  user: {},
  allowInlineTimes: false,
  hideUserSelector: false,
};

export default ManualEntryFormDrawer;
