import React from 'react';
import { connect } from 'react-redux'
import { Route, withRouter, Redirect } from 'react-router-dom';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import UserList from './userList';

import { getUsers,createUser, getUserLabels } from './state/users.actions';
import { setBreadcrumb, } from '../common/breadcrumbContainer/state/breadcrumb.actions';
import { getUnions } from '../unions/state/unions.actions';
import { getCardLinks } from '../boards/state/boards.actions';
import { getSageShifts } from '../sage/state/sage.actions';
import { getLabels } from '../labels/state/labels.actions';

import Permissions from '../auth/Permissions';

export default connect(
  (state,ownProps) => {
    return {
      ...ownProps,
      users:state.users.users,
      costcodes: state.costcodes.costcodes,
      projects: state.projects.projects,
      crumbs:state.breadcrumb,
      selectedUser:state.users.selectedUser,
      selectedDivisions: state.settings.selectedDivisions,
    };
  },{
    getUsers,
    createUser,
    setBreadcrumb,
    getUserLabels,
    getUnions,
    getCardLinks,
    getSageShifts,
    getLabels,
  }
)(withRouter(class Users extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        users: [],
      }
      this.defaultCrumbs = [{
        text:'Staff',
        icon:'user',
      },{
        text:'Manage Users',
      }
      ];

      this.props.setBreadcrumb(this.defaultCrumbs);
      this.props.getUsers();
      this.props.getUserLabels();
      this.props.getLabels();
      this.props.getUnions();
      this.props.getCardLinks();
      this.props.getSageShifts({ divisionIds: Array.from(this.props.selectedDivisions) });
    }


    async onUserCreate(user,projectMap,teamMap) {
      const success = await this.props.createUser(user,projectMap,teamMap);
      if(success) {
        await this.props.getUsers();
      }
      return success;
    }

    async onUserClicked(user) {
      // Need to change crumb state with store.
        if (user) {
          this.props.setBreadcrumb(this.defaultCrumbs.concat([{
            text:user.name
          }]));
        } else {
          this.props.setBreadcrumb(this.defaultCrumbs);
        }
    }

    render() {
      if(!Permissions.match('USERS')) return <Redirect to='/dashboard'/>;
      return (
        <BreadCrumbContainer crumbs={this.props.crumbs}>
          <Route path='/users' render={props =>
            <UserList
              onUserClicked={this.onUserClicked.bind(this)}
              onUserCreate={this.onUserCreate.bind(this)}
              onReload={this.props.getUsers}
            />
          }/>
        </BreadCrumbContainer>
      );
    }
}));
