import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BreadcrumbContainer from '../../common/breadcrumbContainer/breadcrumbContainer';

import ExportSelector from './ExportsSelector';
import CustomExportTable from './CustomExportsTable';

import { getCustomExports } from '../state/reports.actions';
import { getUnions } from '../../unions/state/unions.actions';

const CRUMBS = [{
  text: 'Exports',
  icon: 'table',
}];

function Exports() {
  const dispatch = useDispatch();

  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const exportList = useSelector((state) => state.reports.customExports);

  const [selectedExport, setSelectedExport] = useState();

  const filteredExports = useMemo(() => (
    exportList.filter((ex) => selectedDivisions.has(ex.divisionId))
  ), [exportList, selectedDivisions]);

  const onExportSelected = useCallback((exportId) => {
    const newSelected = filteredExports.find((customExport) => customExport.id === exportId);
    setSelectedExport(newSelected);
  }, [filteredExports]);

  const fullCrumbs = useMemo(() => {
    if (!filteredExports || filteredExports.length === 0 || !filteredExports) return CRUMBS;
    return CRUMBS.concat([{
      text: (
        <ExportSelector
          selectedExport={selectedExport}
          onExportSelected={onExportSelected}
        />
      ),
    }]);
  }, [filteredExports, selectedExport, onExportSelected]);

  useEffect(() => {
    dispatch(getCustomExports());
    dispatch(getUnions());
  }, [dispatch]);

  useEffect(() => {
    if (filteredExports.length > 0
        && !selectedDivisions.has(selectedExport?.divisionId)
    ) {
      setSelectedExport(filteredExports[0]);
    } else if (filteredExports.length === 0 && selectedExport) {
      setSelectedExport();
    }
  }, [filteredExports, selectedExport, selectedDivisions]);

  return (
    <BreadcrumbContainer crumbs={fullCrumbs}>
      <CustomExportTable
        selectedExport={selectedExport}
        scroll="calc(100vh - 232px)"
      />
    </BreadcrumbContainer>
  );
}

export default Exports;
