/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import fieldTypes from './formFields.types';
import BorderlessButton from '../../../common/buttons/BorderlessButton';
import colors from '../../../constants/Colors';
import { getIdMap } from '../../../helpers/helpers';
import { fieldTypesWithAPI } from '../../formHelpers';

const buttonStyle = {
  pointerEvents: 'auto',
  width: 30,
  padding: 0,
};

const textFields = new Set(['text', 'calculation', 'attribute']);

export default function FormField({
  projectId,
  sectionId,
  index,
  onDelete,
  onEdit,
  field = {},
  isDisplay = false,
  draggable = true,
  isExternalForm = false,
  divisions,
  templateId,
  name,
  showCondensedView = false,
  isDetailView = false,
}) {
  const { t } = useTranslation();
  const projects = useSelector((state) => state.projects.projects);
  const users = useSelector((state) => state.users.users);
  const equipment = useSelector((state) => state.equipment.equipment);
  const materials = useSelector((state) => state.materials.materials);
  const customTables = useSelector((state) => state.forms.customTables);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const phases = useSelector((state) => state.costcodes.phases);
  const customers = useSelector((state) => state.customers.customers);
  const vendors = useSelector((state) => state.vendors.vendors);
  const customerList = useMemo(() => Object.values(customers), [customers]);
  const vendorList = useMemo(() => Object.values(vendors), [vendors]);
  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const phaseIdMap = useMemo(() => getIdMap(phases), [phases]);

  const {
    selectedType,
    configProps,
    id:
    fieldId,
    response,
  } = field;
  const fieldObj = fieldTypes[selectedType];

  const isInvalidField = isExternalForm && fieldTypesWithAPI.has(selectedType) && configProps?.dataType !== 'Custom';

  const isText = textFields.has(selectedType);
  const isGPS = selectedType === 'gpsLocation';

  const form = useRef(null);

  if (!fieldObj) return null;
  const { preview } = fieldObj;
  if (!preview) return null;
  const draggableId = `${sectionId}-${fieldId}`;

  const body = (
    <div style={{ pointerEvents: isText || isGPS ? 'auto' : 'none' }}>
      {preview({
        id: fieldId,
        projectId,
        configProps,
        previewProps: response || configProps?.presetData || {},
        disabled: true,
        isDisplay,
        formRef: { current: form },
        users,
        projects,
        materials,
        equipment,
        costcodes,
        phases,
        customers: customerList,
        vendors: vendorList,
        customTables,
        projectIdMap,
        costcodeIdMap,
        phaseIdMap,
        divisions,
        templateId,
        name,
        showCondensedView,
        t,
        isFormBuilder: !isDetailView,
      })}
    </div>
  );

  if (!draggable) return body;
  return (
    <Draggable
      key={draggableId}
      draggableId={draggableId}
      index={index}
      type="FIELD"
      isDragDisabled={isDisplay}
    >
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          {...draggableProps}
          {...dragHandleProps}
          className="form-field-preview"
          style={{
            margin: '10px 0px',
            position: 'relative',
            width: '100%',
            height: '100%',
            ...draggableProps.style,
            border: isInvalidField ? '1px solid red' : 'none',
          }}
          key={index}
          ref={innerRef}
        >
          {body}
          {!isDisplay && (
            <BorderlessButton
              style={{
                position: 'absolute',
                right: 40,
                top: 5,
                ...buttonStyle,
              }}
              iconNode={<EditOutlined style={{ marginLeft: 0 }} />}
              onClick={onEdit(field)}
            />
          )}
          {!isDisplay && (
            <BorderlessButton
              style={{
                position: 'absolute',
                right: 10,
                top: 5,
                ...buttonStyle,
              }}
              iconNode={<DeleteOutlined style={{ color: colors.ONTRACCR_RED, marginLeft: 0 }} />}
              onClick={onDelete(fieldId)}
            />
          )}
          { !!isInvalidField && (
            <Popover
              placement="bottomLeft"
              trigger="hover"
              content="This field is not supported in external forms."
              title="Warning"
            >
              <WarningOutlined
                style={{
                  color: colors.ONTRACCR_DARK_YELLOW,
                  position: 'absolute',
                  top: 12,
                  right: 77,
                }}
              />
            </Popover>
          )}
        </div>
      )}
    </Draggable>
  );
}

FormField.propTypes = {
  projectId: PropTypes.string,
  sectionId: PropTypes.string,
  index: PropTypes.number,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  field: PropTypes.shape({
    id: PropTypes.string,
    selectedType: PropTypes.string,
    configProps: PropTypes.shape({
      label: PropTypes.string,
      required: PropTypes.bool,
      options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })),
    }),
    response: PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
  }),
  isDisplay: PropTypes.bool,
  draggable: PropTypes.bool,
  isExternalForm: PropTypes.bool,
  divisions: PropTypes.arrayOf(PropTypes.string),
  templateId: PropTypes.string,
  name: PropTypes.string,
  showCondensedView: PropTypes.bool,
  isDetailView: PropTypes.bool,
};

FormField.defaultProps = {
  projectId: null,
  sectionId: null,
  index: null,
  onDelete: null,
  onEdit: null,
  field: {},
  isDisplay: false,
  draggable: true,
  isExternalForm: false,
  divisions: [],
  templateId: null,
  name: null,
  showCondensedView: false,
  isDetailView: false,
};
