import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  getClockRows,
} from './timeTrackingConstants';

import CompanySettingsCard from '../CompanySettingsCard';
import CheckRows from '../CheckRows';
import SettingsService from '../state/settings.service'

export default ({ settings,saveCompanySettings }) => {
  const { t } = useTranslation();
  const clockRows = getClockRows(t);
  return (<CompanySettingsCard title='Clock Settings'>
    <CheckRows
      data={clockRows}
      onChange={async (item) => {
        if (item.key === 'enableClocking' && settings.enableClocking) {
          await SettingsService.clockOutAll();
        }
        saveCompanySettings({ [item.key] : !settings[item.key] });
      }}
      isChecked={(item) => {
        return settings[item.key];
      }}
    />
  </CompanySettingsCard>);
};