import React from 'react';
import { connect } from 'react-redux';
import { Tabs, } from 'antd';
import { Redirect } from 'react-router-dom';
import qs from 'query-string';

import config from '../config';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import { setBreadcrumb, } from '../common/breadcrumbContainer/state/breadcrumb.actions';
import { 
  getCompany,
  getCompanyImageURL,
  uploadCompanyImage, 
  getCompanySettings,
  saveCompanySettings,
  updateCompanyOwner,
  getPositions,
  getACPositions,
  resetPermissions,
  togglePermissions,
  toggleACPermissions,
  reorderAC,
  createPosition,
  renamePosition,
  deletePosition,
  viewBilling,
  connectQuickbooks,
  connectProcore,
  connectMicrosoft365,
  getCompanyCustomizationTabs,
  getWorkingHours,
  connectDocusign,
} from './state/settings.actions';
import { connectGmail } from '../profile/state/profile.actions';
import { createTablet, deleteTablet, getTablets, } from '../tablets/state/tablets.actions';
import { getBillingRates } from '../billingRates/state/billingRates.actions';
import { createNuxEntry } from '../nux/state/nux.actions';
import { getUnions } from '../unions/state/unions.actions';
import { getBoardCardTemplates, getBoardTemplates } from '../boards/state/boards.actions';
import { getTemplates, getAllCostCodes, getCategories } from '../costcodes/state/costcodes.actions';
import { getSubTaskTemplates } from '../subtasks/state/subtasks.actions';
import { getEmails } from '../emails/state/email.actions';
import { getCustomers } from '../contacts/customers/state/customers.actions';

import {
  SETTING_PAYROLL_TYPE,
  TIME_TRACKING_TYPE,
  GPS_TYPE,
  OVERTIME_TYPE,
  APPROVALS_TYPE,
  TABLET_TYPE,
  USER_ROLES_TYPE,
  INTEGRATION_TYPE,
} from '../nux/nux.constants';

import CompanyDetail from './CompanyDetail';
import TimeTrackingSettings from './TimeTrackingSettings/TimeTrackingSettings';
import FormSettings from './Forms/FormSettings';
import IntegrationSettings from './Integrations/integrationSettings';
import RolePermissions from './RolePermissions';
import TabletSettings from './TabletSettings/TabletSettings';
import Billing from './Billing';
import Divisions from './Divisions/Divisions';
import Unions from './Unions/Unions';
import Labels from './Labels/Labels';
import Boards from './Boards/Boards';
import ClientPortal from './ClientPortal/ClientPortal';
import Locations from './Locations/Locations';
import Permissions from '../auth/Permissions';
import NotificationPreferences from './Notifications/NotificationsPreferences';
import CustomizationSettings from './Customization/CustomizationSettings';
import { PropTypes } from 'prop-types';

const { TabPane, } = Tabs;

const mapPosition = (approver, positionNames = [], users = []) => {
  for (const p of positionNames) {
    if (approver.toString() === p.id.toString()) {
      return `Any ${p.name}`
    }
  }
  const u = users.filter(u=>u.id===approver)
  return u[0] ? u[0].name : undefined;
};

const nuxMap = {
  'payroll':SETTING_PAYROLL_TYPE,
  'clockSettings':TIME_TRACKING_TYPE,
  'gps':GPS_TYPE,
  'overtime':OVERTIME_TYPE,
  'timeCards':APPROVALS_TYPE,
  'tablet_mode':TABLET_TYPE,
  'roles':USER_ROLES_TYPE,
  'integrations': INTEGRATION_TYPE,
};

const subtabSet = new Set([
  'general',
  'time_tracking',
  'billing',
  'tablet_mode',
  'roles',
  'integrations',
  'forms',
  'labels'
]);

export default connect(
  (state,ownProps) => {
    return {
      ...ownProps,
      crumbs:state.breadcrumb,
      company:state.settings.company,
      users:state.users.users,
      acPositions: state.settings.acPositions,
      positions:state.settings.positions,
      positionNames:state.settings.positionNames,
      approverPositions:state.settings.approverPositions,
      tablets:state.tablets,
      nux:state.nux.nux,
    };
  },{
    setBreadcrumb,
    getCompany,
    getCompanyImageURL,
    uploadCompanyImage,
    getCompanySettings,
    saveCompanySettings,
    updateCompanyOwner,
    getPositions,
    getACPositions,
    resetPermissions,
    togglePermissions,
    toggleACPermissions,
    reorderAC,
    createPosition,
    renamePosition,
    deletePosition,
    getTablets,
    createTablet,
    deleteTablet,
    viewBilling,
    connectQuickbooks,
    createNuxEntry,
    connectProcore,
    connectDocusign,
    getBillingRates,
    getUnions,
    connectMicrosoft365,
    getBoardCardTemplates,
    getBoardTemplates,
    getCompanyCustomizationTabs,
    connectGmail,
    getTemplates,
    getAllCostCodes,
    getCategories,
    getSubTaskTemplates,
    getEmails,
    getWorkingHours,
    getCustomers,
  })(class Settings extends React.Component {
    static propTypes = {
      acPositions: PropTypes.object.isRequired,
      toggleACPermissions: PropTypes.func.isRequired,
      reorderAC: PropTypes.func.isRequired,
      getACPositions: PropTypes.func,
  };
    constructor(props) {
      super(props);

      this.state = {
        activeTab:'general',
      }
      
      this.props.setBreadcrumb([{
        text:'Settings',
        icon:'setting',
      }]);
      this.onCheckChanged = this.checkChanged.bind(this);
      this.onTabSelected = this.tabSelected.bind(this);
      this.onACCheckChanged = this.acCheckChanged.bind(this);
      this.onACOrderChanged = this.acOrderChanged.bind(this);

    }

    async componentDidMount() {
      const {
        location:{
          search,
          pathname,
        } = {},
        connectProcore,
        connectQuickbooks,
        connectDocusign,
        getBillingRates,
        getUnions,
        connectMicrosoft365,
        getBoardCardTemplates,
        getBoardTemplates,
        connectGmail,
        getTemplates,
        getAllCostCodes,
        getCategories,
        getSubTaskTemplates,
        getEmails,
        getWorkingHours,
        getCustomers,
      } = this.props;
      if(search) {
        
        const { code, realmId, state } = qs.parse(search);
        if(pathname.includes('quickbooks') && code && realmId && state) {
          connectQuickbooks({ code, realmId, state });
        } else if (pathname.includes('procore') && code) {
          connectProcore({ code });
        } else if (pathname.includes('microsoft365') && code) {
          connectMicrosoft365({ code });
        } else if (pathname.includes('gmail') && code) {
          connectGmail({ code });
        } else if (pathname.includes('docusign') && code) {
          connectDocusign({ code })
        }
      }

      if(pathname) {
        const pathsplit = pathname.split('/');
        if(pathsplit.length > 2 && subtabSet.has(pathsplit[2])) {
          this.tabSelected(pathsplit[2]);
        }
      }

      await Promise.all([
        this.props.getCompany(),
        this.props.getCompanySettings(),
        this.props.getPositions(),
        this.props.getACPositions(),
        this.props.getTablets(),
        this.props.getCompanyImageURL(),
        getUnions(),
        getBillingRates(),
        getBoardCardTemplates(),
        getBoardTemplates(),
        this.props.getCompanyCustomizationTabs(),
        getTemplates(),
        getAllCostCodes(),
        getCategories(),
        getSubTaskTemplates(),
        getEmails(),
        getWorkingHours(),
        getCustomers(),
      ]);
    }

    componentDidUpdate() {
      const {
        activeTab:stateTab,
        activeSubTab:stateSubTab,
      } = this.state;
      const {
        location:{
          state:{
            activeTab = stateTab,
            activeSubTab = stateSubTab
          } = {}
        } = {},
        createNuxEntry,
        nux = new Set(),
      } = this.props;

      if(activeTab !== stateTab) {
        this.tabSelected(activeTab);
      }
      if(activeSubTab !== stateSubTab) {
        this.setState({
          activeSubTab,
        });
        if(activeSubTab in nuxMap) {
          const nuxEvent = nuxMap[activeSubTab];
          if(!nux.has(nuxEvent)) {
            createNuxEntry(nuxEvent);
          }
        }
      }
    }

    async checkChanged(role,permission) {
      const { positions = {} } = this.props;
      const currentPerms = positions[role];
      const hasPerm = currentPerms.some((perm) => perm === permission);

      await this.props.togglePermissions({ 
        enabled: !hasPerm, position:role, permission,
      });
    }



    async acCheckChanged(role,acPermission) {
      const { acPositions = {} } = this.props; // positions w/ ac permissions: Admin: [...acperms]
      const currentPerms = acPositions[role];
      const hasPerm = currentPerms.some((perm) => perm === acPermission);

      await this.props.toggleACPermissions({ 
        enabled: !hasPerm, position:role, acPermission,
      });
    }

    async acOrderChanged(acPermName, sourceIndex, destinationIndex, roleName) {
      return (await this.props.reorderAC({ 
        acPermName, sourceIndex, destinationIndex, position: roleName
      }));
    }

    async tabSelected(tab) {
      const {
        viewBilling,
        company:{
          userId:ownerId,
        } = {},
        createNuxEntry,
        nux = new Set(),
        history,
      } = this.props;
      if(Permissions.id === ownerId && tab === 'billing') {
        viewBilling();
      }
      this.setState({
        activeTab:tab,
      });
      history.replace(`/settings/${tab}`)
      if(tab in nuxMap) {
        const nuxEvent = nuxMap[tab]
        if(!nux.has(nuxEvent)) {
          createNuxEntry(nuxEvent);
        }
      }
    }

    render() {
      if(!Permissions.has('SETTINGS_WRITE')) return <Redirect to='/dashboard'/>
      const {
        company = {},
        users = [],
        positions = {},
        acPositions = {},
        resetPermissions,
        createPosition,
        renamePosition,
        deletePosition,
        deleteTablet,
        createTablet,
        tablets = [],
        positionNames,
        approverPositions = new Set(),
        createNuxEntry,
        nux,
        location,
        history,
      } = this.props;
      
      const { 
        companyImageURL = '',
        settings = {},
        userId:ownerId,
        billingURL,
      } = company;

      const isOwner = Permissions.id === ownerId;

      const firstApprover = settings.firstApprover ? mapPosition(settings.firstApprover,positionNames,users) : undefined
      const secondApprover = settings.secondApprover ? mapPosition(settings.secondApprover,positionNames,users) : undefined
      
      const {
        activeTab = 'general',
        activeSubTab,
      } = this.state;
      
      return (
        <BreadCrumbContainer
          crumbs={this.props.crumbs}
          bodyStyle={{
            marginLeft:15,
            marginRight:15,
            marginBottom:0,
            marginTop:0,
          }}
        >
          <Tabs 
            style={{ height: '90vh'}}
            defaultActiveKey='general' 
            activeKey={activeTab}
            tabPosition={'left'} 
            tabBarStyle={{marginTop:15}}
            onChange={this.onTabSelected}
          >
            <TabPane tab='General' key='general' style={{
              overflowY:'auto', overflowX:'hidden', maxHeight:'90vh'
            }}>
              <CompanyDetail 
                settings={settings}
                users={users}
                ownerId={ownerId}
                companyImageURL={companyImageURL} 
                key={companyImageURL}
                uploadCompanyImage={this.props.uploadCompanyImage}
                saveCompanySettings={this.props.saveCompanySettings}
                updateCompanyOwner={this.props.updateCompanyOwner}
              />
            </TabPane>
            
            <TabPane tab='Time Tracking' key='time_tracking'>
              <TimeTrackingSettings
                settings={settings}
                saveCompanySettings={this.props.saveCompanySettings}
                users={users}
                positionNames={positionNames}
                firstApprover={firstApprover}
                secondApprover={secondApprover}
                approverPositions={approverPositions}
                activeKey={activeSubTab}
                createNuxEntry={createNuxEntry}
                nuxMap={nuxMap}
                nux={nux}
              />
            </TabPane>

            <TabPane tab='Forms' key='forms'>
              <FormSettings
                activeKey={activeSubTab}
              />
            </TabPane>

            <TabPane tab='Billing' key='billing' style={{height:'90vh'}}>
              <Billing 
                billingURL={billingURL}
                isOwner={isOwner}
              />
            </TabPane>

            <TabPane tab='Tablet Mode' key='tablet_mode' style={{
              overflowY:'auto', overflowX:'hidden', maxHeight:'90vh'
            }}>
              <TabletSettings
                settings={settings}
                saveCompanySettings={this.props.saveCompanySettings}
                deleteTablet={deleteTablet}
                createTablet={createTablet}
                tablets={tablets}
              />
            </TabPane>

            <TabPane tab='Roles & Permissions' key='roles'>
              <RolePermissions
                users={users}
                positions={positions}
                settings={settings}
                resetPermissions={resetPermissions}
                onCheckChanged={this.onCheckChanged}
                createPosition={createPosition}
                renamePosition={renamePosition}
                deletePosition={deletePosition}
              />
            </TabPane>

            <TabPane tab='Integrations' key='integrations' style={{height:'90vh'}}>
              <IntegrationSettings
                company={company}
                location={location}
                history={history}
                visible={activeTab === 'integrations'}
              />
            </TabPane>

            {config.showDivisions && 
              <TabPane tab='Divisions' key='divisions' style={{height:'90vh'}}>
                <Divisions
                  users={users}
                  positions={positions}
                  settings={settings}
                  resetPermissions={resetPermissions}
                  onCheckChanged={this.onCheckChanged}
                  createPosition={createPosition}
                  renamePosition={renamePosition}
                  deletePosition={deletePosition}
                />
              </TabPane>
            }
            <TabPane tab='Unions' key='unions' style={{height:'90vh'}}>
              <Unions/>
            </TabPane>
            <TabPane tab='Labels' key='labels' style={{height:'90vh'}}>
              <Labels
                visible={activeTab === 'labels'}
              />
            </TabPane>
            <TabPane tab='Locations' key='locations' style = {{height:'90vh'}}>
              <Locations visible={activeTab === 'locations'}/>
            </TabPane>
            {config.showBoards && <TabPane tab='Boards' key='boards' style={{height:'90vh'}}>
              <Boards/>
            </TabPane>}

            {Permissions.has('SETTINGS_CUSTOMIZATIONS_WRITE') && (
              <TabPane tab="Customization" key="customization" style={{ height: '90vh' }}>
                <CustomizationSettings
                  activeKey={activeSubTab}
                  acPositions={acPositions}
                  positions={positions}
                  onCheckChanged={this.onACCheckChanged}
                  onOrderChanged={this.onACOrderChanged}
                />
              </TabPane>
            )}
            {config.showClientPortal &&
              <TabPane
                tab='Client Portal'
                key='client_portal'
                style={{
                  height:'90vh',
                  position: 'relative',
                  overflowY: 'auto'
                }}
              >
                <ClientPortal/>
              </TabPane>
            }
             {Permissions.has('NOTIFICATIONS_WRITE') && <TabPane tab='Notifications' key='notification' style={{
              overflowY:'auto', overflowX:'hidden', maxHeight:'90vh'
            }}>
              <NotificationPreferences 
                settings={settings}
                saveCompanySettings={this.props.saveCompanySettings}
              />
            </TabPane>}
          </Tabs>
        </BreadCrumbContainer>
      );
    }
});
