export const getPreferenceRows = (t) => [{
  key: 'suggestNumbers',
  title: `Suggest ${t('Project')} Numbers and Cost Codes`,
  helpText: `With this setting enabled, the web app will suggest numbers for new ${t('Project', { count: 2 })} and Cost Codes by incrementing the previous number used in the series`,
  divider: false,
}, {
  key: 'suggestUsernames',
  title: 'Suggest Usernames and Passwords',
  helpText: 'With this setting enabled, the web app will suggest a randomly generated username and password when creating a user',
  divider: false,
}, {
  key: 'optionalUserEmail',
  title: 'Optional User Emails',
  helpText: 'With this setting enabled, users can be created without an email address. Users without an email will be assigned the company admin\'s email address',
  divider: false,
}, {
  key: 'autoAddMetadataToPhotos',
  title: 'Auto Add Metadata to Photos',
  helpText: 'With this setting enabled, the app will automatically add metadata like location and timestamp to photos uploaded from the mobile app',
  divider: false,
}];

export default {};
