import React from 'react';
import PropTypes from 'prop-types';

import WorkflowSimpleMappingConfigureDrawer from './WorkflowSimpleMappingConfigureDrawer';

const fields = {
  Invoice: [
    { key: 'customer', text: 'Customer', type: 'dropdown - Customers' },
    { key: 'project', text: 'Sub-Customer', type: 'dropdown - Projects' },
    { key: 'total', text: 'Total', type: 'text' },
    { key: 'dueDate', text: 'Due Date', type: 'dateRange' },
    { key: 'type', text: 'Invoice Type', type: 'dropdown - Custom' },
  ],
  PO: [
    { key: 'vendor', text: 'Vendor', type: 'dropdown - Vendors' },
    { key: 'materials', text: 'Materials', type: 'table - Materials' },
    { key: 'memo', text: 'Memo', type: 'text' },
    { key: 'dueDate', text: 'Due Date', type: 'dateRange' },
  ],
};
const requiredFields = {
  Invoice: new Set(['customer', 'total']),
  PO: new Set(['vendor', 'materials']),
};

function WorkflowQuickBooksConfigureDrawer({
  visible,
  onClose,
  onSubmit,
  sourceName,
  sourceSections = [],
  fieldMappings = {},
  onFieldMappingsChange,
  formTypeName,
}) {
  return (
    <WorkflowSimpleMappingConfigureDrawer
      visible={visible}
      onClose={onClose}
      onSubmit={onSubmit}
      sourceSections={sourceSections}
      fieldMappings={fieldMappings}
      onFieldMappingsChange={onFieldMappingsChange}
      fields={fields[formTypeName] ?? []}
      requiredFields={requiredFields[formTypeName] ?? new Set()}
      title={(
        <span>
          Configure which fields from this
          {' '}
          <b>{sourceName}</b>
          {' '}
          form are loaded into the QuickBooks
          {' '}
          {formTypeName}
        </span>
    )}
      targetHeader={`${formTypeName} Field`}
    />
  );
}

/* eslint-disable react/forbid-prop-types */
WorkflowQuickBooksConfigureDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sourceName: PropTypes.string.isRequired,
  sourceSections: PropTypes.array.isRequired,
  fieldMappings: PropTypes.object.isRequired,
  onFieldMappingsChange: PropTypes.func.isRequired,
  formTypeName: PropTypes.string.isRequired,
};

WorkflowQuickBooksConfigureDrawer.defaultProps = {
  visible: false,
};

export default WorkflowQuickBooksConfigureDrawer;
