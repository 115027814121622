import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Drawer,
  Tabs,
} from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import GanttScheduleCustomFilterTab from './GanttScheduleCustomFilterTab';

const { TabPane } = Tabs;

export default function GanttScheduleFilterDrawer(props) {
  const {
    visible,
    onClose,
    users,
    materials,
    costCodes,
    equipment,
    labels,
    phases,
    filterViews,
    schedule = {},
  } = props;

  const activeFilters = useSelector((state) => state.schedule.ganttScheduleFilters);
  const [activeTab, setActiveTab] = useState('custom');

  useEffect(() => {
    setActiveTab(activeFilters.filterView || 'custom');
  }, [activeFilters]);

  const onTabChange = useCallback((key) => {
    setActiveTab(key);
  }, [setActiveTab]);

  const onDrawerClose = useCallback(() => {
    onClose();
    setActiveTab(activeFilters.filterView || 'custom');
  }, [onClose, activeFilters]);

  return (
    <Drawer
      title={`Filters for ${schedule.name}`}
      visible={visible}
      onClose={onDrawerClose}
      width={900}
      destroyOnClose
    >
      <Tabs
        activeKey={activeTab}
        tabPosition="left"
        onChange={onTabChange}
        className="filter-drawer-tabs"
        destroyInactiveTabPane
      >
        <TabPane
          tab="Custom View"
          key="custom"
        >
          <GanttScheduleCustomFilterTab
            onClose={onDrawerClose}
            users={users}
            materials={materials}
            costCodes={costCodes}
            equipment={equipment}
            labels={labels}
            phases={phases}
            setActiveTab={setActiveTab}
            schedule={schedule}
          />
        </TabPane>
        {
          filterViews.map((view) => (
            <TabPane
              tab={view.filterViewName}
              key={view.id}
            >
              <GanttScheduleCustomFilterTab
                onClose={onDrawerClose}
                users={users}
                materials={materials}
                costCodes={costCodes}
                equipment={equipment}
                labels={labels}
                phases={phases}
                filterView={view}
                setActiveTab={setActiveTab}
                schedule={schedule}
              />
            </TabPane>
          ))
        }
      </Tabs>
    </Drawer>
  );
}

GanttScheduleFilterDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  costCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  equipment: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  materials: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  phases: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ),
  filterViews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      filterViewName: PropTypes.string,
    }),
  ).isRequired,
  schedule: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isMaster: PropTypes.number,
  }),
};

GanttScheduleFilterDrawer.defaultProps = {
  labels: [],
  costCodes: [],
  equipment: [],
  materials: [],
  users: [],
  schedule: {},
  phases: [],
};
