import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Table, message } from 'antd';
import { DateTime } from 'luxon';

// Import Helpers/Constants:
import costcodeDistributionColumns from './CostcodeDistributionColumns';
import { INVOICE_DRAWER_ADD_MODE, INVOICE_DRAWER_EDIT_MODE, INVOICE_DRAWER_VIEW_MODE } from './invoiceConstants';
import { getPhaseCostcodeKey, getPhaseCostcodeTreeData } from '../../forms/formHelpers';
import { getCostcodeDistributionHeader } from './invoiceHelpers';
import { getIdMap } from '../../helpers/helpers';

/** Allows the user to distribute invoice amount among linked costcodes */
function InvoiceAmountDistributor({
  formId,
  invoiceId,
  isDisplay,
  mode,
  visible,
  formRef,
  projectId,
  costcode,
}) {
  const phasedCostcodes = useSelector((state) => state.costcodes.phases);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const forms = useSelector((state) => state.forms.forms);
  const invoices = useSelector((state) => state.invoices.invoices);
  const phases = useSelector((state) => state.costcodes.phases);
  const projects = useSelector((state) => state.projects.projects);

  const [distributions, setDistributions] = useState([]);

  const phaseMap = useMemo(() => getIdMap(phasedCostcodes), [phasedCostcodes]);
  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const invoicesMap = useMemo(() => getIdMap(invoices), [invoices]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);

  // Selected Invoice details
  const {
    costcodeDistributions: existingDistributions = [],
  } = invoicesMap[invoiceId] || {};

  const {
    costcodes: formCostcodes = [],
  } = forms[formId] || {};

  const projectCostcodes = useMemo(() => {
    if (!projectId) return [];
    const treeData = getPhaseCostcodeTreeData({
      projectId,
      phases,
      costcodes,
      costcodeMap,
      projectMap,
    });
    const flattenedCCData = treeData.flatMap((phase) => phase.children.map((cc) => cc.value));
    const projectCC = flattenedCCData.map((cc) => {
      const [phaseId, costcodeId] = cc.split('.');
      return {
        phaseId: phaseId === 'unphased' ? null : phaseId,
        costcodeId,
      };
    });
    return projectCC;
  }, [projectId, phases, costcodes, costcodeMap, projectMap]);

  const currentlyUsedPhaseCostcodeKeys = useMemo(() => (
    new Set(distributions
      .filter(({ costcodeId }) => costcodeId)
      .map(({ phaseId, costcodeId }) => getPhaseCostcodeKey(phaseId, costcodeId)))
  ), [distributions]);

  const costcodesAvailableForDistribution = useMemo(() => {
    let costcodesToUse = formCostcodes;
    if (projectId) costcodesToUse = projectCostcodes;
    if (costcode?.costcodeId) costcodesToUse = [costcode];

    return costcodesToUse
      .filter(({ costcodeId }) => costcodeMap[costcodeId])
      .map(({ phaseId, costcodeId }) => {
        const key = getPhaseCostcodeKey(phaseId, costcodeId);
        const { name: phaseName = 'Unphased' } = phaseMap[phaseId] || {};
        const { name: costcodeName, code } = costcodeMap[costcodeId] || {};
        return {
          phaseId,
          phaseName,
          costcodeId,
          costcodeName,
          code,
          phaseCostcodeKey: key,
        };
      });
  }, [formCostcodes, phaseMap, costcodeMap, projectId, projectCostcodes, costcode]);

  const onAddRow = useCallback(() => {
    if (distributions.length === costcodesAvailableForDistribution.length) {
      message.error(`Max available distribution rows reached - ${costcodesAvailableForDistribution.length}`);
      return;
    }
    const newDistribution = {
      isNew: true,
      id: DateTime.local().toMillis(),
      phaseId: null,
      phaseName: null,
      costcodeId: null,
      costcodeName: null,
      code: null,
      amount: 0,
    };
    setDistributions((prvState) => [...prvState, newDistribution]);
  }, [distributions, costcodesAvailableForDistribution]);

  const onAmountChange = useCallback((id, newAmt) => {
    setDistributions((prvState) => prvState.map((distribution) => {
      if (distribution.id !== id) return distribution;
      return {
        ...distribution,
        amount: newAmt,
      };
    }));
  }, []);

  const onCostcodeOptionChange = useCallback((id, selectedVal = '') => {
    if (typeof selectedVal !== 'string') return;
    const [phaseId, costcodeId] = selectedVal.split('.');
    const { name: phaseName = 'Unphased' } = phaseMap[phaseId] || {};
    const { name: costcodeName, code } = costcodeMap[costcodeId] || {};
    setDistributions((prvState) => prvState.map((distribution) => {
      if (distribution.id !== id) return distribution;
      return {
        ...distribution,
        phaseId,
        phaseName,
        costcodeId,
        costcodeName,
        code,
        phaseCostcodeKey: selectedVal,
      };
    }));
  }, [phaseMap, costcodeMap]);

  const costcodeTreeOptions = useMemo(() => {
    const treeData = {};
    costcodesAvailableForDistribution.forEach((phaseCostcode) => {
      const {
        phaseId,
        phaseName,
        costcodeName,
        code,
        phaseCostcodeKey,
      } = phaseCostcode;
      if (!treeData[phaseId]) {
        treeData[phaseId] = {
          value: phaseId,
          title: phaseName,
          selectable: false,
          children: [],
        };
      }
      treeData[phaseId].children.push({
        value: phaseCostcodeKey,
        title: `${phaseName} - ${code} ${costcodeName}`,
        disabled: currentlyUsedPhaseCostcodeKeys.has(phaseCostcodeKey),
        isLeaf: true,
      });
    });
    return Object.values(treeData);
  }, [costcodesAvailableForDistribution, currentlyUsedPhaseCostcodeKeys]);

  const onCostcodeDelete = useCallback((id) => setDistributions(
    (prvState) => prvState.filter((distribution) => distribution.id !== id),
  ), []);

  const columns = useMemo(() => (
    costcodeDistributionColumns({
      isDisplay,
      costcodeTreeOptions,
      onCostcodeOptionChange,
      onAmountChange,
      onCostcodeDelete,
    })
  ), [isDisplay, costcodeTreeOptions, onCostcodeOptionChange, onAmountChange, onCostcodeDelete]);

  useEffect(() => {
    if (visible && (mode === INVOICE_DRAWER_ADD_MODE
      || mode === INVOICE_DRAWER_EDIT_MODE)) {
      setDistributions([]);
    }
  }, [formId]);

  useEffect(() => {
    if (visible) {
      if (mode === INVOICE_DRAWER_ADD_MODE) {
        setDistributions([]);
      } else {
        setDistributions(existingDistributions);
      }
    }
  }, [visible, invoiceId, mode]);

  useEffect(() => {
    if (formRef && formRef.current) {
      const values = formRef.current.getFieldsValue();
      formRef.current.setFieldsValue({
        ...values,
        costcodeDistributions: distributions,
      });
    }
  }, [formRef, existingDistributions, distributions]);

  useEffect(() => {
    if (formRef?.current && !isDisplay) {
      const values = formRef.current.getFieldsValue();
      formRef.current.setFieldsValue({
        ...values,
        costcodeDistributions: [],
      });
      setDistributions([]);
    }
  }, [formRef, projectId]);

  if (costcodesAvailableForDistribution.length === 0) return null;
  return (
    <Table
      title={() => getCostcodeDistributionHeader(isDisplay, onAddRow)}
      columns={columns}
      dataSource={distributions}
      size="small"
      pagination={false}
      style={{
        marginBottom: 50,
        marginLeft: -10,
      }}
    />
  );
}

/* eslint-disable react/forbid-prop-types */
InvoiceAmountDistributor.propTypes = {
  formId: PropTypes.string,
  invoiceId: PropTypes.string,
  isDisplay: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf([INVOICE_DRAWER_ADD_MODE, INVOICE_DRAWER_EDIT_MODE, INVOICE_DRAWER_VIEW_MODE]),
  visible: PropTypes.bool.isRequired,
  formRef: PropTypes.object,
  projectId: PropTypes.string,
  costcode: PropTypes.object,
};

export default InvoiceAmountDistributor;
