import React, { useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Tag } from 'antd';

import CollapsableContainer from '../common/containers/CollapsableContainer';

import BoardCardUsers from './BoardCardUsers';

import { getCardsByLink } from './state/boards.actions';

export default function BoardLinkView({
  linkId,
  linkType,
  links = [],
  style = {},
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const cardUserMap = useSelector((state) => state.boards.cardUserMap);

  useEffect(() => {
    if (dispatch && linkId && linkType) {
      dispatch(getCardsByLink(linkType, linkId));
    }
  }, [linkId, linkType, dispatch]);

  const onClickCard = useCallback(({ boardId, cardId }) => () => {
    history.push({
      pathname: '/boards',
      state: { boardId, cardId },
    });
  }, [history]);

  const contents = useMemo(() => {
    const boardMap = {};
    links.forEach((link) => {
      const {
        boardId, boardTitle, cardTitle, cardId, status,
      } = link;
      if (!(boardId in boardMap)) boardMap[boardId] = { id: boardId, title: boardTitle, cards: [] };
      const {
        [cardId]: users = [],
      } = cardUserMap;
      boardMap[boardId].cards.push({
        cardTitle, cardId, users, status,
      });
    });
    return Object.values(boardMap)
      .map((board) => (
        <CollapsableContainer title={board.title}>
          {
            board.cards.map((card) => (
              <div
                className="boards-card-locked"
                key={card.cardId}
                onClick={onClickCard({ boardId: board.id, cardId: card.cardId })}
              >
                <Row justify="space-between">
                  {card.cardTitle}
                  <BoardCardUsers users={card.users} />
                </Row>
                { card.status
                  ? <Tag style={{ marginBottom: 15, marginLeft: 5 }}>{card.status}</Tag>
                  : null}
              </div>
            ))
          }
        </CollapsableContainer>
      ));
  }, [links, cardUserMap]);
  return (
    <div className="board-link-view-container" style={style}>
      {contents}
    </div>
  );
}
