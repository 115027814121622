export const defaultRoles = new Set(['Admin', 'Manager', 'Supervisor', 'Worker']);
export const getPermissionRows = (t, roleACPermIds = []) => {
  const dictionary = {
    TIME_TRACKING_SELF_AC: {
      key: 'TIME_TRACKING_SELF',
      id: 'TIME_TRACKING_SELF_AC',
      title: 'Clock',
      helpText: 'This role can view the clock in the Action Center',
    },
    SCHEDULE_READ_AC: {
      key: 'SCHEDULE_READ',
      id: 'SCHEDULE_READ_AC',
      title: 'Schedule',
      helpText: 'This role can view the schedule in the Action Center',
    },
    BOARDS_READ_AC: {
      key: 'BOARDS_READ',
      id: 'BOARDS_READ_AC',
      title: 'Board Cards',
      helpText: `This role can view board ${t('Card', { count: 2 }).toLowerCase()} in the Action Center`,
    },
    TASKS_AC: {
      key: '', // no associated permissions
      id: 'TASKS_AC',
      title: 'Tasks',
      helpText: `This role can view ${t('Task', { count: 2 }).toLowerCase()} in the Action Center`,
    },
    EQUIPMENT_READ_AC: {
      key: 'EQUIPMENT_READ',
      id: 'EQUIPMENT_READ_AC',
      title: 'Equipment',
      helpText: `This role can view ${t('Equipment', { count: 2 }).toLowerCase()} in the Action Center`,
    },
    FORMS_READ_AC: {
      key: 'FORMS_READ',
      id: 'FORMS_READ_AC',
      title: 'Forms',
      helpText: `This role can view ${t('Form', { count: 2 }).toLowerCase()} in the Action Center`,
      // divider: false,
      subPermissions: [
        {
          key: 'FORMS_MANUAL',
          id: 'FORMS_MANUAL_AC',
          title: 'View Manual Forms',
          helpText: 'This role can view manual forms in the Action Center. Enabling this will hide assigned/favorited forms.',
        },
      ],
    },
    TIMECARD_VIEW_SELF_AC: {
      key: 'TIMECARD_VIEW_SELF',
      id: 'TIMECARD_VIEW_SELF_AC',
      title: 'Time Cards',
      helpText: `This role can view time ${t('Card', { count: 2 }).toLowerCase()} in the Action Center`,
    },
  };

  const orderedPerms = [];
  roleACPermIds?.forEach((perm) => {
    if (!dictionary[perm]) return;
    orderedPerms.push(dictionary[perm]);
  });

  Object.keys(dictionary).forEach((key) => {
    if (!roleACPermIds?.includes(key)) {
      orderedPerms.push(dictionary[key]);
    }
    return key;
  });

  return orderedPerms;
};
