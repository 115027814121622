import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { downloadFile, getFileDetails, getFileType } from '../files/fileHelpers';
import PhotoReel from '../common/photos/PhotoReel';
import FullPhoto from '../common/photos/FullPhoto';
import { rotateFile } from '../files/state/files.actions';

export default function AttachmentTab({
  attachments: {
    instantiatedFiles = [],
    locationMap = {},
  } = {},
  formId,
}) {
  const dispatch = useDispatch();
  const attachmentWType = useMemo(() => (
    instantiatedFiles.map((a) => ({ ...a, type: getFileType(a) }))
  ), [instantiatedFiles]);
  const [selectedFile, setSelectedFile] = useState({});

  const onFileClick = async (index) => {
    const file = attachmentWType[index];

    if (!file) return;
    if (file instanceof File) {
      setSelectedFile({
        file,
      });
    } else {
      let fileDetails = file;
      if (!file.url) {
        fileDetails = {
          ...await getFileDetails(file),
          ...file,
        };
      }

      setSelectedFile({
        ...fileDetails,
      });
    }
  };

  const clearSelectedFile = useCallback((rotation = -1) => {
    dispatch(rotateFile(selectedFile?.id, { rotation, formId }, selectedFile?.rotation));
    setSelectedFile({});
  }, [selectedFile]);

  return (
    <>
      <PhotoReel
        small
        files={attachmentWType}
        onClick={onFileClick}
        showCondensedView
        locationMap={locationMap}
      />
      <FullPhoto
        file={selectedFile.file}
        url={selectedFile.url}
        type={selectedFile.type}
        rotation={selectedFile.rotation}
        onClose={clearSelectedFile}
        onDownload={() => {
          if (selectedFile?.fullPath) {
            downloadFile({ fileDetails: selectedFile });
          } else if (selectedFile?.file) {
            downloadFile({ fileObject: selectedFile.file });
          }
        }}
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
AttachmentTab.propTypes = {
  attachments: PropTypes.shape({
    instantiatedFiles: PropTypes.array,
    locationMap: PropTypes.object,
  }),
  formId: PropTypes.string,
};

AttachmentTab.defaultProps = {
  attachments: {
    instantiatedFiles: [],
    locationMap: {},
  },
  formId: null,
};
