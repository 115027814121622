import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Row, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CompanySettingsCard from '../CompanySettingsCard';
import CompanyEditRow from '../CompanyEditRow';
import {
  getPermissionRows,
} from './ActionCenterConstants';
import SettingsCardHeader from '../SettingsCardHeader';

export default function ActionCenterCard({
  roleName,
  positions,
  acPositions,
  onCheckChanged,
  onOrderChanged,
}) {
  const { t } = useTranslation();
  const droppableId = roleName ? roleName.toString() : 'null';
  const [permissionRows, setPermissionRows] = useState(getPermissionRows(t, acPositions[roleName]));

  const isChecked = (id, position = []) => position.some((perm) => perm === id);

  useEffect(() => {
    const rows = getPermissionRows(t, acPositions[roleName]);
    setPermissionRows(rows);
  }, [acPositions, roleName, t]);

  const permissionRow = useCallback(({
    key,
    title,
    helpText,
    divider,
    style,
    id,
    isDisabled,
  }) => (
    <CompanyEditRow
      key={key}
      title={title}
      helpText={helpText}
      divider={divider}
      style={style}
    >
      <Checkbox
        onChange={() => {
          onCheckChanged(id);
        }}
        style={{ marginRight: 56 }}
        checked={!isDisabled && isChecked(id, acPositions[roleName])}
        disabled={isDisabled}
      />
    </CompanyEditRow>
  ), [acPositions, roleName, onCheckChanged]);

  const getCheckRows = useMemo(() => permissionRows.map(({
    key, title, helpText, divider = true, style, id, subPermissions = [],
  }, index) => {
    let isDisabled = !positions[roleName].includes(key);
    if (key === '') {
      isDisabled = false;
    }

    return (
      <Draggable key={id} draggableId={id} type="CARDS" index={index} isDragDisabled={!isChecked(id, acPositions[roleName])}>
        {({ draggableProps, dragHandleProps, innerRef }) => (
          <div
            {...dragHandleProps}
            {...draggableProps}
            ref={innerRef}
          >
            {permissionRow({
              key,
              title,
              helpText,
              divider,
              style,
              id,
              isDisabled,
            })}
            {subPermissions.map(({
              key: subKey,
              title: subTitle,
              helpText: subHelpText,
              id: subId,
            }) => (
              permissionRow({
                key: subKey,
                title: subTitle,
                helpText: subHelpText,
                style: { marginLeft: 20 },
                id: subId,
                isDisabled: !isChecked(id, acPositions[roleName]),
              })
            ))}
          </div>
        )}
      </Draggable>
    );
  }), [roleName, acPositions, onCheckChanged, t, permissionRows, permissionRow]);

  const onDragEnd = useCallback(async (dragEvent) => {
    const {
      destination,
      source,
      draggableId, // ac perm name
    } = dragEvent;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index) {
      return;
    }
    if (destination.index >= acPositions[roleName].length) {
      return; // prevent from dragging behind disabled ac components
    }

    const prevPermRows = permissionRows;
    const mutatePermRows = permissionRows;
    const removed = mutatePermRows.splice(source.index, 1)[0];
    mutatePermRows.splice(destination.index, 0, removed);
    setPermissionRows(mutatePermRows);
    if (!(await onOrderChanged(draggableId, source.index, destination.index, roleName))) {
      setPermissionRows(prevPermRows);
    }
  }, [acPositions, roleName, onOrderChanged, permissionRows]);

  return (
    <Row
      style={{
        width: '100%',
        height: '100%',
        padding: 20,
        overflow: 'scroll',
        maxHeight: '87vh',
      }}
    >
      <SettingsCardHeader
        title={roleName}
        subtitle=": Mobile - Action Center Configuration (Drag to order)"
      />
      <Row style={{ width: '100%', height: '100%' }}>
        <CompanySettingsCard title="">
          <CompanyEditRow title="Role Title" />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={droppableId} direction="vertical">
              {({
                droppableProps, innerRef, placeholder, ...rest
              }) => (
                <div
                  {...rest}
                  {...droppableProps}
                  ref={innerRef}
                  style={{
                    height: 'calc(100% - 23px)',
                    width: '100%',
                    overflowY: 'auto',
                  }}
                >
                  {getCheckRows}
                  {placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </CompanySettingsCard>
      </Row>
      <Row style={{ height: 20, width: '100%' }} />
    </Row>
  );
}

ActionCenterCard.propTypes = {
  roleName: PropTypes.string.isRequired,
  acPositions: PropTypes.object.isRequired,
  positions: PropTypes.object.isRequired,
  onCheckChanged: PropTypes.func.isRequired,
  onOrderChanged: PropTypes.func.isRequired,
};
