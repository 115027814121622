import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import {  useSelector } from 'react-redux';
import axios from 'axios';

import TitleRow from './TitleRow';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import DisplayText from '../../../common/text/DisplayText';
import FieldTriggerFlag from './FieldTriggerFlag';

import { isNullOrUndefined, currencyFormatter } from '../../../helpers/helpers';
import { getLinkId } from '../../formHelpers';
import { getSubContractsWithTotalChanges } from '../../../projects/ProjectScheduleOfValues/helpers';

const getSubContractDetails = async (subContractId) => {
  const {
    data: subContractDetails,
  } = await axios.get(`/subcontracts/${subContractId}/details`);
  return subContractDetails;
};

function AttributePreviewField({
  id,
  configProps = {},
  responses = {}, // Actively responding
  previewProps = {}, // Completed form
  setResponses,
  contactAddressBooks = {},
  customers,
  projects,
  scheduleOfValues,
  vendors,
  customerId,
  projectId,
  vendorId,
  contactId,
  costcodeId,
  userId,
  subContractId,
  responding = false,
  sections,
  isExternalForm,
  templateId,
  divisions,
  fieldTriggerMap = {},
  setFieldTriggerMap,
  isDisplay,
  name,
  equipment = [],
  projectEquipment = {},
  equipmentTypeMap = {},
  costcodes = [],
  users = [],
  subContractMap = {},
  subContractCOMap = {},
  bucketTemplateMap = {},
}) {
  const divSet = useMemo(() => new Set(divisions), [divisions]);
  const { id: authorId } = useSelector((state) => state.profile.profile);

  const [lastCustomerId, setLastCustomerId] = useState();
  const [lastVendorId, setLastVendorId] = useState();
  const [lastProjectId, setLastProjectId] = useState();
  const [lastContactId, setLastContactId] = useState();
  const [lastCostcodeId, setLastCostcodeId] = useState();
  const [lastUserId, setLastUserId] = useState();
  const [lastSubContractId, setLastSubContractId] = useState();
  const [lastBucketId, setLastBucketId] = useState();
  const [lastPayableSubContractId, setLastPayableSubContractId] = useState();
  const {
    value = '',
  } = previewProps;

  const {
    optional,
    title = 'Title goes here',
    dataType = 'Customer',
    attribute = 'address',
    type = '',
    fieldTrigger,
    useFormAuthorAttributes,
    linkField,
    allowEdits = true,
  } = configProps;

  const realValue = responding && responses[id] ? responses[id].value : value;

  const onAttributeChange = (e) => {
    if (responding && setResponses) {
      const {
        target: {
          value: newValue,
        } = {},
      } = e;
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          value: newValue,
        },
      });
    }
  };

  const setAttr = (val) => {
    if (!setResponses) return;
    const oldValue = responses[id] ? responses[id].value : '';
    const newValue = isNullOrUndefined(val) ? '' : val;
    if (newValue !== oldValue) {
      setResponses((oldResponses = {}) => ({
        ...oldResponses,
        [id]: {
          ...(oldResponses[id]),
          value: newValue,
        },
      }));
    }
  };

  const linkedCustomerId = useMemo(() => (
    getLinkId({ responses, defaultValue: customerId, linkField })
  ), [responses, customerId, linkField]);

  const linkedProjectId = useMemo(() => (
    getLinkId({ responses, defaultValue: projectId, linkField })
  ), [responses, projectId, linkField]);

  const linkedVendorId = useMemo(() => (
    getLinkId({ responses, defaultValue: vendorId, linkField })
  ), [responses, vendorId, linkField]);

  const linkedContactId = useMemo(() => (
    getLinkId({ responses, defaultValue: contactId, linkField })
  ), [responses, contactId, linkField]);

  const linkedCostCodeId = useMemo(() => (
    getLinkId({ responses, defaultValue: costcodeId, linkField })
  ), [responses, costcodeId, linkField]);

  const linkedUserId = useMemo(() => (
    getLinkId({ responses, defaultValue: userId, linkField })
  ), [responses, userId, linkField]);

  const linkedSubContractId = useMemo(() => (
    getLinkId({ responses, defaultValue: subContractId, linkField })
  ), [responses, subContractId, linkField]);

  const linkedBucketId = useMemo(() => (
    getLinkId({ responses, defaultValue: '', linkField })
  ), [responses, linkField]);

  const linkedPayableSubContractId = useMemo(() => (
    getLinkId({ responses, defaultValue: '', linkField })
  ), [responses, linkField]);

  const subContractsWithTotalChanges = useMemo(() => {
    if (!projectId) return [];

    const projectSubContracts = subContractMap?.[projectId] ?? [];

    return getSubContractsWithTotalChanges({
      subContractValues: projectSubContracts,
      subContractCOs: subContractCOMap,
    });
  }, [projectId, subContractMap, subContractCOMap]);

  useEffect(() => {
    const setAttrValue = async () => {
      switch (dataType) {
        case 'Customer': {
          if (realValue && linkedCustomerId && !lastCustomerId) {
            setLastCustomerId(linkedCustomerId);
            break;
          }
          if (linkedCustomerId === lastCustomerId) break;
          if (customers && linkedCustomerId) {
            const customer = customers.find((r) => r.id === linkedCustomerId);
            if (customer) {
              const attributeValue = attribute === 'billingAddress' && !customer[attribute]
                ? customer.officeAddress
                : customer[attribute];
              setAttr(attributeValue);
            }
            setLastCustomerId(linkedCustomerId);
          }
          break;
        }
        case 'Contact': {
          if (realValue && linkedContactId && !lastContactId) {
            setLastContactId(linkedContactId);
            break;
          }
          if (linkedContactId === lastContactId) break;
          const customerAddressBook = contactAddressBooks[customerId] ?? [];
          const vendorAddressBook = contactAddressBooks[vendorId] ?? [];
          if ((customerAddressBook.length || vendorAddressBook.length) && linkedContactId) {
            const contact = customerAddressBook
              .concat(vendorAddressBook)
              .find((entry) => entry.id === linkedContactId);
            if (contact) setAttr(contact[attribute]);
            setLastContactId(linkedContactId);
          }
          break;
        }
        case 'Project': {
          if (realValue && linkedProjectId && !lastProjectId) {
            setLastProjectId(linkedProjectId);
            break;
          }
          if (linkedProjectId === lastProjectId) break;
          if (projects && linkedProjectId) {
            const project = projects.find((r) => r.id === linkedProjectId);
            if (project) {
              if (type === 'equipment') {
                const filteredEquipment = equipment.filter((eq) => (
                  eq.active
                    && eq.divisionIds.some((divisionId) => divSet.has(divisionId))
                    && !!projectEquipment[linkedProjectId]?.[eq.id]
                ));
                let attrStr = '';
                filteredEquipment.forEach((eq, idx) => {
                  const typeName = equipmentTypeMap[eq.equipmentTypeId]?.name ?? null;
                  const typeString = typeName ? ` - ${typeName}` : '';
                  const newLineSuffix = (idx !== filteredEquipment.length - 1) ? '\n' : '';
                  const eqString = `• ${eq.name}${typeString}${newLineSuffix}`;
                  attrStr += eqString;
                });
                setAttr(attrStr);
              } else {
                setAttr(project[attribute]);
              }
            }
            setLastProjectId(linkedProjectId);
          }
          break;
        }
        case 'Project Contract': {
          if (realValue && linkedProjectId && !lastProjectId) {
            setLastProjectId(linkedProjectId);
            break;
          }
          if (linkedProjectId === lastProjectId) break;
          let attr;
          if (scheduleOfValues?.[linkedProjectId]) {
            attr = scheduleOfValues[linkedProjectId]?.[attribute];
            if (typeof attr === 'object') break;
            if (!isNullOrUndefined(attr)) {
              if (type === 'percentage') {
                attr = `${(attr * 100).toFixed(2)} %`;
              } else if (type === 'currency') {
                attr = currencyFormatter(attr);
              }
            }
          }
          setAttr(attr);
          setLastProjectId(linkedProjectId);
          break;
        }
        case 'Vendor': {
          if (realValue && linkedVendorId && !lastVendorId) {
            setLastVendorId(linkedVendorId);
            break;
          }
          if (linkedVendorId === lastVendorId) break;
          if (vendors && linkedVendorId) {
            const vendor = vendors.find((v) => v.id === linkedVendorId);
            if (vendor) {
              const attributeValue = attribute === 'billingAddress' && !vendor[attribute]
                ? vendor.officeAddress
                : vendor[attribute];
              setAttr(attributeValue);
            }
            setLastVendorId(linkedVendorId);
          }
          break;
        }
        case 'Costcodes': {
          if (realValue && linkedCostCodeId && !lastCostcodeId) {
            setLastCostcodeId(linkedCostCodeId);
            break;
          }
          if (linkedCostCodeId === lastCostcodeId) break;
          if (costcodes && linkedCostCodeId) {
            const actualCostcodeId = linkedCostCodeId.split('.')[1] ?? null;
            const costcode = costcodes.find((c) => c.id === actualCostcodeId);
            if (costcode) {
              let attributeValue = costcode[attribute] ?? null;
              if (typeof attributeValue === 'object') attributeValue = attributeValue?.value; // for custom fields
              if (type === 'currency' && !isNullOrUndefined(attributeValue)) attributeValue = currencyFormatter(attributeValue);
              setAttr(attributeValue);
            }
            setLastCostcodeId(linkedCostCodeId);
          }
          break;
        }
        case 'User': {
          if (realValue && linkedUserId && !lastUserId) {
            setLastUserId(linkedUserId);
            break;
          }
          if (
            (linkedUserId === lastUserId && !useFormAuthorAttributes)
            || (authorId === lastUserId && useFormAuthorAttributes)
          ) break;
          if (users && (linkedUserId || useFormAuthorAttributes)) {
            const userIdToUse = useFormAuthorAttributes ? authorId : linkedUserId;
            const user = users.find((u) => u.id === userIdToUse);
            if (user) {
              let attributeValue = user[attribute];
              if (typeof attributeValue === 'object') attributeValue = attributeValue?.value; // for custom fields
              setAttr(attributeValue);
            }
            setLastUserId(userIdToUse);
          }
          break;
        }
        case 'Sub-Contract': {
          if (realValue && linkedSubContractId && !lastSubContractId) {
            setLastSubContractId(linkedSubContractId);
            break;
          }
          if (linkedSubContractId === lastSubContractId) break;
          let attr;
          const ourSubContract = subContractsWithTotalChanges.find(({ rowId }) => (
            linkedSubContractId === rowId
          ));
          if (ourSubContract) {
            attr = ourSubContract?.[attribute];
            if (!isNullOrUndefined(attr)) {
              if (type === 'percentage') {
                attr = `${(attr * 100).toFixed(2)} %`;
              } else if (type === 'currency') {
                attr = currencyFormatter(attr);
              }
            }
          }
          setAttr(attr);
          setLastSubContractId(linkedSubContractId);
          break;
        }
        case 'Buckets': {
          if (realValue && linkedBucketId && !lastBucketId) {
            setLastBucketId(linkedBucketId);
            break;
          }
          if (linkedBucketId === lastBucketId) break;
          let attr;
          const buckets = Object.values(bucketTemplateMap)?.flat() || [];
          const relevantBucket = buckets.find(({ id: bucketId }) => bucketId === linkedBucketId);

          if (relevantBucket) {
            const relevantField = relevantBucket.customData?.fields?.find(
              ({ id: fieldId }) => fieldId === attribute,
            );

            if (relevantField) attr = relevantField.response?.value || '';
          }

          setAttr(attr);
          setLastBucketId(linkedBucketId);
          break;
        }
        case 'PayableSubContracts': {
          if (realValue && linkedPayableSubContractId && !lastPayableSubContractId) {
            setLastPayableSubContractId(linkedPayableSubContractId);
            break;
          }

          if (linkedPayableSubContractId === lastPayableSubContractId) break;

          let attr;
          let subContractDetails;
          if (linkedPayableSubContractId) {
            subContractDetails = await getSubContractDetails(linkedPayableSubContractId);
          }

          if (subContractDetails) {
            attr = subContractDetails?.[attribute];
            if (!isNullOrUndefined(attr)) {
              if (type === 'percentage') {
                attr = `${(attr * 100).toFixed(2)} %`;
              } else if (type === 'currency') {
                attr = currencyFormatter(attr);
              }
            }
          }
          setAttr(attr);
          setLastPayableSubContractId(linkedPayableSubContractId);
          break;
        }
        default:
          break;
      }
    }

    setAttrValue();
  }, [
    responses,
    realValue,
    dataType,
    linkedCustomerId,
    linkedProjectId,
    linkedVendorId,
    linkedContactId,
    linkedCostCodeId,
    linkedUserId,
    linkedSubContractId,
    linkedBucketId,
    customerId,
    vendorId,
    scheduleOfValues,
    contactAddressBooks,
    bucketTemplateMap,
    lastCustomerId,
    lastProjectId,
    lastVendorId,
    lastContactId,
    lastCostcodeId,
    lastSubContractId,
    lastUserId,
    lastBucketId,
    authorId,
    useFormAuthorAttributes,
    subContractsWithTotalChanges,
  ]);

  useState(() => {
    setLastCustomerId();
    setLastProjectId();
    setLastVendorId();
    setLastContactId();
    setLastCostcodeId();
    setLastUserId();
    setLastSubContractId();
    setLastBucketId();
  }, [id]);

  return (
    <div>
      <TitleRow
        title={title}
        optional={optional || !allowEdits}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                isExternalForm={isExternalForm}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                id={id}
                fieldTriggerMap={fieldTriggerMap}
                setFieldTriggerMap={setFieldTriggerMap}
                name={name}
              />
            ) : null
        }
      />
      <Row style={{ marginTop: 15, minHeight: 32 }}>
        {
          allowEdits
            ? (
              <OnTraccrTextInput
                placeholder="Attribute Value Goes Here"
                onChange={onAttributeChange}
                value={realValue}
                textarea={type === 'equipment'}
                style={{ resize: 'none' }}
                autoSize
              />
            ) : (
              <DisplayText title={realValue} />
            )
        }
      </Row>

    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
AttributePreviewField.propTypes = {
  id: PropTypes.string.isRequired,
  configProps: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string,
  })).isRequired,
  responses: PropTypes.object.isRequired,
  previewProps: PropTypes.object.isRequired,
  setResponses: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  scheduleOfValues: PropTypes.object.isRequired,
  vendors: PropTypes.array.isRequired,
  customerId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  responding: PropTypes.bool.isRequired,
  vendorId: PropTypes.string.isRequired,
  subContractId: PropTypes.string.isRequired,
  equipment: PropTypes.array,
  projectEquipment: PropTypes.object,
  equipmentTypeMap: PropTypes.object,
  costcodeId: PropTypes.string.isRequired,
  costcodes: PropTypes.array,
  userId: PropTypes.string.isRequired,
  users: PropTypes.array,
  subContractMap: PropTypes.object,
  subContractCOMap: PropTypes.object,
};

AttributePreviewField.defaultProps = {
  equipment: [],
  projectEquipment: {},
  equipmentTypeMap: {},
  costcodes: [],
  users: [],
  subContractMap: {},
  subContractCOMap: {},
};

export default AttributePreviewField;
