import React, { useCallback, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { Row, Col, Input } from 'antd';
import {
  PlusOutlined,
  DollarOutlined,
  PercentageOutlined,
  FolderAddOutlined,
  LeftOutlined,
  SwapOutlined,
  DeleteOutlined,
  CopyOutlined,
} from '@ant-design/icons';

import Permissions from '../auth/Permissions';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import BorderlessButton from '../common/buttons/BorderlessButton';

import MaterialsColumnChanger from './MaterialsColumnChanger';
import MaterialSearchDropdown from './MaterialSearchDropdown';

export default ({
  onAdd,
  onFolderAdd,
  onBulkChangeClicked,
  selected = [],
  onColumnChange,
  onBack,
  onMove,
  onCopy,
  onArchive,
  onSearchClick,
}) => {
  const [secondaryButtonStyle,setSecondaryButtonStyle] = useSpring(() => ({ height: 32, marginTop: 0, width: '100%' }));

  const onCostChangeClicked = useCallback(() => onBulkChangeClicked('Cost'),[]);
  const onMarkupChangeClicked = useCallback(() => onBulkChangeClicked('Markup'),[]);

  useEffect(() => {
    const hasSelected = selected.length > 0;
    setSecondaryButtonStyle({
      transform: `translateY(${hasSelected ? -42 : 0}px)`
      // marginTop: hasSelected ? -32 : 0,
    });
  },[selected]);
  
  const selectedModifier =  selected.length === 1 ? '' : 's';
  return (
    <div className='materials-header' >
      <animated.div style={secondaryButtonStyle}>
        <Row justify='space-between' align='top'>
            <Col>
              <Row justify='start' align='middle' gutter={14}>
                <Col flex='35px'>
                  {onBack &&<BorderlessButton
                    style={{ backgroundColor:'transparent', padding:0, }}
                    iconNode={<LeftOutlined style={{ padding:0, margin:0 }}/>}
                    onClick={onBack}
                  />}
                </Col>
                <Col flex='200px'>
                  <MaterialSearchDropdown onMaterialSelect={onSearchClick} />
                </Col>
                {Permissions.hasWrite('MATERIALS') && <Col>
                  <OnTraccrButton
                    title='Add Folder'
                    icon={<FolderAddOutlined/>}
                    onClick={onFolderAdd}
                  />
                </Col>}
                {Permissions.hasWrite('MATERIALS') && <Col>
                  <OnTraccrButton
                    title='Add'
                    icon={<PlusOutlined/>}
                    onClick={onAdd}
                  />
                </Col>}
              </Row>
            </Col>
            <Col>
              <Row style={{ height: 32, marginBottom: 10 }} justify='end'>
                <MaterialsColumnChanger
                  onChange={onColumnChange}
                />
              </Row>
            </Col>
        </Row>
        <Row justify='end' align='middle' gutter={14} style={{ height: 32 }}>
          <Col>
            Selected <b>{selected.length}</b> item{selectedModifier}
          </Col>
          <Col>
            <OnTraccrButton
              title='Copy'
              icon={<CopyOutlined />}
              onClick={onCopy}
              type='cancel'
              disabled={!selected.length}
            />
          </Col>
          <Col>
            <OnTraccrButton
              title='Move'
              icon={<SwapOutlined/>}
              onClick={onMove}
              type='cancel'
              disabled={!selected.length}
            />
          </Col>
          <Col>
            <OnTraccrButton
              title='Change Costs'
              icon={<DollarOutlined />}
              onClick={onCostChangeClicked}
              type='cancel'
              disabled={!selected.length}
            />
          </Col>
          <Col>
            <OnTraccrButton
              title='Change Markup'
              icon={<PercentageOutlined />}
              onClick={onMarkupChangeClicked}
              type='cancel'
              disabled={!selected.length}
            />
          </Col>
          <Col>
            <OnTraccrButton
              title='Archive'
              type='back'
              icon={<DeleteOutlined/>}
              onClick={onArchive}
              disabled={!selected.length}
            />
          </Col>
        </Row>
      </animated.div>
    </div>
  );
};
