import {
  GET_SCHEDULE,
  CREATE_SHIFT,
  UPDATE_SHIFT,
  DELETE_SHIFT,
  SET_SCHEDULE_SCALE,
  HOVER_MONTHLY_SCHEDULE_ENTRY,
  VIEW_SHIFT,
  GET_SHIFT_EVENT_FORM_DATA,
  RESET_GANTT_STATE,
  GET_GANTT_SCHEDULES,
  CREATE_GANTT_SCHEDULE,
  UPDATE_GANTT_SCHEDULE,
  DELETE_GANTT_SCHEDULE,
  GET_GANTT_SCHEDULE_ROWS,
  ADD_GANTT_SCHEDULE_ROW,
  UPDATE_GANTT_SCHEDULE_ROW,
  DELETE_GANTT_SCHEDULE_ROW,
  GET_GANTT_SCHEDULE_SETTINGS,
  UPDATE_GANTT_SCHEDULE_SETTINGS,
  GET_GANTT_SCHEDULE_HOLIDAYS,
  UPDATE_GANTT_SCHEDULE_HOLIDAYS,
  ADD_GANTT_SCHEDULE_HOLIDAYS,
  DELETE_GANTT_SCHEDULE_HOLIDAYS,
  IMPORT_GANTT_SCHEDULE,
  GET_GANTT_SCHEDULE_LABELS,
  UPDATE_GANTT_SCHEDULE_FILTERS,
  GET_USER_GANTT_SCHEDULE_FILTER_VIEWS,
  CREATE_USER_GANTT_SCHEDULE_FILTER_VIEW,
  UPDATE_USER_GANTT_SCHEDULE_FILTER_VIEW,
  DELETE_USER_GANTT_SCHEDULE_FILTER_VIEW,
  GET_SCHEDULE_GROUP,
  SET_BIWEEKLY_MODAL_CONFIG,
  UPDATE_SHIFT_AFTER_DRAG,
} from '../../state/actionTypes';
import ScheduleService from './schedule.service';

import Permissions from '../../auth/Permissions';
import Analytics from '../../helpers/Analytics';

export default {};

export const getSchedule = (query = {}) => async (dispatch) => {
  const { data } = await ScheduleService.getSchedule(query);
  if (!data) return false;
  dispatch({
    type: GET_SCHEDULE,
    payload: {
      schedule: data,
    },
  });
  return true;
};

export const getShiftGroup = (groupId) => async (dispatch) => {
  const { data } = await ScheduleService.getSchedule({ groupId });
  if (!data) return false;
  dispatch({
    type: GET_SCHEDULE_GROUP,
    payload: {
      shifts: data,
    },
  });
  return true;
};

export const createShift = ({
  title,
  description,
  startTime,
  endTime,
  users,
  files,
  projectId,
  phaseId,
  costcodeId,
  divisionId,
  isEvent,
  formTemplateId,
  formData,
  repeat, // There are some unwanted properties in the object param, so we extract what we need
  repeatEndDate,
  reminders,
  emailNotification,
  pushNotification,
  color,
  shouldLockClockIn,
}) => async (dispatch) => {
  Analytics.track('Schedule/CreateShift', {
    users: users ? users.length : 0,
    files: files ? files.length : 0,
  });
  const { data } = await ScheduleService.createShift({
    title,
    description,
    startTime,
    endTime,
    users,
    files,
    projectId,
    phaseId,
    costcodeId,
    divisionId,
    repeat,
    repeatEndDate,
    reminders,
    emailNotification,
    pushNotification,
    isEvent,
    formTemplateId,
    formData,
    color,
    shouldLockClockIn,
  });
  if (!data) return false;
  dispatch({
    type: CREATE_SHIFT,
    payload: {
      shifts: data.map((datum) => ({ ...datum, files })),
    },
  });
  return true;
};

export const updateShift = (id, payload) => async (dispatch) => {
  Analytics.track('Schedule/UpdateShift', {
    id,
    title: payload.title,
    startTime: payload.startTime,
    endTime: payload.endTime,
  });
  const { data } = await ScheduleService.updateShift(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_SHIFT,
    payload: {
      shifts: data,
    },
  });
  return true;
};

export const updateShiftAfterDrag = (id, payload) => async (dispatch) => {
  Analytics.track('Schedule/UpdateShiftAfterDrag', {
    id,
    startTime: payload.startTime,
    endTime: payload.endTime,
  });
  const { data } = await ScheduleService.updateShiftAfterDrag(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_SHIFT_AFTER_DRAG,
    payload: {
      shifts: data,
    },
  });
  return true;
};

export const deleteShift = (shift, isGroup) => async (dispatch) => {
  Analytics.track('Schedule/DeleteShift', {
    id: shift.id,
    title: shift.title,
    startTime: shift.startTime,
    endTime: shift.endTime,
  });
  const id = isGroup ? shift.groupId : shift.id;
  const { data } = await ScheduleService.deleteShift(id, isGroup);
  if (!data) return false;
  dispatch({
    type: DELETE_SHIFT,
    payload: {
      shiftIds: data,
    },
  });
  return true;
};

export const getShiftFormData = (id) => async (dispatch) => {
  const { data } = await ScheduleService.getShiftFormData(id);
  if (!data) return false;
  dispatch({
    type: GET_SHIFT_EVENT_FORM_DATA,
    payload: {
      formTemplate: data,
    },
  });
  return true;
};

export const setScale = (scale) => ({
  type: SET_SCHEDULE_SCALE,
  payload: {
    scale,
  },
});

export const setHoverShift = (shift) => ({
  type: HOVER_MONTHLY_SCHEDULE_ENTRY,
  payload: {
    shift,
  },
});

export const toggleShiftView = (visible) => ({
  type: VIEW_SHIFT,
  payload: {
    visible,
  },
});

export const setBiweeklyModalConfig = (config) => ({
  type: SET_BIWEEKLY_MODAL_CONFIG,
  payload: {
    config,
  },
});

export const resetGanttState = () => ({
  type: RESET_GANTT_STATE,
});

export const getGanttSchedules = () => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_READ'))) return false;
  const { data } = await ScheduleService.getGanttSchedules();

  if (!data) {
    return false;
  }

  dispatch({
    type: GET_GANTT_SCHEDULES,
    payload: {
      ganttSchedules: data,
    },
  });
  return true;
};

export const createGanttSchedule = (payload) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE'))) return false;
  const {
    data: {
      schedules,
      newSchedule,
    },
  } = await ScheduleService.createGanttSchedule(payload);

  if (!newSchedule) {
    return false;
  }

  dispatch({
    type: CREATE_GANTT_SCHEDULE,
    payload: {
      ganttSchedules: schedules,
    },
  });
  return newSchedule;
};

export const updateGanttSchedule = (scheduleId, payload) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE'))) return false;
  const { data } = await ScheduleService.updateGanttSchedule(scheduleId, payload);

  if (!data) {
    return false;
  }

  dispatch({
    type: UPDATE_GANTT_SCHEDULE,
    payload: {
      ganttSchedules: data.schedules,
      ganttScheduleRows: data.rows,
    },
  });
  return true;
};

export const deleteGanttSchedule = (scheduleId) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE'))) return false;
  const { data } = await ScheduleService.deleteGanttSchedule(scheduleId);

  if (!data) {
    return false;
  }

  dispatch({
    type: DELETE_GANTT_SCHEDULE,
    payload: {
      ganttSchedules: data,
    },
  });
  return true;
};

export const getGanttScheduleRows = (
  scheduleId,
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_READ'))) return false;
  const { data } = await ScheduleService.getGanttScheduleRows(
    scheduleId,
  );

  if (!data) {
    return false;
  }

  dispatch({
    type: GET_GANTT_SCHEDULE_ROWS,
    payload: {
      ganttScheduleRows: data,
    },
  });
  return true;
};

export const addGanttScheduleRows = (
  scheduleId,
  payload,
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE'))) return false;
  const { data } = await ScheduleService.addGanttScheduleRow(
    scheduleId,
    payload,
  );

  if (!data) {
    return false;
  }

  dispatch({
    type: ADD_GANTT_SCHEDULE_ROW,
    payload: {
      ganttScheduleRows: data,
    },
  });
  return true;
};

export const updateGanttScheduleRow = (
  scheduleId,
  rowId,
  payload,
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE'))) return false;
  const { data } = await ScheduleService.updateGanttScheduleRow(
    scheduleId,
    rowId,
    payload,
  );

  if (!data) {
    return false;
  }

  dispatch({
    type: UPDATE_GANTT_SCHEDULE_ROW,
    payload: {
      ganttScheduleRows: data,
    },
  });
  return true;
};

export const deleteGanttScheduleRow = (
  scheduleId,
  rowId,
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE'))) return false;
  const { data } = await ScheduleService.deleteGanttScheduleRow(
    scheduleId,
    rowId,
  );

  if (!data) {
    return false;
  }

  dispatch({
    type: DELETE_GANTT_SCHEDULE_ROW,
    payload: {
      ganttScheduleRows: data,
      deleteId: rowId,
    },
  });
  return true;
};

export const getGanttScheduleSettings = (
  scheduleId,
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_READ'))) return false;
  const { data } = await ScheduleService.getGanttScheduleSettings(
    scheduleId,
  );

  if (!data) {
    return false;
  }

  dispatch({
    type: GET_GANTT_SCHEDULE_SETTINGS,
    payload: {
      ganttScheduleSettings: data,
    },
  });
  return true;
};

export const updateGanttScheduleSettings = (
  scheduleId,
  payload,
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE'))) return false;
  const { data } = await ScheduleService.updateGanttScheduleSettings(
    scheduleId,
    payload,
  );

  if (!data) {
    return false;
  }

  dispatch({
    type: UPDATE_GANTT_SCHEDULE_SETTINGS,
    payload: {
      ganttScheduleRows: data.rows,
      ganttScheduleSettings: data.settings,
    },
  });
  return true;
};

export const getGanttScheduleHolidays = (
  scheduleId,
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_READ'))) return false;
  const { data } = await ScheduleService.getGanttScheduleHolidays(
    scheduleId,
  );

  if (!data) {
    return false;
  }

  dispatch({
    type: GET_GANTT_SCHEDULE_HOLIDAYS,
    payload: {
      ganttScheduleHolidays: data,
    },
  });
  return true;
};

export const addGanttScheduleHolidays = (
  scheduleId,
  payload,
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE'))) return false;
  const { data } = await ScheduleService.addGanttScheduleHoliday(
    scheduleId,
    payload,
  );

  if (!data) {
    return false;
  }

  dispatch({
    type: ADD_GANTT_SCHEDULE_HOLIDAYS,
    payload: {
      ganttScheduleRows: data.rows,
      ganttScheduleHolidays: data.holidays,
    },
  });
  return true;
};

export const updateGanttScheduleHoliday = (
  scheduleId,
  holidayId,
  payload,
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE'))) return false;
  const { data } = await ScheduleService.updateGanttScheduleHoliday(
    scheduleId,
    holidayId,
    payload,
  );

  if (!data) {
    return false;
  }

  dispatch({
    type: UPDATE_GANTT_SCHEDULE_HOLIDAYS,
    payload: {
      ganttScheduleRows: data.rows,
      ganttScheduleHolidays: data.holidays,
    },
  });
  return true;
};

export const deleteGanttScheduleHoliday = (
  scheduleId,
  holidayId,
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE'))) return false;
  const { data } = await ScheduleService.deleteGanttScheduleHoliday(
    scheduleId,
    holidayId,
  );

  if (!data) {
    return false;
  }

  dispatch({
    type: DELETE_GANTT_SCHEDULE_HOLIDAYS,
    payload: {
      ganttScheduleRows: data.rows,
      ganttScheduleHolidays: data.holidays,
    },
  });
  return true;
};

export const importGanttSchedule = (
  scheduleId,
  payload,
) => async (dispatch) => {
  if (!(Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE'))) return false;
  const { data } = await ScheduleService.importGanttSchedule(
    scheduleId,
    payload,
  );

  if (!data) {
    return false;
  }

  dispatch({
    type: IMPORT_GANTT_SCHEDULE,
    payload: {
      ganttScheduleRows: data.rows,
      ganttScheduleHolidays: data.holidays,
      ganttScheduleSettings: data.settings,
    },
  });
  return true;
};

export const getGanttScheduleLabels = () => async (dispatch) => {
  const { data } = await ScheduleService.getGanttScheduleLabels();
  if (!data) return false;
  dispatch({
    type: GET_GANTT_SCHEDULE_LABELS,
    payload: {
      ganttScheduleLabels: data,
    },
  });
  return true;
};

export const updateGanttScheduleFilters = (filters) => ({
  type: UPDATE_GANTT_SCHEDULE_FILTERS,
  payload: {
    ganttScheduleFilters: filters,
  },
});

export const getUserGanttScheduleFilterViews = (scheduleId) => async (dispatch) => {
  const { data } = await ScheduleService.getUserGanttScheduleFilterViews(scheduleId);
  if (!data) return false;
  dispatch({
    type: GET_USER_GANTT_SCHEDULE_FILTER_VIEWS,
    payload: {
      ganttScheduleFilterViews: data,
    },
  });
  return true;
};

export const addUserGanttScheduleFilterView = (scheduleId, payload) => async (dispatch) => {
  const {
    data: {
      newFilterView,
      filterViews,
    } = {},
  } = await ScheduleService.addUserGanttScheduleFilterView(scheduleId, payload);
  if (!newFilterView) return false;
  dispatch({
    type: CREATE_USER_GANTT_SCHEDULE_FILTER_VIEW,
    payload: {
      ganttScheduleFilterViews: filterViews,
    },
  });

  return newFilterView;
};

export const updateUserGanttScheduleFilterView = (scheduleId, filterViewId, payload) => (
  async (dispatch) => {
    const { data } = await ScheduleService.updateUserGanttScheduleFilterView(
      scheduleId,
      filterViewId,
      payload,
    );
    if (!data) return false;
    dispatch({
      type: UPDATE_USER_GANTT_SCHEDULE_FILTER_VIEW,
      payload: {
        ganttScheduleFilterViews: data,
      },
    });
    return true;
  });

export const deleteUserGanttScheduleFilterView = (scheduleId, filterViewId) => async (dispatch) => {
  const { data } = await ScheduleService.deleteUserGanttScheduleFilterView(
    scheduleId,
    filterViewId,
  );
  if (!data) return false;
  dispatch({
    type: DELETE_USER_GANTT_SCHEDULE_FILTER_VIEW,
    payload: {
      ganttScheduleFilterViews: data,
    },
  });
  return true;
};
