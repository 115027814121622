import React, { useCallback } from 'react';
import { Row, Select } from 'antd';
import PropTypes from 'prop-types';

import { includesTerm } from '../../helpers/helpers';
import FormVariableTextInput from './selectors/FormVariableTextInput';

export default function EmailSubject({
  id,
  data,
  setDataMap,
  fields = [],
  visible,
}) {
  const { subject, subjectField } = data || {};

  const onChange = useCallback((newSubject) => {
    if (setDataMap) {
      setDataMap((dataMap) => {
        const {
          [id]: oldData = {},
        } = dataMap;
        return {
          ...dataMap,
          [id]: {
            ...oldData,
            subject: newSubject,
          },
        };
      });
    }
  }, [id, setDataMap]);

  const onSelect = useCallback((newField) => {
    if (setDataMap) {
      setDataMap((dataMap) => {
        const {
          [id]: oldData = {},
        } = dataMap;
        return {
          ...dataMap,
          [id]: {
            ...oldData,
            subjectField: newField,
          },
        };
      });
    }
  }, [id, setDataMap]);

  return (
    <>
      <Row style={{ marginTop: 5 }}>
        Default Email Subject:
      </Row>
      <Row style={{ margin: '10px 0px' }} className="nodrag">
        <FormVariableTextInput
          textAreaProps={{
            defaultValue: subject,
            onKeyPress: (e) => {
              if (e.key === 'Enter') e.preventDefault();
            },
          }}
          onTextChange={onChange}
          text={subject}
          visible={visible}
        />
      </Row>
      <Row style={{ marginTop: 5 }}>
        Use field input as subject:
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Select
          optionLabelProp="label"
          value={subjectField}
          dropdownMatchSelectWidth={false}
          onSelect={onSelect}
          allowClear
          style={{ width: '100%' }}
          filterOption={(input, option) => (
            includesTerm(option.props.children, input)
          )}
        >
          {
            fields.map((opt) => (
              <Select.Option key={opt.id} value={opt.id} label={opt.label} title={opt.label}>
                {opt.children}
              </Select.Option>
            ))
          }
        </Select>
      </Row>
    </>
  );
}

EmailSubject.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    subject: PropTypes.string,
  }),
  setDataMap: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
};

EmailSubject.defaultProps = {
  id: null,
  data: null,
  setDataMap: null,
  fields: [],
};
