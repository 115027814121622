import {
  GET_ALL_TEAMS,
  CREATE_TEAM,
  UPDATE_TEAM,
  ARCHIVE_TEAM,
  DELETE_TEAM,
  TRANSFER_DIVISIONS,
} from '../../state/actionTypes';

import sortByString, { updateState } from '../../helpers/helpers';
import { updateTeam } from '../teams.helpers';

const parseTeam = ({
  name, supervisors = [], members = [], projects = [], active = 1,
  id,
}) => ({
  id,
  name,
  members: supervisors.map((userId) => ({
    id: userId,
    teamRole: 'Supervisor',
  })).concat(members.map((userId) => ({
    id: userId,
    teamRole: 'Worker',
  }))),
  projects,
  active,
});

const initialState = {
  teams: [],
};

export default function teamsActions(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_TEAMS:
      return {
        ...state,
        teams: action.payload.teams,
      };
    case CREATE_TEAM:
      return {
        ...state,
        teams: state.teams.concat([parseTeam(action.payload.team)]).sort(sortByString('name')),
      };
    case UPDATE_TEAM: {
      return {
        ...state,
        teams: state.teams.map((team) => {
          if (team.id !== action.payload.id) return team;
          return updateTeam(team, action.payload.team);
        }).sort(sortByString('name')),
      };
    }
    case ARCHIVE_TEAM: {
      const {
        payload: {
          active,
          id,
        },
      } = action;
      return {
        ...state,
        teams: updateState(state.teams, { id, active: active ? 1 : 0 }),
      };
    }
    case DELETE_TEAM: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      return {
        ...state,
        teams: state.teams.filter((team) => team.id !== id),
      };
    }
    case TRANSFER_DIVISIONS: {
      const {
        payload: { type, divisionId, ids = [] },
      } = action;
      const {
        teams: stateTeams = [],
      } = state;
      if (type !== 'teams') return state;
      const idSet = new Set(ids);
      return {
        ...state,
        teams: stateTeams.map((team) => {
          if (!idSet.has(team.id)) return team;
          return {
            ...team,
            divisionId,
          };
        }),
      };
    }
    default:
      return state;
  }
}
