import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Select,
  Row,
  Col,
  Switch,
} from 'antd';
import { useTranslation } from 'react-i18next';

import CustomModal from '../common/modals/CustomModal';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';

import { getIdMap } from '../helpers/helpers';

import colors from '../constants/Colors';

export default function EquipmentCheckModal({
  id,
  name,
  currentProjectId,
  currentLocationText,
  onCloseClicked,
  onCheckOut,
  onCheckIn,
  visible,
}) {
  const { t } = useTranslation();
  const equipmentProjects = useSelector((state) => state.equipment.equipmentProjects);
  const projects = useSelector((state) => state.projects.projects);

  const [projectId, setProjectId] = useState(currentProjectId);
  const [location, setLocation] = useState(currentLocationText);
  const [isCustom, setIsCustom] = useState(!!currentLocationText);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const ourProjects = useMemo(() => {
    const {
      [id]: ourProjectIds = [],
    } = equipmentProjects;
    return ourProjectIds.map((projId) => projectIdMap[projId])
      .filter((project) => project);
  }, [id, equipmentProjects, projectIdMap]);

  const onSave = useCallback(() => {
    const payload = {};
    if (isCustom) {
      payload.location = location;
    } else {
      payload.projectId = projectId;
    }
    onCheckIn({ id, payload });
  }, [id, isCustom, projectId, location]);
  const onCheckoutClicked = useCallback(() => {
    const payload = {};
    if (isCustom) {
      payload.location = location;
    } else {
      payload.projectId = projectId;
    }
    onCheckOut({ id, payload });
  }, [id, isCustom, projectId, location]);
  const onTextChanged = useCallback((e) => {
    const {
      target: { value } = {},
    } = e;
    setLocation(value);
  }, []);

  useEffect(() => {
    setProjectId(currentProjectId);
  }, [currentProjectId]);

  useEffect(() => {
    setLocation(currentLocationText);
    setIsCustom(!!currentLocationText);
  }, [currentLocationText]);

  const isActive = currentProjectId || currentLocationText;

  return (
    <CustomModal
      title={name}
      onCloseClicked={onCloseClicked}
      visible={visible}
      maskColor={colors.MODAL_MASK_DARK}
      width={currentProjectId ? 500 : 400}
    >
      <div id='equipment-check-modal-flavour-text'>
        {currentProjectId ? 'Switch' : 'Check In'} equipment
        <div id='equipment-check-modal-switch'>
          <div className='equipment-check-modal-switch-child'>
            <Switch checked={isCustom} onChange={setIsCustom}/>
          </div>
          <div className='equipment-check-modal-switch-child'>
            {isCustom ? 'Location' : t('Project')}
          </div>
        </div>
      </div>
      {
        isCustom
        ? <OnTraccrTextInput
            className='equipment-check-modal-input'
            value={location}
            onChange={onTextChanged}
          />
        : (
          <Select
            className='equipment-check-modal-input'
            placeholder={`Select a ${t('Project').toLowerCase()}`}
            onSelect={setProjectId}
            value={projectId}
          >
            {
              ourProjects.map((project) => (
                <Select.Option value={project.id} key={project.id}>{project.name}</Select.Option>
              ))
            }
          </Select>
        )
      }

      <Row
        className='equipment-check-modal-button-container '
        justify={currentProjectId ? 'space-around' : 'center' }
        gutter={12}
      >
        <Col>
          <OnTraccrButton
            type='cancel'
            title='Cancel'
            roundStyle
            onClick={onCloseClicked}
          />
        </Col>
        {isActive &&
          <Col>
            <OnTraccrButton
              type='back'
              title='Check Out'
              roundStyle
              onClick={onCheckoutClicked}
            />
          </Col>
        }
        <Col>
          <OnTraccrButton
            title={isActive ? 'Switch' : 'Check In'}
            roundStyle
            disabled={!projectId && !location}
            onClick={onSave}
          />
        </Col>
      </Row>
    </CustomModal>
  )
};
