import React, {
  useState, useCallback, useMemo, useEffect, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Empty,
  Row,
  notification,
} from 'antd';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { Gantt, ViewMode } from 'web-dashboard-gantt';
import Permissions from '../../auth/Permissions';
import BreadCrumbContainer from '../../common/breadcrumbContainer/breadcrumbContainer';
import {
  workingDayMap,
  findDateRangeOverlap,
  filterOnProperty,
  calculateDateDifferenceInDays,
  getVisibleColumnMap,
  importFile,
} from './ganttScheduleHelpers';

import 'web-dashboard-gantt/dist/index.css';

import {
  getGanttSchedules,
  getGanttScheduleRows,
  getGanttScheduleHolidays,
  getGanttScheduleSettings,
  updateGanttScheduleSettings,
  importGanttSchedule,
  addGanttScheduleRows,
  updateGanttScheduleRow,
  deleteGanttScheduleRow,
  getGanttScheduleLabels,
  updateGanttScheduleFilters,
  getUserGanttScheduleFilterViews,
  resetGanttState,
  createGanttSchedule,
} from '../state/schedule.actions';
import {
  getProjects,
} from '../../projects/state/projects.actions';
import {
  getUsers,
  getUserSettings,
  updateUserSettings,
} from '../../users/state/users.actions';
import {
  getAllCostCodes, getAllPhases,
} from '../../costcodes/state/costcodes.actions';
import {
  getEquipment,
} from '../../equipment/state/equipment.actions';
import {
  getMaterials,
} from '../../materials/state/materials.actions';
import { toggleFullscreen } from '../../main/state/main.actions';
import { getDivisions } from '../../settings/state/settings.actions';

import GanttScheduleImportDrawer from './GanttScheduleImportDrawer';
import GanttScheduleSettingsDrawer from './Settings/GanttScheduleSettingsDrawer';
import GanttScheduleAddDrawer from './GanttScheduleAddDrawer';
import { getIdMap, includesTerm } from '../../helpers/helpers';
import GanttScheduleHeader from './GanttScheduleHeader';
import GanttScheduleList from './GanttScheduleList';
import GanttScheduleFilterDrawer from './Filters/GanttScheduleFilterDrawer';
import GanttScheduleExportDrawer from './GanttScheduleExportDrawer';

const crumbs = [{ text: 'Scheduling', icon: 'calendar' }];

export default function GanttSchedule({
  history,
  projectId: selectedProjectId,
}) {
  const dispatch = useDispatch();

  const {
    projects,
  } = useSelector((state) => state.projects);
  const {
    ganttSchedules,
    ganttScheduleRows,
    ganttScheduleSettings = {
      workingDays: [],
    },
    ganttScheduleHolidays,
    ganttScheduleLabels,
    ganttScheduleFilters,
    ganttScheduleFilterViews,
  } = useSelector((state) => state.schedule);
  const {
    settings: userSettings = {
      ganttScheduleSettings: JSON.stringify({
        visibleColumns: ['Name'],
      }),
    },
  } = useSelector((state) => state.users);
  const {
    selectedDivisions,
  } = useSelector((state) => state.settings);
  const isFullScreen = useSelector((state) => state.main.fullscreen);
  const scale = useSelector((state) => state.schedule.scale);
  const {
    costcodes: costCodes,
    phases,
  } = useSelector((state) => state.costcodes);
  const equipment = useSelector((state) => state.equipment.equipment);
  const users = useSelector((state) => state.users.users);
  const materials = useSelector((state) => state.materials.materials);

  const [viewType, setViewType] = useState(ViewMode.Month);
  const [visibleDrawer, setVisibleDrawer] = useState('');
  const [scheduleId, setScheduleId] = useState('');
  const [projectId, setProjectId] = useState(selectedProjectId);
  const [viewDate, setViewDate] = useState(DateTime.local());
  const [newTasks, setNewTasks] = useState([]);
  const [newHolidays, setNewHolidays] = useState([]);
  const [newSettings, setNewSettings] = useState({});
  const [columnWidth, setColumnWidth] = useState(150);
  const [selectedTask, setSelectedTask] = useState({});
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [documentHeight, setDocumentHeight] = useState(document.body.offsetHeight);
  const [activeTab, setActiveTab] = useState('schedule');

  const inputRef = useRef();

  useEffect(() => {
    dispatch(getGanttSchedules());
    dispatch(getProjects());
    dispatch(getUserSettings());
    dispatch(getGanttScheduleLabels());
    dispatch(getUsers());
    dispatch(getAllCostCodes());
    dispatch(getEquipment());
    dispatch(getMaterials());
    dispatch(getDivisions());
  }, []);

  useEffect(() => {
    setViewDate(viewDate.plus({ seconds: 1 }));
  }, [scale]);

  useEffect(() => {
    if (scheduleId) {
      dispatch(getGanttScheduleRows(scheduleId));
      dispatch(getGanttScheduleSettings(scheduleId));
      dispatch(getGanttScheduleHolidays(scheduleId));
      dispatch(getAllPhases());
      dispatch(getUserGanttScheduleFilterViews(scheduleId));
      dispatch(updateGanttScheduleFilters());
    } else {
      resetGanttState();
    }
  }, [scheduleId]);

  const hideDrawer = useCallback(() => {
    setVisibleDrawer('');
    setSelectedTask({});
  }, []);

  const openDrawer = useCallback((type) => {
    setVisibleDrawer(type);
  }, []);

  const onDateChange = useCallback((newDate) => {
    const ts = newDate.valueOf();
    const dt = DateTime.fromMillis(ts);
    setViewDate(dt);
  }, []);

  const onViewTypeChange = useCallback((newViewType) => {
    setViewType(newViewType);

    if (newViewType === 'month') {
      setColumnWidth(150);
      return;
    }

    setColumnWidth(275);
    setViewDate(viewDate.plus({ seconds: 1 }));
  }, [viewType]);

  const onEventClick = useCallback((task) => {
    setSelectedTask(ganttScheduleRows.find((row) => row.id === task.id));
    openDrawer('add');
  }, [ganttScheduleRows]);

  const filteredProjects = useMemo(() => projects.filter(
    (project) => selectedDivisions.has(project.divisionId),
  ), [projects, selectedDivisions]);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const labelMap = useMemo(() => getIdMap(ganttScheduleLabels), [ganttScheduleLabels]);
  const equipmentMap = useMemo(() => getIdMap(equipment), [equipment]);
  const costCodeMap = useMemo(() => getIdMap(costCodes), [costCodes]);
  const taskMap = useMemo(() => getIdMap(ganttScheduleRows), [ganttScheduleRows]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const filteredProjectMap = useMemo(() => getIdMap(filteredProjects), [filteredProjects]);
  const scheduleMap = useMemo(() => getIdMap(ganttSchedules), [ganttSchedules]);

  useEffect(() => {
    if (scheduleId) {
      setSelectedSchedule(scheduleMap[scheduleId] || {});
    } else {
      setSelectedSchedule({});
    }
  }, [scheduleId, scheduleMap]);

  const phaseMap = useMemo(() => phases.reduce((acc, phase) => {
    if (!acc[phase.id]) {
      acc[phase.id] = [];
    }

    acc[phase.id].push(phase);
    return acc;
  }, {}), [phases]);

  const filteredTasks = useMemo(() => {
    const {
      name: nameFilter,
      dateRange: dateRangeFilter,
      users: userFilter,
      equipment: equipmentFilter,
      costCodes: costCodeFilter,
      phases: phaseFilter,
      materials: materialFilter,
      labels: labelFilter,
      schedules: scheduleFilter,
    } = ganttScheduleFilters;

    const userFilterMap = new Set(userFilter);
    const equipmentFilterMap = new Set(equipmentFilter);
    const costCodeFilterMap = new Set(costCodeFilter);
    const phaseFilterMap = new Set(phaseFilter);
    const materialFilterMap = new Set(materialFilter);
    const labelFilterMap = new Set(labelFilter);
    const scheduleFilterMap = new Set(scheduleFilter);

    const filteredRows = [];
    let formattedRow;

    ganttScheduleRows.forEach((row) => {
      const schedule = scheduleMap[row.ganttScheduleId];
      let scheduleName = schedule.name;

      if (schedule.projectId) {
        scheduleName = projectMap[schedule.projectId].name;
      }

      formattedRow = {
        ...row,
        realStartDate: row.startDate,
        realEndDate: row.endDate,
        label: labelMap[row.labelId] || {},
        actualExpectedUserCount: {
          label: `${row.users.length}/${row.expectedNumberOfUsers}`,
          color: row.expectedNumberOfUsers === row.users.length ? 'green' : 'red',
          users: row.users.map((userId) => userMap[userId]),
        },
        usersColumn: row.users.map((userId) => userMap[userId]),
        equipmentColumn: row.equipment.map((equipmentId) => equipmentMap[equipmentId]),
        costCodeColumn: row.costCodes.map((codeObject) => {
          const [, costCodeId] = codeObject.split('.');
          return costCodeMap[costCodeId];
        }),
        phaseColumn: phaseMap[row.phaseId],
        materialColumn: row.materials.map((material) => ({
          ...materials[material.id],
          ...material,
        })),
        actualExpectedStart: calculateDateDifferenceInDays(row.startDate, row.actualStartDate),
        actualExpectedEnd: calculateDateDifferenceInDays(row.endDate, row.actualEndDate),
        dependencies: row.parentDependencies,
        parentDependenciesColumn: row.parentDependencies.map((taskId) => taskMap[taskId]),
        childDependenciesColumn: row.childDependencies.map((taskId) => taskMap[taskId]),
        schedule: scheduleName,
      };

      if (
        (nameFilter && !includesTerm(formattedRow.name, nameFilter))
        || !filterOnProperty(formattedRow.users, userFilterMap)
        || !filterOnProperty(formattedRow.equipment, equipmentFilterMap)
        || !filterOnProperty(formattedRow.costCodes, costCodeFilterMap)
        || !filterOnProperty(formattedRow.phaseId, phaseFilterMap)
        || !filterOnProperty(
          formattedRow.materials.map((material) => material.id),
          materialFilterMap,
        )
        || !filterOnProperty(formattedRow.labelId, labelFilterMap)
        || !filterOnProperty(formattedRow.ganttScheduleId, scheduleFilterMap)
        || (formattedRow.projectId && !filteredProjectMap[formattedRow.projectId])
      ) {
        return;
      }

      let formattedDateFilter = null;

      if (dateRangeFilter) {
        formattedDateFilter = {
          start: dateRangeFilter[0].startOf('day').utcOffset(0).valueOf() / 1000,
          end: dateRangeFilter[1].startOf('day').utcOffset(0).valueOf() / 1000,
        };
      } else {
        filteredRows.push({
          ...formattedRow,
        });
        return;
      }

      const overlappingDateRange = findDateRangeOverlap(
        {
          start: formattedRow.startDate,
          end: formattedRow.endDate,
        },
        formattedDateFilter,
      );

      if (!overlappingDateRange) {
        return;
      }

      // Set the end date to the end of the day in order for it to be rendered correctly
      const endDate = new Date(
        overlappingDateRange.end * 1000 + (new Date().getTimezoneOffset() * 60 * 1000),
      );
      endDate.setHours(23);
      endDate.setMinutes(59);

      filteredRows.push({
        ...formattedRow,
        realStartDate: formattedRow.startDate,
        realEndDate: formattedRow.endDate,
        startDate: overlappingDateRange.start,
        endDate: overlappingDateRange.end,
        start: new Date(
          overlappingDateRange.start * 1000 + (new Date().getTimezoneOffset() * 60 * 1000),
        ),
        end: endDate,
      });
    });

    return filteredRows;
  }, [
    ganttScheduleFilters,
    userMap,
    labelMap,
    equipmentMap,
    costCodeMap,
    projectMap,
    scheduleMap,
    taskMap,
    materials,
    phaseMap,
    ganttScheduleRows,
    ganttScheduleSettings,
    ganttScheduleHolidays,
    selectedDivisions,
    filteredProjectMap,
  ]);

  const formattedSchedules = useMemo(() => ganttSchedules.map((schedule) => ({
    ...schedule,
    project: projectMap[schedule.projectId],
  })), [ganttSchedules, projectMap]);

  const filteredSchedules = useMemo(() => {
    const newSchedules = [];
    const projMap = { ...filteredProjectMap };

    formattedSchedules.forEach((schedule) => {
      if (!schedule.projectId || projMap[schedule.projectId]) {
        newSchedules.push(schedule);
        delete projMap[schedule.projectId];
      }
    });

    // Construct new schedules for any projects that don't have one
    Object.keys(projMap).forEach((projId) => {
      newSchedules.push({
        id: '',
        isNew: true,
        name: 'Default',
        project: projMap[projId],
        projectId: projId,
      });
    });

    return newSchedules;
  }, [
    formattedSchedules,
    filteredProjectMap,
  ]);

  // If the filtered schedules don't include the current schedule, clear the schedule
  useEffect(() => {
    if (!projectId) {
      return;
    }

    const schedule = filteredSchedules.find(
      ({ projectId: scheduleProjectId }) => scheduleProjectId === projectId,
    );

    if (!schedule) {
      setProjectId(null);
      setScheduleId(null);
    } else if (schedule.isNew) {
      const createSchedule = async () => {
        const newSchedule = await dispatch(createGanttSchedule({
          name: 'Default',
          projectId,
        }));

        if (newSchedule) {
          setScheduleId(newSchedule.id);
        }
      };

      createSchedule();
    } else {
      setScheduleId(schedule.id);
    }
  }, [projectId, filteredSchedules]);

  const nonWorkingDays = useMemo(() => {
    const dayMap = { ...workingDayMap };
    const days = [];

    if (ganttScheduleSettings.workingDays) {
      ganttScheduleSettings.workingDays.forEach((day) => {
        delete dayMap[day];
      });

      // Any keys left in the map must be a non-working day
      Object.keys(dayMap).forEach((day) => {
        // In luxon 7 is sunday, but in the gantt schedule 0 is sunday
        if (day === '7') {
          days.push(0);
          return;
        }
        days.push(parseInt(day, 10));
      });
    }

    return days;
  }, [ganttScheduleSettings]);

  const visibleColumnMap = useMemo(() => (
    getVisibleColumnMap({
      tasks: ganttScheduleRows,
      activeTab,
    })
  ), [ganttScheduleRows, activeTab]);

  const ganttUserSettings = useMemo(() => {
    if (userSettings.ganttScheduleSettings) {
      const projectSettings = JSON.parse(userSettings.ganttScheduleSettings);
      projectSettings.visibleColumns = projectSettings.visibleColumns.map(
        (column) => visibleColumnMap[column],
      );

      return projectSettings;
    }

    return {
      visibleColumns: [visibleColumnMap.Name],
    };
  }, [userSettings, visibleColumnMap]);

  const onTaskClick = useCallback((task) => {
    setViewDate(DateTime.fromSeconds(task.startDate));
  }, [setViewDate]);

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    const result = await importFile(file, notification);

    if (result) {
      setNewTasks(result.tasks);
      setNewHolidays(result.holidays);
      setVisibleDrawer('import');
      setNewSettings({
        workingDays: result.workingDays,
      });
    }
  };

  const onImport = () => {
    inputRef.current.click();
  };

  const onImportSubmit = async (payload) => {
    const result = await dispatch(importGanttSchedule(scheduleId, payload));

    if (result) {
      hideDrawer();
    }
  };

  const onSettingsSubmit = async (payload) => {
    const result = await dispatch(updateGanttScheduleSettings(scheduleId, payload));

    if (result) {
      hideDrawer();
    }
  };

  const onUserSettingsSubmit = async (payload) => {
    const result = await dispatch(updateUserSettings(payload));

    if (result) {
      hideDrawer();
    }
  };

  const onAddSubmit = async (payload) => {
    const addPayload = {
      name: payload.name,
      color: payload.color,
      duration: payload.duration,
      startDate: payload.start.valueOf() / 1000,
      endDate: payload.end,
      type: 'Task',
      priority: 0,
      progress: payload.progress || 0,
      labelId: payload.label,
      expectedNumberOfUsers: payload.expectedNumberOfUsers,
      users: payload.users,
      costCodes: payload.costCodes.map((costCode) => {
        const [phaseId, costCodeId] = costCode.split('.');
        return {
          phaseId,
          costCodeId,
        };
      }),
      equipment: payload.equipment,
      materials: (payload.materials || []).map((material) => ({
        id: material.id,
        quantity: material.quantity,
      })),
      phaseId: payload.phaseId || null,
      actualStartDate: payload.actualStartDate
        ? payload.actualStartDate.valueOf() / 1000
        : null,
      actualEndDate: payload.actualEndDate
        ? payload.actualEndDate.valueOf() / 1000
        : null,
      parentDependencies: payload.parentDependencies,
      childDependencies: payload.childDependencies,
      notes: payload.notes,
    };

    let result;

    if (selectedTask.id) {
      result = await dispatch(
        updateGanttScheduleRow(selectedTask.ganttScheduleId, selectedTask.id, addPayload),
      );
    } else {
      result = await dispatch(addGanttScheduleRows(scheduleId, addPayload));
    }

    if (result) {
      hideDrawer();
    }
  };

  const onDelete = async (ganttScheduleId, rowId) => {
    if (await dispatch(deleteGanttScheduleRow(ganttScheduleId, rowId))) {
      hideDrawer();
    }
  };

  window.addEventListener('resize', () => {
    setDocumentHeight(document.body.offsetHeight);
  });

  const readOnly = !Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE');
  const offsetHeight = useMemo(() => {
    // Miscellaneous offset
    const baseOffset = 160;
    // Breadcrumb title + padding
    const breadcrumbHeight = 70;
    // Header + tabs + footer
    const projectOffsetHeight = 155;

    if (isFullScreen) {
      return baseOffset;
    }

    if (selectedProjectId) {
      return baseOffset + projectOffsetHeight;
    }

    return baseOffset + breadcrumbHeight;
  }, [isFullScreen, selectedProjectId]);

  const onExport = useCallback(() => openDrawer('export'), [openDrawer]);

  if (!Permissions.has('PROJECT_GANTT_SCHEDULE_READ')) {
    history.replace('/dashboard');
    return null;
  }

  return (
    <BreadCrumbContainer
      crumbs={crumbs}
      fullscreen={isFullScreen}
      showHeader={!selectedProjectId}
    >
      <input type="file" hidden ref={inputRef} onChange={onFileChange} />
      <Row id="gantt-schedule-container" style={{ flexDirection: 'row' }}>
        <GanttScheduleHeader
          viewType={viewType}
          onViewTypeChange={onViewTypeChange}
          onDateChange={onDateChange}
          date={viewDate}
          openDrawer={openDrawer}
          onImport={onImport}
          schedules={filteredSchedules}
          setProjectId={setProjectId}
          projectId={projectId}
          setScheduleId={setScheduleId}
          scheduleId={scheduleId}
          toggleFullscreen={toggleFullscreen}
          isFullScreen={isFullScreen}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isSelected={!!selectedProjectId}
        />
      </Row>
      { scheduleId
        && (
          <>
            {
              activeTab === 'schedule'
                && (
                  <Row id="schedule-container">
                    {
                      filteredTasks.length > 0
                      && (
                      <Gantt
                        tasks={filteredTasks}
                        viewMode={viewType}
                        columnWidth={columnWidth * scale}
                        handleWidth={500}
                        viewDate={viewDate}
                        ganttHeight={documentHeight - offsetHeight}
                        onDoubleClick={onEventClick}
                        rowHeight={30}
                        weekendDays={nonWorkingDays}
                        onListItemClick={onTaskClick}
                        visibleColumns={ganttUserSettings.visibleColumns}
                        readOnly={readOnly}
                      />
                      )
                    }
                    {
                      filteredTasks.length === 0
                      && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                  </Row>
                )
            }
            {
              activeTab === 'list'
                && (
                  <GanttScheduleList
                    tasks={filteredTasks}
                    visibleColumns={ganttUserSettings.visibleColumns}
                    onEditClick={onEventClick}
                    isReadOnly={readOnly}
                  />
                )
            }
          </>
        )}
      <GanttScheduleAddDrawer
        visible={visibleDrawer === 'add'}
        onClose={hideDrawer}
        settings={ganttScheduleSettings}
        holidays={ganttScheduleHolidays}
        readOnly={readOnly}
        handleSubmit={onAddSubmit}
        row={selectedTask}
        labels={ganttScheduleLabels}
        onDelete={onDelete}
        costCodes={costCodes}
        equipment={equipment}
        users={users}
        materials={materials}
        tasks={ganttScheduleRows}
        phases={phases}
      />
      <GanttScheduleImportDrawer
        visible={visibleDrawer === 'import'}
        tasksToImport={newTasks}
        handleSubmit={onImportSubmit}
        onClose={hideDrawer}
        existingHolidays={ganttScheduleHolidays}
        newHolidays={newHolidays}
        setNewHolidays={setNewHolidays}
        newSettings={newSettings}
        existingSettings={ganttScheduleSettings}
      />
      <GanttScheduleSettingsDrawer
        visible={visibleDrawer === 'settings'}
        onClose={hideDrawer}
        holidays={ganttScheduleHolidays}
        settings={ganttScheduleSettings}
        userSettings={userSettings}
        handleSubmit={onSettingsSubmit}
        handleUserSettingsSubmit={onUserSettingsSubmit}
        scheduleId={scheduleId}
        history={history}
        schedules={formattedSchedules}
        setScheduleId={setScheduleId}
        selectedSchedule={selectedSchedule}
        onExport={onExport}
      />
      <GanttScheduleFilterDrawer
        visible={visibleDrawer === 'filters'}
        onClose={hideDrawer}
        costCodes={costCodes}
        equipment={equipment}
        users={users}
        materials={materials}
        labels={ganttScheduleLabels}
        filterViews={ganttScheduleFilterViews}
        projects={filteredProjects}
        schedule={selectedSchedule}
        phases={phases}
      />
      <GanttScheduleExportDrawer
        visible={visibleDrawer === 'export'}
        onClose={hideDrawer}
        tasks={filteredTasks}
        userSettings={userSettings}
      />
    </BreadCrumbContainer>
  );
}

GanttSchedule.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  projectId: PropTypes.string,
};

GanttSchedule.defaultProps = {
  projectId: null,
};
