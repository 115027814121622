import formToPDF from 'ontraccr-form-to-pdf';
import { DateTime } from 'luxon';

import {
  prepareResponsePayload,
  constructFormPayloadForAPI,
  generateResponseMap,
} from './ResponderHelpers';

import { loadFromSigned } from '../files/fileHelpers';
import { clearConditionalResponses } from './formHelpers';

const FILE_FIELD_SET = new Set([
  'attachment',
  'multiSig',
  'staticAttachments',
]);

const getFileData = async (fieldId, files = []) => {
  // Need to download existing files from Signed URLs
  const rawData = await Promise.all(
    files.map(async (file) => {
      let jsFileObject = file;
      if (!(file instanceof File)) {
        jsFileObject = await loadFromSigned({
          signedURL: file.url,
          name: file.name,
          id: file.id,
        });
      }

      return jsFileObject.arrayBuffer();
    }),
  );
  return files.map((fileObject, fileIndex) => {
    const fileId = `${fieldId}-${fileIndex}`;
    return {
      id: fileId,
      data: rawData[fileIndex],
      name: fileObject.name,
    };
  });
};

export default async ({
  title,
  formData,
  useStandardTemplate,
  drawOptions,
  currentUser,
  sections,
  responses,
  formNumber,
  fileMap = {},
  settings = {},
  logo,
}) => {
  const {
    name: employeeName,
    employeeId,
  } = currentUser || {};

  const clearedResponses = clearConditionalResponses({ responses, sections });

  const formPayload = prepareResponsePayload({
    sections,
    responses: clearedResponses,
  });
  if (formNumber) formPayload.collected.number = formNumber.toString();

  // Get files from responses to store in file map
  // formToPDF is expect attachment responses to be a list of fileIDs
  // and for there to be a file map (files param) that links fileIDs to raw file data
  let files = [];
  const responseProms = Object.keys(clearedResponses).map(async (fieldId) => {
    const {
      responses: completedResponses = {},
    } = formPayload;

    const {
      [fieldId]: { type } = {},
    } = completedResponses;

    if (!FILE_FIELD_SET.has(type)) return;
    const isMultiSig = type === 'multiSig';
    const isStatic = type === 'staticAttachments';
    const fileKey = isMultiSig ? 'values' : 'files';
    const {
      [fileKey]: responseFiles = [],
    } = clearedResponses[fieldId];

    let fullResponseFiles = [];
    if (isMultiSig) {
      const parsedFiles = await Promise.all(responseFiles.map(async (value, idx) => ({
        ...value,
        data: value.sig ? await value.sig.arrayBuffer() : null,
        name: value.sig ? value.sig.name : `${fieldId}.${idx}.png`,
      })));
      fullResponseFiles = parsedFiles.filter((file) => file.data);
    } else if (isStatic) {
      // Static Attachment files are already loaded in fileMap
      fullResponseFiles = responseFiles;
    } else {
      fullResponseFiles = await getFileData(fieldId, responseFiles);
    }

    if (!isStatic) files = files.concat(fullResponseFiles);

    if (fieldId in completedResponses && !isMultiSig) {
      completedResponses[fieldId].files = fullResponseFiles;
    }
  });

  await Promise.all(responseProms);

  // Load static files from fileMap
  const staticProms = Object.values(fileMap).map(async (file) => {
    const { id, name, jsFileObject } = file;
    files.push({
      id,
      name,
      data: await jsFileObject.arrayBuffer(),
    });
  });
  await Promise.all(staticProms);

  if (logo) {
    files.push({
      id: 'logo',
      name: logo.name,
      data: await logo.arrayBuffer(),
    });
  }

  const { data: completedForm } = await constructFormPayloadForAPI({
    form: formPayload,
    shouldUploadFiles: false,
  });

  const responseMap = generateResponseMap(completedForm.sections);
  const parsedSections = formData?.sections?.map((section) => {
    const { fields = [] } = section;
    const parsedFields = fields.map((field) => {
      const { id: fieldId } = field;
      const response = responseMap[fieldId];
      return {
        ...field,
        response,
      };
    });
    return {
      ...section,
      fields: parsedFields,
    };
  }) ?? [];

  const rawPDFData = await formToPDF({
    ...formData,
    sections: parsedSections,
    useStandardTemplate,
  }, {
    employeeName,
    employeeId,
    timestamp: DateTime.local().toMillis(),
    completedForm,
    files,
    drawOptions,
    settings,
    fileMap,
  });
  return new File([rawPDFData], `${title}.pdf`, { type: 'application/pdf' });
};
