import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Row, Col, Spin,
} from 'antd';
import { LineChartOutlined } from '@ant-design/icons';

import ReportFilterCard from './ReportsFilterCard';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import colors from '../../constants/Colors';

const margin = 20;
const colWidth = 150;

export default function ReportSkeleton({
  colFilters,
  rowFilters,
  data,
  columns,
  height,
  showProgress = false,
  onProgressClick,
  summaryConfig,
  zeroHoursConfig,
  loading,
}) {
  const offset = margin + (showProgress ? colWidth + margin : 0);

  return (
    <div style={{ width: '100%', height: 'calc(100% - 32px)' }}>
      <Row>
        <Col flex="125px" style={{ alignItems: 'right', marginRight: margin }}>
          <Row>
            {summaryConfig && <ReportFilterCard {...summaryConfig} title="Show Summary" />}
          </Row>
        </Col>
        <Col style={{ width: `calc(100% - 125px - ${offset}px` }}>
          {colFilters}
        </Col>
        {showProgress && (
          <Col flex={`${colWidth}px`} style={{ marginLeft: margin, paddingTop: 20 }}>
            <Row justify="end" style={{ width: '100%', height: '100%' }} align="bottom">
              <Col>
                <BorderlessButton
                  title="Show Progress"
                  iconNode={<LineChartOutlined />}
                  style={{
                    color: colors.ONTRACCR_RED,
                    backgroundColor: 'transparent',
                  }}
                  onClick={onProgressClick}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row style={{ width: '100%' }}>
        <Col flex="125px" style={{ alignItems: 'right', marginRight: margin }}>
          {zeroHoursConfig && <ReportFilterCard {...zeroHoursConfig} title="Show Zero Hr." />}
          {rowFilters}
        </Col>
        <Col style={{ width: `calc(100% - 125px - ${margin}px` }}>
          <Table
            size="small"
            pagination={false}
            tableLayout="fixed"
            bordered
            style={{
              borderRadius: 6,
              border: '1 solid gray',
              height,
              marginTop: 20,
            }}
            columns={columns}
            dataSource={data}
            scroll={{
              y: height,
              x: 'max-content',
            }}
            onRow={() => ({ height: 40, overflow: 'ellipsis' })}
            rowClassName={() => 'ontraccr-row'}
          />
          {
            loading && (
              <Row
                style={{
                  position: 'absolute',
                  inset: '0',
                }}
                align="middle"
                justify="center"
              >
                <Col>
                  <Spin />
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
ReportSkeleton.propTypes = {
  summaryConfig: PropTypes.object,
  zeroHoursConfig: PropTypes.object,
  loading: PropTypes.bool,
};

ReportSkeleton.defaultProps = {
  summaryConfig: undefined,
  zeroHoursConfig: undefined,
  loading: false,
};
