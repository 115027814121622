import { TreeSelect } from 'antd';

// Import Components
import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import BorderlessButton from '../../common/buttons/BorderlessButton';

// Import Helpers:
import { getFormattedCurrency } from '../../forms/formHelpers';
import colors from '../../constants/Colors';
import {
  currencyFormatter as formatter,
  currencyParser as parser,
} from '../../helpers/inputParsers';

export default ({
  isDisplay,
  costcodeTreeOptions = [],
  onCostcodeOptionChange,
  onAmountChange,
  onCostcodeDelete,
}) => [
  {
    title: 'Costcode',
    dataIndex: 'phaseCostcodeKey',
    key: 'phaseCostcodeKey',
    align: 'center',
    width: '65%',
    render: (key, record) => {
      const { isNew, id, phaseName, costcodeName, code } = record || {};
      return (
        isDisplay || !isNew
          ? `${phaseName || 'Unphased'} - ${code} ${costcodeName}`
          : <TreeSelect
              showSearch
              treeDefaultExpandAll
              treeNodeFilterProp='title'
              treeData={costcodeTreeOptions}
              value={key}
              onSelect={(val) => onCostcodeOptionChange(id, val)}
              style={{ width: '95%' }}
            />
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    width: '35%',
    render: (amount, record) => {
      const { id } = record || {};
      return (
        isDisplay ? getFormattedCurrency(amount)
        : <OnTraccrNumberInput
            style={{ width: '95%', marginTop: 5 }}
            value={amount}
            onChange={(val) => onAmountChange(id, val)}
            formatter={formatter}
            parser={parser}
            precision={2}
            step={0.01}
          />
      );
    }
  },
  {
    key:'action',
    dataIndex: 'id',
    align:'center',
    width: 60,
    render:(id) => {
      return (
        isDisplay ? null
          : <BorderlessButton
              title=''
              icon='delete'
              color={colors.ONTRACCR_RED}
              onClick={() => onCostcodeDelete(id)}
              style={{
                paddingRight:8, 
                paddingLeft:0,
              }}
            />
      );
    },
  },
];
