import axios from 'axios';
import { message } from 'antd';
import { archive, request, parseError } from '../../helpers/requests';

import { parseAndUploadFiles } from '../../files/fileHelpers';
import { formatPayloadCustomData } from '../../equipment/state/equipment.service';

const formatCostcodes = async (costcodes) => (
  Promise.all(
    costcodes.map(async (costcode) => {
      const { customData, files } = await formatPayloadCustomData(costcode.customData);
      return {
        ...costcode,
        customData,
        files,
      };
    }),
  )
);

const generateProjectPayload = async (payload, isOnDetails) => {
  const {
    newCostcodes,
    createdEquipment,
    createdCostcodes,
    ...restOfPayload
  } = payload;
  const realPayload = { ...restOfPayload };

  if (createdEquipment) {
    const equipment = await Promise.all(
      createdEquipment.map(async ({
        name,
        divisionIds,
        code,
        files,
        hasImage,
        userId,
        hourlyCost,
        hourlyBillingRate,
        dailyCost,
        dailyBillingRate,
        files: equipmentFiles,
        image,
        labels,
        statusId,
        equipmentTypeId,
        customData,
        qrCodeValue,
        qrCodeFormat,
      }) => {
        const fullEq = {
          name,
          divisionIds,
          code,
          files,
          hasImage,
          userId,
          hourlyCost,
          hourlyBillingRate,
          dailyCost,
          dailyBillingRate,
          labels,
          statusId,
          equipmentTypeId,
          qrCode: {
            value: qrCodeValue,
            format: qrCodeFormat,
          },
        };
        const parsedLabels = labels?.map((label) => ({
          title: label.title,
          labelId: label.id,
        })) ?? [];
        const parsedFiles = await parseAndUploadFiles(equipmentFiles);
        const {
          customData: formattedCustomData,
          files: formattedFiles,
        } = await formatPayloadCustomData(customData);
        fullEq.labels = parsedLabels;
        fullEq.files = parsedFiles.concat(formattedFiles);
        fullEq.hasImage = !!image;
        fullEq.customData = formattedCustomData;
        return fullEq;
      }),
    );
    realPayload.createdEquipment = equipment;
  }

  if (createdCostcodes) {
    realPayload.createdCostcodes = await formatCostcodes(createdCostcodes);
  }

  if (newCostcodes) {
    realPayload.newCostcodes = await formatCostcodes(newCostcodes);
  }

  const payloadToModify = isOnDetails ? realPayload.details : realPayload;

  const {
    customData: formattedCustomData,
    files: formattedFiles,
  } = await formatPayloadCustomData(payloadToModify.customData);
  payloadToModify.customData = formattedCustomData;
  payloadToModify.files = formattedFiles;
  delete payloadToModify.error;
  delete payloadToModify.errorMap;

  return realPayload;
};

const ProjectsService = {
  create: (payload) => request({
    call: async () => {
      const realPayload = await generateProjectPayload(payload);
      return axios.post('/projects', realPayload);
    },
    errMsg: parseError('Could not create project'),
  }),
  getAll: async (query) => {
    try {
      const { data } = await axios.get('/projects', { params: query });
      return data;
    } catch (err) {
      message.error('Could not retrieve projects');
      return [];
    }
  },
  archive: (id, active) => archive({
    id, active, type: 'projects',
  }),
  delete: (id) => request({
    call: axios.delete(`/projects/${id}`),
    errMsg: parseError('Failed to delete project'),
    successMsg: 'Project deleted successfully',
  }),
  update: (id, payload) => request({
    call: async () => {
      const realPayload = await generateProjectPayload(payload, true);
      return axios.put(`/projects/${id}`, realPayload);
    },
    errMsg: parseError('Failed to update project'),
  }),
  getEquipment: () => request({
    call: axios.get('/projects/equipment'),
    errMsg: parseError('Could not get project equipment'),
    hideSuccessToast: true,
  }),
  updateCustomer: (payload) => request({
    call: axios.put('/projects/customer', payload),
    errMsg: parseError('Failed to update projects customer'),
  }),
  getScheduleOfValuesTemplateData: (templateId) => request({
    call: axios.get(`/templates/${templateId}/projects/scheduleOfValues`),
    errMsg: parseError('Failed to get schedule of values template data'),
    hideSuccessToast: true,
  }),
  getScheduleOfValues: (projectId) => {
    const apiUrl = projectId
      ? `/projects/${projectId}/scheduleOfValues`
      : '/projects/scheduleOfValues';

    return request({
      call: axios.get(apiUrl),
      errMsg: parseError('Failed to get schedule of values'),
      hideSuccessToast: true,
    });
  },
  updateScheduleOfValues: ({
    templateId,
    projectId,
    payload,
    projectName,
    files,
  }) => request({
    call: async () => {
      const parsedFiles = await parseAndUploadFiles(files, `Projects/${projectName}`);

      const apiUrl = templateId
        ? `/templates/${templateId}/projects/scheduleOfValues`
        : `/projects/${projectId}/scheduleOfValues`;

      return axios.post(apiUrl, {
        ...payload,
        files: parsedFiles,
      });
    },
    errMsg: parseError('Failed to update schedule of values'),
  }),
  deleteScheduleOfValuesRow: ({
    templateId,
    projectId,
    rowId,
  }) => request({
    call: () => {
      const apiUrl = templateId
        ? `/templates/${templateId}/projects/scheduleOfValues/${rowId}`
        : `/projects/${projectId}/scheduleOfValues/${rowId}`;

      return axios.delete(apiUrl);
    },
    errMsg: parseError('Failed to delete schedule of values row'),
  }),
  submitScheduleOfValues: (projectId, payload, projectName, files) => request({
    call: async () => {
      const parsedFiles = await parseAndUploadFiles(files, `Projects/${projectName}`);
      return axios.post(`/projects/${projectId}/scheduleOfValues/submit`, {
        ...payload,
        files: parsedFiles,
      });
    },
    errMsg: parseError('Failed to update schedule of values'),
  }),
  swapRowsScheduleOfValues: ({
    projectId,
    templateId,
    payload,
  }) => request({
    call: () => {
      const apiUrl = templateId
        ? `/templates/${templateId}/projects/scheduleOfValues/swap`
        : `/projects/${projectId}/scheduleOfValues/swap`;
      return axios.post(apiUrl, payload);
    },
    errMsg: parseError('Failed to update schedule of values'),
  }),
  getProjectScheduleOfValuePDFRows: ({
    projectId,
    templateId,
  }) => request({
    call: () => {
      const apiUrl = templateId
        ? `/templates/${templateId}/projects/scheduleOfValues/pdfRows`
        : `/projects/${projectId}/scheduleOfValues/pdfRows`;
      return axios.get(apiUrl);
    },
    errMsg: parseError('Failed to get schedule of values PDF rows'),
    hideSuccessToast: true,
  }),
  updateProjectScheduleOfValuePDFRows: ({
    projectId,
    templateId,
    rowId,
    payload,
  }) => request({
    call: () => {
      const apiUrl = templateId
        ? `/templates/${templateId}/projects/scheduleOfValues/pdfRows/${rowId}`
        : `/projects/${projectId}/scheduleOfValues/pdfRows/${rowId}`;
      return axios.put(apiUrl, payload);
    },
    errMsg: parseError('Failed to update schedule of values PDF rows'),
  }),
  addProjectScheduleOfValuePDFRows: ({
    projectId,
    templateId,
    payload,
  }) => request({
    call: () => {
      const apiUrl = templateId
        ? `/templates/${templateId}/projects/scheduleOfValues/pdfRows`
        : `/projects/${projectId}/scheduleOfValues/pdfRows`;
      return axios.post(apiUrl, payload);
    },
    errMsg: parseError('Failed to add schedule of values PDF rows'),
  }),
  deleteProjectScheduleOfValuePDFRows: ({
    projectId,
    templateId,
    rowId,
  }) => request({
    call: () => {
      const apiUrl = templateId
        ? `/templates/${templateId}/projects/scheduleOfValues/pdfRows/${rowId}`
        : `/projects/${projectId}/scheduleOfValues/pdfRows/${rowId}`;
      return axios.delete(apiUrl);
    },
    errMsg: parseError('Failed to delete schedule of values PDF rows'),
  }),
  getAllProjectCostcodeDetails: (projectId, query) => request({
    call: axios.get(`/projects/${projectId}/costcodeDetails`, { params: query }),
    errMsg: parseError('Failed to get project costcode details'),
    hideSuccessToast: true,
  }),
  addProjectProgressHistoryFiles: (projectId, payload, projectName, files) => request({
    call: async () => {
      const parsedFiles = await parseAndUploadFiles(files, `Projects/${projectName}`);

      return axios.post(`/projects/${projectId}/progressHistory`, {
        ...payload,
        files: parsedFiles,
        device: 'web',
      });
    },
    errMsg: parseError('Failed to add files to project progress history'),
  }),
  getScheduleOfValueTemplates: () => request({
    call: axios.get('/templates/projects/scheduleOfValues'),
    errMsg: parseError('Failed to get schedule of value templates'),
    hideSuccessToast: true,
  }),
  addScheduleOfValueTemplate: (payload) => request({
    call: axios.post('/templates/projects/scheduleOfValues', payload),
    errMsg: parseError('Failed to add schedule of value template'),
    hideSuccessToast: true,
  }),
  updateScheduleOfValueTemplate: (templateId, payload) => request({
    call: axios.put(`/templates/${templateId}/projects/scheduleOfValues`, payload),
    errMsg: parseError('Failed to update schedule of value template'),
  }),
  deleteScheduleOfValueTemplate: (templateId) => request({
    call: axios.delete(`/templates/${templateId}/projects/scheduleOfValues`),
    errMsg: parseError('Failed to delete schedule of value template'),
  }),
  applyScheduleOfValueTemplate: (projectId, templateId) => request({
    call: axios.post(`/projects/${projectId}/scheduleOfValues/import/${templateId}`, { shouldReturnSections: true }),
    errMsg: parseError('Failed to apply schedule of value template'),
  }),
  getProjectFileTemplates: () => request({
    call: axios.get('templates/projects/files'),
    errMsg: parseError('Failed to get project file templates'),
    hideSuccessToast: true,
  }),
  addProjectFileTemplate: (payload) => request({
    call: axios.post('templates/projects/files', payload),
    errMsg: parseError('Failed to add project file template'),
  }),
  updateProjectFileTemplate: (templateId, payload) => request({
    call: axios.put(`templates/${templateId}/projects/files`, payload),
    errMsg: parseError('Failed to update project file template'),
  }),
  deleteProjectFileTemplate: (templateId) => request({
    call: axios.delete(`templates/${templateId}/projects/files`),
    errMsg: parseError('Failed to delete project file template'),
  }),
  getProjectGroups: () => request({
    call: axios.get('/projectGroups', { params: { shouldReturnBucketFormat: true } }),
    errMsg: parseError('Failed to get project groups'),
    hideSuccessToast: true,
  }),
  getProjectAttachments: (id) => request({
    call: axios.get(`/project/${id}/attachments`),
    errMsg: parseError('Failed to get project attachment'),
    hideSuccessToast: true,
  }),
  createProjectGroup: (payload) => request({
    call: axios.post('/projectGroups', { ...payload, isBucketFormat: true }),
    errMsg: parseError('Failed to create project group'),
  }),
  updateProjectGroup: (id, payload) => request({
    call: axios.put(`/projectGroups/${id}`, { ...payload, isBucketFormat: true }),
    errMsg: parseError('Failed to update project group'),
  }),
  deleteProjectGroup: (id) => request({
    call: axios.delete(`/projectGroups/${id}`, { params: { shouldReturnBucketFormat: true } }),
    errMsg: parseError('Failed to delete project group'),
  }),
  getProjectGroupNotes: (id) => request({
    call: axios.get(`/projectGroups/${id}/notes`),
    errMsg: parseError('Failed to get project group notes'),
    hideSuccessToast: true,
  }),
  addProjectGroupNote: (id, note) => request({
    call: axios.post(`/projectGroups/${id}/note`, { note }),
    errMsg: parseError('Failed to add project group note'),
  }),
  getProjectTypes: () => request({
    call: axios.get('/customTypes/projects'),
    errMsg: parseError('Failed to get project types'),
    hideSuccessToast: true,
  }),
  getProjectCostUpdates: (id) => request({
    call: axios.get(`/project/${id}/costUpdates`),
    errMsg: parseError('Failed to get cost updates'),
    hideSuccessToast: true,
  }),
  getScheduleOfValueSections: ({
    projectId,
    templateId,
  }) => request({
    call: () => {
      let apiUrl = '/projects/scheduleOfValues/sections';
      if (templateId) apiUrl = `/templates/${templateId}/projects/scheduleOfValues/sections`
      if (projectId) apiUrl = `/projects/${projectId}/scheduleOfValues/sections`;
      return axios.get(apiUrl);
    },
    errMsg: parseError('Failed to get schedule of values sections'),
    hideSuccessToast: true,
  }),
  createScheduleOfValueSection: ({
    projectId,
    templateId,
    payload,
  }) => request({
    call: () => {
      const apiUrl = templateId
        ? `/templates/${templateId}/projects/scheduleOfValues/sections`
        : `/projects/${projectId}/scheduleOfValues/sections`;
      return axios.post(apiUrl, payload);
    },
    errMsg: parseError('Failed to add schedule of values sections'),
  }),
  updateScheduleOfValueSection: ({
    projectId,
    templateId,
    sectionId,
    payload,
  }) => request({
    call: () => {
      const apiUrl = templateId
        ? `/templates/${templateId}/projects/scheduleOfValues/sections/${sectionId}`
        : `/projects/${projectId}/scheduleOfValues/sections/${sectionId}`;
      return axios.put(apiUrl, payload);
    },
    errMsg: parseError('Failed to update schedule of values sections'),
  }),
  deleteScheduleOfValueSection: ({
    projectId,
    templateId,
    sectionId,
  }) => request({
    call: () => {
      const apiUrl = templateId
        ? `/templates/${templateId}/projects/scheduleOfValues/sections/${sectionId}`
        : `/projects/${projectId}/scheduleOfValues/sections/${sectionId}`;
      return axios.delete(apiUrl);
    },
    errMsg: parseError('Failed to delete schedule of values sections'),
  }),
  getProgressSubContractUpdates: ({
    projectId,
    filters,
  }) => request({
    call: axios.get(`/projects/${projectId}/subcontracts/updates`, { params: filters }),
    hideSuccessToast: true,
    errMsg: parseError('Failed to get subcontract updates'),
  }),
  updateProgressSubContract: ({
    projectId,
    payload,
    projectName,
    files,
  }) => request({
    call: async () => {
      const parsedFiles = await parseAndUploadFiles(files, `Projects/${projectName}`);

      return axios.post(`/projects/${projectId}/subcontracts/updates`, {
        ...payload,
        files: parsedFiles,
      });
    },
    errMsg: parseError('Failed to update progress subcontract'),
  }),
  deleteProgressSubContractUpdate: ({
    projectId,
    rowId,
  }) => request({
    call: axios.delete(`/projects/${projectId}/subcontracts/${rowId}`),
    errMsg: parseError('Failed to delete progress subcontract row'),
  }),
  swapRowsProgressSubContract: ({
    projectId,
    payload,
  }) => request({
    call: axios.post(`projects/${projectId}/subcontracts/swap`, payload),
    errMsg: parseError('Failed to swap progress subcontract rows'),
  }),
  getCustomFieldTemplate: () => request({
    call: axios.get('/projects/customFieldTemplate'),
    errMsg: 'Failed to get project custom field template',
    hideSuccessToast: true,
  }),
  updateCustomFieldTemplate: (payload) => request({
    call: axios.post('/projects/customFieldTemplate', payload),
    errMsg: 'Failed to update project custom field template',
  }),
  getCustomData: (id) => request({
    call: axios.get(`/projects/${id}/data`),
    errMsg: 'Failed to get project custom data',
    hideSuccessToast: true,
  }),
  getNotes: (id) => request({
    call: axios.get(`/projects/${id}/notes`, { params: { getFileMap: true } }),
    errMsg: 'Could not get project notes',
    hideSuccessToast: true,
  }),
  addNote: ({
    id, text, tags, files,
  }) => request({
    call: async () => {
      const parsedFiles = await parseAndUploadFiles(files);
      const result = await axios.post(`/projects/${id}/note`, { note: text, tags, files: parsedFiles });
      return result;
    },
    errMsg: 'Could not add project note',
  }),
};

export default ProjectsService;
