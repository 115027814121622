import React from 'react';
import { Row } from 'antd';

import OnTraccrButton from '../buttons/OnTraccrButton';

export default ({
  onClose,
  onSubmit,
  onDelete,
  loading,
  canSubmit = true,
  submitTitle = 'Submit',
  submitButtonStyle = {},
  cancelButtonStyle = {},
}) => (
  <div className='drawer-footer'>
    <Row justify='end' gutter={10}>
      {onDelete &&
        <OnTraccrButton
          style={{ marginRight: 'auto' }}
          title="Delete"
          type='back'
          onClick={onDelete}
          loading={loading}
        />
      }
      {onClose && <OnTraccrButton
        title='Cancel'
        type='cancel'
        style={{ marginRight: 8, ...cancelButtonStyle }}
        onClick={onClose}
      />}
      {onSubmit && (
        <OnTraccrButton
          title={submitTitle}
          disabled={!canSubmit}
          onClick={onSubmit}
          loading={loading}
          style={submitButtonStyle}
        />
      )}
    </Row>
  </div>
);