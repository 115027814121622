import React, { useCallback } from 'react';
import { Row, Col } from 'antd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';


import MonthWeek from './MonthWeek';
import MonthVerticalLines from './MonthVerticalLines';
import { getWeekStart } from '../scheduleHelpers';

import Permissions from '../../auth/Permissions';

export default ({
  date,
  onDaySelect,
  onShiftSelect,
  onShiftEdit,
  shifts = {},
}) => {
  const monthStart = date.startOf('month');
  const monthEnd = date.endOf('month');
  const weekStart = getWeekStart(date);
  const firstWeekStart = getWeekStart(monthStart);
  const days = new Array(7).fill(1).map((_,idx) => weekStart.plus({ day: idx }));

  const dayEnd = monthEnd.ordinal;
  let dayStart = getWeekStart(monthStart).ordinal;
  if (dayStart > dayEnd) dayStart -= monthStart.startOf('week').daysInYear;
  const numWeeks = Math.ceil(( dayEnd - dayStart + 1) / 7);
  const weeks = new Array(numWeeks).fill(1);

  const handleShiftSelect = useCallback((shift) => {
    onShiftSelect(shift, Permissions.has('SCHEDULE_WRITE'));
    onShiftEdit(shift);
  },[onShiftSelect,onShiftEdit]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='schedule-month-view-container'>
        <Row className='schedule-month-header-container'>
          {
              days.map((day, idx) => (
                <Col className='schedule-week-day-column' key={idx}>
                  <div className='schedule-month-day-header'>
                    {day.toLocaleString({ weekday: 'short' })}
                  </div>
                </Col>
              ))
          }
        </Row>
        {
          weeks.map((_,idx) => (
            <MonthWeek
              showBorder={idx < numWeeks - 1}
              weekStart={firstWeekStart.plus({ week: idx })}
              monthStart={monthStart}
              monthEnd={monthEnd}
              onDaySelect={onDaySelect}
              shifts={shifts}
              onShiftSelect={handleShiftSelect}
            />
          ))
        }
        <MonthVerticalLines/>
      </div>
    </DndProvider>
  );
}