export default {};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'hh:mm A';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm';

export const DEFAULT_FIELD_TYPES = [
  'user',
  'date',
  'type',
  'division',
  'project',
  'phase',
  'costcode',
  'local',
  'class',
  'sageShift',
  'hourBased',
  'time',
  'note',
  'status',
  'enteredVia',
];

export const EDITABLE_DEFAULT_FIELD_TYPES = [
  'date',
  'type',
  'project',
  'phase',
  'costcode',
  'hourBased',
  'time',
  'note',
];

export const EDITABLE_CUSTOM_FIELD_TYPES = new Set([
  'text',
  'yes-no',
  'calculation',
  'dropdown',
  'dateRange',
  'dateTime',
  'attribute',
]);
