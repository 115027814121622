import { useRef, useEffect } from 'react';

const sortByString = (key = 'id') => (a, b) => {
  const aVal = (a?.[key] || '~')?.toLowerCase()?.trim();
  const bVal = (b?.[key] || '~')?.toLowerCase()?.trim();
  if (aVal < bVal) return -1;
  if (aVal > bVal) return 1;
  return 0;
};

export const sortByCode = (key = 'code') => (a, b) => {
  let aCode = a[key] ?? '';
  let bCode = b[key] ?? '';
  aCode = typeof aCode === 'string' ? aCode : aCode.toString();
  bCode = typeof bCode === 'string' ? bCode : bCode.toString();
  return aCode.localeCompare(bCode, undefined, { numeric: false, sensitivity: 'variant', caseFirst: 'upper' });
};

export const sortByNumber = (key) => (a, b) => parseInt(b[key]) - parseInt(a[key]);

export default sortByString;

export const uuid = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
  .replace(/[018]/g, (c) => (
    // eslint-disable-next-line no-bitwise, no-mixed-operators
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  ));

export const search = ({
  data,
  value,
  getField = (item) => item,
  customSearchRanking,
  searchTieBreaker = (searchTerm, a, b) => sortByString('name')(a, b),
}) => {
  if (!value) return data;
  const searchTerm = value.toLowerCase();
  const sortVals = data.map((item) => ({
    index: customSearchRanking
      ? customSearchRanking(item, searchTerm)
      : getField(item).toLowerCase().indexOf(searchTerm),
    item,
  }));

  return sortVals
    .filter(({ index }) => index >= 0)
    .sort((a, b) => {
      if (a.index !== b.index) return a.index - b.index;
      return searchTieBreaker(searchTerm, a.item, b.item);
    })
    .map(({ item }) => item);
};

export const updateState = (arr, item) => {
  const data = JSON.parse(JSON.stringify(arr));
  const myItems = data.filter((oldItems) => oldItems.id === item.id);
  if (myItems.length === 0) return arr;
  const myItem = myItems[0];

  Object.keys(item).forEach((key) => {
    myItem[key] = item[key];
  });
  return data;
};

export const getIdMap = (arr = [], key = 'id') => {
  const map = {};
  arr.forEach((item) => {
    if (!item) return;
    map[item[key]] = item;
  });
  return map;
};
export const getId = (teamMember) => teamMember.id;
export const getNames = (arr) => arr.map((a = {}) => a.name);
export const getUnique = (arr, { key = 'name', valueExtractor } = {}) => (
  Array.from(new Set(arr.map(
    (item) => (valueExtractor ? valueExtractor(item) : item[key]),
  )))
);

export const compareArrays = (oldArr = [], newArr = [], key = 'id') => {
  const oldMap = getIdMap(oldArr, key);
  const newMap = getIdMap(newArr, key);
  return {
    added: newArr.filter((item) => !(item[key] in oldMap)),
    removed: oldArr.filter((item) => !(item[key] in newMap)),
  };
};

export const compareStringArrays = (oldArr = [], newArr = []) => {
  const newSet = new Set(newArr);
  const oldSet = new Set(oldArr);
  return {
    added: newArr.filter((id) => !oldSet.has(id)),
    removed: oldArr.filter((id) => !newSet.has(id)),
  };
};

export const propArraysUpdated = (arr1 = [], arr2 = []) => (arr1 !== arr2
    && !(arr1.length === 0 && arr2.length === 0));

export const useInterval = (callback, delay = null) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  // eslint-disable-next-line
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const getPathEnd = (location = {}) => {
  const {
    pathname = '',
  } = location;
  const splitPath = pathname.split('/');
  return splitPath.pop();
};

export const toTitleCase = (s) => (
  s
    ? s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()
    : s
);

export const convertUpperCamelCaseToText = (str) => (
  str.toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
);

/**
 * Example: 'subContracts' would return 'Sub Contracts'
 * @param {string} str
 * @returns {str}
 */
export const convertLowerCamelCaseToText = (str) => {
  const result = str.replace(/([A-Z])/g, ' $1').replace(/^./, (char) => char.toUpperCase());
  return result;
};

export const getHighestNumber = (data = [], key = 'number') => (
  data.reduce((acc, object) => {
    const {
      [key]: number = 0,
    } = object;
    if (typeof number === 'string') {
      const int = parseInt(Number(number), 10);
      if (!int) return acc;
      return Math.max(int, acc);
    }

    if (Number.isInteger(number)) {
      return Math.max(number, acc);
    }
    return acc;
  }, 0)
);

export const mergeSets = (setList) => {
  const set = new Set();
  setList.forEach((setItem) => {
    Array.from(setItem).forEach((item) => {
      if (item) set.add(item);
    });
  });
  return set;
};

export const isNullOrUndefined = (value) => (
  value === null || value === undefined
);

export const percentageFormatter = (val) => `${val * 100} %`;

export const currencyFormatter = (v) => {
  let ourValue = v ?? 0;
  if (typeof ourValue === 'number') ourValue = ourValue.toFixed(2);
  return `$ ${(ourValue)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const areStringsEqual = (str1, str2) => {
  if (typeof str1 !== 'string' || typeof str2 !== 'string') return false;
  return str1?.toLowerCase() === str2?.toLowerCase();
};

export const includesTerm = (str = '$', term = '') => {
  if (typeof str !== 'string' || typeof term !== 'string') return false;
  return str.toLowerCase().includes(term.toLowerCase());
};

/**
 * Returns true if array contains term, false otherwise
 * @param {string[]} array
 * @param {string} term
 * @returns
 */
export const arrayIncludesTerm = (array = [], term = '') => {
  if (!Array.isArray(array) || typeof term !== 'string') return false;
  return array.some((value = '') => includesTerm(value, term));
};

export const generateRandomString = (isPassword) => {
  let chars = [];
  let counter = 0;
  let hasUpper = false;
  let hasLower = false;
  let hasNumber = false;
  let hasSymbol = false;
  while (counter < 10) {
    counter += 1;
    chars = [];
    hasUpper = false;
    hasLower = false;
    hasNumber = false;
    hasSymbol = false;
    const lengthArr = new Uint8Array(512);
    window.crypto.getRandomValues(lengthArr);
    // eslint complains about the forEach loop in the while
    // eslint-disable-next-line no-loop-func
    lengthArr.forEach((code) => {
      if (chars.length === 20) return;
      if (code >= 33 && code <= 38 && code !== 34) {
        // Symbol
        chars.push(String.fromCharCode(code));
        hasSymbol = true;
      } else if (code >= 48 && code <= 57) {
        // Number
        chars.push(String.fromCharCode(code));
        hasNumber = true;
      } else if (code >= 65 && code <= 90) {
        // Upper Case
        chars.push(String.fromCharCode(code));
        hasUpper = true;
      } else if (code >= 97 && code <= 122) {
        // Lower case
        chars.push(String.fromCharCode(code));
        hasLower = true;
      }
    });
    if (
      chars.length >= 20
        && (!isPassword || (hasUpper && hasLower && hasNumber && hasSymbol))
    ) break;
  }
  return chars.join('');
};

export const removeDuplicateArrayValues = (arr) => {
  const duplicateRemovedSet = new Set(arr);
  return Array.from(duplicateRemovedSet);
};

export const getProperty = (object, property, defaultValue) => {
  const value = object[property];

  if (!isNullOrUndefined(value)) {
    return value;
  }

  return defaultValue;
};

// antd popconfirm api doesn't support a direct ref
// so a hacky way of getting the ref
export const getPopupRef = (ref) => {
  if (ref && ref.current && ref.current.popupRef && ref.current.popupRef.current) {
    return ref.current.popupRef.current.popupRef;
  }

  return null;
};

/**
 * This function is used to validate fields that are dependent on other fields
 * @param {Object} changedValues - The values that have changed
 * @param {Array} properties - The properties when updated to run handler
 * @param {Function} handler - The handler to call to validate the fields
 */
export const validateFieldsOnFormUpdate = (changedValues, properties, handler) => {
  if (properties.some((property) => changedValues[property])) {
    handler();
  }
};

/**
 * Gets the relation set for an array
 *
 * @param {string} groupBy - the key that we group by (e.g. projectId)
 * @param {string} groupVal - the value that we collect
 * @param {array} arr
 * @returns {Set}
 */
export const getRelationSet = ({ groupBy, groupVal, arr }) => {
  const relations = {};
  arr.forEach(({ [groupBy]: key, [groupVal]: val }) => {
    if (key && !(key in relations)) {
      relations[key] = new Set();
    }
    if (key && val) {
      relations[key].add(val);
    }
  });
  return relations;
};

// Google charts loads a font from googleapis.com that causes slow rendering of the charts
// https://stackoverflow.com/questions/25523806/google-maps-v3-prevent-api-from-loading-roboto-font
export const optimizeGoogleChartsLoading = () => {
  // Preventing the Google Maps libary from downloading an extra font
  const head = document.getElementsByTagName('head')[0];

  // Save the original method
  const { insertBefore } = head;

  // Replace it!
  head.insertBefore = (newElement, referenceElement) => {
    if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') > -1) {
      return;
    }

    insertBefore.call(head, newElement, referenceElement);
  };
};

export const capitalizeFirstCharacter = (str) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const compareToFirstPasswordHelper = (formRef, passwordFieldName = 'password') => (_rule, value, callback) => {
  if (value && value !== formRef.current.getFieldValue(passwordFieldName)) {
    callback('Passwords must match');
  } else {
    callback();
  }
};

/**
 * Returns true if val is an object, false otherwise
 * @param {any} val
 * @returns {boolean}
 */
export const isObject = (val) => typeof val === 'object' && val !== null;

export const validatePasswordFormatHelper = (
  formRef,
  confirmPasswordFieldName = 'confirmPassword',
  allowEmpty = false,
) => (_rule, value, callback) => {
  if (!value || (!value.length && allowEmpty)) return callback();
  if (value.length < 8) return callback('Password must be 8 or more characters');

  const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
  if (!(regex.test(value))) {
    return callback('Password must contain one uppercase character, one lowercase character and one number');
  }
  formRef.current.validateFields([confirmPasswordFieldName]);
  return callback();
};

/**
 * Generates random id of length 9
 * @returns {number} random id
 */
export const generateRandomId = () => {
  const { crypto } = window;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
};

/**
 * Returns cropped name
 * @param {string} name
 * @param {number} length
 * @returns {string} name cropped to certain length
 */
export const cropName = (name = '', length = 0) => (name.length > length ? `${name.slice(0, length - 3)}...` : name);

const editDistance = (s1, s2) => {
  const costs = [];
  for (let i = 0; i <= s1.length; i += 1) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j += 1) {
      if (i === 0) {
        costs[j] = j;
      } else if (j > 0) {
        let newValue = costs[j - 1];
        if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
          newValue = Math.min(
            Math.min(newValue, lastValue),
            costs[j],
          ) + 1;
        }
        costs[j - 1] = lastValue;
        lastValue = newValue;
      }
    }
    if (i > 0) {
      costs[s2.length] = lastValue;
    }
  }
  return costs[s2.length];
};

/**
 * Calculates the similarity between two strings from 0 to 1
 * @param {*} s1 first string to compare
 * @param {*} s2  second string to compare
 * @returns {Float} returns the similarity between two strings using the levenshtein distance: https://en.wikipedia.org/wiki/Levenshtein_distance
 */
export const calculateStringSimilarity = (s1, s2) => {
  if (typeof s1 !== 'string' || typeof s2 !== 'string') {
    return 0;
  }

  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (
    longerLength - editDistance(longer.toLowerCase(), shorter.toLowerCase())
  ) / parseFloat(longerLength);
};

export const doesNumberExist = (num) => num || num === 0;
