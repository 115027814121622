import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Form,
  Table,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_PROFILE_NAMES, keyMap } from '../../translations/profileNames';
import CompanySettingsCard from '../CompanySettingsCard';
import useToggle from '../../common/hooks/useToggle';
import SimpleFormModal from '../../common/modals/SimpleFormModal';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import Colors from '../../constants/Colors';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import { getIdMap } from '../../helpers/helpers';
import { updateCompanyTranslations } from '../state/settings.actions';

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

export default function ProfileNames() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const translations = useSelector((state) => state.settings.translations);
  const { isToggled, toggle } = useToggle();
  const [selectedEntry, setSelectedEntry] = useState();

  useEffect(() => {
    if (!isToggled) {
      return form.resetFields();
    }

    return form.setFieldsValue(selectedEntry);
  }, [isToggled, form, selectedEntry]);

  const columns = useMemo(() => [
    {
      title: 'Original Name',
      dataIndex: 'label',
      key: 'label',
      onHeaderCell,
    },
    {
      title: 'Current Name',
      dataIndex: 'key',
      key: 'key',
      onHeaderCell,
      render: (key) => t(key),
    },
    {
      dataIndex: 'edit',
      align: 'edit',
      width: 60,
      onHeaderCell,
      render: (_, record) => {
        const { id } = record || {};
        return (
          <BorderlessButton
            key={id}
            title=""
            icon="edit"
            color={Colors.ONTRACCR_BLACK}
            onClick={() => {
              const name = t(record.key);
              setSelectedEntry({
                ...record,
                name,
              });
              toggle();
            }}
            style={{
              paddingRight: 8,
              paddingLeft: 0,
            }}
          />
        );
      },
    },
  ], [toggle]);

  const closeModal = useCallback(() => {
    setSelectedEntry();
    toggle();
  }, [toggle]);

  const onSave = useCallback(async () => {
    const { name } = form.getFieldsValue();
    const keysToUpdate = keyMap[selectedEntry.key];

    const translationKeyMap = getIdMap(translations, 'key');
    const payload = keysToUpdate.map((key) => ({
      ...(translationKeyMap[key] ?? {}),
      key,
      value: name,
      language: 'en',
    }));

    const result = await dispatch(updateCompanyTranslations(payload));
    if (result) {
      notification.warn({
        message: 'Translations Updated',
        description: 'Translations will be updated after refreshing the page',
      });
      closeModal();
    }
  }, [closeModal, selectedEntry, translations]);

  return (
    <>
      <CompanySettingsCard
        title="Profile Names"
      >
        <Table
          columns={columns}
          dataSource={DEFAULT_PROFILE_NAMES}
          pagination={false}
          size="small"
          scroll={{ y: 'calc(100vh - 220px', x: 'hidden' }}
        />
      </CompanySettingsCard>
      <SimpleFormModal
        title="Update Profile Name"
        visible={isToggled}
        onSave={onSave}
        onClose={closeModal}
      >
        <div style={{ width: '100%' }}>
          <Form form={form}>
            <Form.Item name="name" label="Name">
              <OnTraccrTextInput placeholder="Enter Name" />
            </Form.Item>
          </Form>
        </div>
      </SimpleFormModal>
    </>
  );
}
