import React, { useCallback, useMemo, useState } from 'react';
import { List } from 'antd';
import { ImportOutlined, PlusOutlined } from '@ant-design/icons';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import WarningHeader from '../common/text/WarningHeader';
import CustomTableListItem from '../settings/Forms/CustomTableListItem';
import BoardWorkflowSubtasksDrawer from './BoardWorkflowSubtasksDrawer';
import SubtaskTemplateImportDrawer from '../subtasks/SubtaskTemplateImportDrawer';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useSelector } from 'react-redux';

const deleteModal = (onOk) => CustomConfirmModal({
  title: (
    <WarningHeader
      justify="center"
      title="Are you sure you want to delete this task?"
    />
  ),
  onOk,
});

function BoardWorkflowSubtasks({
  value = [],
  onChange,
  cardTypeId,
}) {
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showImportDrawer, setShowImportDrawer] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState();

  const clearValues = useCallback(() => {
    setSelectedTaskId();
    setShowDrawer(false);
    setShowImportDrawer(false);
  }, []);

  const onAddClicked = useCallback(() => {
    setShowDrawer(true);
  }, []);

  const onDelete = useCallback(
    (id) => deleteModal(() => {
      let newData = value || [];
      newData = newData.filter((template) => template.id !== id);
      onChange(newData);
    }),
    [
      value,
      onChange,
    ],
  );

  const onEdit = useCallback((id) => {
    setSelectedTaskId(id);
    setShowDrawer(true);
  }, []);

  const onSubmit = useCallback((task) => {
    if (!task) return;
    const newTask = { ...task };
    if (!newTask.id) newTask.id = DateTime.local().toMillis();
    if (task.dueDate && moment.isMoment(task.dueDate)) {
      newTask.dueDate = task.dueDate.startOf('day').valueOf();
    }

    if (task.repeatEndDate && moment.isMoment(task.repeatEndDate)) {
      newTask.repeatEndDate = task.repeatEndDate.startOf('day').valueOf();
    }

    if (task.reminders && newTask.dueDate) {
      newTask.reminders = task.reminders.map((reminder) => ({
        time: reminder.time,
        amount: reminder.amount,
      }));
    } else {
      delete newTask.reminders;
    }

    let newTasks = value || [];
    if (selectedTaskId) {
      newTasks = newTasks.map((t) => (t.id === selectedTaskId ? newTask : t));
    } else {
      newTasks.push(newTask);
    }
    onChange(newTasks);
    clearValues();
  }, [
    value,
    onChange,
    selectedTaskId,
    clearValues,
  ]);

  const renderItem = useCallback((item) => (
    <CustomTableListItem
      onDelete={onDelete}
      onEdit={onEdit}
      item={{
        id: item.id,
        name: item.title,
        description: item.description,
      }}
    />
  ), [onDelete, onEdit]);

  const onShowImportClicked = useCallback(() => setShowImportDrawer(true), []);

  const {
    [cardTypeId]: {
      fields: sections = [],
    } = {},
  } = cardTemplates;

  // Form Fields that we currently extract date data from
  const relativeOptions = useMemo(() => {
    const options = [
      { value: 'creation', label: 'Creation Date' },
      { value: 'dueDate', label: 'Due date' },
    ];
    sections.forEach(({ fields = [] }) => {
      fields.forEach((field) => {
        const {
          id: fieldId,
          selectedType,
          configProps: {
            title,
          } = {},
        } = field;
        if (selectedType === 'dateTime' || selectedType === 'dateRange') {
          options.push({ value: fieldId, label: title, type: selectedType });
        }
      });
    });
    return options;
  }, [sections]);

  return (
    <>
      <OnTraccrButton
        title="Add"
        icon={<PlusOutlined />}
        onClick={onAddClicked}
      />
      <OnTraccrButton
        title="Import from Template"
        icon={<ImportOutlined />}
        onClick={onShowImportClicked}
        style={{ marginLeft: 15 }}
      />
      <List
        style={{ marginLeft: 15 }}
        dataSource={value}
        renderItem={renderItem}
      />
      <BoardWorkflowSubtasksDrawer
        visible={showDrawer}
        onClose={clearValues}
        triggerTasks={value}
        selectedTaskId={selectedTaskId}
        onSubmit={onSubmit}
        relativeOptions={relativeOptions}
      />
      <SubtaskTemplateImportDrawer
        visible={showImportDrawer}
        onClose={clearValues}
        onSubmit={onSubmit}
        relativeOptions={relativeOptions}
        isTemplate
        isTriggeredTask
      />
    </>
  );
}

export default BoardWorkflowSubtasks;
