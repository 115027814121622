import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

// Import Components:
import InvoiceAddDrawer from './InvoiceAddDrawer';
import FormPoInvoiceHeader from './InvoiceTabHeader';
import getInvoiceColumns from './FormInvoiceColumns';

// Import Actions:
import { getInvoices } from './state/invoices.actions';
import { getLabels } from '../../labels/state/labels.actions';
import { getVendorLabels, getVendors } from '../../contacts/vendors/state/vendors.actions';
import { getCustomerLabels, getCustomers } from '../../contacts/customers/state/customers.actions';
import { getProjects } from '../../projects/state/projects.actions';
import { getForms } from '../../forms/state/forms.actions';
import { getAllCostCodes } from '../../costcodes/state/costcodes.actions';
import { getCompanyCustomizationTabs } from '../../settings/state/settings.actions';
import { getFileStructure } from '../../files/state/files.actions';

// Import Helpers:
import { getIdMap, includesTerm, isNullOrUndefined } from '../../helpers/helpers';
import { INVOICE_DRAWER_ADD_MODE, INVOICE_DRAWER_VIEW_MODE } from './invoiceConstants';

const MAX_FILTER_LENGTH = 100;

const prepareInvoiceQuery = ({ formId, useRange, range }) => {
  const query = {};
  if (formId) query.formId = formId;
  if (useRange && Array.isArray(range) && range.length === 2) {
    const [
      start = DateTime.local().minus({ days: 7 }).toMillis(),
      end = DateTime.local().toMillis(),
    ] = range;
    query.start = start;
    query.end = end;
  }
  return query;
};

/** Form tab listing all invoices */
function FormInvoices({
  formId,
  visible,
  useSummary,
  useRange,
  rowSize = 'small',
  height = 'calc(100vh - 380px)',
  formType,
  isClosed,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const invoices = useSelector((state) => state.invoices.invoices);
  const statuses = useSelector((state) => state.invoices.statuses);
  const vendors = useSelector((state) => state.vendors.vendors);
  const customerMap = useSelector((state) => state.customers.customers);
  const projects = useSelector((state) => state.projects.projects);
  const forms = useSelector((state) => state.forms.forms);

  const [searchInput, setSearchInput] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [invDrawerVisible, setInvDrawerVisible] = useState(false);
  const [drawerMode, setDrawerMode] = useState(INVOICE_DRAWER_ADD_MODE);
  const [range, setRange] = useState([
    DateTime.local().minus({ days: 7 }).toMillis(),
    DateTime.local().toMillis(),
  ]);

  const onSearchInputChange = useCallback((val) => setSearchInput(val), []);
  const updateDrawerMode = useCallback((mode) => setDrawerMode(mode), []);
  const closeInvDrawer = useCallback(() => setInvDrawerVisible(false), []);
  const openInvDrawer = useCallback((mode) => {
    setDrawerMode(mode);
    setInvDrawerVisible(true);
  }, []);
  const onRowActionHandler = useCallback((inv) => ({
    onClick: () => {
      setSelectedInvoice(inv);
      openInvDrawer(INVOICE_DRAWER_VIEW_MODE);
    },
  }), []);

  const associatedFormIds = useMemo(() => Array.from(new Set(Object.values(invoices)
    .map(({ formId }) => formId)
    .filter((formId) => !isNullOrUndefined(formId)))), [invoices]);
  const statusMap = useMemo(() => getIdMap(statuses), [statuses]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const {
    invoiceList,
    projectFilters,
    vendorFilters,
    customerFilters,
    statusFilters,
  } = useMemo(() => {
    const vendorFilterSet = new Set();
    const customerFilterSet = new Set();
    const projectFilterSet = new Set();
    const statusFilterSet = new Set();
    const vendorFilters = [];
    const customerFilters = [];
    const projectFilters = [];
    const statusFilters = [];
    const list = invoices.map((invoice) => {
      // Extract invoice details:
      const { formId, vendorId, statusId } = invoice || {};
      const { name: vendorName } = vendors[vendorId] || {};
      const { title: statusName } = statusMap[statusId] || {};
      const { projectId } = forms[formId] || {};
      const { name: projectName, customerId } = projectMap[projectId] || {};
      const { name: customerName } = customerMap[customerId] || {};

      // Add Filters:
      if (vendorId && !vendorFilterSet.has(vendorId) && vendorFilterSet.size < MAX_FILTER_LENGTH) {
        vendorFilters.push({ text: vendorName, value: vendorId });
        vendorFilterSet.add(vendorId);
      }
      if (customerId
        && !customerFilterSet.has(customerId) && customerFilterSet.size < MAX_FILTER_LENGTH) {
        customerFilters.push({ text: customerName, value: customerId });
        customerFilterSet.add(customerId);
      }
      if (projectId
        && !projectFilterSet.has(projectId) && projectFilterSet.size < MAX_FILTER_LENGTH) {
        projectFilters.push({ text: projectName, value: projectId });
        projectFilterSet.add(projectId);
      }
      if (statusId && !statusFilterSet.has(statusId) && statusFilterSet.size < MAX_FILTER_LENGTH) {
        statusFilters.push({ text: statusName, value: statusId });
        statusFilterSet.add(statusId);
      }
      return {
        ...invoice,
        vendor: vendorName,
        status: statusName,
        project: projectName,
        customer: customerName,
      };
    })
      .filter(({
        invoiceNumber, amount, status, vendor, description,
      }) => (
        !searchInput
      || (invoiceNumber && includesTerm(invoiceNumber, searchInput))
      || (amount && includesTerm(amount.toString(), searchInput))
      || (status && includesTerm(status, searchInput))
      || (vendor && includesTerm(vendor, searchInput))
      || (description && includesTerm(description, searchInput))
      ));
    return {
      invoiceList: list,
      projectFilters,
      customerFilters,
      vendorFilters,
      statusFilters,
    };
  }, [invoices, statusMap, customerMap, projectMap, vendors, forms, searchInput]);

  const invoiceColumns = useMemo(() => getInvoiceColumns({
    projectFilters,
    vendorFilters,
    customerFilters,
    statusFilters,
    t,
  }), [projectFilters, vendorFilters, customerFilters, statusFilters]);

  useEffect(() => {
    if (!visible) {
      setSearchInput('');
      setSelectedInvoice({});
      setInvDrawerVisible(false);
      setDrawerMode(INVOICE_DRAWER_ADD_MODE);
    }
  }, [dispatch, visible]);

  useEffect(() => {
    if (visible) {
      const invoiceQuery = prepareInvoiceQuery({ formId, useRange, range });
      dispatch(getInvoices(invoiceQuery));
    }
  }, [visible, formId, useRange, range]);

  useEffect(() => {
    if (visible) {
      dispatch(getForms({ type: formType, formIds: associatedFormIds }));
    }
  }, [visible, formType, associatedFormIds]);

  useEffect(() => {
    if (visible) {
      dispatch(getFileStructure());
      dispatch(getProjects());
      dispatch(getLabels());
      dispatch(getCustomers());
      dispatch(getCustomerLabels());
      dispatch(getVendors());
      dispatch(getVendorLabels());
      dispatch(getAllCostCodes());
      dispatch(getCompanyCustomizationTabs());
    }
  }, [visible]);

  useEffect(() => {
    if (!invDrawerVisible) {
      setSelectedInvoice({});
    }
  }, [invDrawerVisible]);

  return (
    <>
      <FormPoInvoiceHeader
        invoices={invoices}
        searchInput={searchInput}
        useSummary={useSummary}
        useRange={useRange}
        onRangeChange={setRange}
        onSearchInputChange={onSearchInputChange}
        onAddClick={openInvDrawer}
        isClosed={isClosed}
      />
      <Table
        size={rowSize}
        columns={invoiceColumns}
        dataSource={invoiceList}
        className="invoices-table"
        rowClassName="invoices-table-row"
        onRow={onRowActionHandler}
        pagination={false}
        scroll={{
          x: 'max-content',
          y: height,
        }}
        style={{
          width: '100%',
          height: 'auto',
          borderRadius: 8,
          marginTop: 10,
          marginBottom: 2,
        }}
      />
      <InvoiceAddDrawer
        formId={formId}
        invoice={selectedInvoice}
        visible={invDrawerVisible}
        mode={drawerMode}
        useRange={useRange}
        range={range}
        updateMode={updateDrawerMode}
        closeDrawer={closeInvDrawer}
      />
    </>
  );
}

FormInvoices.propTypes = {
  formId: PropTypes.string,
  visible: PropTypes.bool,
  useSummary: PropTypes.bool,
  useRange: PropTypes.bool,
  rowSize: PropTypes.string,
  height: PropTypes.string,
  formType: PropTypes.string,
  isClosed: PropTypes.bool,
};

FormInvoices.defaultProps = {
  formId: null,
  visible: false,
  useSummary: false,
  useRange: false,
  rowSize: 'small',
  height: 'calc(100vh - 380px)',
  formType: 'PO',
  isClosed: false,
};

export default FormInvoices;
