import WorkingHours from './WorkingHours';

export default {};

export const defaultRoles = new Set(['Admin', 'Manager', 'Supervisor', 'Worker']);

export const reports = [
  'Employee Payroll',
  'Staff Timesheets',
  'Service Jobs',
  'Project Status',
  'Timeline',
  'Cost Code Billing',
];
export const getPermissionRows = (t) => [{
  key: 'projects',
  title: `Manage ${t('Project', { count: 2 })}`,
  helpText: `This role can view, add, edit and archive ${t('Project', { count: 2 }).toLowerCase()}`,
}, {
  key: 'customers_read',
  title: `View ${t('Customer', { count: 2 })}`,
  helpText: `This role can view ${t('Customer', { count: 2 }).toLowerCase()}`,
  appendKey: false,
}, {
  key: 'customers_write',
  title: `Manage ${t('Customer', { count: 2 })}`,
  helpText: `This role can view, add, edit and archive ${t('Customer', { count: 2 }).toLowerCase()}`,
  appendKey: false,
}, {
  key: 'vendors_read',
  title: 'View Vendors',
  helpText: 'This role can view vendors',
  appendKey: false,
}, {
  key: 'vendors_write',
  title: 'Manage Vendors',
  helpText: 'This role can view, add, edit and archive Vendors',
  appendKey: false,
}, {
  key: 'costcodes',
  title: 'Manage Cost Codes',
  helpText: 'This role can view, add, edit and archive cost codes',
}, {
  key: 'equipment_read',
  title: 'View Equipment',
  helpText: 'This role can view equipment',
  appendKey: false,
}, {
  key: 'equipment_write',
  title: 'Manage Equipment',
  helpText: 'This role can view, add, edit and archive equipment',
  appendKey: false,
}, {
  key: 'materials_read',
  title: 'View Materials',
  helpText: 'This role can view materials',
  appendKey: false,
}, {
  key: 'materials_write',
  title: 'Manage Materials',
  helpText: 'This role can view, add, edit and archive materials',
  appendKey: false,
}, {
  key: 'settings',
  title: 'Manage Settings',
  helpText: 'This role can view and configure the company\'s Settings page, including time tracking settings and role permissions',
}, {
  key: 'SETTINGS_CUSTOMIZATIONS_WRITE',
  title: 'Manage Customizations',
  helpText: 'This role can view and configure the company\'s Customizations page',
  appendKey: false,
}, {
  key: 'teams',
  title: 'Manage Teams',
  helpText: 'This role can view, add, edit and archive teams. This permission is required for all timecard approvers.',
}, {
  key: 'schedule_read',
  title: 'View Own Schedule',
  helpText: 'This role can view their own schedule',
  appendKey: false,
}, {
  key: 'schedule_write',
  title: 'Manage Schedule',
  helpText: 'This role can add, edit and update schedule shifts. This role can also view all other user\'s schedules',
  appendKey: false,
}, {
  key: 'NOTIFICATIONS_WRITE',
  title: 'Manage Notification Preferences',
  helpText: 'This role can manage notification preferences.',
  appendKey: false,
}, {
  key: 'BOARDS_READ',
  title: 'View Own Boards',
  helpText: 'This role can view boards with cards they are assigned to',
  appendKey: false,
}, {
  key: 'BOARDS_WRITE',
  title: 'Manage Boards',
  helpText: 'This role can add, edit and update boards and board cards. This role can also view all other user\'s board cards',
  appendKey: false,
}, {
  key: 'files_read',
  title: 'View Files',
  helpText: 'This role can view the files page.',
  appendKey: false,
}, {
  key: 'files_write',
  title: 'Manage Files',
  helpText: 'This role can add, edit and delete files.',
  appendKey: false,
}, {
  key: 'forms_read',
  title: 'View Forms',
  helpText: 'This role can view the forms page.',
  appendKey: false,
}, {
  key: 'forms_read_logs',
  title: 'View Log in Forms',
  helpText: 'This role can view logs in forms',
  appendKey: false,
}, {
  key: 'forms_write',
  title: 'Manage Forms',
  helpText: 'This role can add, edit and delete forms.',
  appendKey: false,
}, {
  key: 'reports_work_done_view',
  title: 'View Material Cost Code Progress',
  helpText: 'This role is able to view progress of tasks and Cost Codes',
  appendKey: false,
}, {
  key: 'reports_work_done_edit',
  title: 'Edit Material Cost Code Progress',
  helpText: 'This role is able to edit progress of tasks and Cost Codes',
  appendKey: false,
}, {
  key: 'PROJECT_SCHEDULEOFVALUES_READ',
  title: 'View Schedule of Values',
  helpText: `This role can view the contracts tab for a ${t('Project').toLowerCase()}.`,
  appendKey: false,
}, {
  key: 'PROJECT_SCHEDULEOFVALUES_WRITE',
  title: 'Edit Schedule of Values',
  helpText: `This role can edit the contracts tab for a ${t('Project').toLowerCase()}.`,
  appendKey: false,
}, {
  key: 'PROJECT_GANTT_SCHEDULE_READ',
  title: `View ${t('Project')} Gantt Schedule`,
  helpText: `This role can view the ${t('Project').toLowerCase()} gantt schedule page.`,
  appendKey: false,
}, {
  key: 'PROJECT_GANTT_SCHEDULE_WRITE',
  title: `Edit ${t('Project')} Gantt Schedule`,
  helpText: `This role can edit the ${t('Project').toLowerCase()} gantt schedule page.`,
  appendKey: false,
}, {
  key: 'PROJECT_PROGRESS_READ',
  title: 'View Progress',
  helpText: `This role can view the progress tab for a ${t('Project').toLowerCase()}.`,
  appendKey: false,
}, {
  key: 'PROJECT_PROGRESS_WRITE',
  title: 'Edit Progress',
  helpText: `This role can edit the progress tab for a ${t('Project').toLowerCase()}.`,
  appendKey: false,
}, {
  key: 'users_view',
  title: 'View Users',
  helpText: 'This role can view following role types:',
  multi: true,
}, {
  key: 'users',
  title: 'Manage Users',
  helpText: 'This role can view, add, edit and archive the following role types:',
  multi: true,
}, {
  key: 'INVOICES_WRITE',
  title: 'Edit Invoices',
  helpText: 'This role can edit invoices',
  appendKey: false,
}, {
  key: 'INVOICES_READ',
  title: 'View Invoices',
  helpText: 'This role can view invoices',
  appendKey: false,
}, {
  key: 'PURCHASE_ORDERS_WRITE',
  title: 'Edit Purchase Orders',
  helpText: 'This role can edit purchase orders',
  appendKey: false,
}, {
  key: 'PURCHASE_ORDERS_READ',
  title: 'View Purchase Orders',
  helpText: 'This role can view purchase orders',
  appendKey: false,
}, {
  key: 'CHANNELS_READ',
  title: 'View & Send Messages in Custom Channels',
  helpText: 'This role can view and send message in custom channels',
  appendKey: false,
}, {
  key: 'CHANNELS_WRITE',
  title: 'Manage Custom Channels',
  helpText: 'This role can create, modify & delete custom channels',
  appendKey: false,
}, {
  key: 'POSTS_EDIT',
  title: 'Delete Posts',
  helpText: 'This role can delete posts made in the live-feed',
  appendKey: false,
}, {
  key: 'translations_write',
  title: 'Manage Profile Names',
  helpText: 'This role can view and edit profile names',
  appendKey: false,
}, {
  key: 'RESTRICT_PROJECT_SELECTION',
  title: `Restrict ${t('Project')} Selection`,
  helpText: `This role can only clock in or create manual entries for ${t('Project', { count: 2 }).toLowerCase()} they are directly assigned to`,
  appendKey: false,
}, {
  key: 'users_wage',
  title: 'Manage User Wage',
  helpText: 'This role view and edit wage information for the following role types:',
  multi: true,
}, {
  key: 'working_hours',
  title: 'Working Hours',
  helpText: 'Set the working hours for this role',
  CustomView: WorkingHours,
}, {
  key: 'time_tracking',
  title: 'Time Tracking',
  helpText: 'This role can perform all time tracking functions, including clock in/out, take/end break, and switching tasks for the following selected role types:',
  multi: true,
}, {
  key: 'manual_entry',
  title: 'Manual Entry',
  helpText: 'This role can manually add time entries for the following selected role types:',
  multi: true,
}, {
  key: 'AUTOMATIC_BREAKS',
  title: 'Automatic Breaks',
  helpText: 'This role will have breaks automatically applied per the configured automatic break settings',
  appendKey: false,
}, {
  key: 'AUTO_BREAK_EXCLUDE',
  title: 'Create Tasks Excluded From Automatic Breaks',
  helpText: 'This role will be able to create manual entries that are excluded from automatic break calculations',
  appendKey: false,
}, {
  key: 'timecard_view',
  title: 'View Time Cards',
  helpText: 'This role can view completed time cards for the following selected role types:',
  multi: true,
}, {
  key: 'timecard_edit',
  title: 'Edit Time Cards',
  helpText: 'This role can edit completed time cards for the following selected role types:',
  multi: true,
}, {
  key: 'approved_timecard_edit',
  title: 'Edit Approved Cards',
  helpText: 'This role can edit approved time cards for the following selected role types:',
  multi: true,
}, {
  key: 'enable_approve_all',
  title: 'Enable Time Card Approve-All Button',
  helpText: 'This role can approve all submitted time cards at once if allowed as an approver',
  appendKey: false,
}, {
  key: 'reports_view',
  title: 'Reports',
  helpText: 'This role has access to the following reports:',
  multi: true,
  divider: false,
}, {
  key: 'users_map_read',
  title: 'View Active Users Map',
  helpText: 'This role can view the Active Users Map',
  appendKey: false,
}, {
  key: 'BUCKETS_READ',
  title: 'View Buckets',
  helpText: 'This role can view buckets',
  appendKey: false,
}, {
  key: 'BUCKETS_WRITE',
  title: 'Manage Buckets',
  helpText: 'This role can view, add, and edit buckets',
  appendKey: false,
}];

// These keys control their write counterparts.
// e.g. Cant have FILES_WRITE without FILES_READ
export const writeControlledPermissions = new Set([
  'files_read',
  'forms_read',
  'schedule_read',
  'reports_work_done_view',
  'reports_work_done_view',
  'customers_read',
  'forms_read_logs',
  'equipment_read',
  'materials_read',
  'PROJECT_SCHEDULEOFVALUES_READ',
  'PROJECT_GANTT_SCHEDULE_READ',
  'INVOICES_READ',
  'PURCHASE_ORDERS_READ',
  'BOARDS_READ',
  'timecard_view',
  'CHANNELS_READ',
  'users_view',
  'BUCKETS_READ',
  'PROJECT_PROGRESS_READ',
]);

const writeControlMap = {
  reports_work_done_edit: 'REPORTS_WORK_DONE_VIEW',
  customers_write: 'CUSTOMERS_READ',
  equipment_write: 'EQUIPMENT_READ',
  materials_write: 'MATERIALS_READ',
  files_write: 'FILES_READ',
  forms_write: 'FORMS_READ',
  forms_read_logs: 'FORMS_READ',
  schedule_write: 'SCHEDULE_READ',
  PROJECT_SCHEDULEOFVALUES_WRITE: 'PROJECT_SCHEDULEOFVALUES_READ',
  PROJECT_GANTT_SCHEDULE_WRITE: 'PROJECT_GANTT_SCHEDULE_READ',
  INVOICES_WRITE: 'INVOICES_READ',
  PURCHASE_ORDERS_WRITE: 'PURCHASE_ORDERS_READ',
  BOARDS_WRITE: 'BOARDS_READ',
  timecard_edit: 'timecard_view',
  CHANNELS_WRITE: 'CHANNELS_READ',
  users: 'users_view',
  BUCKETS_WRITE: 'BUCKETS_READ',
  PROJECT_PROGRESS_WRITE: 'PROJECT_PROGRESS_READ',
};

export const formatPerm = (key, role) => `${key}_${role}`.replace(/ /g, '_').toUpperCase();

export const isMultiCheckboxDisabled = (key, role, rolePerms = []) => {
  if (key in writeControlMap) {
    return !rolePerms.some((perm) => perm === formatPerm(writeControlMap[key], role));
  }

  return false;
};

export const checkBoxDisabled = (key, rolePerms = [], myRole) => {
  if (key === 'settings') return myRole === 'Admin';
  if (key in writeControlMap) {
    return !rolePerms.some((perm) => perm === writeControlMap[key]);
  }
  return false;
};
