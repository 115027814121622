import { 
  GET_ADDRESS_BOOKS,
  DELETE_FROM_ADDRESS_BOOK,
  UPDATE_ADDRESS_BOOK,
  ADD_TO_ADDRESS_BOOK,
} from '../../state/actionTypes';
import ContactService from './contacts.service';

export default {};

export const getAddressBooks = () => async (dispatch) => {
  const { data } = await ContactService.getAddressBooks();
  if (!data) return false;
  dispatch({
    type: GET_ADDRESS_BOOKS,
    payload: {
      addressBooks: data,
    },
  });
  return true;
};

export const deleteFromAddressBook = (contactId, addressBookEntryId) => async (dispatch) => {
  const { data } = await ContactService.deleteFromAddressBook(addressBookEntryId);
  if (!data) return false;
  dispatch({
    type: DELETE_FROM_ADDRESS_BOOK,
    payload: {
      contactId,
      addressBookEntryId,
    },
  });
  return true;
};


export const updateAddressBook = (isVendor, payload) => async (dispatch) => {
  const { id: addressBookEntryId } = payload;
  const { data } = await ContactService.updateAddressBook(addressBookEntryId, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_ADDRESS_BOOK,
    payload: {
      isVendor,
      ...payload,
    },
  });
  return true;
};

export const addToAddressBook = (isVendor, payload) => async (dispatch) => {
  const { data: { id: addressBookEntryId } = {} } = await ContactService.addToAddressBook(payload);
  if (!addressBookEntryId) return false;
  dispatch({
    type: ADD_TO_ADDRESS_BOOK,
    payload: {
      isVendor,
      addressBookEntryId,
      ...payload,
    },
  });
  return true;
};
