/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSpring, animated } from 'react-spring';
import { Draggable } from 'react-beautiful-dnd';

import FormSectionHeader from './FormSectionHeader';
import FormFieldList from '../FormFields/FormFieldList';

import colors from '../../../constants/Colors';

const COLLAPSED_HEIGHT = 40;
const EXPANDED_HEIGHT = 600;

const border = '1px solid lightgray';

export default function FormSection({
  id,
  projectId,
  title,
  index,
  settings,
  dragging,
  onDelete,
  onRename,
  onSectionsSettingUpdated,
  onFieldAdd,
  onFieldDelete,
  fields = [],
  isDisplay = false,
  isAdd = false,
  sections = [],
  isBoardCards,
  scrollable,
  isExternalForm,
  divisions,
  templateId,
  name,
  shouldHideCustomRenderingFields,
  enablePermissions,
  isDetailView,
}) {
  const [style, setStyle] = useSpring(() => ({ maxHeight: EXPANDED_HEIGHT }));
  const [open, setOpen] = useState(true);
  const onClick = useCallback(() => {
    setOpen(!open);
  }, [open]);
  const onDeleteClicked = useCallback(() => {
    onDelete(id);
  }, [onDelete, id]);

  const fieldDelete = useCallback((fieldId) => () => {
    onFieldDelete({
      fieldId,
      sectionId: id,
    });
  }, [id, onFieldDelete]);

  useEffect(() => {
    setStyle({
      maxHeight: open ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT,
    });
  }, [open]);

  const responses = useMemo(() => {
    const responseMap = {};
    sections.forEach(({ fields = [] }) => {
      fields.forEach(({ id, response }) => {
        responseMap[id] = response;
      });
    });

    return responseMap;
  }, [sections]);

  const nonscrollableMaxHeight = open ? undefined : COLLAPSED_HEIGHT;
  return (
    <Draggable draggableId={id} index={index} type="SECTION" isDragDisabled={isDisplay}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <animated.div
          {...draggableProps}
          style={{
            border,
            marginBottom: 5,
            borderRadius: 4,
            overflow: 'hidden',
            backgroundColor: 'white',
            ...style,
            ...draggableProps.style,
            boxShadow: dragging ? '0px 2px 8px rgba(75, 75, 75, 0.25)' : null,
            ...scrollable ? {} : { maxHeight: nonscrollableMaxHeight },
          }}
          ref={innerRef}

        >
          <div {...dragHandleProps}>
            <FormSectionHeader
              id={id}
              title={title}
              style={{
                // Subtract 1 for border or end of animation has a weird snap effect
                height: COLLAPSED_HEIGHT - 1,
                borderBottom: border,
              }}
              open={open}
              onClick={onClick}
              onDeleteClicked={onDeleteClicked}
              onRename={onRename}
              isDisplay={isDisplay}
              settings={settings}
              onSectionsSettingUpdated={onSectionsSettingUpdated}
              enablePermissions={enablePermissions}
              isBoardCards={isBoardCards}
              isExternalForm={isExternalForm}
              divisions={divisions}
            />
          </div>
          <animated.div
            style={{
              height: style.maxHeight.interpolate({
                range: [COLLAPSED_HEIGHT, EXPANDED_HEIGHT],
                output: [0, 'fit-content'],
              }),
              maxHeight: scrollable ? EXPANDED_HEIGHT - COLLAPSED_HEIGHT : nonscrollableMaxHeight,
              backgroundColor: colors.OPACITY_LIGHT_GRAY,
              paddingBottom: 0,
              overflow: scrollable ? 'scroll' : 'hidden',
            }}
          >
            <FormFieldList
              id={id}
              projectId={projectId}
              fields={fields}
              onSave={onFieldAdd}
              onDelete={fieldDelete}
              isDisplay={isDisplay}
              isAdd={isAdd}
              sections={sections}
              isBoardCards={isBoardCards}
              isExternalForm={isExternalForm}
              divisions={divisions}
              templateId={templateId}
              name={name}
              shouldHideCustomRenderingFields={shouldHideCustomRenderingFields}
              responses={responses}
              isDetailView={isDetailView}
            />
          </animated.div>
        </animated.div>
      )}
    </Draggable>
  );
}
