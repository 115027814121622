import {
  GET_SIGNATURE_URL,
  SET_TIMECARD_CALENDAR_TYPE,
  SET_TIMECARD_TIME_RANGE,
  GET_CUSTOM_TIMECARD_FIELDS,
  SET_CUSTOM_TIMECARD_FIELDS,
  GET_TASK_PHOTOS,
  GET_TIMECARD_HISTORY,
  UPDATE_TIMECARD_FILTERS,
} from '../../state/actionTypes';
import TimecardsService from './timecards.service';

export default {};

export const setCalendarType = (calendarType) => ({
  type: SET_TIMECARD_CALENDAR_TYPE,
  payload: {
    calendarType,
  },
});

export const setTimeRange = (timeRange) => ({
  type: SET_TIMECARD_TIME_RANGE,
  payload: {
    timeRange,
  },
});

export const getSignatureURL = ({ userId, signatureReferenceId }) => async (dispatch) => {
  const { data } = await TimecardsService.getSignatureURL({ userId, signatureReferenceId });
  if (!data) return false;
  dispatch({
    type: GET_SIGNATURE_URL,
    payload: {
      url: data,
    },
  });
  return data;
};

export const getCustomFields = () => async (dispatch) => {
  const { data } = await TimecardsService.getCustomFields();
  if (!data) return false;
  dispatch({
    type: GET_CUSTOM_TIMECARD_FIELDS,
    payload: {
      fields: data,
    },
  });
  return true;
};

export const setCustomFields = (payload) => async (dispatch) => {
  const { data } = await TimecardsService.setCustomFields(payload);
  if (!data) return false;
  dispatch({
    type: SET_CUSTOM_TIMECARD_FIELDS,
    payload: {
      newField: {
        ...payload,
        fields: data,
      },
    },
  });
  return true;
};

export const getTaskPhotos = (taskIds) => async (dispatch) => {
  const { data } = await TimecardsService.getTaskPhotos(taskIds);
  if (!data) return false;
  dispatch({
    type: GET_TASK_PHOTOS,
    payload: {
      taskPhotos: data,
    },
  });
  return true;
};

export const getTimecardHistory = (userId) => async (dispatch) => {
  const { data } = await TimecardsService.getTimecardHistory(userId);
  if (!data) return false;
  dispatch({
    type: GET_TIMECARD_HISTORY,
    payload: {
      history: data,
    },
  });
  return true;
};

export const updateTimeCardFilters = (filters) => ({
  type: UPDATE_TIMECARD_FILTERS,
  payload: {
    filters,
  },
});
