import { compareStringArrays } from '../helpers/helpers';

export default {};

export const validateName = (id, teams) => (_rule, value) => {
  if (teams.some((team) => team.name === value && id !== team.id)) {
    return Promise.reject(new Error(`Team name ${value} is in use by another team`));
  }

  return Promise.resolve();
};

export const validateTeamMembers = (form) => () => {
  const {
    supervisors = [],
    workers = [],
  } = form.getFieldsValue();
  const memberSet = new Set(workers);
  const intersection = new Set(supervisors.filter((x) => memberSet.has(x)));

  return intersection.size === 0
    ? Promise.resolve()
    : Promise.reject(new Error('User cannot be both supervisor and worker'));
};

export const getSupervisorsAndWorkers = (team) => {
  const supervisors = [];
  const workers = [];
  team?.members?.forEach((member) => {
    if (member.teamRole === 'Supervisor') {
      supervisors.push(member.id);
    } else {
      workers.push(member.id);
    }
  });
  return { supervisors, workers };
};

export const getUpdatePayload = (oldValues, newValues) => {
  if (!newValues || !oldValues) {
    return {};
  }

  const {
    projects: oldProjects = [], // Format [{ id, name }]
  } = oldValues;

  const {
    projects: newProjects = [],
    supervisors: newSupers = [],
    workers: newWorkers = [],
  } = newValues;

  const {
    supervisors: oldSupers = [],
    workers: oldWorkers = [],
  } = getSupervisorsAndWorkers(oldValues);

  const {
    added: supersToAdd,
    removed: supersToDelete,
  } = compareStringArrays(oldSupers, newSupers);
  const {
    added: workersToAdd,
    removed: workersToDelete,
  } = compareStringArrays(oldWorkers, newWorkers);

  const {
    added: projectsToAdd,
    removed: projectsToDelete,
  } = compareStringArrays(oldProjects.map((proj) => proj.id), newProjects);

  return {
    name: newValues.name,
    divisionId: newValues.divisionId,
    supersToDelete,
    supersToAdd,
    workersToDelete,
    workersToAdd,
    projectsToAdd,
    projectsToDelete,
  };
};

const updateArr = (arr, added, deleted) => (
  arr.filter((item) => !deleted.has(item.id))
    .concat(added)
);
export const updateTeam = (oldTeam, newTeam) => {
  const {
    name,
    divisionId,
    supersToDelete = [],
    supersToAdd = [],
    workersToDelete = [],
    workersToAdd = [],
    projectsToAdd = [],
    projectsToDelete = [],
  } = newTeam;
  const ourTeam = { ...oldTeam };
  if (name) ourTeam.name = name;
  if (divisionId) ourTeam.divisionId = divisionId;

  const toDelete = new Set(supersToDelete.concat(workersToDelete));
  const toAdd = supersToAdd.map((id) => ({
    id,
    teamRole: 'Supervisor',
  })).concat(
    workersToAdd.map((id) => ({ id, teamRole: 'Worker' })),
  );

  const pToAdd = projectsToAdd.map((id) => ({ id }));

  ourTeam.members = updateArr(ourTeam.members, toAdd, toDelete);
  ourTeam.projects = updateArr(ourTeam.projects, pToAdd, new Set(projectsToDelete));
  return ourTeam;
};
