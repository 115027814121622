import Analytics from '../../../helpers/Analytics';
import { replaceEmptyStrings } from '../../../helpers/contacts';
import { decoratePayloadWithLabels } from '../../../helpers/labels';
import {
  ADD_VENDOR_NOTE,
  ARCHIVE_VENDOR,
  CREATE_VENDOR,
  DELETE_VENDOR,
  GET_VENDORS,
  GET_VENDOR_LABELS,
  GET_VENDOR_NOTES,
  UPDATE_VENDOR,
} from '../../../state/actionTypes';
import VendorService from './vendors.service';

const modifyPayload = (payload = {}, id = null, customerToLabel = {}) => {
  let ourPayload = decoratePayloadWithLabels(payload, id, customerToLabel);
  ourPayload = replaceEmptyStrings(ourPayload, null);
  return ourPayload;
};

export const getVendors = () => async (dispatch) => {
  const { data } = await VendorService.get();
  if (!data) return false;
  dispatch({
    type: GET_VENDORS,
    payload: {
      vendors: data,
    },
  });
  return true;
};

export const createVendor = (payload) => async (dispatch) => {
  Analytics.track('Vendor/Create');
  const ourPayload = decoratePayloadWithLabels(payload);
  const { data } = await VendorService.create(ourPayload);
  if (!data) return false;
  dispatch({
    type: CREATE_VENDOR,
    payload: data,
  });
  return true;
};

export const updateVendor = (id, payload, vendorToLabel) => async (dispatch) => {
  const ourPayload = modifyPayload(payload, id, vendorToLabel);

  const { data } = await VendorService.update(id, ourPayload);
  if (!data) return false;
  dispatch({
    type: UPDATE_VENDOR,
    payload: {
      id,
      newData: ourPayload,
      removedLabels: ourPayload.removedLabels,
      addedLabels: data.labels,
    },
  });

  return true;
};

export const deleteVendor = (id) => async (dispatch) => {
  const { data } = await VendorService.delete(id);
  if (!data) return false;
  dispatch({
    type: DELETE_VENDOR,
    payload: {
      id,
    },
  });
  return true;
};

export const archiveVendor = (id, active) => async (dispatch) => {
  const { data } = await VendorService.archive(id, active);
  if (!data) return false;
  dispatch({
    type: ARCHIVE_VENDOR,
    payload: {
      id,
      active,
    },
  });
  return true;
};

export const addNote = ({ id, text }) => async (dispatch) => {
  const { data } = await VendorService.addNote(id, text);
  if (!data) return false;
  dispatch({
    type: ADD_VENDOR_NOTE,
    payload: data,
  });
  return true;
};

export const getNotes = (id) => async (dispatch) => {
  const { data } = await VendorService.getNotes(id);
  if (!data) return false;
  dispatch({
    type: GET_VENDOR_NOTES,
    payload: {
      id,
      data,
    },
  });
  return true;
};

export const getVendorLabels = () => async (dispatch) => {
  const { data } = await VendorService.getVendorLabels();
  if (!data) return false;
  dispatch({
    type: GET_VENDOR_LABELS,
    payload: {
      vendorLabels: data,
    },
  });
  return true;
};
