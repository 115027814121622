import React, { useCallback, useState } from 'react';
import { Drawer } from 'antd';

import getGoogleMapKey from '../../common/keys';
import OnTraccrMap from '../../common/map/OnTraccrMap';
import BorderlessButton from  '../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import DrawerFooter from '../../common/containers/DrawerFooter';

const height = 400;
const width = height * 1.5;

export default ({
  address,
  lat,
  lng,
  name,
  onPlaceChanged,
  disabled,
}) => {
  const [showDrawer, setShowDrawer] = useState();
  const [place,setPlace] = useState({ address, lat, lng, });

  const onToggleDrawer = useCallback(() => setShowDrawer(!showDrawer), [showDrawer]);

  const onClose = useCallback(() => {
    setPlace({ address, lat, lng, });
    setShowDrawer(false);
  },[address, lat, lng]);

  const onSave = useCallback(() => {
    onPlaceChanged(place);
    setShowDrawer(false);
  }, [place]);

  return (
    <div>
      <BorderlessButton
        title={
          <div style={{ overflow:'hidden', textOverflow:'ellipsis'}}>
           {place.address || 'Add Address'}
          </div>
        }
        disabled={disabled}
        onClick={onToggleDrawer}
      />
      <Drawer
        visible={showDrawer} 
        title={`Add Address for ${name}`}
        width={width + 40}
        onClose={onClose}
      >
        <OnTraccrMap
          key={address || 'no-address-map'}
          address={place.address}
          lat={place.lat}
          lng={place.lng}
          showSearch
          googleMapURL={getGoogleMapKey()}
          loadingElement={<div style={{ height,}} />}
          containerElement={
            <div style={{
              width,
              height:1.5,
            }}/>
          }
          mapElement={<div style={{ height, }} />}
          onPlaceChanged={setPlace}
        />
        <DrawerFooter>
          <OnTraccrButton title='Save' onClick={onSave}/>
        </DrawerFooter>
      </Drawer>
    </div>
  );
}
