import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DatePicker } from 'antd';
import DateFilter from '../datePicker/DateFilter';

import {
  DATE_FORMAT,
} from './analytics.constants';
import {
  getDateRangeFromConfig,
} from './analytics.helpers';

import { setAnalyticsConfig } from './state/analytics.actions';

export default function AnalyticsDatePicker({
  type,
  analyticsConfig: {
    dateRange,
    datePreset,
  } = {},
}) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const onDatesChanged = useCallback(([startMoment, endMoment]) => {
    if (!startMoment || !endMoment) return;
    dispatch(setAnalyticsConfig({
      type,
      payload: {
        dateRange: [startMoment.valueOf(), endMoment.valueOf()],
        datePreset: undefined,
      },
    }));
  }, [type]);

  const onPresetClicked = useCallback((newDatePreset) => {
    dispatch(
      setAnalyticsConfig({
        type,
        payload: {
          datePreset: newDatePreset,
          dateRange: undefined,
        },
      }),
    );
    setOpen(false);
  }, [type]);

  const formattedRange = useMemo(() => (
    getDateRangeFromConfig({ dateRange, datePreset })
  ), [datePreset, dateRange]);

  return (
    <DatePicker.RangePicker
      style={{ width: 250 }}
      format={DATE_FORMAT}
      showTime={false}
      allowClear={false}
      onChange={onDatesChanged}
      value={formattedRange}
      open={open}
      onOpenChange={setOpen}
      renderExtraFooter={() => (
        <DateFilter datePreset={datePreset} handlePreset={onPresetClicked} />
      )}
    />
  );
}

/* eslint-disable react/forbid-prop-types */
AnalyticsDatePicker.propTypes = {
  type: PropTypes.string,
  analyticsConfig: PropTypes.object,
};

AnalyticsDatePicker.defaultProps = {
  type: null,
  analyticsConfig: {},
};
