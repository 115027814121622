import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Select,
} from 'antd';
import useFilteredBoardList from '../common/hooks/useFilteredBoardList';

function BoardsSelector({
  value,
  onChange,
}) {
  const boardList = useFilteredBoardList({ asOptions: true });

  return (
    <>
      <Row style={{ marginTop: 5 }}>
        Select Boards:
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Select
          options={boardList}
          onChange={onChange}
          style={{ width: '100%' }}
          dropdownMatchSelectWidth={false}
          value={value}
          showSearch
          optionFilterProp="title"
          placeholder="All"
          mode="multiple"
        />
      </Row>
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
BoardsSelector.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BoardsSelector;
