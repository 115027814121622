import React from 'react';
import {
  Row,
  Col,
  Select,
  Checkbox,
  TreeSelect,
  Popover,
} from 'antd';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { WarningOutlined } from '@ant-design/icons';
import { Buckets } from 'ontraccr-common';

import { fieldOption } from './formFieldsHelpers';
import OptionalRow from './OptionalRow';
import TitleRow from './TitleRow';

import SelectLabelFilter from './SelectLabelFilter';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import OnTraccrNumberInput from '../../../common/inputs/OnTraccrNumberInput';
import HoverHelp from '../../../common/HoverHelp';

import { getIdMap } from '../../../helpers/helpers';
import {
  formatFormTemplateName,
  getDescriptivePhaseCostcodeName,
  getPhaseCostcodeTreeData,
  getLinkId,
  formatFormDropdownList,
} from '../../formHelpers';
import { getLabelTypes } from '../../../settings/Labels/label.constants';
import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import FieldTriggerFlag from './FieldTriggerFlag';
import ConditionalRenderingRow from './ConditionalRenderingRow';
import Colors from '../../../constants/Colors';
import DropdownFieldSubDataTypeSelect from './DropdownFieldSubDataTypeSelect';
import { formatCardTitle } from '../../../boards/boardHelpers';
import DropdownFieldStyleSelect from './DropdownFieldStyleSelect';
import DropdownFieldSelect from './DropdownFieldSelect';

const canFilter = {
  Customers: true,
  Users: true,
  Vendors: true,
};

const enableGlobalSelectionSet = new Set(['Equipment']);
const shouldDisableSmartFilteringSet = new Set([
  'Customers',
  'Projects',
  'Costcodes',
  'CompletedForms',
  'Buckets',
  'PayableSubContracts',
]);

const getTypes = (t) => [
  {
    title: t('Customer', { count: 2 }),
    description: `User can select from existing ${t('Customer', { count: 2 })}`,
    value: 'Customers',
  },
  {
    title: t('Project', { count: 2 }),
    description: `User can select from existing ${t('Project', { count: 2 })}`,
    value: 'Projects',
    hasSubDataType: true,
    subDataTypePlaceholder: 'All',
  },
  {
    title: 'Users',
    description: 'User can select from existing Users',
    value: 'Users',
  },
  {
    title: 'Equipment',
    description: 'User can select from existing Equipment',
    value: 'Equipment',
    hasSubDataType: true,
    subDataTypePlaceholder: 'All',
  },
  {
    title: 'Vendors',
    description: 'User can select from existing Vendors',
    value: 'Vendors',
  },
  {
    title: 'Costcodes',
    description: 'User can select from existing Costcodes',
    value: 'Costcodes',
  },
  {
    title: 'SoV Sub-Contracts',
    description: `User can select from a ${t('Project').toLowerCase()} SoV Sub-Contract`,
    value: 'Sub-Contracts',
  },
  {
    title: 'Contacts',
    description: `User can select a Contact from a Vendor/${t('Customer')} address Book`,
    value: 'Contacts',
  },
  {
    title: 'Custom',
    description: 'Add a list of options for the user to select from',
    value: 'Custom',
  },
  {
    title: 'Forms Templates',
    description: 'User can select from existing Form Templates',
    value: 'Forms',
  },
  {
    title: 'Labels',
    description: 'User can select from existing Labels',
    hasSubDataType: true,
    value: 'Labels',
  },
  {
    title: 'Form Submissions',
    description: 'User can select from existing Forms',
    hasSubDataType: true,
    value: 'CompletedForms',
    subDataTypePlaceholder: 'Select Form Template',
    mode: 'single',
    hasSubDataTypeFilter: true,
    subDataTypeFilterPlaceHolder: 'Select a status',
    subDataTypeFilterMode: 'multiple',
  },
  {
    title: 'Cards',
    description: 'User can select from existing Board Cards',
    hasSubDataType: true,
    subDataTypePlaceholder: 'Select Board',
    value: 'Cards',
    subDataTypeSelectMode: 'single',
  },
  {
    title: 'Buckets',
    description: 'User can select from existing Buckets',
    hasSubDataType: true,
    subDataTypePlaceholder: 'Select Bucket Type',
    value: 'Buckets',
    subDataTypeSelectMode: 'single',
  },
  {
    title: 'Sub-Contracts',
    description: 'User can select from existing Sub-Contracts',
    hasSubDataType: true,
    value: 'PayableSubContracts',
    subDataTypePlaceholder: 'Select Sub-Contract Form Template',
    mode: 'single',
    hasSubDataTypeFilter: true,
    subDataTypeFilterPlaceHolder: 'Select a status',
    subDataTypeFilterMode: 'multiple',
  },
];

// Backwards compatible.
// Versions <= 2.3.2 store dropdown values as strings, instead of objects
const getId = (selectedItem) => (
  typeof selectedItem === 'string'
    ? selectedItem
    : selectedItem.id
);

const dataTypesThatRequireFetch = new Set(['CompletedForms', 'Cards', 'Buckets', 'PayableSubContracts']);

const configure = ({
  setConfigProps,
  configProps = {},
  isExternalForm,
  sections = [],
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  t,
  projectTypes = [],
  equipmentTypes = [],
  id,
  labels = [],
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  contactAddressBooks = {},
  isExistingField = false,
  formStatuses = [],
  boards = [],
  bucketTemplates = [],
  positions,
  buckets = [],
} = {}) => {
  let types = getTypes(t);
  const LABEL_TYPES = getLabelTypes(t);

  const {
    hasConditionalRendering = false,
    conditionalRenderingFormula,
    selectMode,
    optional,
    dataType = '',
    openLimit,
    numAnswers = 1,
    customOptions: customOpts = [],
    subDataType,
    enableGlobalSelection,
    linkField,
    title,
    autoCollect,
    subDataTypeFilter = [],
    style,
    positionIds,
    shouldDisableSmartFiltering = false,
  } = configProps;

  let hasFormSelector = false;
  let hasSubContractSelector = false;

  sections?.forEach(({ fields = [] }) => {
    fields.forEach((field) => {
      const { configProps: { dataType: fieldDataType } = {} } = field;
      if (fieldDataType === 'CompletedForms') hasFormSelector = true;
      if (fieldDataType === 'PayableSubContracts') hasSubContractSelector = true;
    });
  });

  // We only allow one choose one or more forms
  if (hasFormSelector && dataType !== 'CompletedForms') {
    types = types.filter((type) => type.value !== 'CompletedForms');
  }
  // We only allow one subcontract choose one or more
  if (hasSubContractSelector && dataType !== 'PayableSubContracts') {
    types = types.filter((type) => type.value !== 'PayableSubContracts');
  }

  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    const newConfig = {
      ...configProps,
      title: value,
    };
    setConfigProps(newConfig);
  };

  const {
    hasSubDataTypeFilter,
    subDataTypeFilterPlaceHolder,
    subDataTypeFilterMode,
  } = types.find((type) => type.value === dataType) ?? {};

  const onSelect = (newDataType) => {
    const typeChanged = newDataType !== dataType;
    const ourType = types.find((type) => type.value === newDataType);
    const newConfig = {
      ...configProps,
      dataType: newDataType,
      hasSubDataType: ourType?.hasSubDataType,
      subDataTypePlaceholder: ourType?.subDataTypePlaceholder,
      enableGlobalSelection: enableGlobalSelectionSet.has(newDataType)
        ? enableGlobalSelection
        : false,
      selectMode: ourType?.mode,
      subDataTypeSelectMode: ourType?.subDataTypeSelectMode,
      linkField: typeChanged ? null : linkField,
    };

    if (newConfig.selectMode === 'single') {
      newConfig.numAnswers = 1;
      newConfig.openLimit = false;
    }

    setConfigProps(newConfig);
  };

  const onSubDataTypeSelect = (newSubDataType) => {
    const newConfig = {
      ...configProps,
      subDataType: newSubDataType,
    };

    setConfigProps(newConfig);
  };

  const onSubDataTypeFilter = (newSubDataTypeFilter) => {
    const newConfig = {
      ...configProps,
      subDataTypeFilter: newSubDataTypeFilter,
    };

    setConfigProps(newConfig);
  };

  const onNumberChange = (num) => {
    const newConfig = {
      ...configProps,
      numAnswers: num,
    };
    setConfigProps(newConfig);
  };

  const onCustomOptionSelect = (val) => {
    const {
      customOptions = [],
    } = configProps;
    const newConfig = {
      ...configProps,
      customOptions: customOptions.concat([{
        id: val,
        name: val,
      }]),
    };
    setConfigProps(newConfig);
  };
  const onCustomOptionDeselect = (val) => {
    const {
      customOptions = [],
    } = configProps;
    const newConfig = {
      ...configProps,
      customOptions: customOptions.filter((item) => item.id !== val),
    };
    setConfigProps(newConfig);
  };

  const onCheckChanged = (key) => (e) => {
    const { target: { checked } = {} } = e;
    setConfigProps({
      ...configProps,
      [key]: checked,
    });
  };

  const setFieldTrigger = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      fieldTrigger: checked,
    });
  };

  const setConditionalRenderingFormula = (formula) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: formula,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setLinkField = (value) => {
    setConfigProps({
      ...configProps,
      linkField: value,
    });
  };

  const setPositionIds = (value) => {
    setConfigProps({
      ...configProps,
      positionIds: value,
    });
  };

  const setDropdownFieldStyle = (value) => {
    const newConfigProps = {
      ...configProps,
      style: value,
    };

    if (value === 'radio') {
      newConfigProps.openLimit = false;
      newConfigProps.numAnswers = 1;
    }

    setConfigProps(newConfigProps);
  };

  const ourType = types.find((type) => type.value === dataType);
  const {
    hasSubDataType,
    subDataTypePlaceholder,
    subDataTypeSelectMode,
  } = ourType ?? configProps;

  const isCustom = dataType === 'Custom';
  const showAutoCollect = dataType === 'Projects' || dataType === 'Customers';
  const relevantTypes = isExternalForm
    ? [types.find((type) => type.value === 'Custom')]
    : types;

  const relevantDivisions = new Set(divisions ?? []);

  const subDataTypeMap = {
    Labels: LABEL_TYPES,
    Equipment: equipmentTypes,
    Projects: projectTypes,
    CompletedForms: formTemplates
      .filter((ft) => (isBoardCards || relevantDivisions.has(ft.divisionId)) && ft.active)
      .map((ft) => ({
        value: ft.id,
        label: formatFormTemplateName(ft, projectIdMap),
      })),
    Cards: boards.map((board) => ({
      value: board.id,
      label: board.title,
    })),
    Buckets: bucketTemplates.map((bucket) => ({
      value: bucket.id,
      label: bucket.name,
    })),
    PayableSubContracts: formTemplates
      .filter((ft) => (isBoardCards || relevantDivisions.has(ft.divisionId)) && ft.active && ft.type === 'Sub-Contract')
      .map((ft) => ({
        value: ft.id,
        label: formatFormTemplateName(ft, projectIdMap),
      })),
  };

  const subDataTypes = hasSubDataType
    ? subDataTypeMap[dataType]
    : [];

  const formStatusOptions = Object.values(formStatuses).map((status) => ({
    value: status.id,
    label: status.status,
  }));

  const subDataTypeFilterMap = {
    CompletedForms: formStatusOptions,
    PayableSubContracts: formStatusOptions,
  };

  const subDataTypeFilters = hasSubDataTypeFilter
    ? subDataTypeFilterMap[dataType]
    : [];

  const subDataTypeSet = new Set(subDataTypes?.map?.((type) => type.value));

  let filteredSubType;

  if ((subDataTypeSelectMode ?? selectMode) === 'single') {
    filteredSubType = subDataType;
  } else {
    filteredSubType = subDataType
      ?.filter?.((type) => subDataTypeSet.has(type)) ?? [];
  }

  const linkOptions = dataType
    ? (
      sections.reduce((acc, section) => {
        const { fields = [], name: sectionName } = section;
        return acc.concat(
          fields.reduce((fieldAcc, field) => {
            const {
              id: fieldId,
              selectedType,
              configProps: {
                dataType: fieldDataType,
                title: fieldTitle,
              } = {},
            } = field;
            if (selectedType !== 'dropdown') return fieldAcc;
            if (fieldDataType !== 'Customers' && fieldDataType !== 'Vendors') return fieldAcc;
            return fieldAcc.concat([{
              label: `${sectionName} - ${fieldTitle}`,
              value: fieldId,
            }]);
          }, []),
        );
      }, [])
    ) : [];

  return (
    <div>
      <Row className="form-required-field">
        Title:
      </Row>
      <Row style={{ marginTop: 5, width: 350 }}>
        <OnTraccrTextInput
          textarea
          placeholder="Insert title here"
          onChange={setTitle}
          value={title}
        />
      </Row>
      <OptionalRow onChange={onCheckChanged('optional')} optional={optional} disabled={disableOptional} />
      <ConditionalRenderingRow
        id={id}
        onChange={setHasConditionalRendering}
        onFormulaChange={setConditionalRenderingFormula}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
        sections={sections}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        divisions={divisions}
        buckets={buckets}
      />
      {
        enableGlobalSelectionSet.has(dataType)
        && (
          <Row gutter={10}>
            <Col>
              <Checkbox
                onChange={onCheckChanged('enableGlobalSelection')}
                checked={enableGlobalSelection}
              >
                Enable Global Selection
              </Checkbox>
            </Col>
            <Col>
              <HoverHelp
                placement="topRight"
                content={(
                  <div style={{ width: 250 }}>
                    Check this box if you want Ontraccr to allow selecting all
                    {' '}
                    {dataType}
                  </div>
              )}
              />
            </Col>
          </Row>
        )
      }
      {showAutoCollect && (
      <Row style={{ marginTop: 10 }} gutter={10}>
        <Col>
          <Checkbox
            onChange={onCheckChanged('autoCollect')}
            checked={autoCollect}
          >
            Automatically Collect?
          </Checkbox>
        </Col>
        <Col>
          <HoverHelp
            placement="topRight"
            content={(
              <div style={{ width: 250 }}>
                Check this box if you want Ontraccr to automatically collect this data
              </div>
          )}
          />
        </Col>
      </Row>
      )}
      { shouldDisableSmartFilteringSet.has(dataType) && (
        <Row gutter={10}>
          <Col>
            <Checkbox
              onChange={onCheckChanged('shouldDisableSmartFiltering')}
              checked={shouldDisableSmartFiltering}
            >
              Disable Smart Filtering?
            </Checkbox>
          </Col>
          <Col>
            <HoverHelp
              placement="topRight"
              content={(
                <div style={{ width: 250 }}>
                  Check this box if you want this dropdown to be excluded from Smart Filtering
                </div>
            )}
            />
          </Col>
        </Row>
      )}
      <Row className="form-required-field" style={{ marginTop: 15 }}>
        <Col>
          Data Type:
        </Col>
        <Col>
          {isExistingField && (
            <Popover
              placement="bottomLeft"
              trigger="hover"
              content={(
                <div style={{ width: 200 }}>
                  Data type cannot be altered for existing forms.
                  You must create a new dropdown field
                  to use a different data type.
                </div>
              )}
              title="Warning"
              width={200}
            >
              <WarningOutlined
                style={{
                  color: Colors.ONTRACCR_DARK_YELLOW,
                  marginLeft: 10,
                }}
              />
            </Popover>
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: 5 }}>
        <Select
          style={{ width: 350 }}
          placeholder="Select Data Type"
          onSelect={onSelect}
          showSearch
          optionFilterProp="label"
          value={dataType}
          disabled={isExistingField}
        >
          {
            relevantTypes.map((type) => (
              <Select.Option value={type.value} key={type.title} label={type.title}>
                {fieldOption(type)}
              </Select.Option>
            ))
          }
        </Select>
      </Row>
      {dataType === 'Contacts' && (
        <Row style={{ marginTop: 5 }}>
          <Select
            style={{ width: 350 }}
            placeholder="Link to a Customer/Vendor Field"
            value={linkField}
            optionFilterProp="label"
            onChange={setLinkField}
            options={linkOptions}
            allowClear
          />
        </Row>
      )}
      {dataType === 'Users' && (
        <Row style={{ marginTop: 5 }}>
          <Select
            style={{ width: 350 }}
            placeholder="Roles"
            value={positionIds}
            optionFilterProp="label"
            onChange={setPositionIds}
            options={positions.map((pos) => ({ value: pos.id, label: pos.name }))}
            allowClear
            showSearch
            mode="multiple"
          />
        </Row>
      )}
      <DropdownFieldSubDataTypeSelect
        placeholder={subDataTypePlaceholder ?? `Select ${dataType} Type`}
        onChange={onSubDataTypeSelect}
        value={filteredSubType}
        mode={subDataTypeSelectMode ?? selectMode}
        disabled={isExistingField}
        options={subDataTypes}
      />
      <DropdownFieldSubDataTypeSelect
        placeholder={subDataTypeFilterPlaceHolder}
        onChange={onSubDataTypeFilter}
        value={subDataTypeFilter}
        mode={subDataTypeFilterMode}
        options={subDataTypeFilters}
      />
      {
        isCustom
        && (
        <div>
          <Row className="form-required-field" style={{ marginTop: 15 }}>
            Custom options:
          </Row>
          <Row style={{ marginTop: 5, marginLeft: 0, marginRight: 0 }} gutter={10}>
            <Select
              style={{ width: 350 }}
              mode="tags"
              tokenSeparators={[',']}
              open={false}
              placeholder="Enter custom options"
              onSelect={onCustomOptionSelect}
              onDeselect={onCustomOptionDeselect}
              value={customOpts.map((opt) => opt.id)}
            />
          </Row>
        </div>
        )
      }
      { !!isCustom && (
        <DropdownFieldStyleSelect
          value={style}
          onChange={setDropdownFieldStyle}
        />
      )}
      <Row style={{ marginTop: 15 }} gutter={10}>
        <Col>
          Number of answers:
        </Col>
        <Col>
          <HoverHelp placement="top" content="Configure how many options the user can select" />
        </Col>
      </Row>
      <Row style={{ marginTop: 5 }} align="middle" gutter={20}>
        <Col style={{ height: 32 }}>
          <OnTraccrNumberInput
            style={{ width: 100 }}
            value={numAnswers}
            min={1}
            max={autoCollect ? 1 : undefined}
            onChange={onNumberChange}
            disabled={autoCollect || openLimit || selectMode === 'single' || style === 'radio'}
          />
        </Col>
        <Col style={{ height: 32 }}>
          <Row align="middle" style={{ height: '100%' }}>
            <Col>
              <Checkbox
                onChange={onCheckChanged('openLimit')}
                checked={openLimit}
                disabled={selectMode === 'single' || style === 'radio'}
              >
                Leave open?
              </Checkbox>
            </Col>
            <Col>
              <HoverHelp placement="top" content="Check this box if you want to remove restrictions on the number of answers" />
            </Col>
          </Row>
        </Col>
      </Row>
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={setFieldTrigger}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          isExternalForm={isExternalForm}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  );
};

function Preview({
  setPreviewProps,
  previewProps = {},
  configProps = {},
  projects = [],
  projectTypes = [],
  equipmentTypes = [],
  projectIdMap = {},
  users = [],
  customers = [],
  equipment = [],
  vendors = [],
  costcodes = [],
  costcodeIdMap = {},
  phases = [],
  phaseIdMap = {},
  contactAddressBooks = {},
  formTemplates = [],
  labels = [],
  projectEquipment = {},
  boardDetailsMap = {},
  bucketTemplateMap = {},
  id,
  responses,
  setResponses,
  projectId,
  customerId,
  vendorId,
  setCustomerId,
  setProjectId,
  setVendorId,
  setContactId,
  setCostcodeId,
  setUserId,
  setSubContractId,
  setBucketId,
  responding = false,
  userToLabel = {},
  customerToLabel = {},
  vendorToLabel = {},
  subContractMap = {},
  locked,
  templateId,
  sections,
  isExternalForm,
  divisions,
  fieldTriggerMap = {},
  setFieldTriggerMap,
  isDisplay,
  name,
  forms = [],
  subContractForms = [],
  parentForm,
  t,
  selectedBucket,
  selectedBucketTypeToIdMap,
  selectedBucketTypes,
  isFormBuilder,
  isDetailView = false,
} = {}) {
  const {
    dataType,
    optional,
    title = 'Title goes here',
    numAnswers = 1,
    customOptions = [],
    openLimit,
    subDataType,
    enableGlobalSelection,
    fieldTrigger,
    linkField,
    style,
    positionIds,
    shouldDisableSmartFiltering = false,
  } = configProps;
  const {
    selected: selectedProp = [],
    filterLabels = [],
  } = previewProps;

  const selected = Array.isArray(selectedProp) ? selectedProp : [];

  const shouldLock = (dataType === 'Projects' || dataType === 'Customers') && locked;

  const listResponses = responses && responses[id] && responses[id].values
    ? responses[id].values
    : [];

  const actualSelected = responding ? listResponses : selected;
  const onFilterChanged = (newFilterLabels = []) => {
    setPreviewProps({
      ...previewProps,
      filterLabels: newFilterLabels,
    });
  };

  const actualSelectedSet = new Set(
    actualSelected.map(getId),
  );

  const divSet = new Set(divisions);

  const linkedCustomerId = getLinkId({ responses, defaultValue: customerId, linkField });
  const linkedVendorId = getLinkId({ responses, defaultValue: vendorId, linkField });
  let data = [];
  let allData = [];
  let placeholder = 'Select an option';
  if (dataType === 'Customers') {
    const {
      [projectId]: { customerId: projectCustomerId } = {},
    } = projectIdMap;
    data = customers.filter((customer) => {
      const {
        [customer.id]: customerLabels = [],
      } = customerToLabel;
      const customerLabelSet = new Set(customerLabels);

      return (
        customer.active
        && (shouldDisableSmartFiltering || !projectId || customer.id === projectCustomerId)
        && filterLabels.every((label) => customerLabelSet.has(label))
        && (shouldDisableSmartFiltering || !selectedBucket || Buckets.getLinkedBuckets([selectedBucket], [customer.id], ['customers']).allBuckets.length > 0)
        && customer?.divisionIds?.some((divisionId) => divSet.has(divisionId))
      );
    });
    placeholder = `Select a ${t('Customer')}`;
  } else if (dataType === 'Projects') {
    const projectTypeSet = new Set(projectTypes.map((type) => type.id));
    const subDataTypeSet = new Set(
      subDataType?.filter?.((typeId) => projectTypeSet.has(typeId) || typeId === null) ?? [],
    );
    data = projects.filter((project) => project.active
      && (shouldDisableSmartFiltering || !customerId || project.customerId === customerId)
      && (!subDataTypeSet?.size || subDataTypeSet.has(project.projectTypeId))
      && (shouldDisableSmartFiltering || !selectedBucket || Buckets.getLinkedBuckets([selectedBucket], [project.id], ['projects']).allBuckets.length > 0)
    );
    placeholder = `Select a ${t('Project')}`;
  } else if (dataType === 'Users') {
    data = users.filter((user) => {
      const positionSet = new Set(positionIds ?? []);
      const {
        [user.id]: userLabels = [],
      } = userToLabel;
      const userLabelSet = new Set(userLabels);
      return (
        user.active
        && (
          actualSelectedSet.has(user.id)
          || (
            (filterLabels.length === 0 || filterLabels.every((label) => userLabelSet.has(label)))
            && (positionSet.size === 0 || positionSet.has(user.positionId))
          )
        )
      );
    });
    placeholder = 'Select a User';
  } else if (dataType === 'Costcodes') {
    data = getPhaseCostcodeTreeData({
      responding,
      projectId: shouldDisableSmartFiltering ? null : projectId,
      phases,
      costcodes,
      costcodeMap: costcodeIdMap,
      projectMap: projectIdMap,
      disableOptions: !openLimit && numAnswers > 1 && actualSelected.length >= numAnswers,
    });
    placeholder = 'Select a Costcode';
  } else if (dataType === 'Equipment') {
    const equipmentTypeSet = new Set(equipmentTypes.map((type) => type.id));
    const subDataTypeSet = new Set(
      subDataType?.filter?.((typeId) => equipmentTypeSet.has(typeId) || typeId === null) ?? [],
    );
    data = equipment.filter((eq) => (
      eq.active
        && eq.divisionIds.some((divisionId) => divSet.has(divisionId))
        && (enableGlobalSelection || !projectId || !!projectEquipment[projectId]?.[eq.id])
        && (!subDataTypeSet?.size || subDataTypeSet.has(eq.equipmentTypeId))
    ));
    placeholder = 'Select Equipment';
  } else if (dataType === 'Vendors') {
    data = vendors.filter((vendor) => {
      const {
        [vendor.id]: vendorLabels = [],
      } = vendorToLabel;
      const vendorLabelSet = new Set(vendorLabels);

      return (
        vendor.active
        && filterLabels.every((label) => vendorLabelSet.has(label))
        && vendor?.divisionIds?.some((divisionId) => divSet.has(divisionId))
      );
    });
    placeholder = 'Select Vendor';
  } else if (dataType === 'Sub-Contracts') {
    const {
      [projectId]: subcontracts = [],
    } = subContractMap;
    data = subcontracts.map((sub) => ({ id: sub.rowId, name: sub.description }));
  } else if (dataType === 'Contacts') {
    const customerAddressBook = contactAddressBooks[linkedCustomerId] ?? [];
    const vendorAddressBook = contactAddressBooks[linkedVendorId] ?? [];
    if (!responding) {
      data = actualSelected ?? [];
    } else if (linkedCustomerId === linkedVendorId) { // the same if linked
      data = customerAddressBook;
    } else {
      data = customerAddressBook.concat(vendorAddressBook);
    }
    placeholder = 'Select Contact';
  } else if (dataType === 'Custom') {
    data = customOptions;
  } else if (dataType === 'Forms') {
    // Prevent infinite form workflow loop
    data = formTemplates.filter((ft) => ft.active && ft.id !== templateId);
    placeholder = 'Select Form Template';
  } else if (dataType === 'Labels') {
    const subDataTypeSet = new Set(subDataType);
    data = labels
      .filter((label) => !subDataType?.length || subDataTypeSet.has(label.type))
      .map((label) => ({ id: label.id, name: label.title }));
    placeholder = 'Select Label';
  } else if (dataType === 'CompletedForms') {
    data = formatFormDropdownList({
      formList: forms,
      projectIdMap,
      projectId,
      shouldFilterByProjects: !shouldDisableSmartFiltering,
    });
    placeholder = 'Select Form';
  } else if (dataType === 'Cards') {
    if (responding) {
      const statusMap = getIdMap(boardDetailsMap?.[subDataType]?.statuses);
      data = boardDetailsMap?.[subDataType]?.cards?.map((card) => ({
        id: card.id,
        name: formatCardTitle(card),
        subNames: [
          statusMap[card.statusId]?.title ?? '',
          card.lastUpdated ? DateTime.fromMillis(card.lastUpdated).toLocaleString(DateTime.DATETIME_MED) : '',
        ],
      })) ?? [];
    } else {
      data = actualSelected ?? [];
    }
    placeholder = 'Select Card';
  } else if (dataType === 'Buckets') {
    const buckets = bucketTemplateMap?.[subDataType] ?? [];
    const filterIds = Buckets.getFilterIdsForBucketDropdown({
      buckets,
      customerId,
      projectId,
      selectedBucketTypeToIdMap,
      subDataType,
    });

    data = buckets;
    allData = buckets;

    if (!shouldDisableSmartFiltering && filterIds.length) {
      data = Buckets.getLinkedBuckets(buckets, filterIds, ['customers', 'projects', ...selectedBucketTypes]).allBuckets;
    }

    placeholder = 'Select Bucket';
  } else if (dataType === 'PayableSubContracts') {
    data = formatFormDropdownList({
      formList: subContractForms,
      projectIdMap,
      projectId,
      shouldFilterByProjects: !shouldDisableSmartFiltering,
    });
    placeholder = 'Select Sub-Contract';
  }

  const dataIdMap = getIdMap(data);
  const options = responding || isFormBuilder ? data : selected;
  let validData;
  if (dataType === 'Costcodes') {
    const allValidIds = [];
    if (responding) {
      options.forEach((phase) => {
        const { children = [] } = phase || {};
        children.forEach((costcode) => {
          if (!costcode.value) return;
          allValidIds.push(costcode.value);
        });
      });
    } else {
      options.forEach((selectedOption) => {
        const { id: selectedId } = selectedOption || {};
        if (selectedId) allValidIds.push(selectedId);
      });
    }
    validData = new Set(allValidIds);
  } else if (dataType === 'CompletedForms' && parentForm) {
    // getLightWeightForms takes some time so options is initially empty
    validData = new Set([parentForm.id]);
  } else {
    validData = new Set(options.map((datum) => datum.id));
  }

  const onSelectedChange = (newValue) => {
    let newSelected = newValue;

    // Radio Style Dropdown
    if (newValue?.target?.value) {
      newSelected = newValue.target.value;
    }
    const selectedIds = Array.isArray(newSelected) ? newSelected : [newSelected];
    const values = selectedIds
      .filter((selectedId) => !!selectedId)
      .map((selectedId) => {
        if (dataType === 'Costcodes') {
          const descriptiveName = getDescriptivePhaseCostcodeName({
            projectId,
            selectedId,
            phaseMap: phaseIdMap,
            costcodeMap: costcodeIdMap,
          });
          return { id: selectedId, name: descriptiveName };
        }
        const {
          [selectedId]: { name: selectedName } = {},
        } = dataIdMap;
        return { id: selectedId, name: selectedName };
      });
    if (!responding) {
      setPreviewProps?.({
        ...previewProps,
        selected: values,
      });
      return;
    }
    const [{ id: firstId } = {}] = values;
    if (dataType === 'Customers' && setCustomerId) {
      setCustomerId(firstId);
      if (setProjectId && projectId && firstId) {
        const {
          [projectId]: { customerId: projectCustomerId } = {},
        } = projectIdMap;
        if (projectCustomerId !== firstId) setProjectId();
      }
    } else if (dataType === 'Projects' && setProjectId) {
      setProjectId(firstId);
    } else if (dataType === 'Vendors' && setVendorId) {
      setVendorId(firstId);
    } else if (dataType === 'Contacts' && setContactId) {
      setContactId(firstId);
    } else if (dataType === 'Costcodes' && setCostcodeId) {
      setCostcodeId(firstId);
    } else if (dataType === 'Users' && setUserId) {
      setUserId(firstId);
    } else if (dataType === 'Buckets' && setBucketId) {
      setBucketId(firstId);
    } else if (dataType === 'Sub-Contracts' && setSubContractId) {
      setSubContractId(firstId);
    }
    setResponses({
      ...responses,
      [id]: {
        ...(responses[id]),
        values,
      },
    });
  };

  const validateSelectedItemsInFetchedData = (selectedItems, dataSource, fetchedData) => {
    const newSelectedItems = [];

    const dataSourceIdSet = new Set(dataSource.map((datum) => datum.id));
    const fetchedDataIdSet = new Set(fetchedData.map((datum) => datum.id));

    selectedItems?.forEach((selectedItem) => {
      const selectedId = getId(selectedItem);
      // If data is not fetched yet or it is already fetched, keep it
      if (
        !dataSourceIdSet.has(selectedId)
        || fetchedDataIdSet.has(selectedId)
      ) {
        newSelectedItems.push(selectedItem);
      }
    });

    if (selectedItems?.length !== newSelectedItems.length) {
      setTimeout(() => {
        onSelectedChange(newSelectedItems);
      });
    }
  };

  const actualSelectedIds = [];

  actualSelected.forEach((selectedItem) => {
    // Backwards compatible.
    // Versions <= 2.3.2 store dropdown values as strings, instead of objects
    const val = typeof selectedItem === 'string' ? selectedItem : selectedItem.id;
    if (val) {
      actualSelectedIds.push(val);
      actualSelectedSet.add(val);
    }
  });

  if (!dataTypesThatRequireFetch.has(dataType) || options?.length) {
    const invalidSelected = new Set(actualSelectedIds
      .filter((selectedId) => !validData.has(selectedId)));
    if (invalidSelected.size > 0) {
      // This usually happens when a dropdown is dependent on another dropdown
      // And that dropdown de-selects/selects another value (ie. customers and contact dropdown)
      // We need to wait for the other dropdown to update before updating this one
      // Or else we run into a race condition causing React to batch the updates
      setTimeout(() => {
        onSelectedChange(actualSelectedIds
          .filter((selectedId) => !invalidSelected.has(selectedId)));
      });
    }
  } else if (dataType === 'Buckets' && allData?.length !== data?.length) {
    // Buckets are fetched in a separate call
    // We need to wait for the data to be fetched before checking to see if the selected items are valid
    validateSelectedItemsInFetchedData(
      actualSelected,
      allData,
      data,
    );
  }

  return (
    <div>
      <TitleRow
        title={title}
        optional={optional}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                isExternalForm={isExternalForm}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                id={id}
                fieldTriggerMap={fieldTriggerMap}
                setFieldTriggerMap={setFieldTriggerMap}
                name={name}
              />
            ) : null
        }
      />
      {responding && (canFilter[dataType])
        ? (
          <SelectLabelFilter
            selected={filterLabels}
            onSelectedChanged={onFilterChanged}
            style={{ margin: '15px 10px 5px 0px' }}
            type={dataType.toLowerCase()}
          />
        )
        : null}
      <Row style={{ marginTop: 10 }}>
        {dataType !== 'Costcodes' || !responding ? (
          <DropdownFieldSelect
            style={{ width: '100%', margin: '0px', pointerEvents: shouldLock ? 'none' : 'inherit' }}
            dropdownStyle={style}
            placeholder={placeholder}
            numAnswers={numAnswers}
            openLimit={openLimit}
            onChange={onSelectedChange}
            value={actualSelectedIds}
            disabled={!!parentForm}
            options={options}
            actualSelected={actualSelected}
            actualSelectedSet={actualSelectedSet}
          />
        ) : (
          <TreeSelect
            allowClear
            showSearch
            onChange={onSelectedChange}
            treeDefaultExpandAll={!projectId}
            treeNodeFilterProp="title"
            value={actualSelectedIds}
            placeholder={placeholder}
            multiple={numAnswers > 1 || openLimit}
            treeData={data}
            style={{ width: '100%', margin: '0px 10px', pointerEvents: shouldLock ? 'none' : 'inherit' }}
          />
        )}
      </Row>
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
Preview.propTypes = {
  setCostcodeId: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired,
  setSubContractId: PropTypes.func.isRequired,
  subContractForms: PropTypes.array,
};

Preview.defaultProps = {
  subContractForms: [],
};

export default {
  configure,
  preview: Preview,
  title: 'Choose one or more',
  description: 'User can choose one or more items from multiple options',
};
