import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Input,
  Menu,
  Popover,
} from 'antd';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

import Badge from '../common/badge';
import { includesTerm } from '../helpers/helpers';

const badgeStyle = {
  paddingTop: '0',
  paddingBottom: '1px',
  fontSize: '12px',
  position: 'absolute',
  lineHeight: '20px',
  paddingRight: 0,
};

const LARGE_BOARD_WARNING = (
  <div id="large-board-warning-container">
    <b>This board is now using High Volume Mode.</b>
    <br />
    <br />
    High Volume Mode is automatically applied to any board
    that has at least 2500 active cards.
    <br />
    <br />
    In High Volume Mode, the following functionality is disabled to preserve performance:
    <ul>
      <li>
        Fullscreen Mode
      </li>
      <li>
        Filtering / Sorting
      </li>
      <li>
        Card View
      </li>
      <li>
        Drag & Drop
      </li>
    </ul>
  </div>
);

function BoardSelector({
  boardList = [],
  tagsPerBoard = {},
  fullSelectedBoard = {},
  largeBoardMode,
  onBoardSelected,
}) {
  const inputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState();
  const [open, setOpen] = useState(false);

  const onClick = useCallback(({ key: boardId }) => {
    onBoardSelected(boardId);
  }, [onBoardSelected]);

  // Auto focus search upon opening
  useEffect(() => {
    if (open && inputRef && inputRef.current) {
      // Don't like this, but couldn't get it to work without timeout
      // We do similar in PDFDesignerItem
      setTimeout(inputRef.current.focus, 200);
    }
  }, [open, inputRef]);

  const onSearchChange = useCallback((e) => {
    const {
      target: {
        value: searchText,
      } = {},
    } = e;
    setSearchTerm(searchText);
  }, [boardList]);

  const filteredList = useMemo(() => boardList.filter(
    (board) => board.title && includesTerm(board.title, searchTerm),
  ), [boardList, searchTerm]);

  const menu = useMemo(() => (
    <Menu style={{ maxHeight: 500, overflow: 'scroll', paddingTop: 0 }}>
      <Menu.Item
        disabled
        style={{
          cursor: 'default',
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          borderBottom: '1px lightgray solid',
          zIndex: 1000,
          paddingTop: 4,
        }}
      >
        <Input.Search
          key="Search"
          type="search"
          className="searchbar"
          placeholder="Search"
          allowClear
          onChange={onSearchChange}
          ref={inputRef}
        />
      </Menu.Item>
      {filteredList.map((board) => {
        const { id: boardId } = board;
        const {
          [boardId]: boardTagCount = 0,
        } = tagsPerBoard;
        const showBadge = boardTagCount > 0;
        const style = {};
        if (showBadge) style.paddingRight = 30;
        if (boardId === fullSelectedBoard.id) style.fontWeight = 'bold';
        return (
          <Menu.Item
            key={boardId}
            style={style}
            onClick={onClick}
          >
            {board.title}
            {showBadge && <Badge count={boardTagCount} style={badgeStyle} />}
          </Menu.Item>
        );
      })}
    </Menu>
  ), [filteredList, tagsPerBoard, fullSelectedBoard, onClick]);

  return (
    <span>
      <Dropdown
        trigger={['click']}
        onVisibleChange={setOpen}
        overlay={menu}
      >
        <span id="board-crumb-button">
          {fullSelectedBoard.title}
        </span>
      </Dropdown>
      {largeBoardMode && (
      <span>
        <Popover
          placement="rightTop"
          content={LARGE_BOARD_WARNING}
          trigger={['click', 'hover']}
        >
          <ExclamationCircleTwoTone
            twoToneColor="darkOrange"
            id="large-board-warning-icon"
          />
        </Popover>
      </span>
      )}
    </span>
  );
}

/* eslint-disable react/forbid-prop-types */
BoardSelector.propTypes = {
  boardList: PropTypes.array.isRequired,
  tagsPerBoard: PropTypes.object.isRequired,
  fullSelectedBoard: PropTypes.object.isRequired,
  largeBoardMode: PropTypes.bool.isRequired,
  onBoardSelected: PropTypes.func.isRequired,
};

export default BoardSelector;
