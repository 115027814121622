/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import FormField from '../forms/FormBuilder/FormFields/FormField';

import BoardDates from './BoardDates';
import BoardText from './BoardText';

import { parseDateField } from './boards.helpers';

import { startsWithDollarSign } from '../forms/ResponderHelpers';

export default function BoardField({
  index,
  field = {},
  showCondensedView = false,
}) {
  const { selectedType, id, configProps = {} } = field;
  const fullField = {
    ...field,
    configProps: { ...configProps, optional: true },
  };
  const {
    response = {},
  } = field;
  const { title, isCurrency } = configProps;

  const formattedTitle = showCondensedView ? `${title}: ` : title;

  switch (selectedType) {
    case 'dateRange': {
      return (
        <BoardDates
          {...field}
          showCondensedView={showCondensedView}
          valueStyle={{
            fontSize: 14,
            paddingLeft: 5,
            minHeight: 22,
          }}
          titleStyle={{
            textDecoration: 'underline',
          }}
        />
      );
    }
    case 'dateTime': {
      return (
        <BoardText
          value={parseDateField(response)}
          title={formattedTitle}
          showCondensedView={showCondensedView}
          valueStyle={{
            fontSize: 14,
            paddingLeft: 5,
            minHeight: 22,
          }}
          titleStyle={{
            textDecoration: 'underline',
          }}
        />
      );
    }
    case 'text':
    case 'attribute':
    case 'calculation': {
      const { value = '' } = response;
      const prefix = isCurrency && !startsWithDollarSign(value) ? '$ ' : '';
      return (
        <BoardText
          value={`${prefix}${value}`}
          title={formattedTitle}
          showCondensedView={showCondensedView}
          valueStyle={{
            fontSize: 14,
            paddingLeft: 5,
            minHeight: 22,
          }}
          titleStyle={{
            textDecoration: 'underline',
          }}
        />
      );
    }
    case 'dropdown': {
      const { values = [] } = response;
      return (
        <BoardText
          value={values.reduce((acc, val) => {
            if (val.name) return `${acc}${acc.length > 0 ? ', ' : ''}${val.name}`;
            return acc;
          }, '')}
          title={formattedTitle}
          showCondensedView={showCondensedView}
          valueStyle={{
            fontSize: 14,
            paddingLeft: 5,
            minHeight: 22,
          }}
          titleStyle={{
            textDecoration: 'underline',
          }}
        />
      );
    }
    case 'yes-no': {
      const { value, explanation } = response;
      let ans;
      // value === undefined if user didnt fill out the field
      if (value === true || value === 'yes') {
        ans = 'Yes';
      } else if (value === false || value === 'no') {
        ans = 'No';
      }
      if (explanation) ans += `. ${explanation}`;
      return (
        <BoardText
          value={ans}
          title={formattedTitle}
          showCondensedView={showCondensedView}
          valueStyle={{
            fontSize: 14,
            paddingLeft: 5,
            minHeight: 22,
          }}
          titleStyle={{
            textDecoration: 'underline',
          }}
        />
      );
    }
    default:
      return (
        <FormField
          key={id}
          sectionId={id}
          index={index}
          field={fullField}
          isDisplay
          draggable={false}
          showCondensedView={showCondensedView}
        />
      );
  }
}
