import React from 'react';
import { Row } from 'antd';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

import TitleRow from './TitleRow';

import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import FieldTriggerFlag from './FieldTriggerFlag';
import ConditionalRenderingRow from './ConditionalRenderingRow';

const preview = ({
  configProps = {},
  id,
  responding = false,
  responses = {},
  setResponses,
  sections,
  isExternalForm,
  projectId,
  templateId,
  divisions,
  fieldTriggerMap = {},
  setFieldTriggerMap,
  isDisplay,
  name,
} = {}) => {
  const {
    title = 'Title goes here',
    fieldTrigger,
  } = configProps;

  if (responding) {
    if (responses[id]?.value !== title) {
      setResponses((oldResponses) => ({
        ...oldResponses,
        [id]: {
          value: title,
        },
      }));
    }
  }

  return (
    <TitleRow
      title={title}
      optional
      filter={
        fieldTrigger && !isDisplay
          ? (
            <FieldTriggerFlag
              sections={sections}
              isExternalForm={isExternalForm}
              templateId={templateId}
              projectId={projectId}
              divisions={divisions}
              configProps={configProps}
              responding={responding}
              id={id}
              fieldTriggerMap={fieldTriggerMap}
              setFieldTriggerMap={setFieldTriggerMap}
              name={name}
            />
          ) : null
      }
    />
  );
};

const configure = ({
  setConfigProps,
  configProps = {},
  setCanSubmit,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  id,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  buckets = [],
}) => {
  const {
    hasConditionalRendering = false,
    conditionalRenderingFormula,
  } = configProps;

  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title: value,
    });
    setCanSubmit(value);
  };

  const setFieldTrigger = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      fieldTrigger: checked,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setConditionalRenderingFormula = (formula) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: formula,
    });
  };

  return (
    <div>
      <Row className="form-required-field">
        Text:
      </Row>
      <Row style={{ marginTop: 5, marginBottom: 5 }}>
        <OnTraccrTextInput
          textarea
          placeholder="Insert title here"
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <ConditionalRenderingRow
        onChange={setHasConditionalRendering}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
        onFormulaChange={setConditionalRenderingFormula}
        sections={sections}
        id={id}
        customers={customers}
        projects={projects}
        projectIdMap={projectIdMap}
        users={users}
        costcodes={costcodes}
        phases={phases}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
      />
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={setFieldTrigger}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          isExternalForm={isExternalForm}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  );
};
export default {
  configure,
  preview,
  title: 'Static Text',
  description: 'Define some text to display to users filling out the form.',
};
