import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  notification,
  Form,
  Switch,
  Collapse,
} from 'antd';
import {
  DeleteOutlined,
  ExportOutlined, ImportOutlined, LockOutlined, PlusOutlined, SettingOutlined, UnlockOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import DraggableTable from './DraggableTable';
import {
  getScheduleOfValueSections,
  getProjectScheduleOfValues,
  updateProjectScheduleOfValues,
  swapRowsProjectScheduleOfValues,
  updateIndividualProject,
  deleteProjectScheduleOfValueRow,
  deleteScheduleOfValueSection,
} from '../state/projects.actions';
import { getScheduleOfValueColumns, getLiveSummaryColumns } from './ScheduleOfValuesColumns';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import ScheduleOfValuesLog from './ScheduleOfValuesLog';
import ScheduleOfValuesAddRow from './ScheduleOfValuesAddRow';
import ScheduleOfValuesSubmissionReview from './ScheduleOfValuesSubmissionReview';
import ScheduleOfValuesPDFPreview from './PDF/ScheduleOfValuesPDFPreview';
import HistoryDrawer from './History/HistoryDrawer';
import Permissions from '../../auth/Permissions';
import { getIdMap, isNullOrUndefined } from '../../helpers/helpers';
import { getFileType } from '../../files/fileHelpers';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import {
  scheduleOfValuesData,
  filterScheduleOfValues,
  getSubContractsWithTotalChanges,
} from './helpers';
import ScheduleOfValuesPastBillReview from './ScheduleOfValuesPastBillReview';
import ScheduleOfValuesTemplateImport from './ScheduleOfValuesTemplateImport';
import ProjectScheduleOfValuesSubContract from './ProjectScheduleOfValuesSubContract';
import ScheduleOfValuesAddSectionModal from './ScheduleOfValuesAddSectionModal';
import useToggle from '../../common/hooks/useToggle';
import Colors from '../../constants/Colors';

const { Panel } = Collapse;

const DEFAULT_HOLDBACK_AMOUNT = 0.10;
const BASE_PANEL = 'baseContract';
const SUB_CONTRACT_PANEL = 'subContracts';
const CHANGES_PANEL = 'changes';
const NON_HOLDBACK_PANEL = 'nonHoldbackItems';
const DEFAULT_ACTIVE_PANELS = [BASE_PANEL, CHANGES_PANEL,NON_HOLDBACK_PANEL];

export default function ProjectScheduleOfValues({
  projectId,
  templateId,
  containerStyle = {},
  readOnly = false,
}) {
  const dispatch = useDispatch();

  const {
    scheduleOfValues = {},
    totalBaseContractValue,
    holdbackPercentage,
    isScheduleOfValueLocked,
  } = useSelector((state) => {
    const currentProject = state.projects.project.id === projectId
      ? state.projects.project
      : state.projects.projects.find((project) => project.id === projectId);

    return {
      ...state.projects,
      totalBaseContractValue: currentProject
        ? currentProject.totalContractAmount
        : 0,
      holdbackPercentage: currentProject
        ? currentProject.holdbackPercentage
        : 0,
      isScheduleOfValueLocked: currentProject
        ? currentProject.isScheduleOfValueLocked
        : false,
    };
  });
  const scheduleOfValueSections = useSelector((state) => state.projects.scheduleOfValueSections);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [activePanels, setActivePanels] = useState(DEFAULT_ACTIVE_PANELS);
  const [selectedRecord, setSelectedRecord] = useState();
  const [baseContractValues, setBaseContractValues] = useState([]);
  const [subContractValues, setSubContractValues] = useState([]);
  const [changeOrderValues, setChangeOrderValues] = useState([]);
  const [liveSummaryValues, setLiveSummaryValues] = useState([]);
  const [nonHoldbackValues, setNonHoldbackValues] = useState([]);
  const [subContractCOs, setSubContractCOs] = useState({});
  const [logValues, setLogValues] = useState([]);
  const [currentHoldbackPercentage, setCurrentHoldbackPercentage] = useState(holdbackPercentage);
  const [newItemNumbers, setNewItemNumbers] = useState({
    baseContract: 1,
    subContract: 1,
    subContractCOs: 1,
    changeOrder: 1,
    nonHoldback: 1,
  });
  const [sovSnapshot, setSOVSnapshot] = useState(null);
  const [sectionValueMap, setSectionValueMap] = useState({});

  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [isLocked, setIsLocked] = useState(isScheduleOfValueLocked);
  const [selectedSovBill, setSelectedSovBill] = useState();
  const [selectedSection, setSelectedSection] = useState();

  const clearSelectedFile = useCallback(() => setSelectedFile({}), []);
  const {
    toggle: toggleAddSectionModal,
    isToggled: isAddSectionModalToggled,
  } = useToggle();

  const switchDrawer = (drawer) => {
    if (visibleDrawer === 'export') {
      setSOVSnapshot(null);
    }

    setFiles([]);
    clearSelectedFile();
    setVisibleDrawer(drawer);
  };

  const onCloseDrawer = useCallback(() => switchDrawer(false), []);
  const onHistoryClick = useCallback((record) => {
    switchDrawer('history');
    setSelectedRecord(record);
  }, []);

  const onInfoClick = useCallback((record) => {
    switchDrawer('sub-contract');
    setSelectedRecord(record);
  }, []);

  const onDeleteClick = useCallback((record) => {
    const onDelete = () => {
      dispatch(deleteProjectScheduleOfValueRow({
        projectId,
        templateId,
        rowId: record.rowId,
      }));
    };

    return new Promise((resolve) => {
      CustomConfirmModal({
        title: 'Delete Confirmation',
        content: (
          <p>
            Are you sure you wish to delete this row?
          </p>
        ),
        okText: 'Delete',
        cancelText: 'Cancel',
        async onOk() {
          resolve(await onDelete());
        },
        onCancel() {
          resolve();
        },
      });
    });
  }, [projectId, templateId]);

  const isReadOnly = !Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE') || readOnly;
  const columns = useMemo(
    () => getScheduleOfValueColumns({
      onHistory: onHistoryClick,
      onDelete: onDeleteClick,
      readOnly: isReadOnly,
      liveSummaryValues,
      isScheduleOfValueLocked,
    }),
    [
      onHistoryClick,
      liveSummaryValues,
      isReadOnly,
      isScheduleOfValueLocked,
      onDeleteClick,
    ],
  );

  const subcontractColumns = useMemo(
    () => getScheduleOfValueColumns({
      onInfo: onInfoClick,
      onHistory: onHistoryClick,
      onDelete: onDeleteClick,
      readOnly: isReadOnly,
      isSubContract: true,
      liveSummaryValues,
      isScheduleOfValueLocked,
    }),
    [
      onHistoryClick,
      onInfoClick,
      liveSummaryValues,
      isReadOnly,
      isScheduleOfValueLocked,
      onDeleteClick,
    ],
  );

  const liveSummaryColumns = useMemo(
    () => getLiveSummaryColumns({
      onClick: () => setVisibleDrawer('review'),
      readOnly: isReadOnly || templateId,
      showHoldback: currentHoldbackPercentage,
      isScheduleOfValueLocked,
    }),
    [currentHoldbackPercentage, isReadOnly, isScheduleOfValueLocked, templateId],
  );

  useEffect(() => {
    dispatch(getProjectScheduleOfValues({
      projectId,
      templateId,
    }));
    dispatch(getScheduleOfValueSections({
      projectId,
      templateId,
    }));
  }, [projectId, templateId]);

  const scheduleOfValueSectionMap = useMemo(() => (
    getIdMap(scheduleOfValueSections)
  ), [scheduleOfValueSections]);

  useEffect(() => {
    const {
      newBaseContractValues,
      newSubContractValues,
      newSubContractCOs,
      newChangeOrderValues,
      newNonHoldbackItems,
      newLiveSummaryValue,
      maxItemNumbers,
      sectionValueMap: newSectionValueMap,
    } = scheduleOfValuesData({
      projectId,
      templateId,
      totalBaseContractValue,
      holdbackPercentage,
      scheduleOfValues,
      setLogValues,
      scheduleOfValueSectionMap,
    });
    setBaseContractValues(newBaseContractValues);
    setSubContractValues(newSubContractValues);
    setSubContractCOs(newSubContractCOs);
    setChangeOrderValues(newChangeOrderValues);
    setNonHoldbackValues(newNonHoldbackItems);
    setLiveSummaryValues([newLiveSummaryValue]);
    setNewItemNumbers(maxItemNumbers);
    setSectionValueMap(newSectionValueMap);

    let newActivePanels = [...DEFAULT_ACTIVE_PANELS];

    if (newSubContractValues.length) {
      newActivePanels = newActivePanels.concat(SUB_CONTRACT_PANEL);
    }

    if (Object.keys(newSectionValueMap).length) {
      newActivePanels = newActivePanels.concat(Object.keys(newSectionValueMap));
    }

    setActivePanels(newActivePanels);
  }, [
    scheduleOfValues,
    totalBaseContractValue,
    holdbackPercentage,
    projectId,
    templateId,
    scheduleOfValueSectionMap,
    setBaseContractValues,
    setChangeOrderValues,
    setLiveSummaryValues,
    setLogValues,
    setNewItemNumbers,
  ]);

  const handleSubmit = useCallback(async (payload) => {
    const updatePayload = {
      ...payload,
      isSubContract: visibleDrawer === 'sub-contract' || visibleDrawer === 'addRowSubContract',
      isChangeOrder: payload.isChangeOrder || visibleDrawer === 'addRowChangeOrder',
      excludeHoldback: visibleDrawer === 'addRowNonHoldbackItems',
      sectionId: selectedSection?.id || null,
    };

    const result = await dispatch(updateProjectScheduleOfValues({
      projectId,
      templateId,
      payload: updatePayload,
    }));

    if (result) {
      notification.close('addRowKey');
      if (visibleDrawer !== 'sub-contract') onCloseDrawer();
      const newVals = { ...newItemNumbers };
      if (payload.isChangeOrder) {
        if (payload.isSubContract) {
          newVals.subContractCOs += 1;
        } else {
          newVals.changeOrder += 1;
        }
      } else if (payload.isSubContract) {
        newVals.subContract += 1;
      } else if (payload.excludeHoldback) {
        newVals.nonHoldback += 1;
      } else if (updatePayload.sectionId) {
        newVals[updatePayload.sectionId] += 1;
      } else {
        newVals.baseContract += 1;
      }

      setNewItemNumbers(newVals);
    }
    return result;
  }, [
    projectId,
    templateId,
    liveSummaryValues,
    newItemNumbers,
    visibleDrawer,
    selectedSection,
  ]);

  const onSetData = useCallback((type, newData) => {
    const updatedData = newData.map((item, index) => ({
      ...item,
      oldItemNumber: isNullOrUndefined(item.oldItemNumber)
        ? item.itemNumber
        : item.oldItemNumber,
      itemNumber: index + 1,
      isChangeOrder: !!item.isChangeOrder,
      isSubContract: !!item.isSubContract,
      updatedField: 'itemNumber',
      excludeHoldback: !!item.excludeHoldback,
    }));

    dispatch(swapRowsProjectScheduleOfValues({
      projectId,
      templateId,
      updatedRows: updatedData,
    }));

    switch (type) {
      case 'baseContract':
        return setBaseContractValues(updatedData);
      case 'subContract':
        return setSubContractValues(updatedData);
      case 'changeOrder':
        return setChangeOrderValues(updatedData);
      case 'subContractChangeOrders': {
        const newSubContractCOs = { ...subContractCOs };
        // parentRowId will be the same for all
        const [{ parentRowId } = {}] = updatedData;
        newSubContractCOs[parentRowId] = {
          ...newSubContractCOs[parentRowId] ?? {},
          changes: updatedData,
        };
        setSubContractCOs(newSubContractCOs);
        break;
      }
      case 'nonHoldbackItems':
        return setNonHoldbackValues(updatedData);
      default:
        return setSectionValueMap((prev) => ({
          ...prev,
          [type]: updatedData,
        }));
    }
  }, [projectId, templateId, subContractCOs]);

  const onPreviewClick = useCallback((index) => {
    const file = files[index];

    if (file) {
      if (file instanceof File) {
        setSelectedFile({
          file,
          type: getFileType(file),
        });
      } else {
        setSelectedFile({
          ...file,
          type: getFileType(file),
        });
      }
    }
  }, [files]);

  const addFile = (file) => {
    setFiles([...files, file]);
  };

  const updateFile = useCallback((oldFile, updatedFile) => {
    if (!updatedFile) return clearSelectedFile();
    const newFiles = files.map((file) => {
      if (file === oldFile) return updatedFile;
      return file;
    });
    setFiles(newFiles);
    clearSelectedFile();
    return null;
  }, [files, clearSelectedFile]);

  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const onSubmitSuccess = (snapshot) => {
    setSOVSnapshot(snapshot);
    setVisibleDrawer('export');
  };

  const getSovSnapshot = useCallback((timestamp) => {
    const snapshotScheduleOfValues = filterScheduleOfValues(scheduleOfValues, timestamp);

    const {
      newBaseContractValues,
      newSubContractValues,
      newSubContractCOs,
      newChangeOrderValues,
      newNonHoldbackItems,
      newLiveSummaryValue,
      sectionValueMap: newSectionValueMap,
    } = scheduleOfValuesData({
      projectId,
      templateId,
      totalBaseContractValue,
      holdbackPercentage,
      scheduleOfValues: snapshotScheduleOfValues,
      scheduleOfValueSectionMap,
    });

    const snapshot = {
      baseContractValues: newBaseContractValues,
      subContractCOs: newSubContractCOs,
      subContractValues: newSubContractValues,
      changeOrderValues: newChangeOrderValues,
      nonHoldbackValues: newNonHoldbackItems,
      liveSummaryValues: [newLiveSummaryValue],
      sectionValueMap: newSectionValueMap,
    };

    return snapshot;
  }, [scheduleOfValues, projectId, templateId, scheduleOfValueSectionMap]);

  const onLogPdfClicked = useCallback((record) => {
    const {
      timestamp,
    } = record;

    const snapshot = getSovSnapshot(timestamp);

    setSOVSnapshot(snapshot);
    setVisibleDrawer('export');
  }, [scheduleOfValues]);

  const onLogEditClicked = useCallback((record) => {
    const {
      timestamp,
    } = record;

    const snapshot = getSovSnapshot(timestamp);

    setSelectedSovBill(snapshot);
    setVisibleDrawer('past');
  }, [scheduleOfValues]);

  const onHoldbackToggled = async (checked) => {
    const result = await dispatch(updateIndividualProject({
      projectId,
      payload: {
        details: {
          holdbackPercentage: checked ? DEFAULT_HOLDBACK_AMOUNT : 0,
        },
      },
    }));

    if (!result) {
      return;
    }

    setCurrentHoldbackPercentage(checked ? DEFAULT_HOLDBACK_AMOUNT : 0);
  };

  const toggleLockedStatus = useCallback(async () => {
    const unlockProject = async () => {
      if (await dispatch(updateIndividualProject({
        projectId,
        payload: {
          details: {
            isScheduleOfValueLocked: !isLocked,
          },
        },
      }))) {
        setIsLocked(!isLocked);
      }
    };

    if (isLocked) {
      return new Promise((resolve) => {
        CustomConfirmModal({
          title: 'Unlock Confirmation',
          content: (
            <p>
              Are you sure you wish to unlock the schedule of value settings?
              <br />
              <br />
              Changing the schedule of value settings may cause
              inaccurate calculations on existing rows.
            </p>
          ),
          okText: 'Unlock',
          cancelText: 'Cancel',
          async onOk() {
            resolve(await unlockProject());
          },
          onCancel() {
            resolve();
          },
        });
      });
    }

    return unlockProject();
  }, [isLocked]);

  const getPanelButton = ({ onClick, disabled = false }) => {
    const onButtonClick = (event) => {
      // Prevent panel from collapsing;
      event.stopPropagation();
      onClick();
    };

    return (
      <OnTraccrButton
        title="Add Row"
        onClick={onButtonClick}
        disabled={disabled}
      />
    );
  };

  const getMaxItemNumber = (drawer) => {
    if (!drawer) return 1;
    switch (drawer) {
      case 'addRowBaseContract':
        return newItemNumbers.baseContract;
      case 'addRowSubContract':
        return newItemNumbers.subContract;
      case 'addRowChangeOrder':
        return newItemNumbers.changeOrder;
      case 'addRowNonHoldbackItems':
        return newItemNumbers.nonHoldback;
      default: {
        const sectionId = drawer.split('addRow')?.[1];
        return newItemNumbers[sectionId] || 1;
      };
    }
  };

  const subContractsWithTotalChanges = useMemo(() => (
    getSubContractsWithTotalChanges({
      subContractValues,
      subContractCOs,
    })
  ), [subContractValues, subContractCOs]);

  const onSectionDelete = (section) => (
    CustomConfirmModal({
      title: `Delete Section: ${section.name}`,
      content: (
        <p>
          Are you sure you wish to delete this section?
          <br />
          <br />
          Deleting this section will remove all rows associated with it.
        </p>
      ),
      okText: 'Delete',
      cancelText: 'Cancel',
      async onOk() {
        await dispatch(deleteScheduleOfValueSection({
          projectId,
          templateId,
          sectionId: section.id,
        }));
      },
    })
  )

  const nextSectionOrderIndex = useMemo(() => {
    const lastSection = scheduleOfValueSections[scheduleOfValueSections.length - 1];
    return lastSection ? lastSection.orderIndex + 1 : 0;
  }, [scheduleOfValueSections]);

  return (
    <>
      <div className="project-progress-container contract-container" style={{ ...containerStyle }}>
        { projectId && (
          <div className="project-contract-fixed-header">
            <div className="project-contract-header">
              <h3>Schedule of Values</h3>
              {
                !isReadOnly && (
                  <Form.Item name="toggleHoldback" label="Toggle Holdback" className="project-contract-switch">
                    <Switch
                      checked={currentHoldbackPercentage}
                      onChange={onHoldbackToggled}
                      disabled={isScheduleOfValueLocked}
                    />
                  </Form.Item>
                )
              }
              {
                !isReadOnly && (
                  <OnTraccrButton
                    title={isScheduleOfValueLocked ? 'Unlock' : 'Lock'}
                    icon={isScheduleOfValueLocked ? <UnlockOutlined /> : <LockOutlined />}
                    onClick={toggleLockedStatus}
                  />
                )
              }
              {
                !isReadOnly && (
                  <OnTraccrButton
                    title="Import"
                    icon={<ImportOutlined />}
                    disabled={Object.keys(scheduleOfValues).length > 0}
                    onClick={() => setVisibleDrawer('import')}
                  />
                )
              }
              {
                !isReadOnly && (
                  <OnTraccrButton
                    title="Export"
                    icon={<ExportOutlined />}
                    onClick={() => setVisibleDrawer('export')}
                  />
                )
              }
              <OnTraccrButton
                title="Log"
                type="back"
                onClick={() => switchDrawer('log')}
              />
            </div>
            <Table
              columns={liveSummaryColumns}
              dataSource={liveSummaryValues}
              pagination={false}
              rowKey="projectId"
              className="live-summary-table"
            />
          </div>
        )}
        <div className="project-contract-body">
          <Collapse
            activeKey={activePanels}
            onChange={setActivePanels}
            className="project-sov-collapse"
          >
            <Panel
              header="Base Contract"
              key="baseContract"
              extra={!isReadOnly && getPanelButton({ disabled: isLocked, onClick: () => switchDrawer('addRowBaseContract') })}
            >
              <DraggableTable
                columns={columns}
                dataSource={baseContractValues}
                pagination={false}
                expandable={{
                  defaultExpandAllRows: true,
                }}
                type={BASE_PANEL}
                setData={onSetData}
                isDraggable={!isReadOnly && !isScheduleOfValueLocked}
              />
            </Panel>
            <Panel
              header="Sub-Contracts"
              key={SUB_CONTRACT_PANEL}
              extra={!isReadOnly && getPanelButton({ disabled: isLocked, onClick: () => switchDrawer('addRowSubContract') })}
            >
              <DraggableTable
                columns={subcontractColumns}
                dataSource={subContractsWithTotalChanges}
                pagination={false}
                expandable={{
                  defaultExpandAllRows: true,
                }}
                type="subContract"
                setData={onSetData}
                isDraggable={!isReadOnly && !isScheduleOfValueLocked}
              />
            </Panel>
            <Panel
              header="Changes"
              key={CHANGES_PANEL}
              extra={!isReadOnly && getPanelButton({ onClick: () => switchDrawer('addRowChangeOrder') })}
            >
              <DraggableTable
                columns={columns}
                dataSource={changeOrderValues}
                pagination={false}
                expandable={{
                  defaultExpandAllRows: true,
                }}
                isDraggable={!isReadOnly && !isScheduleOfValueLocked}
                type="changeOrders"
                setData={onSetData}
              />
            </Panel>
            { currentHoldbackPercentage && (
              <Panel
                header="Non-Holdback Items"
                key={NON_HOLDBACK_PANEL}
                extra={!isReadOnly && getPanelButton({ onClick: () => switchDrawer('addRowNonHoldbackItems') })}
              >
                <DraggableTable
                  columns={columns}
                  dataSource={nonHoldbackValues}
                  pagination={false}
                  expandable={{
                    defaultExpandAllRows: true,
                  }}
                  isDraggable={!isReadOnly && !isScheduleOfValueLocked}
                  type="nonHoldbackItems"
                  setData={onSetData}
                />
              </Panel>
            )}
            { scheduleOfValueSections.map((section) => (
              <Panel
                header={section.name}
                key={section.id}
                extra={
                  !isReadOnly && (
                    <>
                      <SettingOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleAddSectionModal();
                          setSelectedSection(section);
                        }}
                      />
                      <DeleteOutlined
                        style={{ color: Colors.ONTRACCR_RED, paddingLeft: 10, paddingRight: 10 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onSectionDelete(section)
                        }}
                      />
                      {getPanelButton({
                        onClick: () => {
                          setSelectedSection(section);
                          switchDrawer('addRow' + section.id)
                        }
                      })}
                    </>
                )}
              >
                <DraggableTable
                  columns={columns}
                  dataSource={sectionValueMap[section.id] || []}
                  pagination={false}
                  expandable={{
                    defaultExpandAllRows: true,
                  }}
                  type={section.id}
                  setData={onSetData}
                  isDraggable={!isReadOnly && !isScheduleOfValueLocked}
                />
              </Panel>
            ))}
            <OnTraccrButton
              title="Add Section"
              icon={<PlusOutlined />}
              style={{ width: '100%', borderRadius: 0 }}
              onClick={toggleAddSectionModal}
            />
          </Collapse>
        </div>
      </div>
      <ScheduleOfValuesAddRow
        templateId={templateId}
        liveSummaryValues={liveSummaryValues}
        newItemNumber={getMaxItemNumber(visibleDrawer)}
        isChangeOrder={visibleDrawer === 'addRowChangeOrder'}
        isNonHoldback={visibleDrawer === 'addRowNonHoldbackItems'}
        visible={visibleDrawer && visibleDrawer.includes('addRow')}
        onClose={onCloseDrawer}
        handleSubmit={handleSubmit}
      />
      <ScheduleOfValuesLog
        logs={logValues}
        visible={visibleDrawer === 'log'}
        onClose={onCloseDrawer}
        onPdf={onLogPdfClicked}
        onEdit={onLogEditClicked}
      />
      <ScheduleOfValuesSubmissionReview
        projectId={projectId}
        baseContractValues={baseContractValues}
        changeOrderValues={changeOrderValues}
        liveSummaryValues={liveSummaryValues}
        nonHoldbackValues={nonHoldbackValues}
        scheduleOfValueSectionMap={scheduleOfValueSectionMap}
        sectionValueMap={sectionValueMap}
        subContractsWithTotalChanges={subContractsWithTotalChanges}
        subContractCOs={subContractCOs}
        visible={visibleDrawer === 'review'}
        onClose={onCloseDrawer}
        files={files}
        onPreviewClick={onPreviewClick}
        removeFile={removeFile}
        addFile={addFile}
        setFiles={setFiles}
        updateFile={updateFile}
        selectedFile={selectedFile}
        clearSelectedFile={clearSelectedFile}
        currentHoldbackPercentage={currentHoldbackPercentage}
        onSubmitSuccess={onSubmitSuccess}
      />
      <ScheduleOfValuesPastBillReview
        snapshot={selectedSovBill}
        visible={visibleDrawer === 'past'}
        onClose={onCloseDrawer}
        files={files}
        onPreviewClick={onPreviewClick}
        removeFile={removeFile}
        addFile={addFile}
        setFiles={setFiles}
        updateFile={updateFile}
        selectedFile={selectedFile}
        clearSelectedFile={clearSelectedFile}
        currentHoldbackPercentage={currentHoldbackPercentage}
      />
      <HistoryDrawer
        visible={visibleDrawer === 'history'}
        record={selectedRecord}
        onClose={onCloseDrawer}
        isReadOnly={isReadOnly}
        files={files}
        onPreviewClick={onPreviewClick}
        removeFile={removeFile}
        addFile={addFile}
        setFiles={setFiles}
        updateFile={updateFile}
        selectedFile={selectedFile}
        clearSelectedFile={clearSelectedFile}
      />
      <ScheduleOfValuesPDFPreview
        projectId={projectId}
        templateId={templateId}
        baseContractValues={
          sovSnapshot
            ? sovSnapshot.baseContractValues
            : baseContractValues
        }
        subContractValues={
          sovSnapshot
            ? sovSnapshot.subContractValues
            : subContractValues
        }
        changeOrderValues={
          sovSnapshot
            ? sovSnapshot.changeOrderValues
            : changeOrderValues
        }
        subContractCOs={
          sovSnapshot
            ? sovSnapshot.subContractCOs
            : subContractCOs
        }
        nonHoldbackValues={
          sovSnapshot
            ? sovSnapshot.nonHoldbackValues
            : nonHoldbackValues
        }
        liveSummaryValues={
          sovSnapshot
            ? sovSnapshot.liveSummaryValues
            : liveSummaryValues
        }
        sectionValueMap={
          sovSnapshot
            ? sovSnapshot.sectionValueMap
            : sectionValueMap
        }
        currentHoldbackPercentage={currentHoldbackPercentage}
        visible={visibleDrawer === 'export'}
        onClose={onCloseDrawer}
      />
      <ScheduleOfValuesTemplateImport
        visible={visibleDrawer === 'import'}
        onClose={onCloseDrawer}
        projectId={projectId}
      />
      <ProjectScheduleOfValuesSubContract
        visible={visibleDrawer === 'sub-contract'}
        onClose={onCloseDrawer}
        record={selectedRecord}
        isReadOnly={isReadOnly}
        liveSummaryValues={liveSummaryValues}
        isScheduleOfValueLocked={isScheduleOfValueLocked}
        onDeleteClick={onDeleteClick}
        templateId={templateId}
        onAddChangeOrder={handleSubmit}
        newItemNumbers={newItemNumbers}
        subContractCOs={subContractCOs}
        files={files}
        onPreviewClick={onPreviewClick}
        removeFile={removeFile}
        addFile={addFile}
        setFiles={setFiles}
        updateFile={updateFile}
        selectedFile={selectedFile}
        clearSelectedFile={clearSelectedFile}
        onSetData={onSetData}
      />
      <ScheduleOfValuesAddSectionModal
        visible={isAddSectionModalToggled}
        section={selectedSection}
        onClose={() => {
          toggleAddSectionModal();
          setSelectedSection();
        }}
        orderIndex={nextSectionOrderIndex}
        projectId={projectId}
        templateId={templateId}
      />
    </>
  );
}

ProjectScheduleOfValues.propTypes = {
  projectId: PropTypes.string,
  templateId: PropTypes.string,
  containerStyle: PropTypes.shape({}),
  readOnly: PropTypes.bool,
};

ProjectScheduleOfValues.defaultProps = {
  projectId: null,
  templateId: null,
  containerStyle: {},
  readOnly: false,
};
