import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Drawer, Timeline } from 'antd';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';

import { getIdMap } from '../../helpers/helpers';
import FormBuilder from '../../forms/FormBuilder/FormBuilder';
import { getSnapshotFormData } from '../../forms/formHelpers';
import DrawerSubmitFooter from '../containers/DrawerSubmitFooter';
import Step from '../containers/TimelineStep';
import useToggle from '../hooks/useToggle';
import OnTraccrEmpty from '../OnTraccrEmpty';

export default function SnapshotDrawer({
  open,
  onClose,
  snapshots = [],
  setSelectedFile,
  setSelectedFileDetails,
  isBoardCard,
}) {
  const users = useSelector((state) => state.users.users);
  const userMap = useMemo(() => getIdMap(users), [users]);

  const [selectedSnapshot, setSelectedSnapshot] = useState();
  const [selectedSnapshots, setSelectedSnapshots] = useState(new Set());

  const {
    toggle: toggleViewDifference,
    isToggled: isViewDifferenceToggled,
  } = useToggle();

  const orderedSnapshots = useMemo(() => {
    if (!isViewDifferenceToggled) return null;

    const [
      firstSnapshotId,
      secondSnapshotId,
    ] = Array.from(selectedSnapshots);

    const firstSnapshot = snapshots.find(({ id }) => id === firstSnapshotId);
    const secondSnapshot = snapshots.find(({ id }) => id === secondSnapshotId);

    if (!firstSnapshot && !secondSnapshot) return null;
    if (!firstSnapshot) return [secondSnapshot];
    if (!secondSnapshot) return [firstSnapshot];
    return [firstSnapshot, secondSnapshot]
      .sort((a, b) => b.timestamp - a.timestamp);
  }, [selectedSnapshots, isViewDifferenceToggled, snapshots]);

  const {
    formData,
    snapshotTitle,
  } = useMemo(() => {
    if (isViewDifferenceToggled && orderedSnapshots?.length === 2) {
      const [
        firstSnapshot,
        secondSnapshot,
      ] = orderedSnapshots;

      const firstSnapshotTitle = DateTime
        .fromMillis(firstSnapshot.timestamp)
        .toLocaleString(DateTime.DATETIME_MED);
      const secondSnapshotTitle = DateTime
        .fromMillis(secondSnapshot.timestamp)
        .toLocaleString(DateTime.DATETIME_MED);

      return {
        formData: getSnapshotFormData({
          snapshot: firstSnapshot,
          secondarySnapshot: secondSnapshot,
          setSelectedFile,
          setSelectedFileDetails,
        }),
        snapshotTitle: `${firstSnapshotTitle} - ${secondSnapshotTitle} Difference`,
      };
    }

    if (selectedSnapshot || orderedSnapshots?.length) {
      const relevantSnapshot = orderedSnapshots?.[0] || selectedSnapshot;
      return {
        formData: getSnapshotFormData({
          snapshot: relevantSnapshot,
          setSelectedFile,
          setSelectedFileDetails,
        }),
        snapshotTitle: DateTime
          .fromMillis(relevantSnapshot.timestamp)
          .toLocaleString(DateTime.DATETIME_MED),
      };
    }

    return {
      formData: null,
    };
  }, [
    selectedSnapshot,
    orderedSnapshots,
    isViewDifferenceToggled,
    setSelectedFile,
    setSelectedFileDetails,
  ]);

  const onCheckChange = useCallback((snapshot) => (e) => {
    if (e?.target?.checked || (selectedSnapshots.size < 2 && !selectedSnapshots.has(snapshot.id))) {
      setSelectedSnapshots((prev) => new Set([...prev, snapshot.id]));
    } else {
      setSelectedSnapshots((prev) => {
        const newSet = new Set(prev);
        newSet.delete(snapshot.id);
        return newSet;
      });
    }
  }, [selectedSnapshots, isViewDifferenceToggled]);

  const onMainDrawerClose = () => {
    onClose();
    setSelectedSnapshot();
    setSelectedSnapshots(new Set());
    if (isViewDifferenceToggled) toggleViewDifference();
  };

  return (
    <>
      <Drawer
        title="Snapshots"
        placement="right"
        closable
        onClose={onMainDrawerClose}
        visible={open}
        width="calc(50% - 25px)"
      >
        <Timeline>
          {snapshots.map((snapshot) => (
            <Step
              key={snapshot.id}
              Icon={EditOutlined}
              title={`${isBoardCard ? 'Card' : 'Form'} Updated`}
              text={`${userMap[snapshot.updaterId]?.name ?? 'Unknown'} updated the ${isBoardCard ? 'card' : 'form'}`}
              timestamp={snapshot.timestamp}
              action={{
                title: 'View',
                onClick: () => setSelectedSnapshot(snapshot),
                disabled: !!selectedSnapshots.size,
              }}
              IconNode={(
                <Checkbox
                  disabled={selectedSnapshots.size === 2 && !selectedSnapshots.has(snapshot.id)}
                  checked={selectedSnapshots.has(snapshot.id)}
                  onChange={onCheckChange(snapshot)}
                />
              )}
              onSelect={onCheckChange(snapshot)}
              isSelected={selectedSnapshots.has(snapshot.id)}
            />
          ))}
        </Timeline>
        <DrawerSubmitFooter
          onClose={onMainDrawerClose}
          submitTitle="View Difference"
          canSubmit={selectedSnapshots.size === 2}
          onSubmit={isViewDifferenceToggled
            ? null
            : toggleViewDifference
          }
        />
      </Drawer>
      <Drawer
        title={snapshotTitle}
        placement="left"
        closable
        onClose={() => {
          setSelectedSnapshot(null);
          if (isViewDifferenceToggled) toggleViewDifference();
        }}
        visible={!!selectedSnapshot || isViewDifferenceToggled}
        width="calc(50% - 25px)"
        bodyStyle={{ padding: 0 }}
        mask={false}
      >
        { !!formData && (
          <FormBuilder
            formId={selectedSnapshot?.formId}
            isDisplay
            initialSections={formData}
            shouldHideCustomRenderingFields
            style={{ top: 75 }}
          />
        )}
        { !formData && (
          <OnTraccrEmpty />
        )}
      </Drawer>
    </>
  );
}

SnapshotDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  snapshots: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  setSelectedFile: PropTypes.func.isRequired,
  setSelectedFileDetails: PropTypes.func.isRequired,
  isBoardCard: PropTypes.bool,
};

SnapshotDrawer.defaultProps = {
  open: false,
  snapshots: [],
  isBoardCard: false,
};
