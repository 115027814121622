import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIdMap } from 'ontraccr-common/lib/Common';
import Permissions from '../../auth/Permissions';

export default function useBoardPermissions({ users, creatorId }) {
  const company = useSelector((state) => state.settings.company);
  const userBoardPermissions = useMemo(() => {
    const permissionMap = getIdMap(users, 'userId');
    const {
      id: userId,
    } = Permissions;
    const {
      [userId]: {
        formPermission,
        type,
      } = {},
    } = permissionMap;

    const isOwnerOrCreator = (company && company.userId === userId) || (userId === creatorId);

    return {
      isOwnerOrCreator,
      isFormsPermitted: isOwnerOrCreator || !!formPermission,
      canEdit: isOwnerOrCreator || type === 'write',
    };
  }, [Permissions, users, creatorId, company]);
  return userBoardPermissions;
}
