export const getTypes = (t) => [
  { label: `Create a card when a ${t('Customer').toLowerCase()} is created`, value: 'customer' },
  { label: `Create a card when a ${t('Project').toLowerCase()} is created`, value: 'project' },
  { label: 'Create a card when a user is created', value: 'user' },
  { label: 'Create a card when a material is created', value: 'material' },
  { label: 'Create a card when equipment is created', value: 'equipment' },
  { label: 'Create tasks on status change', value: 'statusTasks' },
  { label: 'Move card between boards', value: 'moveBoard' },
  { label: 'Trigger action when a card stays in a status for a period of time', value: 'statusTime', hasSubType: true },
];

export const SUB_TYPE_MAP = {
  statusTime: [
    { label: 'Send an email', value: 'sendEmail' },
    { label: 'Create a task', value: 'createTask' },
    { label: 'Change card color', value: 'changeCardColor' },
  ],
};

const CUSTOMER_FIELDS = [
  {
    label: 'Name', value: 'name', type: 'text', required: true,
  },
  {
    label: 'Office Phone Number', value: 'officeNumber', type: 'text', required: true,
  },
  { label: 'Mobile Phone Number', value: 'mobileNumber', type: 'text' },
  { label: 'Fax Phone Number', value: 'faxNumber', type: 'text' },
  {
    label: 'Office Email', value: 'officeEmail', type: 'text', required: true,
  },
  { label: 'Billing Email', value: 'billingEmail', type: 'text' },
  { label: 'Website', value: 'website', type: 'text' },
  { label: 'Office Address', value: 'officeAddress', type: 'text' },
  { label: 'Billing Address', value: 'billingAddress', type: 'text' },
  { label: 'Info', value: 'info', type: 'text' },
];

const PROJECT_FIELDS = [
  {
    label: 'Name', value: 'name', type: 'text', required: true,
  },
  {
    label: 'Number', value: 'number', type: 'text', required: true,
  },
  {
    label: 'Address', value: 'address', type: 'text', required: true,
  },
  {
    label: 'Labour Hours', value: 'labourHours', type: 'text', required: true,
  },
  {
    label: 'Labour Cost', value: 'labourCost', type: 'text', required: true,
  },
  {
    label: 'Material Cost', value: 'estimatedMaterialCost', type: 'text', required: true,
  },
  {
    label: 'Overhead Cost', value: 'estimatedOverheadCost', type: 'text', required: true,
  },
  {
    label: 'Contract Value', value: 'contractValue', type: 'text', required: true,
  },
  {
    label: 'Material Discount', value: 'materialDiscount', type: 'text', required: true,
  },
  {
    label: 'Billing Contact Email', value: 'billingContact', type: 'text', required: true,
  },
  {
    label: 'PO Number', value: 'poNumber', type: 'text', required: true,
  },
  {
    label: 'Info', value: 'info', type: 'text', required: true,
  },
  {
    label: 'Holdback Percent', value: 'holdbackPercentage', type: 'text', required: true,
  },
];

export const getTypeMap = (t) => ({
  customer: CUSTOMER_FIELDS,
  project: PROJECT_FIELDS.concat(
    [
      {
        label: t('Customer'), value: 'customerId', type: 'dropdown', dataType: 'Customers',
      },
    ],
    CUSTOMER_FIELDS.map((field) => ({
      ...field,
      label: `${t('Customer')} - ${field.label}`,
      value: `customer - ${field.value}`,
      isCustomer: true,
    })),
    [
      {
        label: t('Project'), value: 'id', type: 'dropdown', dataType: 'Projects',
      },
    ],
  ),
  user: [
    {
      label: 'Name', value: 'name', type: 'text', required: true,
    },
    {
      label: 'Employee ID', value: 'employeeId', type: 'text', required: true,
    },
    {
      label: 'Email', value: 'email', type: 'text', required: true,
    },
    {
      label: 'Username', value: 'username', type: 'text', required: true,
    },
    {
      label: 'Phone Number', value: 'phoneNumber', type: 'text', required: true,
    },
    {
      label: 'Wage', value: 'wage', type: 'text', required: true,
    },
  ],
  material: [
    {
      label: 'Name', value: 'name', type: 'text', required: true,
    },
    {
      label: 'Description', value: 'description', type: 'text', required: true,
    },
    {
      label: 'Part Number', value: 'partNumber', type: 'text', required: true,
    },
    {
      label: 'Supplier', value: 'supplier', type: 'text', required: true,
    },
    {
      label: 'Units', value: 'units', type: 'text', required: true,
    },
    {
      label: 'Cost', value: 'cost', type: 'text', required: true,
    },
    {
      label: 'Markup', value: 'markup', type: 'text', required: true,
    },
    {
      label: 'Quantity', value: 'quantity', type: 'text', required: true,
    },
  ],
  equipment: [
    {
      label: 'Name', value: 'name', type: 'text', required: true,
    },
    {
      label: 'ID', value: 'code', type: 'text', required: true,
    },
  ],
});

export const TITLE_FIELD_ID = 'cardTitle';
export const TITLE_FIELD = {
  id: TITLE_FIELD_ID,
  configProps: { title: 'Title', required: true },
  selectedType: 'text',
};
