import { Table } from 'antd';
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ResizeObserver from 'rc-resize-observer';
import { VariableSizeGrid as Grid } from 'react-window';

import { getCustomFieldMap, getFormattedListViewTask, getSummaryViewColumns } from './timecardListSummaryView.helpers';
import { getIdMap } from '../helpers/helpers';
import Permissions from '../auth/Permissions';
import { getCustomFields, updateTimeCardFilters } from './state/timecards.actions';

const ROW_HEIGHT = 61;

export default function TimeCardsSummaryView({
  tasks = [],
  isApprovals,
  onSummaryTaskEditCopy,
  onRowSelectChange,
  selectedSummaryRowKeys = [],
  entryDataMap,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasApproveAllPermissions = Permissions.has('ENABLE_APPROVE_ALL');

  const unions = useSelector((state) => state.unions.unions);
  const unionClasses = useSelector((state) => state.unions.classes);
  const unionLocals = useSelector((state) => state.unions.locals);
  const customFields = useSelector((state) => state.timecards.customFields);
  const users = useSelector((state) => state.users.users);
  const enableHourBasedTracking = useSelector((state) => {
    const {
      settings: {
        company: {
          settings: {
            enableHourBasedTracking: enableHourBasedTrackingSetting = false,
          } = {},
        } = {},
      } = {},
    } = state;
    return enableHourBasedTrackingSetting;
  });
  const {
    costcodes,
    phases,
  } = useSelector((state) => state.costcodes);

  const gridRef = useRef();

  const [selectedTimeCardSummaryColumns, setSelectedTimeCardSummaryColumns] = useState();
  const [selectedCustomFieldColumns, setSelectedCustomFieldColumns] = useState();
  const [tableDimensions, setTableDimensions] = useState({ width: 1000, height: 1000 });
  const [connectObject] = useState(() => {
    const obj = {
      scrollTo: gridRef.scrollTo,
    };

    Object.defineProperty(obj, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });

    Object.defineProperty(obj, 'scrollTop', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollTop;
        }
        return null;
      },
      set: (scrollTop) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollTop,
          });
        }
      },
    });
    return obj;
  });

  const userMap = useMemo(() => getIdMap(users), [users]);
  const unionMap = useMemo(() => getIdMap(unions), [unions]);
  const unionClassMap = useMemo(() => getIdMap(unionClasses), [unionClasses]);
  const unionLocalMap = useMemo(() => getIdMap(unionLocals), [unionLocals]);
  const costCodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const phaseMap = useMemo(() => phases.reduce((acc, phase) => {
    if (!acc[phase.id]) acc[phase.id] = [];
    acc[phase.id].push(phase);
    return acc;
  }, {}), [phases]);

  const customFieldMap = useMemo(() => getCustomFieldMap(customFields), [customFields]);

  const filteredTasks = useMemo(() => {
    const seen = new Set();
    return tasks.filter((task) => !task.id || (!seen.has(task.id) && seen.add(task.id)));
  }, [tasks]);

  useEffect(() => {
    const onStorageUpdated = (e) => {
      if (!e || e.detail === 'timeCardSummaryColumns') {
        const rawTimeCardSummaryColumns = window.localStorage.getItem('timeCardSummaryColumns');
        if (rawTimeCardSummaryColumns) {
          setSelectedTimeCardSummaryColumns(JSON.parse(rawTimeCardSummaryColumns));
        }
      }

      if (!e || e.detail === 'timeCardCustomFieldColumns') {
        const rawCustomFieldColumns = window.localStorage.getItem('timeCardCustomFieldColumns');
        if (rawCustomFieldColumns) {
          setSelectedCustomFieldColumns(JSON.parse(rawCustomFieldColumns));
        }
      }
    };

    onStorageUpdated();
    window.addEventListener('localStorageSetItem', onStorageUpdated);

    return () => {
      window.removeEventListener('localStorageSetItem', onStorageUpdated);
    };
  }, []);

  const formattedTasks = useMemo(() => (
    filteredTasks
      .map((task) => getFormattedListViewTask({
        task,
        costCodeMap,
        phaseMap,
        enableHourBasedTracking,
        unionClassMap,
        unionLocalMap,
        unionMap,
        entryDataMap,
        userMap,
      }))
  ), [
    filteredTasks,
    costCodeMap,
    phaseMap,
    unionMap,
    unionClassMap,
    unionLocalMap,
    enableHourBasedTracking,
    userMap,
    entryDataMap,
  ]);

  const divisionIdsInTasks = useMemo(() => (
    new Set(formattedTasks.map((task) => task.divisionId))
  ), [formattedTasks]);

  const columns = useMemo(() => getSummaryViewColumns({
    t,
    isApprovals,
    onEditCopy: onSummaryTaskEditCopy,
    customFieldMap,
    divisionIds: divisionIdsInTasks,
    selectedTimeCardSummaryColumns,
    selectedCustomFieldColumns,
  }), [
    t,
    isApprovals,
    onSummaryTaskEditCopy,
    customFieldMap,
    divisionIdsInTasks,
    selectedTimeCardSummaryColumns,
    selectedCustomFieldColumns,
  ]);

  const decoratedColumns = useMemo(() => {
    const columnWidthSum = columns.reduce((acc, col) => acc + col.width, 0);
    const { width: tableWidth } = tableDimensions;
    return columns.map((col) => {
      const { width } = col;
      if (columnWidthSum === 0) return col;
      const expected = tableWidth * (width / columnWidthSum);
      return {
        ...col,
        width: expected > width ? expected : width,
      };
    });
  }, [tableDimensions, columns]);

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * ROW_HEIGHT;
    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={decoratedColumns.length}
        columnWidth={(index) => {
          const { width } = decoratedColumns[index];
          return totalHeight > tableDimensions.height && index === decoratedColumns.length - 1
            ? width - scrollbarSize - 1
            : width;
        }}
        height={tableDimensions.height - 39}
        rowCount={rawData.length}
        rowHeight={() => ROW_HEIGHT}
        width={tableDimensions.width}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          const column = decoratedColumns[columnIndex];
          const record = rawData[rowIndex] ?? {};
          const value = record[column.dataIndex];
          const body = column.render
            ? column.render(value, record)
            : value;
          return (
            <div
              className={
                columnIndex === columns.length - 1
                  ? 'virtual-table-cell-base'
                  : 'virtual-table-cell'
              }
              style={style}
            >
              <div className="virtual-table-cell-inner">
                {body}
              </div>
            </div>
          );
        }}
      </Grid>
    );
  };

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  const rowSelectionProps = useMemo(() => {
    if (!isApprovals) return {};

    return {
      rowSelection: {
        selectedRowKeys: selectedSummaryRowKeys,
        hideSelectAll: !hasApproveAllPermissions,
        onChange: onRowSelectChange,
      },
    };
  }, [selectedSummaryRowKeys, hasApproveAllPermissions, isApprovals, onRowSelectChange]);

  useEffect(() => {
    resetVirtualGrid();
  }, [tableDimensions.width]);

  useEffect(() => {
    dispatch(getCustomFields());
  }, []);

  useEffect(() => (
    () => {
      dispatch(updateTimeCardFilters(null));
    }
  ), []);

  return (
    <ResizeObserver
      onResize={({ height, width }) => {
        setTableDimensions({ height, width });
      }}
    >
      <Table
        columns={columns}
        dataSource={formattedTasks}
        pagination={false}
        size="small"
        rowClassName={() => 'timecard-entry-row'}
        rowKey="id"
        bordered
        components={{ body: renderVirtualList }}
        {...rowSelectionProps} // eslint-disable-line react/jsx-props-no-spreading
      />
    </ResizeObserver>
  );
}

/* eslint-disable react/forbid-prop-types */
TimeCardsSummaryView.propTypes = {
  tasks: PropTypes.array,
  isApprovals: PropTypes.bool.isRequired,
  onSummaryTaskEditCopy: PropTypes.func.isRequired,
  onRowSelectChange: PropTypes.func.isRequired,
  selectedSummaryRowKeys: PropTypes.array,
  entryDataMap: PropTypes.object.isRequired,
};

TimeCardsSummaryView.defaultProps = {
  tasks: [],
  selectedSummaryRowKeys: [],
};
