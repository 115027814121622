/* eslint-disable react/jsx-filename-extension */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ClockInSelect from './ClockInSelect';

import ClockInTimer from '../common/clock/ClockInTimer';
import sortByString, { getIdMap, sortByCode } from '../helpers/helpers';

import Permissions from '../auth/Permissions';

const unphasedCode = { id: 'Unphased', name: 'Unphased' };

const getDefaultPhase = ({
  isClockedIn,
  phase,
  onlyUnphased,
  selectedProject,
}) => {
  if (!selectedProject) return null;
  if (onlyUnphased) return 'Unphased';
  if (isClockedIn && phase) return phase.name;
  return null;
};

const parseProjects = (projects) => projects.filter((project) => project.active).sort(sortByString('name'));

const getProjectText = (serviceLocation, project) => {
  if (serviceLocation) return `Service Location: ${serviceLocation}`;
  return project?.number && project?.name ? `${project.number} - ${project.name}` : undefined;
};

function MainClockIn({
  user = {},
  onClockIn,
  onClockOut,
  onSwitch,
  onBreakStart,
  onBreakEnd,
  costcodes = [],
  unphasedCostcodes = [],
  phaseMap = {},
  projects = [],
  settings = {},
  setManual,
  switchOpen,
  defaultClassId: userDefaultClassId,
}) {
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);
  const restrictProjectSelection = Permissions.has('RESTRICT_PROJECT_SELECTION') && Permissions.id === user.id;

  const {
    reqProject = false,
    reqCostcode = false,
    enableClassSelection = false,
    enableLocalSelection = false,
    enableSageShiftSelection = false,
  } = settings;

  const ourUser = user || {};

  const tasks = timeEntryUserMap[ourUser?.id] ?? [];

  const lastTask = tasks.length > 0 ? tasks[tasks.length - 1] : {
    project: {},
    costcode: {},
  };

  const {
    projectId,
    phaseId,
    costcodeId,
    serviceLocation,
    classId: lastClassId,
    sageShiftId,
  } = lastTask;

  const phase = phaseMap[phaseId] ?? {};

  const taskOrUserDefaultClassId = lastClassId || userDefaultClassId;

  const isClockedIn = lastTask?.startTime && !lastTask?.endTime;

  const {
    classes = [],
    locals = [],
  } = useSelector((state) => state.unions);
  const {
    defaultSageShiftId,
  } = useSelector((state) => state.profile.profile || {});
  const sageShifts = useSelector((state) => state.sage.shifts);
  const teams = useSelector((state) => state.teams.teams);

  const taskOrUserDefaultShiftId = sageShiftId || defaultSageShiftId;

  const [switchStarted, setSwitchStart] = useState(switchOpen);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCostcode, setSelectedCostcode] = useState();
  const [selectedPhase, setSelectedPhase] = useState();
  const [selectedUnionClass, setSelectedUnionClass] = useState();
  const [selectedUnionLocal, setSelectedUnionLocal] = useState();
  const [selectedSageShift, setSelectedSageShift] = useState();

  const classMap = useMemo(() => getIdMap(classes), [classes]);
  const localMap = useMemo(() => getIdMap(locals), [locals]);
  const project = useMemo(() => projects.find((p) => p.id === projectId), [projects, projectId]);
  const costcode = useMemo(() => (
    costcodes.find((c) => c.id === costcodeId)
  ), [costcodes, costcodeId]);

  const canClockIn = (!reqProject || selectedProject) && (!reqCostcode || selectedCostcode);

  const phases = Object.values(phaseMap);

  const projectUnphased = unphasedCostcodes
    .filter((cc) => !cc.phased && cc.projectId === selectedProject);
  const projectHasNoCostcodes = costcodes
    .filter((cc) => cc.projectId === selectedProject).length === 0;

  let relevantCostcodes = selectedPhase && selectedPhase !== 'Unphased'
    ? phaseMap[selectedPhase].costcodes
    : projectUnphased;
  relevantCostcodes = relevantCostcodes.filter((cc) => cc.active && cc.category !== 'Equipment').sort(sortByCode('code'));

  const relevantPhases = phases
    .filter((relevantPhase) => relevantPhase.projectId === selectedProject)
    .concat(projectUnphased.length > 0 ? unphasedCode : []);
  const onlyUnphased = projectUnphased.length > 0 && relevantPhases.length === 1;

  const userTeamProjects = useMemo(() => {
    const userTeamIds = new Set(user.teams?.map((team) => team.id));
    let projectsAcc = user.projects ?? [];
    teams.forEach((team) => {
      if (userTeamIds.has(team.id)) projectsAcc = [...projectsAcc, ...team.projects];
    });
    return new Set(projectsAcc.map((projectAcc) => projectAcc.id));
  }, [teams, user]);

  const relevantProjects = useMemo(() => {
    if (!restrictProjectSelection) return parseProjects(projects);

    const filteredProjects = projects.filter((projectToFilter) => (
      userTeamProjects.has(projectToFilter.id)
    ));

    return parseProjects(filteredProjects);
  }, [restrictProjectSelection, userTeamProjects, projects]);

  const onLocalChanged = useCallback((newLocal) => {
    const {
      [selectedUnionClass]: { localId: classLocalId } = {},
    } = classMap;
    if (classLocalId !== newLocal) setSelectedUnionClass();
    setSelectedUnionLocal(newLocal);
  }, [classMap, selectedUnionClass]);

  useEffect(() => {
    if (selectedProject && onlyUnphased) setSelectedPhase('Unphased');
  }, [selectedProject, onlyUnphased]);

  useEffect(() => {
    if (switchOpen) setSwitchStart(switchOpen);
  }, [switchOpen]);

  useEffect(() => {
    const {
      [taskOrUserDefaultClassId]: selectedClassObject = {},
    } = classMap;
    const { localId } = selectedClassObject;
    setSelectedUnionLocal(localId);
    setSelectedUnionClass(taskOrUserDefaultClassId);
  }, [taskOrUserDefaultClassId, classMap]);

  useEffect(() => {
    setSelectedSageShift(taskOrUserDefaultShiftId);
  }, [taskOrUserDefaultShiftId]);

  const canEdit = !isClockedIn || switchStarted;

  const ourClass = useMemo(() => (
    classes.find((unionClass) => unionClass.id === taskOrUserDefaultClassId || {})
  ), [classes, taskOrUserDefaultClassId]);

  const defaultLocal = useMemo(() => {
    const { localId } = ourClass || {};
    const {
      [localId]: localObject = {},
    } = localMap;
    return localObject;
  }, [localMap, ourClass]);

  const ourLocals = useMemo(() => (
    locals.filter((local) => local.unionId === defaultLocal.unionId)
  ), [defaultLocal, locals]);

  const ourLocalClasses = useMemo(() => (
    classes.filter((unionClass) => unionClass.localId === selectedUnionLocal)
  ), [classes, selectedUnionLocal]);

  const defaultShift = useMemo(() => (
    sageShifts[taskOrUserDefaultShiftId]
  ), [sageShifts, taskOrUserDefaultShiftId]);

  const shiftArray = useMemo(() => {
    const shiftArr = Object.values(sageShifts);
    shiftArr.sort(sortByString('name'));
    return shiftArr;
  }, [sageShifts]);

  return (
    <div>
      <div style={{ width: '100%' }}>
        <ClockInSelect
          title="Project"
          edit={canEdit}
          disabled={!switchStarted && isClockedIn}
          onChange={(value = null) => {
            if (!value || value !== selectedProject) {
              setSelectedCostcode(null);
              setSelectedPhase(null);
            }
            setSelectedProject(value);
          }}
          defaultValue={isClockedIn && project?.name ? project.name : undefined}
          value={selectedProject}
          data={relevantProjects}
          displayText={getProjectText(serviceLocation, project)}
          optionFormatter={(value) => `${value.number} - ${value.name}`}
        />
        <ClockInSelect
          title="Phase"
          edit={canEdit}
          disabled={
            (onlyUnphased || projectHasNoCostcodes || (reqProject && !selectedProject))
            || (isClockedIn && !switchStarted)
          }
          onChange={setSelectedPhase}
          defaultValue={getDefaultPhase({
            isClockedIn,
            phase,
            onlyUnphased,
            selectedProject,
          })}
          value={selectedPhase}
          data={relevantPhases}
          displayText={phase ? phase.name : undefined}
        />
        <ClockInSelect
          title="Cost Code"
          edit={canEdit}
          disabled={
            (relevantCostcodes.length === 0 || (reqProject && !selectedProject))
            || (isClockedIn && !switchStarted)
          }
          onChange={setSelectedCostcode}
          defaultValue={isClockedIn && costcode?.code && costcode?.name ? `${costcode.code} - ${costcode.name}` : undefined}
          value={selectedCostcode}
          data={relevantCostcodes}
          displayText={costcode?.code && costcode?.name ? `${costcode.code} - ${costcode.name}` : undefined}
          optionFormatter={(cc) => `${cc.code} - ${cc.name}`}
        />
        {enableLocalSelection && (
          <ClockInSelect
            title="Union Local"
            edit={canEdit}
            onChange={onLocalChanged}
            value={selectedUnionLocal}
            defaultValue={defaultLocal.id}
            data={ourLocals}
            displayText={defaultLocal ? `${defaultLocal.name} - ${defaultLocal.description}` : undefined}
            optionFormatter={(unionClass) => `${unionClass.name} - ${unionClass.description}`}
          />
        )}
        {enableClassSelection && (
          <ClockInSelect
            title="Work Classification"
            edit={canEdit}
            onChange={setSelectedUnionClass}
            value={selectedUnionClass}
            defaultValue={taskOrUserDefaultClassId}
            data={ourLocalClasses}
            displayText={ourClass ? `${ourClass.name} - ${ourClass.description}` : undefined}
            optionFormatter={(unionClass) => `${unionClass.name} - ${unionClass.description}`}
          />
        )}
        {enableSageShiftSelection && (
          <ClockInSelect
            title="Shift"
            edit={canEdit}
            onChange={setSelectedSageShift}
            value={selectedSageShift}
            defaultValue={taskOrUserDefaultShiftId}
            data={shiftArray}
            displayText={defaultShift ? defaultShift.name : undefined}
          />
        )}
      </div>
      <div style={{ width: '100%', height: 240, marginBottom: 20 }}>
        <ClockInTimer
          key={ourUser.id}
          user={ourUser}
          onClockIn={
            canClockIn
              ? () => onClockIn(
                selectedProject,
                selectedCostcode,
                selectedPhase !== 'Unphased' ? selectedPhase : null,
                selectedUnionClass,
                selectedSageShift,
              )
              : null
          }
          onClockOut={onClockOut}
          onSwitchStart={() => setSwitchStart(true)}
          onSwitchCancel={() => setSwitchStart(false)}
          onSwitch={async (taskId) => {
            const passed = await onSwitch({
              taskId,
              projectId: selectedProject,
              costcodeId: selectedCostcode,
              phaseId: selectedPhase !== 'Unphased' ? selectedPhase : null,
              classId: selectedUnionClass,
              sageShiftId: selectedSageShift,
            });
            if (passed) setSwitchStart(false);
            return passed;
          }}
          onBreakStart={onBreakStart}
          onBreakEnd={onBreakEnd}
          onManualEntry={setManual}
          switchOpen={switchOpen}
        />
      </div>
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
MainClockIn.propTypes = {
  user: PropTypes.shape({ id: PropTypes.string }),
  onClockIn: PropTypes.func.isRequired,
  onClockOut: PropTypes.func.isRequired,
  onSwitch: PropTypes.func.isRequired,
  onBreakStart: PropTypes.func.isRequired,
  onBreakEnd: PropTypes.func.isRequired,
  costcodes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  unphasedCostcodes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  phaseMap: PropTypes.object.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  settings: PropTypes.object.isRequired,
  switchOpen: PropTypes.bool.isRequired,
  setManual: PropTypes.func,
  defaultClassId: PropTypes.string,
};

MainClockIn.defaultProps = {
  setManual: null,
  defaultClassId: null,
  user: null,
};

export default MainClockIn;
