import React from 'react';
import { Drawer, Tabs } from 'antd';
import { FileOutlined, LaptopOutlined } from '@ant-design/icons';

import FileUpload from '../../common/files/FileUpload';
import FilePicker from '../../common/files/FilePicker';

const { TabPane } = Tabs;

const TabTitle = ({ title,icon }) => (
  <span>
    {icon}
    {title}
  </span>
);

export default ({
  shouldDownload,
  onUploadEnd,
  addFile,
  visible,
  placement='bottom',
  style,
  height='calc(100% - 100px)',
  customProps = {},
}) => (
  <Drawer
    placement={placement}
    visible={visible}
    height={height}
    onClose={onUploadEnd}
    push={false}
    style={{
      position:'absolute',
      zIndex:0,
      ...style,
    }}
    bodyStyle={{ padding: '24px 24px 0px 24px', height:'100%' }}
    {...customProps}
  >
    <Tabs 
      style={{ width: '100%', height:'100%', position:'relative', }}
      defaultActiveKey='1'
      tabBarStyle={{ margin:0 }}
    >
      <TabPane tab={
         <TabTitle icon={<LaptopOutlined />} title='From Your Computer'/>
      } key='1'>
        <FileUpload
          addFile={addFile}
          style={{ paddingTop: 8 , paddingBottom: 24 }} // Tab bar has 16 padding, we want 24 total to match drawer padding
        />
      </TabPane>
      <TabPane tab={
        <TabTitle icon={<FileOutlined />} title= 'From Existing Files'/>
       } key='2'>
        <FilePicker
          className='livefeed-file-picker'
          onSelect={addFile}
          shouldDownload={shouldDownload}
        />
      </TabPane>
    </Tabs>
  </Drawer>
);