import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

export default {};

export const COLUMN_PROP_TYPE = PropTypes.shape({
  title: PropTypes.string,
  field: PropTypes.string,
  id: PropTypes.string,
});

export const CUSTOM_EXPORT_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  columns: PropTypes.arrayOf(COLUMN_PROP_TYPE),
});

export const EXPORTABLE_FIELD_TYPES = new Set([
  'text',
  'calculation',
  'attribute',
  'dropdown',
  'yes-no',
  'table',
  'dateTime',
  'dateRange',
  'gpsLocation',
  'staticText',
]);

const emptyColumn = {
  key: 'empty',
  title: 'Empty',
};

const projectColumns = (t) => ({
  key: 'project',
  title: t('Project'),
  selectable: false,
  children: [{
    key: 'projects.name',
    title: `${t('Project')} - Name`,
  }, {
    key: 'projects.number',
    title: `${t('Project')} - Number`,
  }, {
    key: 'projects.address',
    title: `${t('Project')} - Address`,
  }, {
    key: 'projects.qboClass',
    title: `${t('Project')} - QuickBooks Class`,
  }],
});

const userColumns = {
  key: 'user',
  title: 'User',
  selectable: false,
  children: [{
    key: 'users.name',
    title: 'User - Name',
  }, {
    key: 'users.employeeId',
    title: 'User - Employee ID',
  }, {
    key: 'users.email',
    title: 'User - Email',
  }, {
    key: 'users.wage',
    title: 'User - Wage',
  }],
};

export const getTimecardFields = (t) => [
  emptyColumn,
  {
    key: 'displayId',
    title: 'ID',
  }, {
    key: 'date',
    title: 'Date',
  }, {
    key: 'startTime',
    title: 'Start Time',
  }, {
    key: 'endTime',
    title: 'End Time',
  }, {
    key: 'timezone',
    title: 'Timezone',
  }, {
    key: 'totalHours',
    title: 'Total Hours',
  }, {
    key: 'workHours',
    title: 'Work Hours',
  }, {
    key: 'breakHours',
    title: 'Break Hours',
  }, {
    key: 'regularHours',
    title: 'Regular Hours',
  }, {
    key: 'otHours',
    title: 'Overtime Hours',
  }, {
    key: 'doubleOT',
    title: 'Double Time Hours',
  }, {
    key: 'wageType',
    title: 'Wage Type',
  }, {
    key: 'calculatedWage',
    title: 'Calculated Wage',
  }, {
    key: 'cost',
    title: 'Calculated Cost',
  }, {
    key: 'burdenCost',
    title: 'Burden Cost',
  }, {
    key: 'type',
    title: 'Type',
  }, {
    key: 'state',
    title: 'Submitted Status',
  }, {
    key: 'note',
    title: 'Note',
  }, {
    key: 'serviceLocation',
    title: 'Service Location',
  },
  userColumns,
  {
    key: 'division',
    title: 'Division',
    selectable: false,
    children: [{
      key: 'divisions.name',
      title: 'Division - Name',
    }, {
      key: 'divisions.code',
      title: 'Division - Code',
    }],
  },
  projectColumns(t),
  {
    key: 'phase',
    title: 'Phase',
    selectable: false,
    children: [{
      key: 'phases.name',
      title: 'Phase - Name',
    }, {
      key: 'phases.description',
      title: 'Phase - Description',
    }],
  }, {
    key: 'costcode',
    title: 'Cost Code',
    selectable: false,
    children: [{
      key: 'costcodes.code',
      title: 'Cost Code - Code',
    }, {
      key: 'costcodes.name',
      title: 'Cost Code - Name',
    }, {
      key: 'costcodes.description',
      title: 'Cost Code - Description',
    }, {
      key: 'costcodes.hours',
      title: 'Cost Code - Hours',
    }, {
      key: 'costcodes.hourlywage',
      title: 'Cost Code - Hourly Wage',
    }],
  }, {
    key: 'union',
    title: 'Union',
    selectable: false,
    children: [{
      key: 'union.name',
      title: 'Union - Name',
    }, {
      key: 'local.name',
      title: 'Local - Name',
    }, {
      key: 'class.name',
      title: 'Class - Name',
    }, {
      key: 'class.hourlywage',
      title: 'Class - Hourly Wage',
    }],
  }];

export const getFormFields = (t) => [
  emptyColumn,
  {
    key: 'name',
    title: 'Form Name',
  }, {
    key: 'number',
    title: 'Number',
  }, {
    key: 'createdAt',
    title: 'Created On',
  }, {
    key: 'lastUpdated',
    title: 'Last Updated',
  },
  userColumns,
  projectColumns(t),
];

export const FIELDS_WITH_FILTERS = new Set(['costcodes.hours']);
export const FIELDS_WITH_CONFIG = new Set(['date']);
export const CONFIG_MAP = {
  date: {
    options: [
      { label: 'mm/dd/yyyy', value: 'mm/dd/yyyy' },
      { label: 'mm/dd/yy', value: 'mm/dd/yy' },
      { label: 'dd/mm/yyyy', value: 'dd/mm/yyyy' },
      { label: 'dd/mm/yy', value: 'dd/mm/yy' },
    ],
    default: 'mm/dd/yyyy',
  },
};
