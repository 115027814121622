/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Dropdown,
  Input,
  Menu,
  Row,
} from 'antd';
import { FilterOutlined, CloseOutlined } from '@ant-design/icons';
import { Trie } from 'ontraccr-common';
import PropTypes from 'prop-types';

import OnTraccrButton from '../buttons/OnTraccrButton';
import ProfileAvatar from '../ProfileAvatar';
import colors from '../../constants/Colors';
import BorderlessButton from '../buttons/BorderlessButton';
import useOnClickOutside from '../hooks/useOnClickOutside';
import useDivisionUsers from '../hooks/useDivisionUsers';

export default function UserSelector({
  disabled,
  filteredUsers,
  onUserChange,
  onVisibleChanged,
  multiple = false,
}) {
  const userTrie = useSelector((state) => state.users.userTrie);
  const users = useDivisionUsers();

  const [selectedUserIds, setSelectedUserIds] = useState(filteredUsers || []);
  const [visible, setVisible] = useState();
  const [searchStr, setSearchStr] = useState();
  const dropdownRef = useRef();
  const buttonRef = useRef();

  useOnClickOutside(document, dropdownRef, (e) => {
    if (buttonRef?.current?.contains(e.target)) {
      return;
    }

    setVisible(false);
  });

  const onChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  }, []);

  const onClickUser = useCallback((user) => () => {
    if (!multiple) {
      setVisible(false);
      setSearchStr();
    }

    const newUsers = multiple ? [...selectedUserIds, user.id] : [user.id];

    if (onUserChange) onUserChange(newUsers);
    setSelectedUserIds(newUsers);
  }, [multiple, selectedUserIds, onUserChange]);

  const onFilterClicked = useCallback(() => {
    setVisible(!visible);
    setSearchStr();
  }, [visible]);

  const onRemove = useCallback((user) => {
    if (!multiple) {
      setVisible(!visible);
      setSearchStr();
    }

    const newUsers = selectedUserIds.filter((u) => u !== user);
    if (onUserChange) onUserChange(newUsers);
    setSelectedUserIds(newUsers);
  }, [visible, multiple, selectedUserIds, onUserChange]);

  const activeUsers = useMemo(() => users.filter((user) => user.active), [users]);
  const [selectedUsers, otherUsers] = useMemo(() => {
    const selectedUserMap = new Set(selectedUserIds);

    const fullUsers = searchStr ? Trie.searchTrie(userTrie, searchStr) : activeUsers;
    const selected = [];
    const other = [];
    fullUsers.forEach((user) => {
      if (selectedUserMap.has(user.id)) {
        selected.push(user);
      } else {
        other.push(user);
      }
    });
    return [selected, other];
  }, [selectedUserIds, searchStr, userTrie, activeUsers]);

  const dropdown = useCallback(() => (
    <Menu>
      <div ref={dropdownRef}>
        <Row>
          <Input.Search
            type="search"
            className="searchbar"
            placeholder="Search"
            allowClear
            onChange={onChange}
            value={searchStr}
            style={{ margin: 5 }}
          />
        </Row>
        {selectedUsers.map((user) => (
          <div id="board-user-filter-selected-user-row" key={user.id}>
            <ProfileAvatar
              name={user.name}
              role={user.position}
              avatarRadius={12}
            />
            <Row id="board-user-filter-remove-button" justify="center" align="middle">
              <BorderlessButton
                onClick={() => onRemove(user.id)}
                iconNode={<CloseOutlined />}
              />
            </Row>
          </div>
        ))}
        <Row className="board-user-filter-scroll">
          {
            otherUsers.map((user) => (
              <div
                key={user.id}
                className="livefeed-tag-row"
                onClick={onClickUser(user)}
              >
                <ProfileAvatar
                  name={user.name}
                  role={user.position}
                  avatarRadius={12}
                />
              </div>
            ))
          }
        </Row>
      </div>
    </Menu>
  ), [
    selectedUsers,
    otherUsers,
    searchStr,
    dropdownRef,
    onChange,
    onClickUser,
    onRemove,
  ]);

  const userSelectorTitle = useMemo(() => {
    switch (selectedUsers.length) {
      case 0:
        return 'Filter';
      default:
        return `${selectedUsers.length} Users`;
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (onVisibleChanged) onVisibleChanged(visible);
  }, [onVisibleChanged, visible]);

  return (
    <Dropdown
      trigger={[]}
      visible={visible}
      disabled={disabled}
      overlay={dropdown}
    >
      <div ref={buttonRef}>
        <OnTraccrButton
          ref={buttonRef}
          id="board-user-filter-button"
          type={selectedUsers.length ? 'back' : 'primary'}
          icon={(
            <FilterOutlined
              style={{
                color: selectedUsers.length ? colors.ONTRACCR_RED : 'white',
              }}
            />
          )}
          onClick={onFilterClicked}
          title={userSelectorTitle}
          titleStyle={{
            maxWidth: 175,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            verticalAlign: 'middle',
            marginBottom: 2,
          }}
        />
      </div>
    </Dropdown>
  );
}

UserSelector.propTypes = {
  disabled: PropTypes.bool,
  filteredUsers: PropTypes.arrayOf(PropTypes.string),
  onUserChange: PropTypes.func,
  multiple: PropTypes.bool,
  onVisibleChanged: PropTypes.func,
};

UserSelector.defaultProps = {
  disabled: false,
  filteredUsers: [],
  onUserChange: undefined,
  multiple: false,
  onVisibleChanged: null,
};
