import {
  GET_EQUIPMENT,
  CREATE_EQUIPMENT,
  ARCHIVE_EQUIPMENT,
  DELETE_EQUIPMENT,
  UPDATE_EQUIPMENT,
  GET_EQUIPMENT_HISTORY,
  CHECK_IN_EQUIPMENT,
  CHECK_OUT_EQUIPMENT,
  GET_EQUIPMENT_FILES,
  GET_EQUIPMENT_NOTES,
  ADD_EQUIPMENT_NOTE,
  GET_EQUIPMENT_STATUSES,
  GET_EQUIPMENT_TYPES,
  GET_EQUIPMENT_CUSTOM_DATA,
} from '../../state/actionTypes';
import Analytics from '../../helpers/Analytics';
import EquipmentService from './equipment.service';
import { decorateFormWithFiles } from '../../forms/formHelpers';

export const createEquipment = (payload) => async (dispatch) => {
  Analytics.track('Equipment/Create');
  const { data } = await EquipmentService.create({
    ...payload,
    labels: payload.labels?.map((label) => ({
      title: label.title,
      labelId: label.id,
    })) ?? [],
  });
  if (!data) return false;
  dispatch({
    type: CREATE_EQUIPMENT,
    payload: {
      equipment: data,
      files: payload.files,
    },
  });
  return true;
};

export const getEquipment = () => async (dispatch) => {
  const { data } = await EquipmentService.get();
  if (!data) return false;
  dispatch({
    type: GET_EQUIPMENT,
    payload: {
      equipment: data,
    },
  });
  return true;
};

export const archiveEquipment = (id, active = false) => async (dispatch) => {
  Analytics.track('Equipment/Archive', { active });
  const didArchive = await EquipmentService.archive(id, active);
  if (!didArchive) return false;
  dispatch({
    type: ARCHIVE_EQUIPMENT,
    payload: {
      id,
      active,
    },
  });
  return true;
};

export const deleteEquipment = (id) => async (dispatch) => {
  Analytics.track('Equipment/Delete');
  const didDelete = await EquipmentService.deleteEquipment(id);
  if (!didDelete) return false;
  dispatch({
    type: DELETE_EQUIPMENT,
    payload: {
      id,
    },
  });
  return true;
};

export const updateEquipment = (id, payload) => async (dispatch) => {
  Analytics.track('Equipment/Update');
  const { data } = await EquipmentService.update(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_EQUIPMENT,
    payload: {
      id,
      newData: payload,
      createdLabels: data,
    },
  });
  return true;
};

export const getEquipmentHistory = (id) => async (dispatch) => {
  const { data } = await EquipmentService.getHistory(id);
  if (!data) return false;
  dispatch({
    type: GET_EQUIPMENT_HISTORY,
    payload: {
      id,
      ...data,
    },
  });
  return true;
};

export const checkIn = (id, payload) => async (dispatch) => {
  const { data } = await EquipmentService.checkIn(id, payload);
  if (!data) return false;
  dispatch({
    type: CHECK_IN_EQUIPMENT,
    payload: data,
  });
  return true;
};

export const checkOut = (id, payload) => async (dispatch) => {
  const { data } = await EquipmentService.checkOut(id, payload);
  if (!data) return false;
  dispatch({
    type: CHECK_OUT_EQUIPMENT,
    payload: data,
  });
  return true;
};

export const getEquipmentFiles = (id) => async (dispatch) => {
  const { data: files } = await EquipmentService.getFiles(id);
  if (!files) return false;
  dispatch({
    type: GET_EQUIPMENT_FILES,
    payload: {
      id,
      files,
    },
  });
  return true;
};

export const getNotes = (id) => async (dispatch) => {
  const { data } = await EquipmentService.getNotes(id);
  if (!data) return false;
  dispatch({
    type: GET_EQUIPMENT_NOTES,
    payload: {
      id,
      data,
    },
  });
  return true;
};

export const addNote = ({ id, text }) => async (dispatch) => {
  const { data } = await EquipmentService.addNote(id, text);
  if (!data) return false;
  dispatch({
    type: ADD_EQUIPMENT_NOTE,
    payload: data,
  });
  return true;
};

export const getStatuses = () => async (dispatch) => {
  const { data } = await EquipmentService.getStatuses();
  if (!data) return false;
  dispatch({
    type: GET_EQUIPMENT_STATUSES,
    payload: {
      statuses: data,
    },
  });
  return true;
};

export const getEquipmentTypes = () => async (dispatch) => {
  const { data } = await EquipmentService.getEquipmentTypes();
  if (!data) return false;
  dispatch({
    type: GET_EQUIPMENT_TYPES,
    payload: {
      customTypes: data,
    },
  });
  return true;
};

export const getEquipmentCustomData = (id) => async (dispatch) => {
  if (!id) return dispatch({ type: GET_EQUIPMENT_CUSTOM_DATA, payload: { data: [] } });

  const { data } = await EquipmentService.getEquipmentCustomData(id);
  await decorateFormWithFiles({ fileMap: data.fileMap });

  if (!data) return false;
  dispatch({
    type: GET_EQUIPMENT_CUSTOM_DATA,
    payload: {
      data,
    },
  });
  return true;
};
