import {
  CLEAR_BOARD_CARD_ANALYTICS_CONFIG,
  CLEAR_PROJECT_ANALYTICS_CONFIG,
  CREATE_BOARD_CARD_ANALYTICS_REPORT,
  CREATE_PROJECT_ANALYTICS_REPORT,
  DELETE_BOARD_CARD_ANALYTICS_REPORT,
  DELETE_PROJECT_ANALYTICS_REPORT,
  GET_BOARD_CARD_ANALYTICS_DATA,
  GET_PROJECT_ANALYTICS_DATA,
  GET_SAVED_BOARD_CARD_ANALYTICS_REPORTS,
  GET_SAVED_PROJECT_ANALYTICS_REPORTS,
  GET_SAVED_ANALYTICS_REPORTS,
  SELECT_SAVED_BOARD_CARD_REPORT,
  SELECT_SAVED_PROJECT_REPORT,
  SET_BOARD_CARD_ANALYTICS_CONFIG,
  SET_PROJECT_ANALYTICS_CONFIG,
  UPDATE_BOARD_CARD_ANALYTICS_FILTERS,
  UPDATE_BOARD_CARD_ANALYTICS_REPORT,
  UPDATE_PROJECT_ANALYTICS_FILTERS,
  UPDATE_PROJECT_ANALYTICS_REPORT,
} from '../state/actionTypes';

export default {};

export const DATE_FORMAT = 'MMM Do YY';
export const AGGREGATE_OPTS = [
  { label: 'Sum', value: 'sum' },
  { label: 'Average', value: 'average' },
  { label: 'Median', value: 'median' },
];

export const BREAKDOWN_OPTS = [
  { label: 'By User', value: 'user' },
  { label: 'By Status', value: 'status' },
  { label: 'By Color', value: 'color' },
];

export const FORM_FIELD_BREAKDOWN_OPTS = [
  ...BREAKDOWN_OPTS,
  { label: 'By Board Card', value: 'boardCard' },
  { label: 'By Board Status', value: 'boardStatus' },
];

export const BREAKDOWN_FILTER_SET = new Set(['user', 'status', 'boardCard', 'boardStatus']);

export const BASE_FIELDS = [
  { label: 'Number of cards', value: 'numberOfCards' },
  { label: 'Conversion rate between statuses', value: 'conversion' },
  { label: 'Time in status', value: 'timeInStatus' },
  { label: 'Number of new cards in status', value: 'newCardsInStatus' },
];

export const FIELD_TYPES = new Set([
  'text',
  'calculation',
  'dropdown',
  'yes-no',
  'table',
]);

export const DATE_PRESETS = [
  'This Week',
  'This Month',
  'This Quarter',
  'This Year',
  'Last 7 Days',
  'Last 30 Days',
];

export const FIELD_BREAKDOWN_CHARTS = new Set(['line', 'bar', 'table']);
export const FIELDS_CAN_BREAKDOWN = new Set(['text', 'calculation', 'table']);

export const FIELDS_WITH_NO_BREAKDOWN = new Set(['yes-no', 'dropdown']);
export const FIELD_IDS_WITH_NO_AGGREGATE = new Set([
  'conversion',
  'newCardsInStatus',
]);

export const PROJECT_ANALYTICS_TYPE = 'project';
export const BOARD_CARD_ANALYTICS_TYPE = 'boardCard';

// type to action maps

export const setAnalyticsConfigActionMap = {
  project: SET_PROJECT_ANALYTICS_CONFIG,
  boardCard: SET_BOARD_CARD_ANALYTICS_CONFIG,
};

export const getAnalyticsDataActionMap = {
  project: GET_PROJECT_ANALYTICS_DATA,
  boardCard: GET_BOARD_CARD_ANALYTICS_DATA,
};

export const getSavedAnalyticsReportsActionMap = {
  project: GET_SAVED_PROJECT_ANALYTICS_REPORTS,
  boardCard: GET_SAVED_BOARD_CARD_ANALYTICS_REPORTS,
  main: GET_SAVED_ANALYTICS_REPORTS,
};

export const createAnalyticsReportActionMap = {
  project: CREATE_PROJECT_ANALYTICS_REPORT,
  boardCard: CREATE_BOARD_CARD_ANALYTICS_REPORT,
};

export const updateAnalyticsReportActionMap = {
  project: UPDATE_PROJECT_ANALYTICS_REPORT,
  boardCard: UPDATE_BOARD_CARD_ANALYTICS_REPORT,
};

export const deleteAnalyticsReportActionMap = {
  project: DELETE_PROJECT_ANALYTICS_REPORT,
  boardCard: DELETE_BOARD_CARD_ANALYTICS_REPORT,
};

export const selectSavedReportActionMap = {
  project: SELECT_SAVED_PROJECT_REPORT,
  boardCard: SELECT_SAVED_BOARD_CARD_REPORT,
};

export const updateAnalyticsFiltersActionMap = {
  project: UPDATE_PROJECT_ANALYTICS_FILTERS,
  boardCard: UPDATE_BOARD_CARD_ANALYTICS_FILTERS,
};

export const clearAnalyticsConfigActionMap = {
  project: CLEAR_PROJECT_ANALYTICS_CONFIG,
  boardCard: CLEAR_BOARD_CARD_ANALYTICS_CONFIG,
};
