/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import ResizeObserver from 'rc-resize-observer';
import { VariableSizeGrid as Grid } from 'react-window';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  Input,
  Table,
  Row,
  Col,
  Spin,
} from 'antd';
import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { getFormLogColumns } from '../FormColumns';

import {
  getForms,
  getFormById,
  getFormApprovals,
  updateFormFilters,
} from '../state/forms.actions';

import FormDetailView from './FormDetailView';
import FormFilterDrawer from './FormFilterDrawer';
import InvoiceAddDrawer from '../../payables/invoices/InvoiceAddDrawer';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import DateRangePicker from '../../common/datepicker/DateRangePicker';

import useFormSearch from '../../common/hooks/useFormSearch';

import {
  arrayIncludesTerm,
  getIdMap,
  includesTerm,
  isNullOrUndefined,
  removeDuplicateArrayValues,
  toTitleCase,
} from '../../helpers/helpers';
import Analytics from '../../helpers/Analytics';
import { INVOICE_DRAWER_ADD_MODE } from '../../payables/invoices/invoiceConstants';
import ReportExcelExport from '../../reports/reportExcelExport';
import { parseFormResponseToReadableFormat } from '../ResponderHelpers';
import { textBasedFieldTypes } from '../formHelpers';

const exporter = new ReportExcelExport();

const MAX_FILTER_LENGTH = 100;
const ROW_HEIGHT = 61;

const setUpFormFilter = ({
  id,
  name,
  set,
  filters,
}) => {
  if (id
    && !set.has(id)
    && set.size < MAX_FILTER_LENGTH
  ) {
    filters.push({ text: name, value: id });
    set.add(id);
  }
};

function FormsList({
  topOffset = 160,
  forms = [],
  hideDate,
  query,
  visible = true,
  range,
  onRangeChange,
  isPayable,
  option,
  hideWhenEmpty,
  enableFormFilters,
  isFilterActive,
  customSearch,
  isPO,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formApprovals = useSelector((state) => state.forms.approvals);
  const customers = useSelector((state) => state.customers.customers);
  const projects = useSelector((state) => state.projects.projects);
  const vendorMap = useSelector((state) => state.vendors.vendors);

  const userMap = useSelector((state) => {
    const {
      users: {
        users = [],
      },
    } = state;
    return getIdMap(users);
  });

  const gridRef = useRef();

  const [searchTerm, setSearchTerm] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [poCloseMode, setPoCloseMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [invDrawerVisible, setInvDrawerVisible] = useState(false);
  const [invDrawerMode, setInvDrawerMode] = useState(INVOICE_DRAWER_ADD_MODE);
  const [selectedFormId, setSelectedFormId] = useState('');
  const [tableDimensions, setTableDimensions] = useState({ width: 1000, height: 1000 });
  const [filteredRows, setFilteredRows] = useState();
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [connectObject] = useState(() => {
    const obj = {
      scrollTo: gridRef.scrollTo,
    };
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });

    Object.defineProperty(obj, 'scrollTop', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollTop;
        }
        return null;
      },
      set: (scrollTop) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollTop,
          });
        }
      },
    });
    return obj;
  });

  const clearFormSelection = useCallback(() => setShowDetail(false), []);
  const updateInvDrawerMode = useCallback(() => setInvDrawerMode(INVOICE_DRAWER_ADD_MODE), []);
  const closeDrawer = useCallback(() => setInvDrawerVisible(false), []);
  const onAddInvoiceClick = useCallback((formId) => {
    setSelectedFormId(formId);
    setInvDrawerVisible(true);
    setInvDrawerMode(INVOICE_DRAWER_ADD_MODE);
  }, []);

  const onPOClose = useCallback(async (formId) => {
    setLoading(true);
    if (await dispatch(getFormById(formId, { getRecentDraft: true }))) {
      setShowDetail(true);
      setPoCloseMode(true);
    }
    setLoading(false);
  }, [loading]);

  const searchParams = useMemo(() => {
    const [
      startTime = DateTime.local().minus({ days: 14 }).toMillis(),
      endTime = DateTime.local().toMillis(),
    ] = range ?? [];
    return {
      ...(query || {}),
      searchTerm,
      ...hideDate ? {} : { startTime, endTime },
    };
  }, [query, searchTerm, hideDate, range]);

  const {
    searchSet,
    searchLoading,
  } = useFormSearch(searchParams);

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  const onSearch = useCallback(async (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchTerm(value);
  }, []);

  const onFormClick = useCallback(async (record) => {
    if (loading) return;
    Analytics.track('Forms/ViewSubmitted');
    setLoading(true);
    if (dispatch && await dispatch(getFormById(record.id, { getRecentDraft: true }))) {
      dispatch(getFormApprovals());
      setShowDetail(true);
    }
    setLoading(false);
  }, [dispatch, loading]);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);

  const {
    formList,
    vendorFilters,
    customerFilters,
    projectFilters,
    userFilters,
    templateFilters,
    statusFilters,
    cardFilters,
  } = useMemo(() => {
    const vFilters = [];
    const vendorFilterSet = new Set();
    const cFilters = [];
    const customerFilterSet = new Set();
    const pFilters = [];
    const projectFilterSet = new Set();
    const uFilters = [];
    const userFilterSet = new Set();
    const tFilters = [];
    const templateFilterSet = new Set();
    const sFilters = [];
    const statusFilterSet = new Set();
    const cardFilterList = [];
    const cardFilterSet = new Set();
    const newForms = forms.map((form) => {
      const {
        customerId,
        templateName,
        status,
        statusId,
        users = [],
        vendors = [],
        userId,
        card,
        projects: formProjects = [],
      } = form;
      const fullUsers = removeDuplicateArrayValues(users.concat([userId])).filter((u) => !!u);
      const userNameList = new Set();
      fullUsers.forEach((assignedUserId) => {
        const {
          [assignedUserId]: { name: userName } = {},
        } = userMap;
        if (userName) userNameList.add(userName);
        if (userName
          && !userFilterSet.has(assignedUserId)
          && userFilterSet.size < MAX_FILTER_LENGTH) {
          uFilters.push({ text: userName, value: assignedUserId });
          userFilterSet.add(assignedUserId);
        }
      });
      const vendorNameList = new Set();
      if (isPayable) {
        vendors.forEach((vendorId) => {
          const {
            [vendorId]: { name: vendorName } = {},
          } = vendorMap ?? {};
          if (vendorName) vendorNameList.add(vendorName);
          if (vendorName
            && !vendorFilterSet.has(vendorId)
            && vendorFilterSet.size < MAX_FILTER_LENGTH
          ) {
            vFilters.push({ text: vendorName, value: vendorId });
            vendorFilterSet.add(vendorId);
          }
        });
      }

      const projectNames = [];
      formProjects.forEach((projectId) => {
        const {
          [projectId]: {
            name: projectName,
          } = {},
        } = projectIdMap;

        projectNames.push(projectName);
        setUpFormFilter({
          id: projectId,
          name: projectName,
          set: projectFilterSet,
          filters: pFilters,
        });
      });

      const {
        [customerId]: {
          name: customer,
        } = {},
      } = customers;

      setUpFormFilter({
        id: customerId,
        name: customer,
        set: customerFilterSet,
        filters: cFilters,
      });

      setUpFormFilter({
        id: templateName,
        name: templateName,
        set: templateFilterSet,
        filters: tFilters,
      });

      if (status
        && statusId
        && !statusFilterSet.has(statusId)
        && statusFilterSet.size < MAX_FILTER_LENGTH
      ) {
        sFilters.push({ text: toTitleCase(status), value: statusId });
        statusFilterSet.add(statusId);
      }

      setUpFormFilter({
        id: card,
        name: card,
        set: cardFilterSet,
        filters: cardFilterList,
      });

      return {
        ...form,
        createdAtDate: form.createdAt
          ? DateTime.fromMillis(form.createdAt).toLocaleString(DateTime.DATETIME_MED)
          : null,
        lastUpdatedDate: form.lastUpdated
          ? DateTime.fromMillis(form.lastUpdated).toLocaleString(DateTime.DATETIME_MED)
          : null,
        users: fullUsers,
        customer,
        project: projectNames.join(', '),
        userNameList,
        userNames: Array.from(userNameList).join(', '),
        vendorNameList,
      };
    })
      .filter((form) => hideDate
        || !range
        || range.length < 2
        || (form.lastUpdated >= range[0] && form.lastUpdated <= range[1]));
    newForms.sort((a, b) => b.lastUpdated - a.lastUpdated);
    return {
      formList: newForms,
      vendorFilters: vFilters,
      customerFilters: cFilters,
      projectFilters: pFilters,
      userFilters: uFilters,
      templateFilters: tFilters,
      statusFilters: sFilters,
      cardFilters: cardFilterList,
    };
  }, [forms, hideDate, range, isPayable, projectIdMap, customers, vendorMap]);

  const columns = useMemo(() => (
    getFormLogColumns({
      userMap,
      vendorMap,
      formApprovals,
      templateFilters,
      statusFilters,
      userFilters,
      vendorFilters,
      customerFilters,
      projectFilters,
      cardFilters,
      query,
      isPayable,
      onAddInvoiceClick,
      onPOClose,
      t,
      isPO,
    })
  ), [
    userMap,
    vendorMap,
    formApprovals,
    templateFilters,
    statusFilters,
    userFilters,
    vendorFilters,
    customerFilters,
    projectFilters,
    cardFilters,
    query,
    onAddInvoiceClick,
    onPOClose,
    isPayable,
    isPO,
  ]);

  const decoratedColumns = useMemo(() => {
    const columnWidthSum = columns.reduce((acc, col) => acc + col.width, 0);
    const { width: tableWidth } = tableDimensions;
    return columns.map((col) => {
      const { width } = col;
      if (columnWidthSum === 0) return col;
      const expected = tableWidth * (width / columnWidthSum);
      return {
        ...col,
        width: expected > width ? expected : width,
      };
    });
  }, [tableDimensions, columns]);

  useEffect(() => {
    const load = async () => {
      const [
        startTime = DateTime.local().minus({ days: 14 }).toMillis(),
        endTime = DateTime.local().toMillis(),
      ] = range ?? [];
      setLoading(true);
      await Promise.all([
        dispatch(getForms({
          ...(query || {}),
          ...(hideDate ? {} : { startTime, endTime }),
        })),
        dispatch(getFormApprovals()),
      ]);
      setLoading(false);
    };
    if (visible && (hideDate || (range && range.length === 2))) {
      load();
    }

    if (!visible) {
      setFilteredRows();
    }
  }, [hideDate, query, range, visible]);

  useEffect(() => resetVirtualGrid, [tableDimensions.width]);

  const tableData = useMemo(() => (
    !searchSet
      ? formList
      : formList.filter(({
        status,
        number,
        templateName,
        userId,
        id: formId,
        project,
        customer,
        formValue,
        costToDate,
        userNameList = new Set(),
        vendorNameList = new Set(),
      }) => (
        !searchTerm
          || (!isNullOrUndefined(status) && includesTerm(status, searchTerm))
          || (!isNullOrUndefined(number) && includesTerm(number, searchTerm))
          || (!isNullOrUndefined(templateName) && includesTerm(templateName, searchTerm))
          || (userId in userMap && includesTerm(userMap[userId].name, searchTerm))
          || searchSet.has(formId)
          || (!isNullOrUndefined(project) && includesTerm(project, searchTerm))
          || (!isNullOrUndefined(customer) && includesTerm(customer, searchTerm))
          || (!isNullOrUndefined(formValue) && typeof formValue === 'number' && includesTerm(formValue.toString(), searchTerm))
          || (!isNullOrUndefined(costToDate) && typeof costToDate === 'number' && includesTerm(costToDate.toString(), searchTerm))
          || (userNameList.size > 0 && arrayIncludesTerm(Array.from(userNameList), searchTerm))
          || (vendorNameList.size > 0 && arrayIncludesTerm(Array.from(vendorNameList), searchTerm))
      ))
  ), [searchSet, formList, searchTerm]);

  const height = `calc(100vh - ${topOffset}px)`;
  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * ROW_HEIGHT;
    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={decoratedColumns.length}
        columnWidth={(index) => {
          const { width } = decoratedColumns[index];
          return totalHeight > tableDimensions.height && index === decoratedColumns.length - 1
            ? width - scrollbarSize - 1
            : width;
        }}
        height={tableDimensions.height - 39}
        rowCount={rawData.length}
        rowHeight={() => ROW_HEIGHT}
        width={tableDimensions.width}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          const column = decoratedColumns[columnIndex];
          const record = rawData[rowIndex] ?? {};
          const value = record[column.dataIndex];
          const body = column.render
            ? column.render(value, record)
            : value;
          return (
            <div
              onClick={() => onFormClick(record)}
              className={
                columnIndex === columns.length - 1
                  ? 'virtual-table-cell-base'
                  : 'virtual-table-cell'
              }
              style={style}
            >
              <div className="virtual-table-cell-inner">
                {body}
              </div>
            </div>
          );
        }}
      </Grid>
    );
  };

  const onExport = useCallback(() => {
    setExportLoading(true);

    const existingTemplateNames = new Set();

    const dataSource = filteredRows || tableData;
    const groupedForms = dataSource.reduce((acc, form) => {
      const {
        templateId,
        templateName,
        project,
      } = form;

      if (!acc[templateId]) {
        const relevantColumns = columns.map((column) => ({
          ...column,
          field: column.exportDataIndex || column.dataIndex,
        })).filter((column) => !!column.title);

        form.templateSchema.sections.forEach(({ fields }) => {
          fields.forEach((field) => {
            const {
              configProps = {},
              selectedType,
              id: fieldId,
            } = field;

            if (textBasedFieldTypes.has(selectedType)) {
              relevantColumns.push({
                title: configProps?.title,
                field: fieldId,
              });
            }
          });
        });

        // Max length of template name is 31 characters
        let adjustedTemplateName = (
          project ? `${templateName}-${project}` : templateName
        ).slice(0, 31);

        // Apparently we have duplicate template names in the database, so we'll append a number to the end of the name
        if (existingTemplateNames.has(adjustedTemplateName)) {
          // Max length of template name is 31 characters, and we add 4 characters for the number
          adjustedTemplateName = adjustedTemplateName.slice(0, 27);

          let i = 1;
          while (existingTemplateNames.has(`${adjustedTemplateName} (${i})`)) {
            i += 1;
          }
          adjustedTemplateName = `${adjustedTemplateName} (${i})`;
        }

        existingTemplateNames.add(adjustedTemplateName);

        acc[templateId] = {
          templateName: adjustedTemplateName,
          columns: relevantColumns,
          data: [],
        };
      }

      acc[templateId].data.push({
        ...form,
        ...parseFormResponseToReadableFormat(
          form.templateSchema.sections,
          form.data?.sections,
        ),
      });
      return acc;
    }, {});

    exporter.createFormListExport(Object.values(groupedForms));
    setExportLoading(false);
  }, [tableData, columns, filteredRows]);

  const onTableFilter = useCallback((_pagination, _filters, _sorter, extra) => {
    if (extra?.currentDataSource) {
      setFilteredRows(extra.currentDataSource);
    }
  }, []);

  const onClearFilter = useCallback(() => {
    dispatch(updateFormFilters(null));
  }, []);

  return (
    <div>
      <Row style={{ marginBottom: 14 }} align="middle" justify="space-between" gutter={20}>
        <Col>
          <Row align="middle" gutter={15}>
            {!customSearch && (
              <Col>
                <Input.Search
                  type="search"
                  className="searchbar"
                  placeholder="Search"
                  allowClear
                  style={{ width: 250 }}
                  onChange={onSearch}
                />
              </Col>
            )}
            {customSearch && (
              <Col>
                { customSearch }
              </Col>
            )}
            <Col style={{ height: 20, width: 20 }}>
              {searchLoading && <Spin size="small" />}
            </Col>
            {option && (
              <Col style={{ marginLeft: 2 }}>
                <OnTraccrButton {...option} />
              </Col>
            )}
            <Col>
              <OnTraccrButton
                title="Export"
                loading={exportLoading}
                onClick={onExport}
              />
            </Col>
            {enableFormFilters && (
              <>
                <Col>
                  <OnTraccrButton
                    title=""
                    onClick={() => setShowFilterDrawer(true)}
                    icon={(
                      isFilterActive
                        ? <FilterFilled style={{ marginLeft: 0 }} />
                        : <FilterOutlined style={{ marginLeft: 0 }} />
                    )}
                  />
                </Col>
                {isFilterActive && (
                  <Col>
                    <OnTraccrButton
                      title="Clear Filter"
                      onClick={onClearFilter}
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        </Col>
        {!hideDate && (
          <Col>
            <DateRangePicker
              onRangeChange={onRangeChange}
            />
          </Col>
        )}
      </Row>
      {(!hideWhenEmpty || tableData.length > 0)
        && (
        <ResizeObserver
          onResize={({ height, width }) => {
            setTableDimensions({ height, width });
          }}
        >
          <div className="file-list-container" style={{ height }}>
            <Table
              scroll={{
                y: height,
              }}
              dataSource={tableData}
              columns={columns}
              pagination={false}
              size="small"
              rowClassName="file-table-row"
              rowKey="id"
              components={{
                body: renderVirtualList,
              }}
              onChange={onTableFilter}
            />
          </div>
        </ResizeObserver>
        )}
      <FormDetailView
        visible={showDetail}
        onClose={clearFormSelection}
        userMap={userMap}
        poCloseMode={poCloseMode}
        setPoCloseMode={setPoCloseMode}
      />
      <InvoiceAddDrawer
        formId={selectedFormId}
        visible={invDrawerVisible}
        mode={invDrawerMode}
        useRange={false}
        closeDrawer={closeDrawer}
        updateInvDrawerMode={updateInvDrawerMode}
      />
      <FormFilterDrawer
        visible={showFilterDrawer}
        onClose={() => setShowFilterDrawer(false)}
      />
      {loading
        && (
          <Row justify="center" align="middle" className="form-loading-container">
            <Spin size="large" />
          </Row>
        )}
    </div>
  );
}

FormsList.propTypes = {
  enableFormFilters: PropTypes.bool,
  isFilterActive: PropTypes.bool,
  isPO: PropTypes.bool,
};

FormsList.defaultProps = {
  enableFormFilters: false,
  isFilterActive: false,
  isPO: false,
};

export default FormsList;
