import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import ScheduleGrid from './ScheduleGrid';
import ShiftBlock from './ShiftBlock';

import {
  onMouseDown as mouseDownHelper,
  onMouseUp as mouseUpHelper,
  onMouseMove as mouseMoveHelper,
  calculateWidths,
} from './scheduleHelpers';

import { DAILY_LEFT_OFFSET } from './schedule.constants';

import Permissions from '../auth/Permissions';

export default ({
  day,
  hideGrid,
  isWeek,
  rightDivider,
  onShiftCreate,
  newShift,
  onNewShiftChange,
  onShiftSelect,
  editShift,
  onShiftEdit,
  headerHeight,
  scrollTop = 0,
  shifts = [],
  containerY,
}) => {
  const hasWritePerms = Permissions.has('SCHEDULE_WRITE');
  const containerRef = useRef(null);
  const scale = useSelector(state => state.schedule.scale);

  const getShifts = () => {
    const filteredShifts = shifts
      .filter((shift) => !editShift || shift.id !== editShift.id)
      .sort((a,b) => (b.endTime - b.startTime) - (a.endTime - a.startTime));
    if(!containerRef || !containerRef.current) return filteredShifts;
    const { clientWidth } = containerRef.current;
    
    
    return calculateWidths({
      dayShifts: filteredShifts,
      day,
      parentWidth: clientWidth,
      leftOffset: isWeek ? 0 : DAILY_LEFT_OFFSET,
    });
  }
  const completedShifts = getShifts();

  const onMouseDown = useCallback((e) => {
    mouseDownHelper({
      day,
      e,
      scrollTop,
      shifts: completedShifts,
      onShiftEdit,
      onNewShiftChange,
      isWeek,
      scale,
      headerHeight,
      containerY,
    });
  },[scrollTop, completedShifts, onShiftEdit, onNewShiftChange, isWeek, day, scale, headerHeight, containerY]);

  const onMouseUp = useCallback((e) => {
    mouseUpHelper({
      day,
      e,
      shifts: completedShifts,
      scrollTop,
      editShift,
      onShiftEdit,
      newShift,
      onShiftSelect,
      onNewShiftChange,
      onShiftCreate,
      isWeek,
      scale,
      headerHeight,
      containerY,
    });
  },[
    scrollTop,
    newShift,
    onShiftCreate,
    completedShifts,
    editShift,
    onNewShiftChange,
    onShiftSelect,
    onShiftEdit,
    isWeek,
    day,
    scale,
    headerHeight,
    containerY,
  ]);

  const onMouseMove = useCallback((e) => {
    mouseMoveHelper({
      day,
      e,
      scrollTop,
      editShift,
      newShift,
      onShiftEdit,
      onNewShiftChange,
      isWeek,
      scale,
      headerHeight,
      containerY,
    })
  },[scrollTop, newShift, editShift, onNewShiftChange, onShiftEdit, isWeek, day, scale, headerHeight, containerY]);

  const hasEdit = editShift && editShift.day && editShift.day.hasSame(day,'day');
  const hasNew = newShift && newShift.day && newShift.day.hasSame(day,'day');
  return (
    <div
      ref={containerRef}
      className={rightDivider
        ? 'schedule-view-inner-container-right-divider'
        : 'schedule-view-inner-container'}
      onMouseDown={hasWritePerms ? onMouseDown : null}
      onMouseMove={hasWritePerms ? onMouseMove : null}
      onMouseUp={hasWritePerms ? onMouseUp : null}
    >
      <ScheduleGrid hideGrid={hideGrid}/>
      {
        completedShifts.map((shift) => (
          <ShiftBlock
            key={shift.id}
            isWeek={isWeek}
            shift={shift}
            onClick={(e) => {
              e.stopPropagation();
              onShiftSelect(shift);
            }}
            style={{ cursor: 'pointer' }}
          />
        ))
      }
      {
        hasEdit && 
        <ShiftBlock
          isWeek={isWeek}
          shift={editShift}
          style={{
            cursor:'move',
            zIndex:100,
            left: isWeek ? 0 : DAILY_LEFT_OFFSET,
            width: '100%',
          }}
        />
      }
      {hasNew && 
        <ShiftBlock
          isWeek={isWeek}
          shift={newShift}
          style={{
            cursor:'row-resize',
            zIndex:100,
            ...newShift.complete ? {} : { maxWidth:'100%' }
          }}
        />
      }
    </div>
  );
}