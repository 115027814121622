import axios from 'axios';
import { message } from 'antd';
import { request, uploadFiles } from '../../helpers/requests';
import { formatPayloadCustomData } from '../../equipment/state/equipment.service';

const fileGetter = axios.create({ responseType: 'blob' });

const splitNewAndExistingFiles = (files) => {
  const newFiles = [];
  const existingFiles = [];
  files.forEach((file) => {
    if (file.id) {
      existingFiles.push({ id: file.id });
    } else {
      newFiles.push(file);
    }
  });
  return { newFiles, existingFiles };
};

const UserService = {
  getAll: async () => {
    try {
      const { data } = await axios.get('/users', { params: { getTasks: false }});
      return data;
    } catch (err) {
      message.error('Could not retrieve users');
      return [];
    }
  },
  get: async (id) => {
    try {
      const { data } = await axios.get(`/users/${id}`);
      return data;
    } catch (err) {
      message.error('Could not retrieve user');
      return null;
    }
  },
  create: async (payload) => request({
    call: async () => {
      const fullPayload = { ...payload };
      const { files = [] } = payload;
      const {
        newFiles,
        existingFiles,
      } = splitNewAndExistingFiles(files);
      const parsedFiles = await uploadFiles({ files: newFiles });
      const {
        customData: formattedCustomData,
        files: formattedFiles,
      } = await formatPayloadCustomData(fullPayload.customData);
      fullPayload.customData = formattedCustomData;
      fullPayload.files = existingFiles.concat(
        parsedFiles.map((file) => ({ ...file, isNew: true })),
        formattedFiles.map((file) => ({ ...file, isNew: true, isFormFile: true })),
      );
      return axios.post('/users', fullPayload);
    },
    errMsg: 'Could not create user',
    successMsg: 'User created successfully',
  }),
  update: async (id, payload) => request({
    call: async () => {
      const fullPayload = { ...payload };
      const {
        addedFiles = [],
        removedFiles = [],
      } = payload;
      delete fullPayload.files;
      const {
        newFiles,
        existingFiles,
      } = splitNewAndExistingFiles(addedFiles);
      const parsedFiles = await uploadFiles({ files: newFiles });
      const {
        customData: formattedCustomData,
        files: formattedFiles,
      } = await formatPayloadCustomData(fullPayload.customData);
      fullPayload.customData = formattedCustomData;
      fullPayload.addedFiles = existingFiles.concat(
        parsedFiles.map((file) => ({ ...file, isNew: true })),
        formattedFiles.map((file) => ({ ...file, isNew: true, isFormFile: true })),
      );
      fullPayload.removedFiles = removedFiles.map((file) => file.id);

      return axios.put(`/users/${id}`, fullPayload);
    },
    errMsg: 'Could not update user',
    successMsg: 'User successfully updated',
  }),
  delete: async (id) => request({
    call: axios.delete(`/users/${id}`),
    errMsg: 'Could not delete user',
    successMsg: 'User successfully deleted',
  }),
  archive: (id, active) => request({
    call: axios.put(`/users/${id}/archive`, { active }),
    errMsg: `Could not ${active ? 'reactivate' : 'archive'} user`,
    successMsg: `User successfully ${active ? 'reactivated' : 'archived'}`,
  }),
  massArchive: (ids) => request({
    call: axios.put('/users/archive', { ids }),
    errMsg: 'Could not archive users',
    successMsg: 'Users archived sucessfully',
  }),
  acceptToS: () => request({
    call: axios.put('/tos/accept'),
    errMsg: 'Error accepting Terms of Service',
    hideSuccessToast: true,
  }),
  getUserTeams: () => request({
    call: axios.get('/users/teams'),
    errMsg: 'Could not get user teams',
    hideSuccessToast: true,
  }),
  getUserLabels: () => request({
    call: axios.get('/users/labels'),
    errMsg: 'Could not get user labels',
    hideSuccessToast: true,
  }),
  getUserFiles: (id) => request({
    call: async () => {
      const { data: files = [] } = await axios.get(`/users/${id}/files`);
      return {
        data: await Promise.all(
          files.map(async (file) => {
            const { signedURL, name, id: fileId } = file;
            if (!signedURL) return file;
            const {
              data: fileBlob,
              headers: {
                'Response-Type': repsonseType,
              },
            } = await fileGetter.get(signedURL);
            const jsFileObject = new File([fileBlob], name, repsonseType);
            jsFileObject.id = fileId;
            return jsFileObject;
          }),
        ),
      };
    },
    errMsg: 'Could not get user files',
    hideSuccessToast: true,
  }),
  getNotes: (id) => request({
    call: axios.get(`/users/${id}/notes`),
    errMsg: 'Could not get user notes',
    hideSuccessToast: true,
  }),
  addNote: (id, note) => request({
    call: axios.post(`/users/${id}/note`, { note }),
    errMsg: 'Could not get user note',
  }),
  getAllCertifications: () => request({
    call: axios.get('/users/certifications', { params: { getCustomData: true } }),
    errMsg: 'Could not get certifications',
    hideSuccessToast: true,
  }),
  getUserSettings: () => request({
    call: axios.get('/users/settings'),
    errMsg: 'Could not get user settings',
    hideSuccessToast: true,
  }),
  updateUserSettings: (payload) => request({
    call: axios.put('/users/settings', payload),
    errMsg: 'Could not update user settings',
  }),
  getCustomFieldTemplate: () => request({
    call: axios.get('/users/customFieldTemplate'),
    errMsg: 'Failed to get user custom field template',
    hideSuccessToast: true,
  }),
  updateCustomFieldTemplate: (payload) => request({
    call: axios.post('/users/customFieldTemplate', payload),
    errMsg: 'Failed to update user custom field template',
  }),
  getCustomData: (id) => request({
    call: axios.get(`/users/${id}/data`),
    errMsg: 'Failed to get user custom data',
    hideSuccessToast: true,
  }),
};

export default UserService;
