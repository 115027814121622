import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import GanttChart from './GanttChart';
import getEmployeeProjectFilters from './EmployeeProjectFilters';

import { getIdMap, getNames } from '../../helpers/helpers';
import { msToHours } from '../../helpers/time';
import { filterOnCols } from '../reportHelpers';

const compareIfValid = (reference, d2, compare) => {
  if (d2 && d2.isValid()) return compare(reference, d2);
  return reference;
};

const isValid = (d) => d && moment.isMoment(d) && d.isValid();

const compareMoments = (d1, d2, compare) => {
  if (!(isValid(d1))) return d2;
  if (!(isValid(d2))) return d1;
  return compare(d1, d2);
};

class ProjectTimeline extends React.Component {
  constructor(props) {
    super(props);
    const {
      users = [],
      height = 450,
      costcodes = [],
      projects = [],
      phases = [],
      columns,
    } = this.props;

    this.checkedColumns = new Set(['phase', 'costcode', 'estimatedHours', 'actualHours'].filter(filterOnCols(columns)));
    this.state = {
      height,
      users,
      phases: new Set(getNames(phases).concat('None')),
      projects: new Set(getNames(projects).concat('None')),
      costcodes: new Set(costcodes.map((cc) => cc.code).concat('None')),
      enabledFilters: {
        project: true,
        costcode: true,
        estimatedHours: true,
        actualHours: true,
      },
    };

    this.onResize = this.resize.bind(this);
    this.onFilterToggle = this.toggleFilter.bind(this);

    this.costcodeIdMap = getIdMap(costcodes);
    this.projectIdMap = getIdMap(projects);

    this.phaseMap = {};
    phases.forEach(({ projectId, costcodeId, name }) => {
      if (!(projectId in this.phaseMap)) {
        this.phaseMap[projectId] = {};
      }
      if (!(costcodeId in this.phaseMap[projectId])) {
        this.phaseMap[projectId][costcodeId] = [];
      }
      this.phaseMap[projectId][costcodeId].push(name);
    });
  }

  componentDidMount() {
    const {
      onRef,
    } = this.props;

    onRef(this);
    this.resize();
  }

  componentDidUpdate(prevProps) {
    const {
      height: prevHeight,
    } = prevProps;
    const {
      height,
    } = this.props;
    if (height !== prevHeight) {
      this.setState({
        height,
      });
    }
  }

  componentWillUnmount() {
    const {
      onRef,
    } = this.props;

    onRef(null);
  }

  getRelevantPhases() {
    const {
      phases = [],
    } = this.props;
    const {
      projects: selectedProjects,
    } = this.state;
    return selectedProjects.size === 0
      ? phases : phases.filter((phase) => {
        const project = this.projectIdMap[phase.projectId];
        return project && selectedProjects.has(project.name);
      });
  }

  getRelevantCostcodes() {
    const {
      costcodes = [],
    } = this.props;
    const {
      projects: selectedProjects,
    } = this.state;
    return selectedProjects.size === 0
      ? costcodes
      : costcodes.filter((costcode) => {
        const project = this.projectIdMap[costcode.projectId];
        return project && selectedProjects.has(project.name);
      });
  }

  getColFilters() {
    const {
      projects = [],
      t,
    } = this.props;

    const filters = getEmployeeProjectFilters({
      onFilterToggle: (type, checked) => {
        this.setState({
          [type]: checked,
        });
      },
      onCheckChanged: (type, checked) => this.updateRowFilters(type, checked),
      projects,
      costcodes: this.getRelevantCostcodes(),
      phases: this.getRelevantPhases(),
      showEmployee: false,
      projectCheckDisabled: true,
      checkedColumns: this.checkedColumns,
      t,
    });
    return filters.concat([
      {
        title: 'Estimated Hours',
        key: 'estimatedHours',
        onCheckChanged: (checked) => this.updateRowFilters('estimatedHours', checked),
        checked: this.checkedColumns.has('estimatedHours'),
      }, {
        title: 'Actual Hours',
        key: 'actualHours',
        onCheckChanged: (checked) => this.updateRowFilters('actualHours', checked),
        checked: this.checkedColumns.has('actualHours'),
      },
    ]);
  }

  getAggregateKey(projectName, costcodeName) {
    const {
      enabledFilters: {
        costcode,
        project,
      },
    } = this.state;
    const keys = [projectName, costcodeName];
    const filters = [costcode, project];
    for (const index in filters) {
      const filter = filters[index];
      if (filter) return keys.join('-');
      keys.pop();
    }
    return 'All';
  }

  toggleFilter(item, checked) {
    const {
      key: itemKey,
    } = item;

    if (checked) {
      this.checkedColumns.add(itemKey);
    } else {
      this.checkedColumns.delete(itemKey);
    }
  }

  resize() {
    const {
      height,
    } = this.props;
    this.setState({
      height,
    });
  }

  updateRowFilters(key, checked) {
    this.setState({
      enabledFilters: {
        ...this.state.enabledFilters,
        [key]: checked,
      },
    });
    this.toggleFilter({ key }, checked);
  }

  failsFilter(filters, item, key = 'name') {
    if (!item && !filters.has('None')) return true;
    if (item && !filters.has(item[key])) return true;
    return false;
  }

  formatData() {
    const {
      users = [],
      projects = [],
      timeEntryUserMap = {},
    } = this.props;
    const {
      enabledFilters,
      projects: selectedProjects,
      costcodes,
    } = this.state;

    const projectTimeMaps = {};
    const projectActualHoursMap = {};

    const relevantProj = [];

    const {
      estimatedHours,
      actualHours,
    } = enabledFilters;
    if (!estimatedHours && !actualHours) return { projects: [] };

    if (actualHours) {
      users.forEach((user) => {
        const tasks = timeEntryUserMap[user?.id] ?? [];
        tasks.forEach((task) => {
          if (!task.startTime || !task.endTime) return;
          const project = this.projectIdMap[task.projectId];
          if (!project) return;
          if (this.failsFilter(selectedProjects, project)) return;

          const costcode = this.costcodeIdMap[task.costcodeId];
          if (this.failsFilter(costcodes, costcode, 'code')) return;

          const projectId = project.id;
          const startMoment = moment(task.startTime);
          const endMoment = moment(task.endTime);

          if (!(projectId in projectTimeMaps)) {
            projectTimeMaps[projectId] = {
              startTime: startMoment,
              endTime: endMoment,
            };
          }

          if (!(projectId in projectTimeMaps)
              || projectTimeMaps[projectId].startTime.isBefore(startMoment)) {
            projectTimeMaps[projectId].startTime = startMoment;
          }
          if (!(projectId in projectTimeMaps)
              || projectTimeMaps[projectId].endTime.isBefore(endMoment)) {
            projectTimeMaps[projectId].endTime = endMoment;
          }
          const runTime = task.endTime - task.startTime;
          const totalHours = msToHours(runTime);
          projectActualHoursMap[projectId] = (projectActualHoursMap[projectId] || 0) + totalHours;
        });
      });
    }

    let minProjectStart = moment().year(10000);
    let maxProjectEnd = moment().year(0);

    projects.forEach((project) => {
      if (!(selectedProjects.has(project.name))) return;
      const { startDate } = project;
      const { endDate } = project;

      if (!estimatedHours && !(project.id in projectTimeMaps)) return;
      if (!actualHours && (!startDate || !endDate)) return;

      if (!startDate || !endDate) {
        if (!(project.id in projectTimeMaps)) return; // No one has clocked into this project yet
      }
      const startMoment = moment(startDate);
      const endMoment = moment(endDate);

      const projectTaskTimes = projectTimeMaps[project.id] || {};
      const {
        startTime: firstTask,
        endTime: lastTask,
      } = projectTaskTimes;

      if (estimatedHours) {
        minProjectStart = compareIfValid(minProjectStart, startMoment, moment.min);
        maxProjectEnd = compareIfValid(maxProjectEnd, endMoment, moment.max);
      }
      if (actualHours) {
        minProjectStart = compareIfValid(minProjectStart, firstTask, moment.min);
        maxProjectEnd = compareIfValid(maxProjectEnd, lastTask, moment.max);
      }

      const data = {
        ...project,
        key: project.id,
      };

      if (estimatedHours) {
        data.startDate = project.startDate ? moment(project.startDate) : null;
        data.endDate = project.endDate ? moment(project.endDate) : null;
      }

      if (actualHours) {
        data.lastTask = lastTask;
        data.firstTask = firstTask;
        data.actualHours = projectActualHoursMap[project.id];
      }
      const minDate = compareMoments(data.startDate, data.firstTask, moment.min);
      const maxDate = compareMoments(data.endDate, data.lastTask, moment.max);
      const totalDuration = Math.ceil(maxDate.diff(minDate, 'month', true)) + 1;
      const percentComplete = project.labourHours
        ? ((data.actualHours || 0) / project.labourHours) * 100
        : null;

      data.maxDate = maxDate;
      data.minDate = minDate;
      data.totalDuration = totalDuration;
      data.percentComplete = percentComplete;

      relevantProj.push(data);
    });

    return {
      minProjectStart,
      maxProjectEnd,
      projects: relevantProj,
    };
  }

  render() {
    const {
      renderColFilters,
    } = this.props;
    const {
      height,
      enabledFilters,
    } = this.state;
    const { minProjectStart, maxProjectEnd, projects } = this.formatData();
    return (
      <GanttChart
        height={height}
        colFilters={renderColFilters(this.getColFilters())}
        minProjectStart={minProjectStart}
        maxProjectEnd={maxProjectEnd}
        projects={projects}
        enabledFilters={enabledFilters}
      />
    );
  }
}

export default withTranslation()(ProjectTimeline);
