import moment from 'moment';
import { DateTime } from 'luxon';
import { isNullOrUndefined } from '../../../../helpers/helpers';
import {
  UNPHASED,
  getAllTimesText,
  getCostcodeOptions, getPhaseOptions, getProjectOptions,
} from '../../../../clock/ManualEntry/manualEntryHelpers';

export default {};

export const parseDateTimeFromString = (date) => {
  const formats = ['YYYY-MM-DD', 'M/D/YYYY', 'M/DD/YYYY', 'M/DD/YY'];

  let parsedDate = moment.invalid();

  formats.forEach((format) => {
    const momentDate = moment(date, format, true);
    if (momentDate.isValid()) {
      parsedDate = momentDate;
    }
  });

  return parsedDate;
};

export const luxonToMoment = (date) => {
  const dateString = date.toMillis();
  return moment(dateString);
};

// Defaults
export const simpleEquality = (a, b) => a === b;
export const defaultFormatter = ({ task, property }) => task?.[property] ?? '';

// Is Equal Checkers
export const areDatesEqual = (a, b) => {
  if (!a) {
    return a === b;
  }

  if (isNullOrUndefined(b) || isNullOrUndefined(a)) {
    return false;
  }

  return a.valueOf() === b.valueOf();
};

const areDateTimeEqual = (a, b) => {
  if (!a) {
    return a === b;
  }

  const {
    date: aDate = 0,
    time: aTime = 0,
  } = a;

  const {
    date: bDate = 0,
    time: bTime = 0,
  } = b;

  return (aDate === bDate) && (aTime === bTime);
};

const areTimesEqual = (a, b) => {
  if (!a || !b) {
    return a === b;
  }

  const times = [
    'startTime',
    'endTime',
    'otStartTime',
    'otEndTime',
    'doubleOTStartTime',
    'doubleOTEndTime',
    'breakStartTime',
    'breakEndTime',
  ];

  return times.every((key) => a[key] === b[key]);
};

// Display Formatters
const dateFormatter = ({ task, timezone }) => {
  const { dateTimestamp: date } = task;

  const dateObject = DateTime.fromMillis(date, { zone: timezone });
  return dateObject.toSQLDate();
};

const timeFormatter = ({ value: time, task, timezone }) => (
  getAllTimesText(
    { ...time, ...task, timezone },
    { showRegularTypeText: false, addTypeAsSuffix: true, returnComponent: true },
  )
);

const dateTimeFormatter = ({ value }) => {
  if (!value || !Object.keys(value).length) {
    return '';
  }

  const {
    date = null,
    time = null,
  } = value;

  if (!date && !time) {
    return '';
  }

  const luxonDate = date ? DateTime.fromMillis(date) : null;
  const luxonTime = time ? DateTime.fromMillis(time) : null;

  const dateString = luxonDate ? luxonDate.toFormat('yyyy-MM-dd') : '';
  const timeString = luxonTime ? luxonTime.toFormat('h:mm a') : '';

  return `${dateString} ${timeString}`;
};

const costCodeFormatter = ({ task, value }) => {
  const { costcode_name: name, costcode_code: code } = task;
  let costcodeValue = '';

  if (code && name) {
    costcodeValue = `${code} - ${name}`;
  }

  return costcodeValue;
}

const phaseFormatter = ({ task, value }) => {
  const { phaseId } = task;
  if (phaseId === UNPHASED) return UNPHASED;
  return defaultFormatter({ task, property: 'phase', value });
};

// Select Source Filters
export const getSimpleSelectOptions = (key) => () => {
  switch (key) {
    case 'type': {
      return [
        {
          value: 'work',
          label: 'Work',
        }, {
          value: 'break',
          label: 'Break',
        },
      ];
    }
    case 'yes-no': {
      return [
        {
          value: 'yes',
          label: 'Yes',
        }, {
          value: 'no',
          label: 'No',
        }, {
          value: 'n/a',
          label: 'N/A',
        },
      ];
    }
    default: {
      return [];
    }
  }
};

export const getHourBasedOptions = ({ settings = {} }) => {
  const { enableHourBasedTracking = false } = settings;
  if (enableHourBasedTracking) {
    return [
      {
        value: true,
        label: 'True',
      }, {
        value: false,
        label: 'False',
      },
    ];
  }

  return [
    {
      value: false,
      label: 'False',
    },
  ];
};

// Helpers
export const getTableInputParameters = ({
  value,
  entry: {
    customData = {},
    ...task
  } = {},
  type,
  id,
}) => {
  switch (type) {
    case 'dateTime': {
      const {
        [id]: {
          date,
          time,
        } = {},
      } = customData;

      return {
        displayFormatter: dateTimeFormatter,
        areEqual: areDateTimeEqual,
        value: {
          date,
          time,
        },
        inputType: 'dateTime',
      };
    }
    case 'date': {
      return {
        displayFormatter: dateFormatter,
        value: parseDateTimeFromString(value),
        areEqual: areDatesEqual,
        inputType: 'date',
      };
    }
    case 'time': {
      const {
        startTime = null,
        endTime = null,
        otStartTime = null,
        otEndTime = null,
        doubleOTStartTime = null,
        doubleOTEndTime = null,
        breakStartTime = null,
        breakEndTime = null,
      } = task;
      return {
        displayFormatter: timeFormatter,
        inputType: 'time',
        areEqual: areTimesEqual,
        property: [
          'startTime', 'endTime',
          'otStartTime', 'otEndTime',
          'doubleOTStartTime', 'doubleOTEndTime',
          'breakStartTime', 'breakEndTime'],
        value: {
          startTime,
          endTime,
          otStartTime,
          otEndTime,
          doubleOTStartTime,
          doubleOTEndTime,
          breakStartTime,
          breakEndTime,
        },
      };
    }
    case 'project': {
      return {
        displayFormatter: ({ task: data }) => defaultFormatter({ task: data, property: 'project' }),
        getSelectOptions: (props) => getProjectOptions({
          renderLabel: (project) => `${project.name} - ${project.number}`,
          ...props,
        }),
        inputType: 'select',
        property: 'projectId',
      };
    }
    case 'costcode': {
      return {
        displayFormatter: costCodeFormatter,
        getSelectOptions: getCostcodeOptions,
        inputType: 'select',
        property: 'costcodeId',
      };
    }
    case 'phase': {
      return {
        displayFormatter: phaseFormatter,
        getSelectOptions: getPhaseOptions,
        inputType: 'select',
        property: 'phaseId',
      };
    }
    case 'dropdown':
    case 'yes-no':
    case 'type':
      return {
        inputType: 'select',
      };
    case 'hourBased': {
      return {
        displayFormatter: ({ value: val }) => (val ? 'True' : 'False'),
        getSelectOptions: getHourBasedOptions,
        inputType: 'select',
      };
    }
    default: {
      return {
        displayFormatter: defaultFormatter,
        areEqual: simpleEquality,
        getSelectOptions: () => [],
        value,
        inputType: 'text',
        property: type,
      };
    }
  }
};

export const getColumnWidth = (type) => {
  switch (type) {
    case 'date': return 105;
    case 'type': return 80;
    case 'phase':
    case 'costcode':
    case 'project': return 150;
    case 'time': return 300;
    case 'enteredVia': return 200;
    default: return 100;
  }
};

/**
 * Converts both Pascal & Kebab-Case text to camelCase
 *
 * Pascal Example: 'SubContracts' would return 'subContracts'
 *
 * Kebab Example: 'Sub-Contracts' would return 'subContracts'
 * @param {string} str
 * @returns {str}
 */
export const convertPascalCaseToCamelCase = (str) => {
  // Remove hyphens
  const parsedStr = str.replace(/-/g, '');

  // Split the string based on capital letters
  const words = parsedStr.match(/[A-Z][a-z]*/g);

  // Convert the first word to lowercase
  words[0] = words[0].toLowerCase();

  // Combine the words back, with the first letter of subsequent words capitalized
  const camelCase = words.join('');

  return camelCase;
};

const getRelevantDropdownValueId = (type) => {
  let camelCaseName = convertPascalCaseToCamelCase(type);

  if (camelCaseName.endsWith('s')) {
    camelCaseName = camelCaseName.slice(0, -1);
  }

  return `${camelCaseName}Id`;
};

export const getRelevantDropdownValues = (customData) => {
  if (!customData) {
    return {};
  }
  const valueMap = {};
  const dropdownFields = Object.values(customData)
    .filter(({ type, values }) => (
      type === 'dropdown'
      && (values && Array.isArray(values))
    ));

  dropdownFields.forEach(({ dataType = '', values = [] } = {}) => {
    if (dataType && values) {
      const [{ id: value = null } = {}] = values;
      const id = getRelevantDropdownValueId(dataType);

      valueMap[id] = value;
    }
  });

  return valueMap;
};

export const parseCustomDataFromTemplate = (response = {}, template = {}) => {
  const customData = {};

  Object.entries(template).forEach(([id, field]) => {
    customData[id] = {
      ...field,
      ...(response[id] ?? {}),
    };
  });

  return customData;
};
