import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Timeline } from 'antd';
import {
  CheckCircleOutlined,
  CoffeeOutlined,
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  LoginOutlined,
  LogoutOutlined,
  PlusCircleOutlined,
  ToolOutlined,
  UserSwitchOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { FormHelpers } from 'ontraccr-common';

import { getIdMap, toTitleCase } from '../../helpers/helpers';
import Step from '../../common/containers/TimelineStep';
import { parseMetadata } from '../../forms/CompletedForms/FormTimeline';

export default function TimeCardTimeline({
  entries = [],
}) {
  const users = useSelector((state) => state.users.users);
  const customers = useSelector((state) => state.customers.customers);
  const projects = useSelector((state) => state.projects.projects);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);

  const addCountToTitle = useCallback((title, count) => {
    if (count) {
      return `${title} (${count})`;
    }

    return title;
  }, []);

  const generateFormTimelineData = useCallback((type, metadata) => {
    const {
      formNumber,
      formName,
      submitter,
      projects: linkedProjects = [],
      cardName,
      customerId,
      count,
    } = metadata;

    const projectNames = linkedProjects?.map((projectId) => projectMap[projectId]?.name).filter(Boolean).join(', ') ?? '';
    const {
      [customerId]: {
        name: customerName,
      } = {},
    } = customers;

    const user = userMap[submitter]?.name || 'Unknown User';
    const formattedName = FormHelpers.formatFormName({
      number: formNumber,
      templateName: formName,
    });

    const relevantCount = count || 1;

    const prefix = type === 'formCreated' ? 'crea' : 'upda';

    const title = `Timecard ${toTitleCase(prefix)}ted from Form`;
    const text = `${user} submitted ${formattedName} ${prefix}ting ${relevantCount} ${relevantCount === 1 ? 'entry' : 'entries'}`;
    const children = (projectNames.length || customerName || cardName) ? (
      <div style={{ fontSize: 12 }}>
        <strong>Form Details</strong>
        <ul>
          {!!projectNames.length && (
            <li>
              <strong>Projects: </strong>
              {projectNames}
            </li>
          )}
          {!!customerName && (
            <li>
              <strong>Customer: </strong>
              {customerName}
            </li>
          )}
          {!!cardName && (
            <li>
              <strong>Card: </strong>
              {cardName}
            </li>
          )}
        </ul>
      </div>
    ) : null;

    return {
      title,
      text,
      children,
    };
  }, [userMap, customers, projectMap]);

  const parseStep = useCallback((step) => {
    const userText = userMap[step.updatedByUserId]?.name || 'Unknown User';
    const count = step.count || 0;

    const metadata = parseMetadata(step.metadata);

    switch (step.type) {
      case 'formCreated':
      case 'created': {
        let title = 'Timecard Created';
        let text = `${userText} created this timecard`;
        let children = null;

        if (step.type === 'formCreated') {
          const generatedData = generateFormTimelineData(step.type, {
            ...metadata,
            count,
          });

          title = generatedData.title;
          text = generatedData.text;
          children = generatedData.children;
        }

        return (
          <Step
            key={step.id}
            Icon={PlusCircleOutlined}
            title={addCountToTitle(title, count)}
            text={text}
            timestamp={step.timestamp}
          >
            {children}
          </Step>
        );
      }
      case 'formUpdated':
      case 'updated': {
        let title = 'Timecard Updated';
        let text = `${userText} updated this timecard`;
        let children = null;

        if (step.type === 'formUpdated') {
          const generatedData = generateFormTimelineData(step.type, {
            ...metadata,
            count,
          });

          title = generatedData.title;
          text = generatedData.text;
          children = generatedData.children;
        }

        return (
          <Step
            key={step.id}
            Icon={EditOutlined}
            title={addCountToTitle(title, count)}
            text={text}
            timestamp={step.timestamp}
          >
            {children}
          </Step>
        );
      }
      case 'deleted':
        return (
          <Step
            key={step.id}
            Icon={DeleteOutlined}
            title={addCountToTitle('Timecard Deleted', count)}
            text={`${userText} deleted this timecard`}
            timestamp={step.timestamp}
          />
        );
      case 'submitted':
        return (
          <Step
            key={step.id}
            Icon={FormOutlined}
            title={addCountToTitle('Timecard Submitted', count)}
            text={`${userText} submitted this timecard`}
            timestamp={step.timestamp}
          />
        );
      case 'approved':
        return (
          <Step
            key={step.id}
            color="green"
            Icon={CheckCircleOutlined}
            title={addCountToTitle('Timecard Approved', count)}
            text={`${userText} approved this timecard`}
            timestamp={step.timestamp}
          />
        );
      case 'clockedIn':
        return (
          <Step
            key={step.id}
            Icon={LoginOutlined}
            title={addCountToTitle('Clocked In', count)}
            text={`${userText} clocked in`}
            timestamp={step.timestamp}
          />
        );
      case 'clockedOut':
        return (
          <Step
            key={step.id}
            Icon={LogoutOutlined}
            title={addCountToTitle('Clocked Out', count)}
            text={`${userText} clocked out`}
            timestamp={step.timestamp}
          />
        );
      case 'breakStart':
        return (
          <Step
            key={step.id}
            Icon={CoffeeOutlined}
            title={addCountToTitle('Break Started', count)}
            text={`${userText} started a break`}
            timestamp={step.timestamp}
          />
        );
      case 'breakEnd':
        return (
          <Step
            key={step.id}
            Icon={ToolOutlined}
            title={addCountToTitle('Break Ended', count)}
            text={`${userText} ended a break`}
            timestamp={step.timestamp}
          />
        );
      case 'workAfterBreak':
        return (
          <Step
            key={step.id}
            Icon={LoginOutlined}
            title={addCountToTitle('Work After Break', count)}
            text={`${userText} clocked in`}
            timestamp={step.timestamp}
          />
        );
      case 'serviceAfterBreak':
        return (
          <Step
            key={step.id}
            Icon={LoginOutlined}
            title={addCountToTitle('Service After Break', count)}
            text={`${userText} clocked in`}
            timestamp={step.timestamp}
          />
        );
      case 'clockedInSwitch':
        return (
          <Step
            key={step.id}
            Icon={UserSwitchOutlined}
            title={addCountToTitle('Shift Switch Clock In', count)}
            text={`${userText} clocked in`}
            timestamp={step.timestamp}
          />
        );
      case 'clockedOutSwitch':
        return (
          <Step
            key={step.id}
            Icon={UserSwitchOutlined}
            title={addCountToTitle('Shift Switch Clock Out', count)}
            text={`${userText} clocked out`}
            timestamp={step.timestamp}
          />
        );
      case 'createdOffline':
        return (
          <Step
            key={step.id}
            Icon={PlusCircleOutlined}
            title={addCountToTitle('Timecard Created Offline', count)}
            text={`${userText} created this timecard offline`}
            timestamp={step.timestamp}
          />
        );
      case 'updatedOffline':
        return (
          <Step
            key={step.id}
            Icon={EditOutlined}
            title={addCountToTitle('Timecard Updated Offline', count)}
            text={`${userText} updated this timecard offline`}
            timestamp={step.timestamp}
          />
        );
      case 'rejected':
        return (
          <Step
            key={step.id}
            color="red"
            Icon={CloseCircleOutlined}
            title={addCountToTitle('Timecard Rejected', count)}
            text={`${userText} rejected this timecard`}
            timestamp={step.timestamp}
            note={step.note}
          />
        );
      default:
        return null;
    }
  }, [userMap, addCountToTitle, generateFormTimelineData]);

  return (
    <div>
      <Timeline>
        {entries.map(parseStep)}
      </Timeline>
    </div>
  );
}

TimeCardTimeline.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    updatedByUserId: PropTypes.string.isRequired,
    count: PropTypes.number,
  })),
};

TimeCardTimeline.defaultProps = {
  entries: [],
};
